<template>
  <portal
    to="modal"
    :disabled="disablePortal"
    slim
  >
    <transition-container
      @show="handleModalShow"
      @hide="handleModalHide"
    >
      <div
        v-if="show"
        ref="modalBackdrop"
        v-shortkey="{ esc: ['esc'] }"
        class="modal-backdrop"
        :class="{ centered }"
        :style="{ '--z-index': zIndex }"
        @shortkey="handleShortkeyAction"
        @mousedown="handleMouseDown"
      >
        <div
          class="modal-container"
          :class="[size && `modal-${size}`, { 'with-navigation': navigation }]"
          :style="{ maxWidth: `${width}px` }"
          @mousedown.stop
        >
          <gl-icon-button
            v-if="navigation"
            :size="navIconSize"
            :disabled="prevDisabled"
            type="black"
            icon-name="left-arrow-large"
            class="modal-container__nav-button prev"
            @click="$emit('prev')"
          />
          <slot></slot>
          <gl-icon-button
            v-if="navigation"
            :size="navIconSize"
            :disabled="nextDisabled"
            type="black"
            icon-name="right-arrow-large"
            class="modal-container__nav-button next"
            @click="$emit('next')"
          />
        </div>
      </div>
    </transition-container>
  </portal>
</template>

<script>
  import Vue from 'vue';
  import VueShortkey from 'vue-shortkey';

  import _includes from 'lodash/includes';

  import toggleBodyScroll from '@/services/modal/bodyScroll';

  import GlIconButton from '../buttons/IconButton.vue';
  import TransitionContainer from './TransitionContainer.vue';

  Vue.use(VueShortkey, { prevent: ['input', 'textarea'] });

  export default {
    components: {
      TransitionContainer,
      GlIconButton,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: null,
        validator(value) {
          return _includes(['3xl', 'xxl', 'xl', 'l', 'm', 's', 'xxs', 'xs', 'sm'], value);
        },
      },
      centered: {
        type: Boolean,
        default: null,
      },
      isOnIconCloseOnly: {
        type: Boolean,
        default: false,
      },
      disablePortal: {
        type: Boolean,
        default: false,
      },
      navigation: {
        type: Boolean,
        default: false,
      },
      zIndex: {
        type: Number,
        default: 999,
      },
      prevDisabled: {
        type: Boolean,
        default: false,
      },
      nextDisabled: {
        type: Boolean,
        default: false,
      },
      width: {
        type: Number,
        default: null,
      },
    },
    computed: {
      navIconSize() {
        if (!this.$screenSize.desktop) {
          return 40;
        }

        return 48;
      },
    },
    methods: {
      handleMouseDown() {
        if (!this.isOnIconCloseOnly) {
          this.$emit('close');
        }
      },
      handleModalShow() {
        this.$emit('show');
        toggleBodyScroll(true);
      },
      handleModalHide() {
        this.$emit('hide');
        toggleBodyScroll(false);
      },
      handleShortkeyAction(event) {
        const key = event.srcKey;

        if (key === 'esc') {
          this.$emit('close');
        }
      },
    },
  };
</script>

<style lang="postcss" scoped>
.modal-backdrop {
  @apply fixed top-0 left-0 w-full h-full;
  z-index: var(--z-index);
  background: rgba(36, 38, 46, 0.8);
  transition: opacity 0.3s ease;
  overflow: hidden;

  &.centered {
    @apply flex items-center;
  }
}

.modal-container {
  @apply relative;

  --container-margin-y: 60px;
  --container-margin-x: 10px;

  max-width: 985px;
  min-width: 320px;
  width: calc(100% - 2 * var(--container-margin-x));
  margin: var(--container-margin-y) auto;
  transition: transform 0.3s ease;

  &.with-navigation {
    @apply flex items-center justify-center;
    gap: 12px;
  }

  &.modal-3xl {
    max-width: 1400px;
    width: calc(100% - 2 * var(--container-margin-x));
  }

  &.modal-xxl {
    max-width: 1100px;
    width: calc(100% - 2 * var(--container-margin-x));
  }

  &.modal-xxl {
    max-width: 1100px;
    width: calc(100% - 2 * var(--container-margin-x));
  }
  &.modal-xl {
    max-width: 960px;
    width: calc(100% - 2 * var(--container-margin-x));
  }

  &.modal-l {
    max-width: 816px;
    width: calc(100% - 2 * var(--container-margin-x));
  }

  &.modal-m {
    max-width: 640px;
    width: calc(100% - 2 * var(--container-margin-x));
  }

  &.modal-s {
    max-width: 440px;
  }

  &.modal-xs {
    max-width: 610px;
    width: calc(100% - 2 * var(--container-margin-x));
  }

  &.modal-sm {
    max-width: 710px;
    width: calc(100% - 2 * var(--container-margin-x));
  }

  &.modal-xxs {
    max-width: 460px;
  }
}

@media (max-width: 1023px) {
  .modal-container {
    &__nav-button {
      position: absolute;
      top: -48px;

      &.prev {
        left: 0;
      }
      &.next {
        left: 48px;
      }
    }
  }
}

@media (min-width: 576px) {
  .modal-container.modal-xxs {
    width: calc(100% - 2 * var(--container-margin-x));
  }
}
</style>
