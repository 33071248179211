<script>
  import _last from 'lodash/last';
  import _head from 'lodash/head';
  import _uniqueId from 'lodash/uniqueId';
  import _find from 'lodash/find';
  import _map from 'lodash/map';
  import _includes from 'lodash/includes';

  function getPageObject(number) {
    return {
      type: 'page',
      value: number,
      id: _uniqueId(`page_${number}_`),
    };
  }

  function getDividerObject() {
    return {
      type: 'divider',
      value: '...',
      id: _uniqueId('divider_'),
    };
  }

  function get7PageButtons(allPages, currentPage) {
    let pages = allPages;
    const firstFive = allPages.slice(0, 5);
    const lastFive = allPages.slice(-5);
    const firstFour = allPages.slice(0, 4);
    const lastFour = allPages.slice(-4);

    if (_find(firstFour, { value: currentPage })) {
      pages = [...firstFive, getDividerObject(), _last(allPages)];
    } else if (_find(lastFour, { value: currentPage })) {
      pages = [_head(allPages), getDividerObject(), ...lastFive];
    } else {
      pages = [_head(allPages), getDividerObject(), getPageObject(currentPage - 1), getPageObject(currentPage), getPageObject(currentPage + 1), getDividerObject(), _last(allPages)];
    }
    return pages;
  }

  function get5PageButtons(allPages, currentPage) {
    let pages = allPages;
    const firstThreePages = allPages.slice(0, 3);
    const lastThreePages = allPages.slice(-3);

    if (_find(firstThreePages, { value: currentPage })) {
      pages = [...firstThreePages, getDividerObject(), _last(allPages)];
    } else if (_find(lastThreePages, { value: currentPage })) {
      pages = [_head(allPages), getDividerObject(), ...lastThreePages];
    } else {
      pages = [_head(allPages), getDividerObject(), getPageObject(currentPage), getDividerObject(), _last(allPages)];
    }

    return pages;
  }

  export default {
    props: {
      maxShownNumbers: {
        type: Number,
        default: 7,
        validate: val => _includes([7, 5], val),
      },
      page: {
        type: Number,
        default: 1,
      },
      pagesCount: {
        type: Number,
        required: true,
      },
    },
    computed: {
      allPages() {
        const allPages = [];
        for (let i = 1; i <= this.pagesCount; i++) {
          allPages.push(i);
        }
        return allPages;
      },
      pageValues() {
        const allPages = _map(this.allPages, num => getPageObject(num));

        if (allPages.length <= this.maxShownNumbers) {
          return allPages;
        }

        if (this.maxShownNumbers === 5) {
          return get5PageButtons(allPages, this.page);
        }

        return get7PageButtons(allPages, this.page);
      },
    },
    methods: {
      getPageObject(number) {
        return {
          type: 'page',
          value: number,
          id: _uniqueId(`page_${number}_`),
        };
      },
      getDividerObject() {
        return {
          type: 'divider',
          value: '...',
          id: _uniqueId('divider_'),
        };
      },
    },
    render() {
      return this.$scopedSlots.default({
        pageValues: this.pageValues,
        allPages: this.allPages,
      });
    },
  };
</script>
