<template>
  <navigation-list>
    <navigation-option
      v-if="!isClient && isAuthorized"
      :link="stateRoutes.agencies"
      :count="companiesCount"
      label="Agencies"
      description="View a list and access your agencies here."
      color="#F7F8FE"
    >
      <template #icon>
        <agencies-icon />
      </template>
    </navigation-option>
    <navigation-option
      v-if="isReferralsEnabled"
      :link="stateRoutes.affiliateProgram"
      label="Affiliate Program"
      description="Be a part of the Great Freelance Affiliate Gold Rush!"
      color="#FFF6FA"
    >
      <template #icon>
        <referrals-icon />
      </template>
    </navigation-option>
    <navigation-option
      v-if="isClient && isAuthorized"
      :link="stateRoutes.companies"
      :count="companiesCount"
      label="Companies"
      description="View a list and access your companies here."
      color="#FFF8F1"
    >
      <template #icon>
        <companies-icon />
      </template>
    </navigation-option>
    <navigation-option
      :url="stateLinks.knowledgeBase"
      target="_blank"
      label="Help Center"
      description="Guides and articles explaining goLance and our features!"
      color="#F4FCF9"
    >
      <template #icon>
        <knowlege-base-icon />
      </template>
    </navigation-option>

    <navigation-option
      :url="stateLinks.contactUs"
      target="_blank"
      label="Contact Us"
      description="Reach out to us!"
      color="#F4F5FB"
    >
      <template #icon>
        <contact-us-icon />
      </template>
    </navigation-option>
    <navigation-option
      :url="stateLinks.blog"
      target="_blank"
      label="Blog"
      description="We have a library of relevant articles to read from!"
      color="#F0F9FE"
    >
      <template #icon>
        <blog-icon />
      </template>
    </navigation-option>
  </navigation-list>
</template>

<script>
  import _get from 'lodash/get';
  import _filter from 'lodash/filter';

  import { mapGetters } from 'vuex';

  import config from '@/config';

  import NavigationOption from './NavigationOption.vue';
  import NavigationList from './NavigationList.vue';

  import AgenciesIcon from './icons/AgenciesIcon.vue';
  import CompaniesIcon from './icons/CompaniesIcon.vue';
  import KnowlegeBaseIcon from './icons/KnowlegeBaseIcon.vue';
  import ContactUsIcon from './icons/ContactUsIcon.vue';
  import BlogIcon from './icons/BlogIcon.vue';
  import ReferralsIcon from './icons/ReferralsIcon.vue';

  export default {
    components: {
      NavigationOption,
      NavigationList,
      KnowlegeBaseIcon,
      ContactUsIcon,
      BlogIcon,
      ReferralsIcon,
      AgenciesIcon,
      CompaniesIcon,
    },
    data() {
      return {
        stateLinks: {
          knowledgeBase: config.helpCenterUrl,
          contactUs: `${config.landingUrl}/contact-us`,
          blog: 'https://blog.golance.com',
        },
        stateRoutes: {
          agencies: { name: 'agencies' },
          companies: { name: 'companies' },
          affiliateProgram: { name: 'affiliate-program' },
        },
      };
    },
    computed: {
      ...mapGetters(['currentUser', 'permissions', 'isAuthorized']),
      isReferralsEnabled() {
        return this.currentUser.referralSettings?.enabled;
      },
      isClient() {
        return this.permissions.isEmployer;
      },
      companiesCount() {
        const companies = _get(this.currentUser, 'companies', []);
        const filteredCompanies = _filter(companies, company => company.isDeleted !== true);

        return filteredCompanies.length;
      },
    },
  };
</script>
