<template>
  <gl-icon-button
    :type="type"
    icon-name="menu-dots-vertical"
    :active="active"
    :size="size"
    :disabled="disabled"
  />
</template>

<script>
  import _includes from 'lodash/includes';

  import GlIconButton from 'uikit/components/buttons/IconButton.vue';

  export default {
    components: {
      GlIconButton,
    },
    props: {
      active: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'default',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      size: {
        type: Number,
        default: 40,
        validator: val => _includes([16, 20, 24, 40, 48], val),
      },
    },
  };
</script>
