<template>
  <div class="thread-user-avatar">
    <user-avatar
      :user="user || {}"
      :size="size"
      :show-online-indicator="!isCounterShown"
      class="thread-user-avatar__picture"
    />
    <div
      v-if="isCounterShown"
      class="thread-user-avatar__counter"
      :title="counter"
    >
      +{{ counter }}
    </div>
  </div>
</template>

<script>
  import fullName from '@/filters/fullName';
  import avatarUrl from '@/filters/avatarUrl';

  import UserAvatar from '@/components/Profile/ProfileInfoUserAvatar.vue';

  export default {
    filters: {
      fullName,
      avatarUrl,
    },
    components: {
      UserAvatar,
    },
    props: {
      user: {
        type: Object,
        default: () => ({}),
      },
      size: {
        type: String,
        default: '44',
      },
      counter: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      isCounterShown() {
        return this.counter > 0;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .thread-user-avatar {
    @apply flex;
    position: relative;
  }

  .thread-user-avatar__counter {
    @apply flex items-center justify-center absolute text-royal-blue text-12 font-medium bg-foundation-gray-2 border-2 border-white;
    right: -9px;
    bottom: -2px;
    min-width: 24px;
    height: 24px;
    padding: 3px;
    line-height: 150%;
    letter-spacing: 0.048px;
    border-radius: 24px;
  }
</style>
