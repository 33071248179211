<template>
  <div
    v-if="isSitejabberFeedbackWidgetShown"
    class="sitejabber-widget"
  >
    <div id="InstantFeedbackWidget">
      <div
        id="InstantFeedbackWidgetInner"
        class="stjr-widget"
      ></div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  const showFeedbackModal = user => {
    // eslint-disable-next-line no-undef
    new STJR.InstantFeedbackWidget({
      id: 'InstantFeedbackWidget',
      url: 'golance.com',
      language: 'en',
      user: { first_name: user.firstName, last_name: user.lastName, email: user.email },
      order_id: user._id,
    }).render();
  };

  export default {
    computed: {
      ...mapGetters(['currentUser', 'isSitejabberFeedbackWidgetShown']),
    },

    watch: {
      isSitejabberFeedbackWidgetShown(isSitejabberFeedbackWidgetShown) {
        if (isSitejabberFeedbackWidgetShown === true) {
          if (!window.STJR) {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = 'https://biz.sitejabber.com/js/widget.min.1585586954.js';
            s.onload = () => showFeedbackModal(this.currentUser);

            const t = document.getElementsByTagName('script')[0];
            t.parentNode.insertBefore(s, t);
          } else {
            showFeedbackModal(this.currentUser);
          }
        }
      },
    },
  };
</script>

<style lang="postcss">
  @screen md {
    .sitejabber-widget {
      & .stjr-review-checkout-widget {
        margin-top: 150px;
      }
    }
  }
</style>
