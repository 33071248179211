<template>
  <div
    :class="[
      'input-block',
      {
        'invalid-date': !isValidDate,
      },
    ]"
  >
    <input
      v-model="date"
      type="text"
      @keyup.enter="submitDate(date)"
      @blur="submitDate(date)"
    >
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  const DEFAULT_FORMAT = 'MMM D, YYYY';

  export default {
    model: {
      event: 'select',
    },
    props: {
      value: {
        type: Date,
        default: new Date(),
      },
    },
    data() {
      return {
        date: '',
        isValidDate: true,
      };
    },
    watch: {
      value: {
        deep: true,
        handler(val) {
          this.formatDate(val);
          this.isValidDate = true;
        },
      },
    },
    created() {
      this.formatDate(this.value);
    },
    methods: {
      formatDate(val) {
        this.date = moment.utc(val).format(DEFAULT_FORMAT);
      },
      submitDate(val) {
        if (moment(val).isValid()) {
          this.isValidDate = true;
          this.$emit('enter', val);
        } else {
          this.isValidDate = false;
        }
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .input-block {
    align-self: flex-end;
    & input {
      @apply flex items-center justify-center bg-white font-primary font-normal text-14 text-text-black p-0 m-0 text-center border border-foundation-gray-4;
      outline: none;
      border-radius: 8px;
      padding: 9px 12px;
      width: 120px;
    }
    &.invalid-date {
      @apply relative;
      & input {
        @apply border-red-error;
      }
      &:after {
        @apply text-red-error absolute text-10;
        content: 'date is not correct';
        top: calc(100% + 1px);
        right: 0;
        z-index: 99;
      }
    }
  }
</style>
