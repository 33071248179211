<template>
  <span class="user-avatar">
    <gl-company-logo
      :url="url"
      :size="size"
      class="user-avatar__avatar"
    />
    <div
      v-if="isOnline"
      class="user-avatar__online-indicator"
      title="Online"
    ></div>
  </span>
</template>

<script>
  import fullName from '@/filters/fullName';
  import avatarUrl from '@/filters/avatarUrl';

  import userService from '@/services/api/users';

  import GlCompanyLogo from '@/components/CompanyLogo.vue';

  export default {
    filters: {
      fullName,
      avatarUrl,
    },
    components: {
      GlCompanyLogo,
    },
    props: {
      user: {
        type: Object,
        required: true,
      },
      size: {
        type: String,
        default: '20',
      },
      showOnlineIndicator: {
        type: Boolean,
        default: false,
      },
      counter: {
        type: Number,
        default: null,
      },
    },
    computed: {
      url() {
        return avatarUrl(this.user);
      },
      isOnline() {
        return this.showOnlineIndicator
          && this.user.lastActivity
          && userService.isUserOnline(this.user.lastActivity);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .user-avatar {
    @apply flex;
    position: relative;
  }

  .user-avatar__online-indicator {
    @apply bg-success-green;
    box-sizing: border-box;
    border: 2px solid white;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    bottom: 0;
    right: 0;
  }
</style>
