const defaultAvatarUrl = 'https://res.cloudinary.com/golance/image/upload/v1660221603/app_new_ui/user-profile/DefaultAvatar.png';
const defaultBackgroundImageUrl = 'https://res.cloudinary.com/golance/image/upload/v1665493937/app_new_ui/company-profile/default-background.png';

export { defaultAvatarUrl, defaultBackgroundImageUrl };

export const UserRoles = {
  Employer: 'employer',
  Freelancer: 'freelancer',
  Collaborator: 'collaborator',
};

export const UserVerificationStatuses = {
  Accepted: 'accepted',
  Verified: 'verified',
  Canceled: 'canceled',
  Pending: 'pending',
  UnderManualReview: 'under_manual_review',
};

export const UserLocationNames = {
  UNITED_STATES: 'United States',
  CANADA: 'Canada',
};

/**
 * User protection statuses.
 * @enum {string}
 */
export const UserProtectionStatuses = {
  TRUSTED: 'trusted',
};

export const UserStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};
