<template>
  <div
    :class="[type]"
    class="global-notification"
  >
    <div class="message-icon">
      <gl-base-icon
        v-if="!$slots.icon"
        :name="typeConfig.icon"
        :size="24"
      />
      <slot name="icon"></slot>
    </div>
    <div class="message-content">
      <slot></slot>
    </div>
    <div
      v-if="actionButtonLabel || isCloseIconShown"
      class="message-actions"
    >
      <gl-button
        v-if="actionButtonLabel"
        :variant="typeConfig.actionButton"
        @click="$emit('action-click')"
      >
        {{ actionButtonLabel }}
      </gl-button>
      <close-button
        v-if="isCloseIconShown"
        icon="close"
        class="message-close"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
  import _includes from 'lodash/includes';

  import { GlBaseIcon, GlButton } from 'uikit/components';

  import CloseButton from '@/components/buttons/CloseButton.vue';

  const CONFIG_BY_TYPE = {
    info: {
      icon: 'info-circle',
      actionButton: 'primary',
    },
    warning: {
      icon: 'exclamation-triangle',
      actionButton: 'warning',
    },
    danger: {
      icon: 'exclamation-octagon',
      actionButton: 'danger',
    },
  };

  export default {
    components: {
      GlBaseIcon,
      GlButton,
      CloseButton,
    },

    props: {
      type: {
        type: String,
        default: 'info',
        validator: val => _includes(['warning', 'danger', 'info'], val),
      },
      title: {
        type: String,
        default: null,
      },
      actionButtonLabel: {
        type: String,
        default: '',
      },
      isCloseIconShown: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      typeConfig() {
        return CONFIG_BY_TYPE[this.type];
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .global-notification {
    @apply flex font-primary text-text-black text-14 relative items-center shadow-popover-default;
    padding: 8px 16px;
    min-height: 68px;
    gap: 16px;

    &.warning {
      --message-icon-color: #ffa83a;
      background-color: #fefcf2;
    }

    &.danger {
      --message-icon-color: #dd2929;
      background-color: #fdf7f8;
    }

    &.info {
      @apply bg-foundation-gray-2;
      --message-icon-color: #475baf;
    }
  }
  .message-icon {
    @apply flex;
    color: var(--message-icon-color);
  }
  .message-content {
    flex-grow: 1;
    line-height: 20px;
  }

  .message-actions {
    @apply flex items-center;
    flex-shrink: 0;
    gap: 24px;
  }

  @media (max-width: 767px) {
    .global-notification {
      @apply flex-col items-center relative;
      gap: 8px;
      padding: 16px;
    }

    .message-content {
      margin-bottom: 8px;
      text-align: center;
    }

    .message-close {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }
</style>
