<template>
  <div class="filters-list">
    <filter-item
      v-for="(filter, index) in filtersConfigs"
      :key="index"
      :index="index"
      :selected-index="selectedIndex"
      :filter="filter"
      class="filters-list__button"
      @click="handleClick"
    />
  </div>
</template>

<script>
  import FilterItem from './FilterItem.vue';

  export default {
    components: {
      FilterItem,
    },
    props: {
      filtersConfigs: {
        type: Array,
        required: true,
      },
      selectedIndex: {
        type: Number,
        required: true,
      },
    },
    methods: {
      handleClick(value) {
        this.$emit('change', value);
      },
    },
  };
</script>

<style lang="postcss" scoped>
.filters-list {
  @apply flex flex-col bg-white;
  padding: 12px 14px;
  min-width: 230px;
  border-radius: 12px 0 0 12px;

  &__button {
    @apply my-1;
  }
}
</style>
