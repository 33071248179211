<template>
  <div class="wrapper">
    <div
      class="loader"
      :class="[variant]"
    >
      <div class="loader__item"></div>
      <div class="loader__item"></div>
      <div class="loader__item"></div>
      <div class="loader__item"></div>
      <div class="loader__item"></div>
    </div>
  </div>
</template>

<script>
  import _includes from 'lodash/includes';

  export default {
    props: {
      variant: {
        type: String,
        default: 'primary',
        validator(value) {
          return _includes(['primary', 'secondary', 'danger', 'success', 'danger-secondary'], value);
        },
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .wrapper {
    & .loader {
      @apply w-full h-full;
      position: relative;
      & .loader__item {
        float: left;
        height: 16px;
        margin-left: 5px;
        width: 6px;
        opacity: 0.1;

        transform: scale(0.8);
        animation-name: loading;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-direction: linear;
      }

      &.primary,
      &.success,
      &.danger {
        & .loader__item {
          @apply bg-white;
        }
      }

      &.danger-secondary {
        & .loader__item {
          @apply bg-persian-red;
        }
      }

      &.secondary {
        & .loader__item {
          @apply bg-steam-gray;
        }
      }

      & div:nth-child(1) {
        animation-delay: 0.24s;
      }

      & div:nth-child(2) {
        animation-delay: 0.48s;
      }

      & div:nth-child(3) {
        animation-delay: 0.72s;
      }

      & div:nth-child(4) {
        animation-delay: 0.96s;
      }

      & div:nth-child(5) {
        animation-delay: 1.2s;
      }
    }
  }

  @keyframes loading {
    0% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(0.7);
      opacity: 0.1;
    }
  }
</style>
