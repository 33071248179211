import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _some from 'lodash/some';
import _map from 'lodash/map';

import { memberRoles } from '@/constants/company';

import config from '../../config';

export default class Permissions {
  constructor(user) {
    this.user = user;
    this.exclusiveCompany = _find(this.user.companies, ({ exclusive }) => exclusive);
    this.isEmployer = _includes(this.user.type, 'employer');
    this.isFreelancer = _includes(this.user.type, 'freelancer');
    this.isExclusiveFreelancer = !!this.exclusiveCompany;

    this.isGoBoth = this.isEmployer && this.isFreelancer;
  }

  canManageJob(job) {
    const jobCompanyId = _get(job, 'company._id');

    if (jobCompanyId) {
      return this.isHiringManager(job.company, job.team);
    }
    return this._isJobCreator(job.user);
  }

  _isJobCreator(user) {
    return this.user._id === user?._id;
  }

  isHiringManager(company, team) {
    const teamId = team?._id;
    company = _find(this.user.companies, { _id: _get(company, '_id') || company });

    if (!company) {
      return false;
    }

    const isHiringManagerForTeam = _some(company.assignedTeams, { _id: teamId });

    return this._hasRole(company._id, 'hiring_manager') && (!company.teamManager || isHiringManagerForTeam || !teamId);
  }

  getAssignedTeams(company) {
    const userCompany = _find(this.user.companies, { _id: _get(company, '_id', company) });

    if (!this.isHiringManager(company)) {
      return [];
    }

    if (userCompany.teamManager) {
      return userCompany.assignedTeams;
    }

    return userCompany.teams;
  }

  getCompanyMemberRoles(companyId) {
    const company = this.getCompanyById(companyId);

    if (!company) {
      return null;
    }

    return _map(company.roles, 'value');
  }

  _hasRole(companyId, roleValue) {
    let targetCompany;

    if (this.exclusiveCompany) {
      if (this.exclusiveCompany._id !== companyId) {
        return false;
      }

      targetCompany = this.exclusiveCompany;
    } else {
      targetCompany = this.getCompanyById(companyId);
    }

    if (roleValue === memberRoles.CompanyOwner) {
      return targetCompany?.owner || false;
    }

    if (targetCompany) {
      return _some(targetCompany.roles, { value: roleValue });
    }

    return false;
  }

  getCompanyById(companyId) {
    return _find(this.user.companies, company => company._id === companyId || company.username === companyId);
  }

  getCompaniesByRole(roleValue) {
    return _filter(this.user.companies, company => this._hasRole(company._id, roleValue));
  }

  hasCompanyByRole(roleValue) {
    return _some(this.user.companies, company => this._hasRole(company._id, roleValue));
  }

  isSalesManager(company) {
    return this._hasRole(_get(company, '_id') || company, 'sales_manager');
  }

  isCompanyOwner(company) {
    const companyId = company._id ? company._id : company;
    const userCompany = this.getCompanyById(companyId);
    return !!(userCompany && userCompany.owner);
  }

  isCompanyMember(company) {
    const companyId = _get(company, '_id') || company;

    return !!this.getCompanyById(companyId);
  }

  isCompanyModerator(companyId) {
    return this._hasRole(companyId, 'company_moderator');
  }

  canManageActivityCodes(companyId) {
    const userCompany = this.getCompanyById(companyId);
    return this.isCompanyOwner(userCompany) || this._hasRole(userCompany._id, 'hiring_manager') || this.isCompanyModerator(userCompany._id);
  }

  isWithdrawalPMForbidden() {
    return _includes(config.disabledAddingWithdrawalPaymentMethodUserIds, this.user._id);
  }

  isOrderCardAllowedForCountry(country) {
    return _includes(config.debitCard.authorizedCountries, country);
  }

  canManageCompany(companyId) {
    return this.isCompanyModerator(companyId) || this.isSalesManager(companyId) || this.isHiringManager(companyId);
  }
}
