const Offer = () => import(/* webpackChunkName: "offer", webpackPrefetch: true */ '@/views/Offers/OfferPage');
const CreateOffer = () => import(/* webpackChunkName: "offers" */ '@/views/Offers/CreateOffer/index');

export default [
  {
    name: 'offer',
    path: '/offers/:offerId',
    component: Offer,
  },
  {
    name: 'create-offer',
    path: '/offers/new',
    component: CreateOffer,
  },
];
