<template>
  <ul
    ref="list"
    class="nav-menu__list"
  >
    <slot></slot>
  </ul>
</template>

<script>
  export default {
    data() {
      return {
        listChildren: 0,
      };
    },
    mounted () {
      this.$emit('children-count', this.$refs.list.children.length);
    },
  };
</script>

<style lang="postcss" scoped>
  .nav-menu__list {
    @apply flex flex-col items-stretch;
    padding-left: 0;
    gap: 8px;
  }
</style>
