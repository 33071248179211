import _isString from 'lodash/isString';
import _isArray from 'lodash/isArray';
import _get from 'lodash/get';

export default (text, options) => {
  let exceptTags;

  if (_get(options, 'except')) {
    const except = _get(options, 'except');
    if (_isString(except)) {
      exceptTags = [except];
    } else if (_isArray(except)) {
      exceptTags = except;
    }
  }

  let tagsRegExp;

  if (exceptTags) {
    const tags = exceptTags.join('|');
    tagsRegExp = new RegExp(`(<(?!/?(${tags}))[^>]+>)`, 'igm');
  } else {
    tagsRegExp = /(<[^>]+>)/igm;
  }

  return String(text)
    .replace(/<br\s*\/?>/mg, ' ')
    .replace(tagsRegExp, ' ')
    .replace(/\s\s+/g, ' ');
};

const pre = text => String(text).replace(/\n/gm, '<br />');

export { pre };
