export default {
  'proposal-created': 'New proposal',
  'proposal-declined': 'Proposal declined',
  'proposal-interview-created': 'Interview Request Received',
  'proposal-interview-cancelled': 'Interview Cancelled',
  'proposal-interview-confirmed': 'Interview Confirmed!',
  'proposal-interview-declined': 'Interview Declined',
  'proposal-interview-reminder-for-client': 'Interview is Coming!',
  'proposal-interview-reminder-for-freelancer': 'Interview is Coming!',
  'contract-title-changed': 'Title changed',
  'contract-rate-changed': 'Rate changed',
  'contract-weekly-limit-changed': 'Weekly limit changed',
  'contract-closed': 'Contract closed',
  'contract-paused': 'Contract paused',
  'contract-unpaused': 'Contract resumed',
  'contract-collaborator-added': 'Invitation to collaboration',
  'contract-collaborator-removed': 'Removal from collaboration',
  'contract-review-period-disabled': 'Review period has been disabled',
  'contract-review-period-changed': ' Review period duration changed',
  'service-contract-created-freelancer': 'New contract',
  'service-contract-created-client': 'Contract created',
  'feedback-completed': 'Feedback completed',
  'feedback-expired': 'Feedback expired',
  'offer-created': 'New Offer',
  'offer-accepted': 'Offer accepted',
  'offer-withdrawn': 'Offer withdrawn',
  'offer-declined': 'Offer declined',
  'user-invited-to-company': 'Company invitation',
  'company-invitation-accepted': 'Company invitation accepted',
  'company-invitation-declined': 'Company invitation declined',
  'referral-signed-up': 'Referral signed up',
  'milestone-funded': 'Milestone Funded',
  'milestone-paid': 'Milestone Approved',
  'milestone-released': 'Milestone Released',
  'milestone-work-submitted': 'Milestone Sent for Approval',
  'milestone-work-declined': 'Milestone Sent for Rework',
  'scheduled-payment-rule-created': 'Scheduled Payments added',
  'scheduled-payment-rule-updated': 'Scheduled Payments updated',
  'scheduled-payment-rule-deleted': 'Scheduled Payments deleted',
  'scheduled-payment-updated': 'Scheduled Payment updated',
  'scheduled-payment-deleted': 'Scheduled Payment deleted',
  'scheduled-payment-is-tomorrow': 'Next Scheduled Payment is tomorrow',
  'scheduled-payment-charge-succeeded': 'Next Scheduled Payment charged',
  'scheduled-payment-release-succeeded': 'Next Scheduled Payment released',
  'milestone-added': 'Milestone Added',
  'milestone-updated': 'Milestone Updated',
  'milestone-deleted': 'Milestone Deleted',
  'funds-load-succeeded': 'Funds loaded',
  'funds-unload-succeeded': 'Funds unloaded',
  bonus: 'Bonus',
  'peer-to-peer': 'Payment',
  'refund-succeeded': 'Refund',
  'dispute-created': 'Dispute created',
  'dispute-status-changed': 'Dispute status changed',
  'dispute-solver-assigned': 'Dispute solver assigned',
  custom: 'Notification',
  'proposal-withdrawn': 'Proposal withdrawn',
  'invitation-declined': 'Invitation declined',
  'payment-method-added': 'Payment method added',
  'payment-method-deleted': 'Payment method deleted',
  'payment-method-status-changed': 'Payment method status changed',
  'virtual-card-added': 'Virtual card added',
  'manual-invoice-created': 'New invoice',
  'manual-invoice-paid': 'Invoice paid',
  'job-closing-soon': 'Job post closure',
  'job-closed-by-system': 'Job post closure',
  'job-posted': 'Job status changed',
  'debit-card-negative-balance': 'Debit card has negative balance',
  'payment-request-cancelled': 'Payment Request',
  'payment-request-paid': 'Payment Request',
  'payment-request-created': 'Payment Request',
  'invoice-batch-pdf-created': 'Invoices Ready!',
  'receipt-batch-pdf-created': 'Receipts Ready!',
  'invoice-receipt-batch-pdf-created': 'Invoices and Receipts Ready!',
  'freelancer-invoice-batch-pdf-created': 'Invoices Ready!',
  'contract-feedback-reminder': 'Rate your experience',
};
