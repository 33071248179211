<template>
  <div class="container">
    <div
      class="image-container"
      @touchstart.prevent.stop
    >
      <img
        ref="image"
        :src="imageDataUrl"
        class="image-cropper"
      >
    </div>
    <div class="toolbar">
      <div
        v-if="zoomable"
        class="toolbar__item"
      >
        <image-icon @click.native="handleDecreaseRatioClick" />

        <gl-value-slider
          v-model="scale"
          :min="minScale"
          :max="maxScale"
          :interval="ratioInterval"
          class="slider"
        />

        <image-icon
          class="scale__image-increase"
          @click.native="handleIncreaseRatioClick"
        />
      </div>
      <div
        class="toolbar__item"
        @click="handleChangeImage"
      >
        <refresh-icon />
        Change Image
      </div>
      <div
        v-if="rotatable"
        class="toolbar__item"
        @click="handleChangeRotation"
      >
        <rotate-icon />
        Rotate
      </div>
    </div>
  </div>
</template>

<script>
  import _round from 'lodash/round';
  import _omit from 'lodash/omit';

  import Cropper from 'cropperjs';

  import GlValueSlider from 'uikit/components/ValueSlider/ValueSlider.vue';

  import RotateIcon from './RotateIcon.vue';
  import ImageIcon from './ImageIcon.vue';
  import RefreshIcon from './RefreshIcon.vue';

  export default {
    components: {
      GlValueSlider,
      RefreshIcon,
      ImageIcon,
      RotateIcon,
    },
    props: {
      imageDataUrl: {
        type: String,
        default: null,
      },
      options: {
        type: Object,
        default: () => {},
      },
      zoomable: {
        type: Boolean,
        default: false,
      },
      rotatable: {
        type: Boolean,
        default: true,
      },
      minCropBoxSize: {
        type: Number,
        default: 20,
      },
    },

    data() {
      return {
        imageData: null,
        cropper: null,
        maxScale: 2,
        minScale: 1,
        scale: 1,
        ratioInterval: 0.1,
      };
    },
    watch: {
      scale(val) {
        this.cropper.scale(val);
      },
    },

    mounted() {
      this.cropper = new Cropper(this.$refs.image, {
        zoomOnWheel: false,
        viewMode: 2,
        zoomable: this.zoomable,
        checkOrientation: false,
        rotatable: this.rotatable,
        minCropBoxWidth: this.minCropBoxSize,
        minCropBoxHeight: this.minCropBoxSize,

        zoom: event => {
          const imageData = this.cropper.getImageData();

          if (event.detail.originalEvent.wheelDelta > 0 && imageData.width / imageData.naturalWidth >= this.maxScale) {
            event.preventDefault();
          }
        },
        crop: event => {
          const {
            x, y, height, width, rotate,
          } = event.detail;

          this.$emit('update-dimensions', {
            x,
            y,
            height,
            width,
            rotate,
          });
        },

        ready: () => {
          const cropOptions = this.options;

          if (cropOptions.width && cropOptions.height) {
            const containerData = this.cropper.getContainerData();

            this.cropper.setCropBoxData({
              ...cropOptions,
              left: containerData.width / 2 - cropOptions.width / 2,
              top: containerData.height / 2 - cropOptions.height / 2,
            });
          }
        },

        ..._omit(this.options, 'cropOptions'),
      });
    },
    beforeDestroy() {
      this.cropper.destroy();
      this.cropper = null;
    },
    methods: {
      handleChangeImage() {
        this.$emit('change');
      },
      handleChangeRotation() {
        this.cropper.rotate(90);
      },
      handleDecreaseRatioClick() {
        if (this.scale > this.minScale) {
          this.scale = _round(this.scale - this.ratioInterval, 1);
        }
      },
      handleIncreaseRatioClick() {
        if (this.scale < this.maxScale) {
          this.scale = _round(this.scale + this.ratioInterval, 1);
        }
      },
      getCroppedImageDataUrl() {
        return this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      },
    },
  };
</script>

<style lang="postcss" scoped>
.container {
  @apply relative;
  max-height: 100%;
  max-width: 100%;

  & .image-container{
    max-height: 100%;
    min-height: 52px;

    & .image-cropper {
      @apply w-full;
    }
  }

  & >>> .cropper-view-box {
    outline: none;
  }

  & >>> .cropper-dashed {
    @apply text-white;
    opacity: 1;
  }

  & >>> .cropper-line,
  & >>> .cropper-point {
    background-color: transparent;
  }

  & >>> .cropper-center {
    opacity: 0;
  }

  & .toolbar {
    @apply absolute flex w-full justify-center;
    box-shadow: 0px 1px 20px -8px rgba(24, 39, 75, 0.14), 0px 10px 24px -6px rgba(24, 39, 75, 0.1);
    background: rgba(36, 38, 46, 0.4);
    gap: 24px;
    bottom: 0px;
    height: 52px;

    @media (max-width: 550px) {
      gap: 12px;
    }

    & .slider {
      min-width: 200px;
      max-width: 200px;
      margin-left: 10px;

      @media (max-width: 550px) {
        min-width: 120px;
      }

      @media (max-width: 400px) {
        min-width: 70px;
      }
    }

    & .scale__image-increase {
      width: 30px;
      height: 24px;
    }

    & .toolbar__item {
      @apply flex items-center text-white cursor-pointer;

      transition: 0.3s ease all;
      gap: 10px;
      font-weight: 500;
      letter-spacing: 0.0125em;

      &:hover {
        @apply text-foundation-gray-4;
      }

      & >>> svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}
</style>
