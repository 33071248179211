<template>
  <vue-simple-suggest
    ref="simpleSuggest"
    v-model="valueModel"
    :list="getSuggestions"
    :value-attribute="valueAttribute"
    :display-attribute="displayAttribute"
    :nullable-select="nullableSelect"
    :prevent-hide="preventHide"
    :max-suggestions="maxSuggestions"
    :filter-by-query="filterByQuery"
    :mode="mode"
    :remove-list="!!selected"
    class="combobox"
    :class="{ 'fixed-dropdown': fixedDropdown, overflowed }"
    :style="fixedDropdownStyleVariables"
    @input="$emit('input', $event)"
    @select="handleSelect"
    @show-list="handleShowList"
    @hide-list="handleHideList"
  >
    <gl-input
      v-model="valueModel"
      :placeholder="placeholder"
      :error="error"
    >
      <template #icon>
        <slot name="icon"></slot>
      </template>
    </gl-input>
    <template
      v-if="$scopedSlots['suggestion-item']"
      #suggestion-item="{ suggestion, query }"
    >
      <slot
        name="suggestion-item"
        :suggestion="suggestion"
        :query="query"
      ></slot>
    </template>
  </vue-simple-suggest>
</template>

<script>
  import _includes from 'lodash/includes';
  import VueSimpleSuggest from 'vue-simple-suggest';

  import GlInput from 'uikit/components/inputs/Input.vue';

  export default {
    components: {
      VueSimpleSuggest,
      GlInput,
    },
    props: {
      value: {
        type: String,
        default: '',
      },
      selected: {
        type: Object,
        default: null,
      },
      placehoder: {
        type: String,
        default: '',
      },
      displayAttribute: {
        type: String,
        default: 'name',
      },
      valueAttribute: {
        type: String,
        default: '_id',
      },
      mode: {
        type: String,
        default: 'input',
        validator: val => _includes(['input', 'select'], val),
      },
      nullableSelect: {
        type: Boolean,
        default: false,
      },
      preventHide: {
        type: Boolean,
        default: false,
      },
      maxSuggestions: {
        type: Number,
        default: 10,
      },
      filterByQuery: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: null,
      },
      list: {
        type: Array,
        default: () => [],
      },
      fixedDropdown: {
        type: Boolean,
        default: false,
      },
      scrollableParentSelector: {
        type: String,
        default: 'body',
      },
      maxHeight: {
        type: Number,
        default: 300,
      },
      error: {
        type: [String, Boolean],
        default: false,
      },
    },
    data() {
      return {
        scrollableParent: null,
        resizableParent: null,
        resizeObserver: null,
        overflowed: false,
        fixedDropdownStyleVariables: {
          '--list-width': '100px',
          '--list-top': '0',
          '--list-left': '0',
          '--list-max-height': `${this.maxHeight}px`,
        },
      };
    },
    computed: {
      valueModel: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
    watch: {
      list(val, old) {
        if (val === old) {
          return;
        }

        if (!this.$refs.simpleSuggest.selected) {
          this.$refs.simpleSuggest.research();
        }
      },
      async value() {
        await this.$nextTick();
        if (!this.$refs.simpleSuggest.isSelectedUpToDate) {
          this.$emit('unselect');
        }
      },
    },
    beforeDestroy() {
      this.resizeObserver?.disconnect();
      this.scrollableParent?.removeEventListener('scroll', this.repositionDropDown);
    },
    methods: {
      getSuggestions() {
        window.simpleSuggest = this.$refs.simpleSuggest;
        return this.list;
      },
      handleSelect(selected) {
        this.$emit('select', selected);
        this.$refs.simpleSuggest.inputElement.blur();
      },
      handleShowList() {
        if (!this.fixedDropdown) {
          return;
        }
        this.resizableParent = this.$el.closest(this.scrollableParentSelector);
        this.scrollableParent = this.resizableParent === document.body ? document : this.resizableParent;
        this.resizeObserver = new ResizeObserver(this.repositionDropDown);
        this.resizeObserver.observe(this.resizableParent);

        this.repositionDropDown();
        this.scrollableParent.addEventListener('scroll', this.repositionDropDown);
      },
      handleHideList() {
        this.resizeObserver?.disconnect();
        this.scrollableParent?.removeEventListener('scroll', this.repositionDropDown);
      },
      repositionDropDown() {
        const resizableParentRect = this.resizableParent.getBoundingClientRect();
        const {
          top, height, bottom, left,
        } = this.$el.getBoundingClientRect();
        const ref = this.$refs.simpleSuggest;

        this.fixedDropdownStyleVariables = {
          '--list-width': `${ref.$el.clientWidth}px`,
          '--list-left': `${left}px`,
          '--list-top': `${top + height + 4}px`,
          '--list-max-height': `${this.maxHeight}px`,
        };

        if (resizableParentRect.top > top + height || resizableParentRect.bottom < bottom) {
          this.overflowed = true;
        } else {
          this.overflowed = false;
        }
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .combobox {
    position: relative;
    box-sizing: border-box;

    &.fixed-dropdown {
      & >>> .suggestions {
        position: fixed;
        bottom: auto;
        width: var(--list-width);
        top: var(--list-top);
        left: var(--list-left);
      }

      &.overflowed >>> .suggestions {
        opacity: 0;
        height: 0;
      }
    }

    & >>> ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    & >>> .suggestions {
      @apply shadow-dropdown-default border-0 bg-white;
      max-height: var(--list-max-height);
      overflow: auto;
      border-radius: 12px;
      padding: 8px 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      top: calc(100% + 5px);
      opacity: 1;
      z-index: 21;

      &.vue-simple-suggest-enter-active,
      &.vue-simple-suggest-leave-active {
        transition: opacity .2s;
      }

      &.vue-simple-suggest-enter,
      &.vue-simple-suggest-leave-to {
        opacity: 0;
      }
    }

    & >>> .suggest-item {
      transition: .3s ease all;
      cursor: pointer;
      user-select: none;
      overflow: hidden;
      max-width: 100%;

      &.hover {
        @apply bg-foundation-gray-2;
      }

      &.selected {
        @apply bg-foundation-gray-2;
      }
    }

    & >>> .suggest-item, & >>> .misc-item {
      @apply text-text-black text-14;
      line-height: 20px;
      padding: 12px 16px;
    }
  }

</style>
