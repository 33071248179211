<template>
  <div>
    <global-notification
      v-if="expiredPrimaryPaymentMethod"
      is-close-icon-shown
      type="danger"
      action-button-label="Update now"
      @action-click="handleUpdateNow"
      @close="handleClose"
    >
      Your card ending {{ expiredPrimaryPaymentMethod.metadata.cardMaskedNumber.slice(-6) }}
      set as a primary payment method has expired. Update it to continue using your account without interruptions.
    </global-notification>
    <global-notification
      v-else-if="expiredPaymentMethods.length"
      is-close-icon-shown
      type="danger"
      action-button-label="Update now"
      @action-click="handleUpdateNow"
      @close="handleClose"
    >
      Some of your payment methods have expired. Update your payment information to continue without interruptions.
    </global-notification>
    <global-notification
      v-else-if="aboutToExpirePrimaryPaymentMethod"
      is-close-icon-shown
      type="warning"
      action-button-label="Update now"
      @action-click="handleUpdateNow"
      @close="handleClose"
    >
      Your card ending {{ aboutToExpirePrimaryPaymentMethod.metadata.cardMaskedNumber.slice(-6) }}
      set as your primary payment method is about to expire. Update it to avoid interruptions in using your account.
    </global-notification>
    <global-notification
      v-else-if="aboutToExpirePaymentMethods.length"
      is-close-icon-shown
      type="warning"
      action-button-label="Update now"
      @action-click="handleUpdateNow"
      @close="handleClose"
    >
      Some of your payment methods are about to expire. Update your payment information to avoid interruptions in using your account.
    </global-notification>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import paymentMethodsService from '@/services/paymentMethods';
  import GlobalNotification from './GlobalNotification.vue';

  const billingMethodsPage = 'gowallet.payment-methods';

  export default {
    components: {
      GlobalNotification,
    },
    computed: {
      ...mapGetters(['currentUser']),
      expiredPaymentMethods() {
        return paymentMethodsService.getExpiredBillingPaymentMethods(this.currentUser.paymentMethods);
      },
      expiredPrimaryPaymentMethod() {
        return this.expiredPaymentMethods.find(pm => pm.primary);
      },
      aboutToExpirePaymentMethods() {
        return paymentMethodsService.getBillingPaymentMethodsAboutToExpire(this.currentUser.paymentMethods);
      },
      aboutToExpirePrimaryPaymentMethod() {
        return this.aboutToExpirePaymentMethods.find(pm => pm.primary);
      },
    },
    methods: {
      handleUpdateNow() {
        this.$router.push({
          name: billingMethodsPage,
        });
      },
      handleClose() {
        this.$emit('close');
      },
    },
  };
</script>
