<template>
  <transition name="header-popup">
    <header-popup
      v-if="show"
      class="popup"
    >
      <template v-if="isFreelancerAndEmployer">
        <router-link
          :to="employerLink"
          :class="{ active: isActive(employerLink) }"
          class="item"
        >
          <office-bag-icon class="icon" />
          <span>Client Profile</span>
        </router-link>
        <router-link
          :to="freelancerLink"
          :class="{ active: isActive(freelancerLink) }"
          class="item"
        >
          <profile-icon class="icon" />
          <span>Freelancer Profile</span>
        </router-link>
      </template>

      <router-link
        v-else
        :to="profileLink"
        :class="{ active: isActive(profileLink) }"
        class="item"
      >
        <profile-icon class="icon" />
        <span>{{ profileLinkTitle }}</span>
      </router-link>

      <router-link
        :to="accountSettingsLink"
        :class="{ active: isActive(accountSettingsLink) }"
        class="item"
      >
        <settings-icon class="icon" />
        <span>Account Settings</span>
      </router-link>

      <template v-if="isShownBalances">
        <separator />

        <router-link
          class="item balances"
          :to="{ name: 'gowallet' }"
        >
          <currency-item
            v-for="(value, currency) in balances"
            :key="currency"
            :currency="currency"
            :value="value"
          />
        </router-link>
      </template>

      <separator />

      <template v-if="isFreelancer">
        <a
          class="item download-gometer"
          :href="gometerLink"
          @click.prevent="handleDownloadGometerClick"
        >
          <download-icon class="icon" />
          <span>Download goMeter</span>
        </a>
        <separator />
      </template>

      <button
        class="item logout-item"
        @click="handleLogoutClick"
      >
        <log-out-icon class="icon" />
        <span>Log Out</span>
      </button>
    </header-popup>
  </transition>
</template>

<script>
  import { mapGetters } from 'vuex';
  import _get from 'lodash/get';
  import _includes from 'lodash/includes';

  import DownloadIcon from '@/components/icons/DownloadIcon.vue';
  import Separator from '@/components/Separator.vue';
  import ProfileIcon from '@/components/icons/AccountIcon.vue';

  import { getUserProfileHomeLink, links } from '@/services/user';

  import config from '@/config';

  import CurrencyItem from './CurrencyItem.vue';

  import OfficeBagIcon from './icons/OfficeBagIcon.vue';
  import LogOutIcon from './icons/LogOutIcon.vue';
  import SettingsIcon from './icons/SettingsIcon.vue';
  import HeaderPopup from './HeaderPopup.vue';

  export default {
    components: {
      ProfileIcon,
      OfficeBagIcon,
      SettingsIcon,
      DownloadIcon,
      LogOutIcon,
      HeaderPopup,
      Separator,
      CurrencyItem,
    },
    props: {
      currentUser: {
        type: Object,
        default() {
          return {};
        },
      },
      show: {
        type: Boolean,
        default: false,
      },
      isFreelancer: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        freelancerLink: links.freelancerDashboard,
        employerLink: links.employerDashboard,
        accountSettingsLink: links.accountSettings,
      };
    },
    computed: {
      ...mapGetters(['permissions']),
      isFreelancerAndEmployer() {
        return this.permissions.isGoBoth;
      },
      balances() {
        return _get(this.currentUser, 'balance.available');
      },
      profileLink() {
        return getUserProfileHomeLink(this.currentUser);
      },
      profileLinkTitle() {
        return this.permissions.isFreelancer ? 'Profile Insights' : 'Client Profile';
      },
      gometerLink() {
        return `${config.landingUrl}/gometer`;
      },
      isShownBalances() {
        const isUserWalletLimited = this.currentUser.isLimitedWalletEnabled;
        const isFreelancerStripeLimited = this.permissions.isFreelancer && this.currentUser.usaFreelancerOnlyStripeEnabled;

        if (isUserWalletLimited || isFreelancerStripeLimited) {
          return false;
        }

        return true;
      },
    },
    watch: {
      $route() {
        this.close();
      },
    },
    methods: {
      close() {
        this.$emit('close');
      },
      handleLogoutClick() {
        window.location = '/auth/local/signout';
      },
      handleDownloadGometerClick() {
        window.open(this.gometerLink, '_blank');
      },
      isActive({ name }) {
        return _includes(this.$route.name, name);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .popup {
    @apply absolute flex flex-col bg-white font-primary;

    right: 0px;
    top: calc(100% + -4px);
    z-index: 101;
    transition: 0.2s linear all;
    line-height: 1.4em;
    padding: 12px 0;
    max-width: 241px;
    gap: 4px;
  }

  .item {
    @apply flex text-text-black cursor-pointer items-center text-14;

    letter-spacing: 0.0025em;
    line-height: 20px;
    padding: 12px 24px;
    transition: 0.3s;
    white-space: nowrap;
    gap: 12px;

    &:hover,
    &:focus-visible {
      @apply bg-foundation-gray-2;
      outline: none;
    }

    &.active {
      @apply font-semibold;
      & .icon {
        @apply text-icon-brand;
      }
    }

    &.logout-item {
      @apply text-text-error;

      & .icon {
        @apply text-text-error;
      }
    }

    &.balances {
      @apply flex-col items-start;

      padding: 12px 24px;
      gap: 8px;
    }

    &.download-gometer {
      @apply text-text-link;
    }

    & .icon {
      @apply text-icon-default-dark;
    }
  }
</style>
