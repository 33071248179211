<template>
  <gl-skeleton-loader
    :loading="loading && !isRecordEmpty"
    class="total-row content-row"
    :style="{'--main-cell-margin-left': marginLeft}"
    @click="handleRowClick"
  >
    <expandable-table-cell
      v-for="(column, idx) in columns"
      :key="column.field"
      :column="column"
      :record="record"
      :loading="loading && isRecordEmpty"
      :class="{first: idx === 0}"
      class="total-row__table-cell"
    >
      <template #cell>
        <slot
          name="cell"
          :field="column.field"
          :total="true"
        >
          {{ getRecordValue(record, column.field, idx) }}
        </slot>
      </template>
    </expandable-table-cell>
  </gl-skeleton-loader>
</template>

<script>
  import _isEmpty from 'lodash/isEmpty';

  import GlSkeletonLoader from 'uikit/components/skeletons/SkeletonLoader.vue';

  import ExpandableTableCell from './ExpandableTableCell.vue';

  export default {
    // eslint-disable-next-line vue/component-definition-name-casing
    name: 'expandable-table-row',
    components: {
      GlSkeletonLoader,
      ExpandableTableCell,
    },
    props: {
      record: {
        type: Object,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      gridTemplateColumns: {
        type: String,
        required: true,
      },
      columns: {
        type: Array,
        required: true,
      },
      spacing: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        expanded: false,
      };
    },
    computed: {
      isRecordEmpty() {
        return _isEmpty(this.record);
      },
      isWhiteBg() {
        return this.isRecordEmpty === this.loading;
      },
      isExpandable() {
        return Array.isArray(this.record?.$children) && this.record.$children.length;
      },
      marginLeft() {
        return `${this.spacing * 42}px`;
      },
    },
    methods: {
      handleRowClick() {
        this.$emit('click', this.record);
      },
      getRecordValue(record, field, idx) {
        if (idx === 0) {
          return 'Total';
        }

        return record[field] ?? '';
      },
    },
  };
</script>

<style lang="postcss" scoped>
.total-row {
  min-height: 50px;
  box-sizing: border-box;
  border-radius: 8px;
  height: fit-content;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: var(--table-col-width);
  gap: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) calc(8px + var(--main-cell-margin-left, 42px)), rgba(247, 247, 247, 1) calc(8px + var(--main-cell-margin-left, 42px)));
  margin-bottom: 4px;
  transition: .1s linear;
}

.total-row__table-cell.first {
  @apply bg-foundation-gray-1 flex items-center;
  margin-left: var(--main-cell-margin-left, 42px);
  height: 100%;
  border-radius: 8px;
  padding-left: 14px;
}

@media (max-width: 767px) {
  .total-row {
    height: 50px;
  }
}

</style>
