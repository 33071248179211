<template>
  <div class="breadcrumbs">
    <span
      v-for="(crumb, index) in crumbs"
      :key="index"
      class="crumb"
    >
      <span
        class="crumb__name"
        :title="crumb.name | unescapeHtml"
        @click="handleClick(index, crumb.link)"
      >
        {{ crumb.name | unescapeHtml }}
      </span>
      <span
        v-if="!isLast(index)"
        class="crumb__divider-container"
      >
        <arrow-right-icon-small />
      </span>
    </span>
  </div>
</template>

<script>
  import unescapeHtml from '@/services/unescapeHtml';
  import ArrowRightIconSmall from '@/components/icons/ArrowRightSmallIcon.vue';

  export default {
    filters: {
      unescapeHtml,
    },
    components: {
      ArrowRightIconSmall,
    },
    props: {
      crumbs: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      handleClick(index, { name, params = {}, query = {} }) {
        if (this.isLast(index)) {
          return;
        }
        this.$emit('navigate', { name, params, query });
      },
      isLast(index) {
        return index === this.crumbs.length - 1;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .breadcrumbs {
    margin-bottom: 24px;
    line-height: 20px;
  }
  .crumb {
    @apply font-primary text-14 inline-flex;
    &:first-child .crumb__name {
      margin-left: 0;
    }
    &:last-child .crumb__name {
      @apply text-text-placeholder;
      cursor: not-allowed;
    }
  }
  .crumb__name {
    @apply text-text-link;
    cursor: pointer;
    margin: 0 4px;
  }
  .crumb__divider-container {
    @apply inline-flex text-text-placeholder;
  }
</style>
