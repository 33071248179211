import {
  getCurrentWeek,
  getLastWeek,
  getCurrentMonth,
  getLastMonth,
  getCurrentQuarter,
  getLastQuarter,
  getCurrentYear,
} from '../../../../../helpers/date';

const DEFAULT_DATE = {
  start: new Date(),
  end: new Date(),
};

export const DateVariants = {
  CUSTOM: 'custom',
  CURRENT_WEEK: 'currentWeek',
  LAST_WEEK: 'lastWeek',
  CURRENT_MONTH: 'currentMonth',
  LAST_MONTH: 'lastMonth',
  CURRENT_QUARTER: 'currentQuarter',
  LAST_QUARTER: 'lastQuarter',
  CURRENT_YEAR: 'currentYear',
  ALL_TIME: 'allTime',
};

export const dateLabelsMap = {
  [DateVariants.CUSTOM]: 'Custom',
  [DateVariants.CURRENT_WEEK]: 'Current week',
  [DateVariants.LAST_WEEK]: 'Last week',
  [DateVariants.CURRENT_MONTH]: 'Current month',
  [DateVariants.LAST_MONTH]: 'Last month',
  [DateVariants.CURRENT_QUARTER]: 'Current quarter',
  [DateVariants.LAST_QUARTER]: 'Last quarter',
  [DateVariants.CURRENT_YEAR]: 'Current year',
  [DateVariants.ALL_TIME]: 'All time',
};

export const dateOptions = [
  {
    type: DateVariants.CURRENT_WEEK,
    label: dateLabelsMap[DateVariants.CURRENT_WEEK],
    value: getCurrentWeek(),
  },
  {
    type: DateVariants.LAST_WEEK,
    label: dateLabelsMap[DateVariants.LAST_WEEK],
    value: getLastWeek(),
  },
  {
    type: DateVariants.CURRENT_MONTH,
    label: dateLabelsMap[DateVariants.CURRENT_MONTH],
    value: getCurrentMonth(),
  },
  {
    type: DateVariants.LAST_MONTH,
    label: dateLabelsMap[DateVariants.LAST_MONTH],
    value: getLastMonth(),
  },
  {
    type: DateVariants.CURRENT_QUARTER,
    label: dateLabelsMap[DateVariants.CURRENT_QUARTER],
    value: getCurrentQuarter(),
  },
  {
    type: DateVariants.LAST_QUARTER,
    label: dateLabelsMap[DateVariants.LAST_QUARTER],
    value: getLastQuarter(),
  },
  {
    type: DateVariants.CURRENT_YEAR,
    label: dateLabelsMap[DateVariants.CURRENT_YEAR],
    value: getCurrentYear(),
  },
  {
    type: DateVariants.CUSTOM,
    label: dateLabelsMap[DateVariants.CUSTOM],
    value: DEFAULT_DATE,
  },
];
