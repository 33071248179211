import _find from 'lodash/find';
import _cloneDeep from 'lodash/cloneDeep';
import fullName from '@/filters/fullName';

import pluralize from '@/filters/pluralize';

import currencyFilter from '@/filters/currency';

import { getProposalLink } from '@/services/proposal';
import { getCompanyLink } from '@/services/company';
import { CompanyType } from '@/constants/company';

import { ThreadEntities, ThreadMessageMetadataTypes } from '@/constants/threads';
import { THREAD_MESSAGE_STATUS } from '@/constants/milestone';

import store from '@/store';

export const getThreadEntityLink = entity => {
  switch (entity.type) {
    case ThreadEntities.CONTRACT:
      return {
        name: 'contract',
        params: { contractId: entity._id },
      };
    case ThreadEntities.MILESTONE:
      return {
        name: 'fixed-contract.milestones',
        params: { contractId: entity.contract._id },
        query: {
          milestoneId: entity._id,
        },
      };
    case ThreadEntities.NEGOTIATIONS:
      return getProposalLink(entity);
    case ThreadEntities.INVITATION:
      return {
        name: 'job-public',
        params: { jobId: entity.jobId, jobSlug: 'p' },
      };
    case ThreadEntities.OFFER:
      return {
        name: 'offer',
        params: { offerId: entity._id },
      };
    case ThreadEntities.DISPUTE:
      return {
        name: 'hourly-contract.disputes',
        params: { contractId: entity.contractId },
      };
    case ThreadEntities.SERVICE:
      return {
        name: 'service.current',
        params: { id: entity._id },
      };
    case ThreadEntities.PAYMENT:
      return {
        name: 'gowallet.transactions',
      };

    case ThreadEntities.AGENCY_REQUEST: {
      const route = getCompanyLink(entity, false, CompanyType.AGENCY);
      return route;
    }
    default:
      return null;
  }
};

export const expandMessage = (msg, thread) => {
  const { currentUser } = store.getters;

  const message = {
    ...msg,
    senderIsCurrentUser: currentUser._id === msg.senderId,
    sender: thread.users.find(user => user._id === msg.senderId),
    isSystem: msg.isSystem || msg.heading,
  };

  return message;
};

export const expandMilestoneMessage = msg => {
  if (!msg.metadata.milestoneId) {
    return msg;
  }

  const message = _cloneDeep(msg);

  switch (message.metadata.status) {
    case THREAD_MESSAGE_STATUS.CREATED:
      message.heading = 'created this milestone';
      break;
    case THREAD_MESSAGE_STATUS.UPDATED:
      message.heading = 'updated this milestone';
      break;
    case THREAD_MESSAGE_STATUS.DELETED:
      message.heading = 'deleted this milestone';
      break;
    case THREAD_MESSAGE_STATUS.IN_PROGRESS:
      message.heading = 'started working on this milestone';
      break;
    case THREAD_MESSAGE_STATUS.ACTIVATED:
      message.heading = 'funded this milestone';
      break;
    case THREAD_MESSAGE_STATUS.PAYMENT_REQUESTED:
      message.heading = 'submitted a completed work for review.';
      break;
    case THREAD_MESSAGE_STATUS.DECLINE_REQUESTED_PAYMENT:
      message.heading = `returned the completed work for rework. #${message.metadata.version}.`;
      break;
    case THREAD_MESSAGE_STATUS.PAID:
      message.heading = `released this milestone. Released amount: <strong>${currencyFilter(message.metadata.amount)}</strong>`;
      break;
    case THREAD_MESSAGE_STATUS.BONUS:
      message.heading = `sent a bonus payment for this milestone: <strong>${currencyFilter(message.metadata.amount)}</strong>`;
      break;
    default:
      message.heading = '';
      break;
  }

  message.isSystem = true;
  message.metadata.type = ThreadMessageMetadataTypes.MILESTONE_SYSTEM;

  return message;
};

export const expandSnapshotsMessage = (msg, thread) => {
  if (!msg.metadata?.snapshotIds && !msg.metadata?.snapshotTimestamps) {
    return msg;
  }

  const message = _cloneDeep(msg);

  message.snapshotsCount = (message.metadata.snapshotIds || message.metadata.snapshotTimestamps).length;
  message.heading = `left comment on ${pluralize(message.snapshotsCount, 'screenshot')}`;
  message.contractId = thread.entity.contractId || thread.entity._id;

  message.isSystem = true;
  message.metadata.type = ThreadMessageMetadataTypes.SNAPSHOTS_SYSTEM;

  return message;
};

export const expandMessageByMetadataType = msg => {
  const message = _cloneDeep(msg);

  if (message.metadata.type === ThreadMessageMetadataTypes.JOIN) {
    message.content = '';
    message.heading = 'joined this thread';
  }

  return message;
};

export const expandMessageMetadata = (msg, thread) => {
  let message = _cloneDeep(msg);

  if (!message.metadata) {
    return message;
  }

  message = expandMessageByMetadataType(message, thread);
  message = expandMilestoneMessage(message, thread);
  message = expandSnapshotsMessage(message, thread);

  return message;
};

export const getThreadPreviewInfo = thread => {
  const { currentUser } = store.getters;

  const currentUserInThread = _find(thread.users, user => user._id === currentUser._id);

  const threadUser = currentUserInThread || thread?.users[0] || {};

  const lastUser = _find(thread.users, user => user._id === threadUser.lastMessageUser);

  const listedUsers = thread.users
    .filter(u => u.isListed && u._id !== currentUser._id && u.state !== 'archived' && u.state !== 'hidden')
    .sort(aUser => (aUser.isMainParticipant ? -1 : 0));

  const mainParticipant = thread.users.find(u => u.type[0] !== currentUser.type[0])
    || thread.users.find(u => u._id !== currentUser._id)
    || { firstName: 'Unrecognized' };

  const mainListedUserFullName = fullName(mainParticipant);
  const otherUsersCounter = (listedUsers.length - 1) > 0 ? `, and +${listedUsers.length - 1}` : '';

  const listedUsersNames = `${mainListedUserFullName}${otherUsersCounter}`;

  const lastExpandedMessage = thread.lastMessage
    ? {
      ...expandMessage(thread.lastMessage, thread),
      ...expandMessageMetadata(thread.lastMessage, thread),
    }
    : {};

  return {
    ...thread,
    lastUser,
    listedUsers,
    listedUsersNames,
    mainParticipant,
    lastExpandedMessage,
    currentUser: currentUserInThread,
    lastMessageContent: threadUser.lastMessageContent,
    lastMessageDate: threadUser.lastMessageDate,
    lastMessageUserId: threadUser.lastMessageUser,
    lastMessageNonTextContent: threadUser.lastMessageNonTextContent,

  };
};
