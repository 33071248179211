<template>
  <gl-skeleton-loader
    class="row"
    :class="[{ compact, selected, scrolled, 'white-bg': isWhiteBg, clickable }]"
    :loading="loading && !isRecordEmpty"
    @click="handleRowClick"
  >
    <gl-checkbox
      v-if="selectable"
      :value="selected"
      class="row__checkbox"
      @change="handleSelectChange($event)"
    />
    <table-cell
      v-for="column in columns"
      :key="column.field"
      :column="column"
      :record="record"
      :loading="loading && isRecordEmpty"
      :compact="compact"
      :inline-actions="inlineActions"
      :selectable="selectable"
    >
      <template #cell>
        <slot
          name="cell"
          :field="column.field"
        >
        </slot>
      </template>
    </table-cell>
    <div
      v-if="inlineActions"
      class="row__inline-actions-container"
    >
      <inline-actions
        v-if="$slots.inlineActions"
        class="row__inline-actions"
        :container="`.${tableName}`"
      >
        <template #actionTrigger>
          <slot name="actionTrigger"></slot>
        </template>
        <template #inlineActions>
          <slot name="inlineActions"></slot>
        </template>
      </inline-actions>
      <slot
        v-else-if="$slots.inlineActionButton"
        name="inlineActionButton"
      ></slot>
    </div>
  </gl-skeleton-loader>
</template>

<script>
  import _isEmpty from 'lodash/isEmpty';

  import GlCheckbox from 'uikit/components/Checkbox/index.vue';
  import GlSkeletonLoader from 'uikit/components/skeletons/SkeletonLoader.vue';

  import InlineActions from 'uikit/components/InlineActions';

  import TableCell from './TableCell.vue';

  export default {
    components: {
      InlineActions,
      TableCell,
      GlCheckbox,
      GlSkeletonLoader,
    },

    inject: ['tableName'],

    props: {
      columns: {
        type: Array,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
      selectable: {
        type: Boolean,
        default: true,
      },
      inlineActions: {
        type: Boolean,
        default: false,
      },
      clickable: {
        type: Boolean,
        default: false,
      },
      selected: {
        type: Boolean,
        default: false,
      },
      compact: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      scrolled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isRecordEmpty() {
        return _isEmpty(this.record);
      },
      isWhiteBg() {
        return this.isRecordEmpty === this.loading;
      },
    },
    methods: {
      handleSelectChange(value) {
        this.$emit('selectChange', this.record, value);
      },
      handleRowClick() {
        this.$emit('click', this.record);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .row {
    @apply border border-transparent font-primary;
    display: grid;
    grid-template-columns: var(--table-col-width);
    min-height: 84px;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    border-radius: 12px;
    transition: 0.2s ease all, 0.1s linear grid-template-columns;

    &.clickable {
      cursor: pointer;

      &:hover {
        @apply border-foundation-gray-4 bg-foundation-gray-1;
      }
    }

    &.white-bg {
      @apply bg-white;
    }

    &.compact {
      @apply border-0 border-b-1 border-moody-gray;
      border-radius: 0;
      min-height: 64px;

      &:last-child {
        @apply border-b-0;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
      }
    }

    &.loading {
      box-shadow: 0 0 8px 1px inset #ffffffb3;
      pointer-events: none;

      & > * {
        opacity: 0.2;
        transition: opacity 0.3s ease;
      }
    }
  }

  .row__checkbox {
    @apply select-none;
    flex: 0 0 28px;
  }

  .row__checkbox >>> .checkbox {
    margin-top: 1px;
  }

  .row.scrolled .row__inline-actions-container {
    position: sticky;
    right: 0;
    z-index: 2;
    box-shadow: -4px 0px 7px -2px rgba(0, 0, 0, 0.12);
    animation: sticky 0.3s ease forwards;
  }

  .row__inline-actions-container {
    @apply flex items-center justify-end;
    background: inherit;
    height: 100%;
  }

  .row__inline-actions {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }

  @keyframes sticky {
    0% {
      right: -64px;
    }

    100% {
      right: -1px;
    }
  }

  @media screen and (max-width: 576px) {
    .row {
      position: relative;
      height: unset;
      border-radius: 20px;

      &.compact {
        @apply relative border-0;
        border-radius: 20px;
        height: unset;

        &:last-child {
          border-bottom: none;
        }
      }
    }
    .row__inline-actions {
      position: absolute;
      right: 12px;
      top: 12px;
      margin-right: 0;
    }

    .row__checkbox {
      flex: 0 0 44px;
    }
  }
</style>
