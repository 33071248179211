import _some from 'lodash/some';
import config from '@/config';
import logger from '@/logger';

const { amplitude } = window;

const analytics = {
  track(eventName, data = {}) {
    if (!amplitude) {
      logger.log('Amplitude events are off', eventName, data);
      return;
    }

    try {
      amplitude.track(eventName, data);
    } catch (err) {
      logger.error(`Cannot track amplitude "${eventName}" event with properties: ${JSON.stringify(data)}`, err);
    }
  },
  identify(user) {
    if (!amplitude) {
      return;
    }

    const { env } = config;
    const IDS_5CA_COMPANY = {
      production: '5ecd3a48222f204823b9ccee',
      development: '5cf8b70e04dd630006242a69',
      staging: '5dc2c2617a19c98a41eaddb7',
    };
    const IDS_PARALECT_COMPANY = {
      production: '55c0cb3cc2798d0700537dc9',
      development: '5cf8b70e04dd630006242a69',
      staging: '5dc2c2617a19c98a41eaddb7',
    };
    const IDS_GOLANCE_COMPANY = {
      production: '55c0e69b4200290500a3171e',
      development: '5cf8b70e04dd630006242a69',
      staging: '5dc2c2617a19c98a41eaddb7',
    };

    const ID_5CA_COMPANY = IDS_5CA_COMPANY[env];
    const ID_PARALECT_COMPANY = IDS_PARALECT_COMPANY[env];
    const ID_GOLANCE_COMPANY = IDS_GOLANCE_COMPANY[env];

    const is5CACompany = _some(user.companies, { _id: ID_5CA_COMPANY });
    const isGolanceCompany = _some(user.companies, { _id: ID_GOLANCE_COMPANY });
    const isParalectCompany = _some(user.companies, { _id: ID_PARALECT_COMPANY });

    const isExclusiveFreelancer = _some(user.companies, { exclusive: true });

    const userProperties = {
      '5CA Company': is5CACompany,
      'Exclusive Freelancer': isExclusiveFreelancer,
      'Paralect Company': isParalectCompany,
      'goLance Company': isGolanceCompany,
      'User Status': user.status,
    };

    try {
      amplitude.setUserId(user._id);
      const identify = new amplitude.Identify();
      Object.entries(userProperties).forEach(([key, value]) => {
        identify.set(key, value);
      });
      amplitude.identify(identify);
    } catch (err) {
      logger.error(err);
    }
  },
  eventProperties: {
    section: {
      name: 'Section',
      values: {
        navBar: 'Nav Bar',
        directLink: 'Direct Link',
        jobsManager: 'Jobs Manager',
        // Create Offer Form
        freelancerProfile: 'Freelancer Profile',
        ownCompanyProfile: 'Own Company Profile',
        jobPage: 'Job Page',
        proposalPage: 'Proposal Page',
        clientDashboard: 'Client Dashboard',
        // MFA
        signUp: 'Sign Up',
        createOffer: 'Create Offer',
        goWallet: 'goWallet',
        accountSettings: 'Account Settings',
      },
    },
    jobType: {
      name: 'Job Type',
      values: {
        hourlyPaid: 'Hourly',
        fixedPrice: 'Fixed Price',
        scheduled: 'Scheduled',
      },
    },
    offerType: {
      name: 'Offer Type',
      values: {
        hourly: 'Hourly Rate',
        fixed: 'Fixed Price',
        schedule: 'Schedule',
        recurring: 'Recurring Payment',
      },
    },
    newJobOpened: {
      name: 'Section',
      values: {
        navBar: 'Nav Bar',
        jobsManager: 'Jobs Manager',
      },
    },
    jobHoursPerWeek: {
      name: 'Hours per Week',
    },
    videoResponse: {
      name: 'Video Response',
    },
    jobCategory: {
      name: 'Job Category',
    },
    recommended: {
      name: 'Recommended?',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    position: {
      name: 'Position',
    },
    jobSkills: {
      name: 'Skills',
    },
    transactionType: {
      name: 'Transaction Type',
    },
    currencyExchange: {
      currencyFrom: 'From',
      currencyTo: 'To',
    },
    appliedDisplayType: {
      name: 'Type',
      values: {
        grid: 'Grid',
        list: 'List',
      },
    },
    interviewInviteAction: {
      name: 'Action',
      values: {
        accept: 'Accept',
        decline: 'Decline',
      },
    },
    contractCreated: {
      name: 'Contract Created ?',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    contractId: {
      name: 'Contract ID',
    },
    offerFreelancer: {
      name: 'Freelancer',
      values: {
        existing: 'Existing',
        invited: 'Invited',
      },
    },
    splitFee: {
      name: 'Split Fee?',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    jobLinked: {
      name: 'Job Linked',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    usage: {
      name: 'Usage',
      values: {
        company: 'Company',
        individual: 'Individual',
      },
    },
    onBehalfOf: {
      name: 'On Behalf of',
      values: {
        company: 'Company',
        individual: 'Individual',
      },
    },
    goal: {
      name: 'Goal',
      values: {
        findFreelancers: 'Find Freelancers',
        manageContractors: 'Manage Contractors',
        joinCompany: 'Join the company',
      },
    },
    withdrawnProposal: {
      name: 'Reason',
      values: {
        mistake: 'Applied by mistake',
        'low-rate': 'Rate too low',
        'scheduling-conflict': 'Scheduling conflict with client',
        'unresponsive-client': 'Unresponsive client',
        'inappropriate-behaviour': 'Inappropriate client behaviour',
        other: 'Other',
      },
    },
    onboardingLink: {
      name: 'Link',
    },
    freelancerOnboarding: {
      name: 'Action',
      values: {
        close: 'Close Modal',
        complete: 'Complete Registration',
        completeOnboarding: 'Complete Onboarding',
        continue: 'Continue Profile Setup',
      },
    },
    photoAdded: {
      name: 'Photo Added',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    profilePictureAdded: {
      name: 'Profile Picture Added',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    firstNameUpdated: {
      name: 'First name updated',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    lastNameUpdated: {
      name: 'Last name updated',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    timezoneUpdated: {
      name: 'Timezone Updated',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    countryUpdated: {
      name: 'Country Updated',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    cityUpdated: {
      name: 'City Updated',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    companyWebsiteAdded: {
      name: 'Company Website Added',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    companyAboutAdded: {
      name: 'About Company Added',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    facebookAdded: {
      name: 'Facebook Added',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    instagramAdded: {
      name: 'Instagram Added',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    linkedinAdded: {
      name: 'Linkedin Added',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    xAdded: {
      name: 'X Added',
      values: {
        yes: 'Yes',
        no: 'No',
      },
    },
    companyName: {
      name: 'Company Name',
    },
    companyWebsite: {
      name: 'Company Website',
    },
    headline: {
      name: 'Headline',
    },
    clientOnboardingFlow: {
      name: 'Flow',
      values: {
        companyOwner: 'Company owner flow',
        companyMember: 'Company member flow',
        individual: 'Individual flow',
      },
    },
    clientOnboardingStep: {
      name: 'Step',
      values: {
        goLance_usage_step: 'GoLance usage step',
        company_setup_step: 'Add company step',
        profile_setup_step: 'Add personal profile step',
        invite_company_members_step: 'Invite company members step',
        share_profile_step: 'Share profile link step',
        experience_step: 'Experience level step',
      },
    },
    freelancerOnboardingFlow: {
      name: 'Flow',
      values: {
        client: 'Invited by Client',
        agency: 'Invited by Agency',
        independent: 'Independent',
      },
    },
    freelancerOnboardingStep: {
      name: 'Step',
      values: {
        welcome_page: 'Welcome Step',
        reason_to_join_page: 'Reason to Join Step',
        personal_info_page: 'Picture & Name Step',
        professional_role_page: 'Headline Step',
        skills_page: 'Skills Step',
        location_step: 'Location Step',
        hourly_rate_step: 'Hourly Rate Step',
        portfolio_step: 'Portfolio Step',
        languages_step: 'Languages Step',
        education_step: 'Education Step',
        work_experience_step: 'Work Experience Step',
        networks_step: 'Social Networks Step',
        share_link_step: 'Share Link Step',
        preview_profile_step: 'Preview Profile Step',
        include_ai_step: 'Include Ai Step',
        categories_step: 'Categories Step',
      },
    },
    rate: {
      name: 'Rate',
    },
    numberOfSkills: {
      name: '# of Skills',
    },
    numberOfProjects: {
      name: '# of Projects',
    },
    numberOfCategories: {
      name: '# of Categories',
    },
    numberOfLanguages: {
      name: '# of Languages',
    },
    numberOfEducations: {
      name: '# of Educations',
    },
    numberOfWorks: {
      name: '# of Works',
    },
    numberOfSocialNetworks: {
      name: '# of Works',
    },
    numberOfTeamMembersAdded: {
      name: '# of team members added',
    },
    userType: {
      name: 'Type',
      values: {
        employer: 'Client',
        freelancer: 'Freelancer or Agency',
      },
    },
    marketplace: {
      name: 'Category',
    },
    withdrawPayments: {
      name: 'Method',
      values: {
        bank: 'Bank Account',
        payoneer: 'Payoneer Account',
        paypal: 'PayPal Account',
        bitcoin: 'Bitcoin Wallet',
        ethereum: 'Ethereum Wallet',
        usdCoin: 'Usd Coin',
        tether: 'Tether (ERC-20)',
        tetherTrc20: 'Tether (TRC-20)',
        tron: 'Tron',
      },
    },
    leaveReviewOpenedFrom: {
      name: 'From',
      values: {
        dashboard: 'Dashboard',
        contract: 'Contract Closed',
      },
    },
    leaveReviewRating: {
      name: 'Rating',
    },
    leaveReviewLiked: {
      name: 'I like',
    },
    leaveReviewDisliked: {
      name: 'I dislike',
    },
    invoiceExportType: {
      name: 'PDF Type',
      values: {
        invoice: 'Invoice',
        receipt: 'Receipt',
        both: 'Both',
      },
    },
    taxFormType: {
      name: 'Type',
      values: {
        w8: 'W8-BEN',
        w9: 'W9',
      },
    },
    taxFormResidence: {
      name: 'Tax Residence',
      values: {
        w8: 'Non-US',
        w9: 'US',
      },
    },
    company5CA: {
      name: '5CA Company',
      values: {
        true: 'True',
        false: 'False',
      },
    },
    jobSelected: {
      name: 'Job Selected',
      values: {
        true: 'True',
        false: 'False',
      },
    },
    methodType: {
      name: 'Method Type',
      values: {
        primary: 'Primary',
        secondary: 'Secondary',
      },
    },
    replacedWith: {
      name: 'Replaced with',
      values: {
        existing: 'Existing method',
        new: 'New Method',
      },
    },
    fileType: {
      name: 'File Type',
      values: {
        pdf: 'PDF',
        doc: 'DOC',
      },
    },
    fileSize: {
      name: 'File Size',
    },
    errorType: {
      name: 'Error Type',
    },
    resumeId: {
      name: 'Resume ID',
    },
    totalFields: {
      name: 'Total Fields',
    },
    fieldName: {
      name: 'Field Name',
    },
    totalEditedFields: {
      name: 'Total Edited Fields',
    },
    totalConfirmedFields: {
      name: 'Total Confirmed Fields',
    },
  },
  eventNames: {
    offer: {
      newOfferFormOpened: 'New Offer Form Opened',
      offerSent: 'Offer sent',
      offerWithdrawn: 'Offer Withdrawn',
      offerAccepted: 'Offer Accepted',
      serviceOfferCreated: 'Service Offer Created',
      sendOfferFormOpened: 'Send Offer form opened',
      offerTypeStepCompleted: 'Offer type step completed',
      offerAiSuggestionsOpened: 'Open Offer AI Descripton Suggestions',
      offerAiSuggestionsSelected: 'Select Offer AI Description Suggestions',
    },
    user: {
      login: 'Log In',
      newAccountTypeChosen: 'New Account Type Chosen',
    },
    jobs: {
      newJobFormOpened: 'New Job Form Opened',
      newJobPosted: 'New Job Posted',
      jobClosed: 'Job Closed',
      helpWithJobDescriptionClicked: 'Help With Job Description Clicked',
      generateDescriptionClicked: 'Generate Description Clicked',
      postJobTypeBudgetOpened: 'Post Job - Type&Budget Opened',
      suggestedCategorySelected: 'Suggested Category Selected',
      postJobCandidatesRequirementsOpened: 'Post Job - Candidates Requirements Opened',
      suggestedSkillsSelected: 'Suggested Skills Selected',
      postJobPostingOpened: 'Post Job Posting Opened',
    },
    ownJob: {
      aiScoreSort: 'AI Sorting Applied',
    },
    proposals: {
      newProposalOpened: 'New Proposal Opened',
      proposalSubmitted: 'Proposal Submitted',
    },
    services: {
      serviceCreated: 'Service Created',
      getInTouch: 'Get in Touch Clicked',
    },
    contracts: {
      contractCreated: 'Contract Created',
    },
    officialReceipt: {
      downloaded: 'Official Receipt Downloaded',
    },
    currencyExchange: {
      currencyExchanged: 'Currency Exchanged',
    },
    virtualCards: {
      addVirtualCard: 'Add Virtual Card',
      virtualCardOrdered: 'Virtual Card Ordered',
    },
    affiliateProgram: {
      referralLinkCopied: 'Referral Link Copied',
      referralLinkShared: 'Referral Link Shared',
    },
    marketplace: {
      displayViewApplied: 'Display View Applied',
      freelancerMarketplaceCategoryClicked: 'Freelancer Marketplace Category Clicked',
      freelancerMarketplaceProfileOpened: 'Freelancer Marketplace Profile Opened',
      jobMarketplaceCardOpened: 'Get Hired Job Card Opened',
    },
    freelancerDashboard: {
      jobCardOpened: 'Freelancer Dashboard Job Card Opened',
    },
    interview: {
      scheduleInterviewOpened: 'Schedule Interview Opened',
      interviewRequestSent: 'Interview Request Sent',
      interviewInviteAction: 'Interview Invite Action',
    },
    reports: {
      reportsPageOpened: 'Reports Page Opened',
    },
    payments: {
      sendPaymentOpened: 'Send Payment Opened',
      sendPaymentSuccess: 'Send Payment Success',
      makeWithdrawalSuccess: 'Make Withdrawal Success',
    },
    wallets: {
      newWalletCreated: 'New Wallet Created',
    },
    mfa: {
      mfaPopupOpened: 'MFA Popup Opened',
      mfaEnabled: 'MFA Enabled',
    },
    jobManager: {
      withdrawProposal: 'Proposal Withdrawn',
    },
    onboarding: {
      linkClicked: 'Onboarding Link Clicked',
    },
    freelancerOnboarding: {
      onboarding: 'Freelancer Onboarding',
      freelancerOnboardingStarted: 'Freelancer Onboarding Started',
      welcomeStepCompleted: 'Welcome Step Completed',
      pictureAndNameStepCompleted: 'Picture&Name Step Completed',
      invitedByClientStepOpened: 'Invited by Client Step Opened',
      invitedByAgencyStepOpened: 'Invited by Agency Step Opened',
      invitedByClientStepCompleted: 'Invited by Client Step Completed',
      invitedByAgencyStepCompleted: 'Invited by Agency Step Completed',
      headlineStepCompleted: 'Headline Step Completed',
      skillsStepCompleted: 'Skills Step Completed',
      categoriesStepCompleted: 'Categories Step Completed',
      locationAndTimezoneStepCompleted: 'Location&Time zone Step Completed',
      rateStepCompleted: 'Rate Step Completed',
      includeAiStepCompleted: 'Include Ai Step Completed',
      previewProfileStepCompleted: 'Preview Profile Step Completed',
      portfolioStepCompleted: 'Portfolio Step Completed',
      languageStepCompleted: 'Language Step Completed',
      educationStepCompleted: 'Education Step Completed',
      workExperienceStepCompleted: 'Work Experience Step Completed',
      socialNetworkStepCompleted: 'Social Network Step Completed',
      onboardingAbandoned: 'Onboarding abandoned',
      linkCopied: 'Link Copied',
      resumeUploaded: 'Resume Uploaded',
      resumeUploadFailed: 'Resume Upload Failed',
      resumeParsingStarted: 'Resume Parsing Started',
      autoPopulatedFieldsPresented: 'Auto-Populated Fields Presented',
      fieldEdited: 'Field Edited',
      profileFinalized: 'Profile Finalized',
      resumeParsingError: 'Resume Parsing Error',
    },
    clientOnboarding: {
      usageStepCompleted: 'GoLance Usage Step Completed',
      clientOnboardingStarted: 'Client Onboarding Started',
      addCompanyStepStarted: 'Add Company Step Started',
      addCompanyStepCompleted: 'Add Company Step Completed',
      personalProfileStepStarted: 'Personal Profile Step Started',
      personalProfileStepCompleted: 'Personal Profile Step Completed',
      inviteCompanyMembersStepStarted: 'Invite Company Members Step Started',
      inviteCompanyMembersStepCompleted: 'Invite Company Members Step Completed',
      shareProfileLinkStepStarted: 'Share Profile Link Step Started',
      shareProfileLinkStepCompleted: 'Share Profile Link Step Completed',
      clientOnboardingAbandoned: 'Client Onboarding Abandoned',
      linkCopied: 'Link Copied',
    },
    inbox: {
      recordLoomClick: 'Record Video Clicked',
      sendGoogleInstantMeeting: 'gMeet Clicked',
      sendZoomInstantMeeting: 'Zoom Clicked',
    },
    activityFeed: {
      feedOpened: 'Activity Feed Opened',
    },
    leaveReview: {
      reviewModalOpened: 'Review Modal Opened',
      reviewSubmitted: 'Review Submitted',
      privateReviewModalOpened: 'Private Review Modal Opened',
      rejectReview: "Review Don't Want clicked",
      privateReviewSubmitted: 'Private Review Submitted',
    },
    paymentRequest: {
      paymentRequestFormOpened: 'Create Payment Request Opened',
      paymentRequestSent: 'Payment Request Sent',
    },
    invoice: {
      invoicesBulkExportClicked: 'Invoices Bulk Export Clicked',
      invoicesExportClicked: 'Invoices Export Clicked',
      frInvoiceGenerated: 'FR-Invoice generated',
      frBulkInvoiceGenerated: 'FR-Bulk Invoice generated',
      frInvoiceListViewed: 'FR-Invoice list viewed',
    },
    taxForm: {
      taxFormStarted: 'Tax Form Started',
      taxFormSubmitted: 'Tax form Submitted',
    },
    highActivitySurvey: {
      surveyOpened: 'HighStroke survey seen',
      surveySubmitted: 'HighStroke survey completed',
    },
    expiredPaymentMethod: {
      updateInitiated: 'Update expired BM initiated',
      updated: 'Expired BM updated',
    },
    hourlyContractReport: {
      tabViewed: 'HR: Report Tab Viewed',
      taskOpened: 'HR: Task Opened',
      filterApplied: 'HR: Filter Applied',
      sortingApplied: 'HR: Sorting Applied',
    },
  },
};

export default analytics;

export const { eventNames } = analytics;
export const { eventProperties } = analytics;
