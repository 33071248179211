<template>
  <button
    class="block-button"
    @click="$emit('click', $event)"
  >
    <div class="block-button__icon-container">
      <slot name="icon">
        <gl-base-icon
          v-if="iconName"
          :name="iconName"
          :size="iconSize"
          :color="iconColor"
        />
      </slot>
    </div>
    <div class="block-button__info">
      <h3
        v-if="heading"
        class="info__heading"
      >
        {{ heading }}
      </h3>
      <span
        v-if="description || $slots.description"
        class="info__description"
      >
        <slot name="description">
          {{ description }}
        </slot>
      </span>
    </div>
  </button>
</template>

<script>
  import GlBaseIcon from 'uikit/icons/BaseIcon.vue';

  export default {
    components: {
      GlBaseIcon,
    },
    props: {
      heading: {
        type: String,
        default: null,
      },
      description: {
        type: String,
        default: null,
      },
      iconName: {
        type: String,
        default: null,
      },
      iconSize: {
        type: [Number, String],
        default: 24,
      },
      iconColor: {
        type: String,
        default: 'inherit',
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .block-button {
    @apply flex items-center justify-start border border-foundation-gray-4 bg-white;

    border-radius: 20px;
    outline: none;
    box-sizing: border-box;
    padding: 24px;
    gap: 24px;
    transition: all 0.3s ease-in-out;

    &:hover {
      @apply border-foundation-gray-5;

      & .info__heading {
        @apply text-text-link;
      }
    }

    &:focus-visible {
      @apply border-icon-brand;

      & .info__heading {
        @apply text-text-focus;
      }
    }

    &:active {
      @apply border-royal-blue bg-foundation-gray-2;

      & .info__heading {
        @apply text-text-black;
      }
    }
  }

  .block-button__icon-container {
    flex-shrink: 0;
  }

  .block-button__info {
    @apply flex flex-col items-start;
    gap: 4px;
  }

  .info__heading {
    @apply font-primary font-semibold text-text-black text-20;
    line-height: 150%;
    letter-spacing: 0.0015em;
    transition: all 0.3s ease-in-out;
  }

  .info__description {
    @apply inline-flex items-center font-primary text-text-placeholder text-12;
    flex-wrap: wrap;
    gap: 4px;
    line-height: 150%;
    letter-spacing: 0.004em;
    font-feature-settings: 'kern' off;
    text-align: left;
  }
</style>
