<template>
  <p class="date-label">
    {{ dateLabel }}
  </p>
</template>

<script>
  import { mapGetters } from 'vuex';
  import formatDate from '@/filters/formatDate';
  import { convertTimezoneDateToUTC } from '../helpers/date';

  export default {
    props: {
      filterValue: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters(['currentTimezone']),
      dateLabel() {
        return `${formatDate(convertTimezoneDateToUTC(this.filterValue.start), 'LLL dd, yyyy', this.currentTimezone)}-${formatDate(convertTimezoneDateToUTC(this.filterValue.end), 'LLL dd, yyyy', this.currentTimezone)}`;
      },
    },
  };
</script>

<style lang="postcss" scoped>
.date-label {
    @apply text-14 font-medium text-text-black;
    white-space: nowrap;
}
</style>
