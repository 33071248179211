<template>
  <div
    class="search-input"
    :class="[uniqueSelector]"
    @focusin="handleFocusIn"
    @focusout="handleFocusOut"
    @keyup.up="handleUp"
    @keyup.down="handleDown"
    @keyup.esc="handleEscape"
  >
    <gl-input
      ref="input"
      v-bind="$attrs"
      :value="inputValue"
      :error="error"
      :clearable="isClearIconShown"
      @input="handleInput"
      @change="handleChange"
      @keypress.enter="handleEnter"
    >
      <template
        v-if="withIcon"
        #icon
      >
        <base-icon
          name="search"
          :width="iconWidth"
          :height="iconHeight"
          class="search-icon"
        />
      </template>
      <template #right-icon>
        <gl-icon-button
          v-if="isClearIconShown"
          icon-name="times-rounded"
          :size="16"
          :icon-size="16"
          class="clearable-button"
          @mousedown.native.prevent
          @click="handleClearInput"
        />
      </template>
    </gl-input>

    <dropdown-transition>
      <drop-down
        v-if="isOpened && !isOptionsEmpty"
        class="search-input__dropdown"
      >
        <ul
          class="search-input__options"
          @mousedown.prevent.stop
        >
          <li
            v-for="(option, idx) in shownOptions"
            :key="option.value"
            :title="option.label"
            class="options__item"
            :class="{ selected: idx === selectedOptionIndex }"
            @click="handleOptionSelect(option)"
          >
            <span class="item__info">
              <base-icon
                :name="optionIcon"
                :width="optionIconSize.width"
                :height="optionIconSize.height"
                class="item__icon"
              />

              <span class="item__name">
                <highlighted-text
                  :query="inputValue"
                  :text="option.label"
                />
              </span>
            </span>

            <button
              v-if="isOptionsRemovable && displayRemoveOptionIcon"
              class="item__delete-button"
              @click.stop="handleRemoveOption(option)"
            >
              <base-icon
                name="cross-circle"
                width="16"
                height="16"
                class="delete-button__icon"
              />
            </button>
          </li>
        </ul>
      </drop-down>
    </dropdown-transition>
  </div>
</template>

<script>
  import _delay from 'lodash/delay';
  import _uniqueId from 'lodash/uniqueId';
  import _isEmpty from 'lodash/isEmpty';
  import _toLower from 'lodash/toLower';
  import _filter from 'lodash/filter';
  import _some from 'lodash/some';
  import _includes from 'lodash/includes';
  import _trim from 'lodash/trim';

  import { BaseIcon } from 'uikit/icons';

  import GlInput from 'uikit/components/inputs/Input.vue';
  import GlIconButton from 'uikit/components/buttons/IconButton.vue';
  import DropdownTransition from 'uikit/components/transitions/DropdownTransition.vue';
  import DropDown from 'uikit/components/DropDown.vue';
  import HighlightedText from 'uikit/components/HighlightedText.vue';

  const optionIconSizeByName = {
    'time-past': { width: 20, height: 20 },
    'skill-autocomplete': { width: 29, height: 9 },
  };

  export default {
    components: {
      BaseIcon,
      GlInput,
      GlIconButton,
      DropDown,
      DropdownTransition,
      HighlightedText,
    },
    props: {
      value: {
        type: String,
        default: '',
      },

      /** Array of { value: string; label: string } */
      options: {
        type: Array,
        default: () => [],
      },

      /** Set freeSolo to true so the textbox can contain any arbitrary value. */
      freesolo: {
        type: Boolean,
        default: false,
      },
      optionsFilterDisabled: {
        type: Boolean,
        default: false,
      },
      error: {
        type: String,
        default: null,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      displayRemoveOptionIcon: {
        type: Boolean,
        default: true,
      },
      clearOnSelect: {
        type: Boolean,
        default: false,
      },

      iconWidth: {
        type: Number,
        default: 16,
      },
      iconHeight: {
        type: Number,
        default: 16,
      },
      withIcon: {
        type: Boolean,
        default: true,
      },
      optionIcon: {
        type: String,
        default: 'time-past',
      },
    },

    data() {
      return {
        inputValue: this.value,
        isOpened: false,
        uniqueSelector: null,
        selectedOptionIndex: -1,
      };
    },

    computed: {
      isClearIconShown() {
        return this.clearable && !!this.inputValue && this.value === this.inputValue;
      },
      isOptionsEmpty() {
        return _isEmpty(this.shownOptions);
      },

      shownOptions() {
        if (!this.inputValue) return this.options;

        return this.matchOptions;
      },

      matchOptions() {
        if (this.optionsFilterDisabled) {
          return this.options;
        }

        const searchTerm = _toLower(this.inputValue.trimStart());

        return _filter(this.options, option => _includes(_toLower(option.label), searchTerm));
      },

      isOptionsRemovable() {
        return Boolean(this.$listeners['remove-option']);
      },

      optionIconSize() {
        return optionIconSizeByName[this.optionIcon];
      },
    },

    watch: {
      value(value) {
        this.inputValue = value;
      },
    },

    created() {
      this.uniqueSelector = _uniqueId('search_input_');
    },

    methods: {
      handleChange(changedValue) {
        const value = _trim(changedValue);

        if (!this.freesolo && !_some(this.options, { label: value })) {
          return;
        }
        this.$emit('change', value);

        this.hideDropdown();
      },

      handleOptionSelect(option) {
        if (this.clearOnSelect) {
          this.inputValue = '';
        } else {
          this.handleChange(option.value);
          this.inputValue = option.value;
        }

        this.$emit('select', option);

        this.hideDropdown();
      },

      handleRemoveOption(option) {
        this.$emit('remove-option', option);
      },

      handleFocusIn() {
        if (this.isOpened) return;

        this.isOpened = true;

        this.$refs.input.selectInput();
      },

      handleFocusOut() {
        _delay(() => {
          const isAutocompleteInFocus = document.activeElement && document.activeElement.closest(`.${this.uniqueSelector}`);
          if (isAutocompleteInFocus) {
            return;
          }

          this.hideDropdown();
        }, 150);
      },

      handleEnter(ev) {
        if (this.shownOptions[this.selectedOptionIndex]) {
          ev.preventDefault();

          this.handleOptionSelect(this.shownOptions[this.selectedOptionIndex]);
        } else {
          this.hideDropdown();
        }
      },

      handleEscape(ev) {
        ev.preventDefault();
        this.hideDropdown();
      },

      handleClearInput() {
        this.handleChange('');
      },

      handleUp() {
        const prevIndex = this.selectedOptionIndex - 1;
        this.selectOption(prevIndex);
      },

      handleDown() {
        const nextIndex = this.selectedOptionIndex + 1;
        this.selectOption(nextIndex);
      },

      selectOption(index) {
        const option = this.shownOptions[index];

        if (option) {
          this.selectedOptionIndex = index;
        }
      },

      handleInput(value) {
        this.$emit('input', value);

        this.inputValue = value;

        this.selectedOptionIndex = -1;
        this.isOpened = true;
      },

      hideDropdown() {
        this.selectedOptionIndex = -1;
        this.isOpened = false;
      },
    },
  };
</script>

<style lang="postcss" scoped>
.search-input {
  @apply relative;
  z-index: 2;
  perspective: 2000px;
}

.search-input__dropdown {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  border-radius: 12px;
  min-width: 290px;
}

.search-input__options {
  list-style-type: none;
  padding: 4px 0;
}

.clearable-button.base-icon {
  @apply text-icon-default border-0;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  &:hover {
    @apply text-icon-hover;
  }
}

.options__item {
  @apply flex items-center justify-between;
  transition: 0.4s ease all;
  padding: 16px;
  gap: 8px;
  cursor: pointer;

  &:hover,
  &.selected {
    @apply bg-foundation-gray-2;
  }
}

.item__info {
  @apply flex items-center justify-start;
  gap: 10px;
  max-width: calc(100% - 28px);
}

.item__icon {
  @apply text-icon-default;
  flex-shrink: 0;
}

.item__name {
  @apply text-text-black font-normal text-14 truncate;
  line-height: 20px;
}

.item__delete-button {
  @apply text-icon-default cursor-pointer;
  outline: none;
  height: 20px;
  width: 20px;

  &:hover,
  &:focus-visible {
    @apply text-icon-default-dark;
  }
}

.delete-button__icon {
  transition: 0.4s ease all;
}
</style>
