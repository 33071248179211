<template>
  <router-link
    v-if="isLink"
    :to="linkPath"
    class="notification-link"
  >
    <base-message-preview
      :label="label"
      :content="notification.message"
      :is-unread="isUnread"
      :date="createdOn"
      class="notification-preview"
    >
      <template v-if="customContent">
        <component
          :is="customContent"
          :notification="notification"
        />
      </template>
    </base-message-preview>
  </router-link>
  <div
    v-else
  >
    <base-message-preview
      :label="label"
      :content="notification.message"
      :is-unread="isUnread"
      :date="createdOn"
      class="notification-preview"
    >
      <template v-if="customContent">
        <component
          :is="customContent"
          :notification="notification"
        />
      </template>
    </base-message-preview>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import formatDate from '@/filters/formatDate';

  import getNotificationLink from '@/services/notificationLink';

  import BaseMessagePreview from './BaseMessagePreview.vue';

  import notificationLabels from '../constants/notificationLabels';

  import customContentNotifications from './notificationContentTemplates/index';

  const companyInvitationNotifications = ['company-invitation-accepted', 'company-invitation-declined', 'user-invited-to-company'];

  const notificationsWithoutLink = ['contract-closed'];

  export default {
    components: {
      BaseMessagePreview,
    },
    props: {
      notification: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters(['currentUser', 'currentTimezone', 'permissions']),
      label() {
        if (companyInvitationNotifications.includes(this.notification.type) && this.permissions.isFreelancer) {
          return notificationLabels[this.notification.type].replaceAll('Company', 'Agency');
        }
        return notificationLabels[this.notification.type];
      },
      isUnread() {
        return this.notification.state === 'unread';
      },
      createdOn() {
        return formatDate(this.notification.createdOn, "MMM dd, yyyy 'at' h:mm a", this.currentTimezone);
      },
      linkPath() {
        return getNotificationLink(this.notification);
      },
      customContent() {
        return customContentNotifications[this.notification.type];
      },
      isLink() {
        return !notificationsWithoutLink.find(type => this.notification.type === type);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .notification-link {
    outline: none;
    cursor: pointer;
    &:hover,
    &:focus-visible {
      & .notification-preview {
        background-color: #fff8f1;
      }
    }
  }

  .notification-preview {
    transition: all ease 0.3s;
    & >>> .icon-background {
      fill: #fff8f1;
    }
    & >>> .icon-path {
      fill: #ffa83a;
    }
  }
</style>
