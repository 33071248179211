const initialState = {
  issueInvoiceModal: false,
};

const actions = {
  handleIssueInvoiceModalChange({ commit }, value) {
    commit('freelancerInvoiceModalChange', value);
  },
};

const mutations = {
  freelancerInvoiceModalChange(state, value) {
    state.issueInvoiceModal = value;
  },
};

const getters = {
  showIssueInvoiceModal: ({ issueInvoiceModal }) => issueInvoiceModal,
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
