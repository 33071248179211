import { render, staticRenderFns } from "./DropdownTransition.vue?vue&type=template&id=47ea5bdc&scoped=true"
var script = {}
import style0 from "./DropdownTransition.vue?vue&type=style&index=0&id=47ea5bdc&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ea5bdc",
  null
  
)

export default component.exports