<template>
  <div class="bulk-actions">
    <span class="bulk-actions__counter">
      {{ count }} {{ pluralizedName }} selected
    </span>
    <slot></slot>
  </div>
</template>

<script>
  import pluralize from 'pluralize';

  export default {
    props: {
      count: {
        type: Number,
        default: 0,
      },
      recordName: {
        type: String,
        default: 'record',
      },
    },
    computed: {
      pluralizedName() {
        return pluralize(this.recordName, this.count);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .bulk-actions {
    display: flex;
    padding: 0 16px;
    margin-top: 32px;
    min-height: 50px;
    background-color: #EFF1FD;
    border: 1px solid #DDE1F9;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .bulk-actions__counter {
    @apply text-14 font-primary text-charade;
    padding-top: 18px;
    flex-shrink: 0;
  }

  .bulk-actions__list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 60px;
    gap: 30px;
  }

  .bulk-actions__list-item {
    @apply text-dark-blue;
    grid-row: 1;
    display: inline-block;
  }

  @media (max-width: 768px) {
    .bulk-actions__list {
      gap: 15px;
      padding-left: 30px;
    }
  }

  @media (max-width: 576px) {
    .bulk-actions {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .bulk-actions__list {
      padding-left: 20px;
    }
    .bulk-actions__list-item {
      min-width: 80px;
    }
  }
</style>
