import sanitizer from 'sanitize-html';

const defaultAllowedTags = [
  'p', 'blockquote', 'h1', 'h2', 'h3', 'h4', 'b', 'i', 'em', 'strong', 'sub',
  'sup', 'ul', 'ol', 'li', 'a', 'img', 'hr', 'br', 'span', 'strike', 'del', 's', 'u',
];
const defaultAllowedAttributes = ['style', 'align'];

export default {
  sanitizeHtml: (string, options = {}) => sanitizer(string, {
    allowedTags: options.allowedTags || defaultAllowedTags,
    allowedAttributes: {
      a: ['href', 'title', 'target', 'rel'],
      img: ['src', 'alt'],
      '*': options.allowedAttributes || defaultAllowedAttributes,
    },
  }),
};
