<template>
  <gl-button
    :variant="variant"
    :class="{ resizable }"
    class="add-time-button"
    @click="$emit('click', $event)"
  >
    <gl-base-icon
      name="time"
      :size="16"
      class="add-time-button__icon"
    />
    <span
      class="add-time-button__text"
    >
      Add Manual Time
    </span>
  </gl-button>
</template>

<script>
  import _includes from 'lodash/includes';

  import { GlButton, GlBaseIcon } from 'uikit/components';

  export default {
    components: {
      GlButton,
      GlBaseIcon,
    },
    props: {
      variant: {
        type: String,
        default: 'primary',
        validator(value) {
          return _includes(['primary', 'secondary'], value);
        },
      },
      resizable: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .add-time-button.button.medium {
    gap: 12px;
    padding: 12px;

    &.secondary svg {
      @apply text-icon-default;
      transition: .3s ease;
    }

    &.secondary:hover svg, &.secondary:focus-visible svg {
      @apply text-icon-hover;
    }
  }

  .add-time-button__icon {
    flex-shrink: 0;
  }

  @media (max-width: 767px) {
    .add-time-button.resizable.button {
      width: 40px;
      min-width: 40px;

      & .add-time-button__text {
        display: none;
      }
    }
  }
</style>
