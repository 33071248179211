// eslint-disable-next-line import/no-cycle
import store from '@/store';
import { CompanyType } from '@/constants/company';

const getCompanyLink = (company, openPublicLink, type) => {
  if (!company?._id && !company?.username) {
    return {
      name: 'page-not-found',
    };
  }

  const companyType = type || company.type;

  if (!companyType) {
    return {
      name: 'company',
      params: {
        companyId: company.username || company._id,
      },
    };
  }

  let name;
  let params;
  const { permissions } = store.getters;
  const canManageCompany = permissions.isCompanyMember(company._id);
  const isPublic = !canManageCompany || openPublicLink;

  if (companyType === CompanyType.AGENCY) {
    name = isPublic ? 'public-agency.summary' : 'agency-home.members';
    params = { agencyId: company.username || company._id };
  } else {
    name = isPublic ? 'public-company' : 'company-home.overview';
    params = { companyId: company.username || company._id };
  }

  return {
    name,
    params,
  };
};

const getActiveCompanies = companies => companies.filter(c => !c.isDeleted);

export { getCompanyLink, getActiveCompanies };
