import vueUtils from '@/services/infrastructure/vueUtils';
import {
  fullWalletTabs, lightWalletFreelancerTabs, lightWalletClientTabs, paymentRequestsTabs, walletPage,
} from '../constants/tabs';

export default () => {
  const { store } = vueUtils;

  const { permissions, currentUser } = store.getters;

  if (currentUser.usaFreelancerOnlyStripeEnabled && permissions.isFreelancer) {
    const dashboardTab = fullWalletTabs.find(t => t.value === 'gowallet.dashboard');
    return [dashboardTab];
  }

  if (!currentUser.isLimitedWalletEnabled) {
    return [...fullWalletTabs, ...paymentRequestsTabs, walletPage];
  }

  if (permissions.isFreelancer) {
    return lightWalletFreelancerTabs;
  }

  if (permissions.isEmployer) {
    return lightWalletClientTabs;
  }

  return fullWalletTabs;
};
