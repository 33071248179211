import sanitizeHTML from 'sanitize-html';
import wordCount from 'wordcount';
import _toArray from 'lodash/toArray';

const defaultOptions = {
  allowedTags: [],
  allowedAttributes: {},
  allowedIframeHostnames: [],
};

const defaultToCompareOptions = {
  allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol',
    'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'br', 'div', 'img', 'iframe'],
  allowedAttributes: {
    img: ['src'],
    iframe: ['src'],
    '*': ['align', 'class'],
  },
  allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com'],
  selfClosing: ['img', 'br'],
  allowedSchemes: ['http', 'https'],
  allowedSchemesByTag: {},
};

function clearText(text) {
  return text.replace(/\uFEFF/g, ''); // remove byte order mark symbol from total string
}

function toText(html, options = defaultOptions) {
  return sanitizeHTML(html || '', options);
}

function countWords(html, options = defaultOptions) {
  const text = sanitizeHTML(html, options);
  return wordCount(text);
}

function countCharacters(html, options = defaultOptions) {
  const text = clearText(sanitizeHTML(html || '', options));

  return _toArray(text).length;
}

function sanitizeToCompare(html, options = defaultToCompareOptions) {
  return clearText(sanitizeHTML(html || '', options));
}

export default {
  clearText,
  toText,
  countWords,
  countCharacters,
  sanitizeToCompare,
};
