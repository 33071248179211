<template>
  <table-pager-wrapper
    v-slot="{ pageValues, allPages }"
    :max-shown-numbers="maxShownNumbers"
    :page="page"
    :pages-count="pagesCount"
  >
    <div class="pager-container">
      <span class="record-counts">
        {{ recordCounts }}
      </span>
      <span
        class="pager"
        tabindex="0"
        @keyup.left="handlePrevPageClick()"
        @keyup.right="handleNextPageClick()"
      >
        <arrow-left-icon
          class="arrow-button left"
          @click.native="handlePrevPageClick()"
        />
        <span class="page-buttons">
          <span
            v-for="{ id, value, type } in pageValues"
            :key="id"
            :class="{ selected: page === value, clickable: type === 'page' }"
            class="page-button"
            @click="handlePageClick(value, type)"
          >
            {{ value }}
          </span>
        </span>
        <select-component
          v-model="pageModel"
          :options="allPages"
          class="page-select"
        />
        <arrow-right-icon
          class="arrow-button right"
          @click.native="handleNextPageClick()"
        />
      </span>
    </div>
  </table-pager-wrapper>
</template>

<script>
  import ArrowRightIcon from 'uikit/components/icons/ArrowRightIcon.vue';
  import ArrowLeftIcon from 'uikit/components/icons/ArrowLeftIcon.vue';

  import SelectComponent from './Select.vue';
  import TablePagerWrapper from './TablePagerWrapper.vue';

  export default {
    components: {
      ArrowRightIcon,
      ArrowLeftIcon,
      SelectComponent,
      TablePagerWrapper,
    },
    model: {
      prop: 'page',
      event: 'change',
    },
    props: {
      pagesCount: {
        type: Number,
        required: true,
      },
      totalCount: {
        type: Number,
        required: true,
      },
      rowsCount: {
        type: Number,
        required: true,
      },
      page: {
        type: Number,
        required: true,
      },
      maxShownNumbers: {
        type: Number,
        default: 7,
      },
    },
    computed: {
      pageModel: {
        get() {
          return this.page;
        },
        set(val) {
          this.$emit('change', val);
        },
      },
      recordCounts() {
        const start = (this.page - 1) * this.rowsCount + 1;
        const end = Math.min(this.page * this.rowsCount, this.totalCount);
        return `${start}-${end} of ${this.totalCount}`;
      },
    },
    methods: {
      handlePrevPageClick() {
        this.handlePageClick(this.page - 1, 'page');
      },
      handleNextPageClick() {
        this.handlePageClick(this.page + 1, 'page');
      },
      handlePageClick(page, type) {
        if (type === 'page' && page >= 1 && page <= this.pagesCount && page !== this.page) {
          this.$emit('change', page);
        }
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .pager-container {
    @apply text-14 text-charade font-primary;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .pager {
    @apply bg-white inline-block flex items-center justify-between;
    height: 32px;
    border-radius: 16px;
    padding: 0;
    margin-left: 23px;
    z-index: 1;
  }

  .arrow-button {
    @apply text-blue flex items-center justify-center;
    user-select: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .page-buttons {
    margin-right: 13px;
    margin-left: 13px;
    @apply inline-block flex items-center justify-between;
  }

  .page-button {
    @apply flex items-center justify-center;
    user-select: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    &.selected {
      @apply text-white;
      background: linear-gradient(89.7deg, #4566c8 -1.66%, #435aae 95.51%);
    }
    &.clickable {
      cursor: pointer;
    }
  }

  .page-button + .page-button {
    margin-left: 15px;
  }

  .page-select {
    display: none;
  }

  .record-counts {
    cursor: default;
  }

  @media (max-width: 768px) {
    .pager {
      background-color: transparent;
      margin-left: 9px;
    }

    .record-counts {
      display: none;
    }

    .page-select {
      display: block;
    }

    .page-buttons {
      display: none;
    }

    .arrow-button {
      @apply bg-white;
      height: 34px;
      width: 34px;
      &.left {
        margin-right: 6px;
      }
      &.right {
        margin-left: 6px;
      }
    }
  }
</style>
