<template>
  <li class="accordion-item">
    <gl-expand-transition
      use-v-show
      :open="open"
      :initial-expanded-height="1000"
    >
      <template #shown>
        <div @click="handleLabelClick">
          <slot
            name="header"
            :open="open"
          >
            <div
              class="accordion-item__header"
              :class="{open}"
            >
              <span class="header__label">
                {{ label }}
              </span>
              <span class="header__icon">
                <gl-icon
                  :open="open"
                  name="dropdown"
                />
              </span>
            </div>
          </slot>
        </div>
      </template>
      <template #expandable>
        <div class="accordion-item__content">
          <slot></slot>
        </div>
      </template>
    </gl-expand-transition>
  </li>
</template>

<script>
  import GlIcon from '@/uikit/icons/BaseIcon.vue';
  import GlExpandTransition from '@/uikit/components/transitions/ExpandTransition.vue';

  export default {
    components: {
      GlIcon,
      GlExpandTransition,
    },
    props: {
      label: {
        type: String,
        default: null,
      },
      preOpen: {
        type: Boolean,
        default: false,
      },
      index: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        open: this.preOpen,
        expandedHeight: 1,
      };
    },
    methods: {
      handleLabelClick() {
        this.open = !this.open;
        if (this.open) {
          this.$emit('open', this.index);
        }
      },
      handleTransitionEnter(el) {
        this.expandedHeight = el.scrollHeight;
      },
      closeAccordion() {
        this.open = false;
      },
      openAccordion() {
        this.open = true;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .header__icon {
    @apply text-icon-default-dark;
    display: inline-flex;
    padding: 4px;
  }

  .accordion-item {
    list-style-type: none;
  }

  .accordion-item__header {
    @apply flex items-center justify-between;
    user-select: none;
    padding: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s ease all;
  }

  .header__label {
    @apply font-primary text-text-black text-14 font-semibold;
    line-height: 1.43em;
    letter-spacing: 0.0025em;
  }
</style>
