<template>
  <div>
    <global-notification
      v-if="isRestricted"
      is-close-icon-shown
      type="danger"
      @close="handleClose"
    >
      We were unable to receive payment from your client. As a result, we will need to restrict your ability to track time for this client
    </global-notification>
    <global-notification
      v-else-if="isRestrictedSoon"
      is-close-icon-shown
      type="warning"
      @close="handleClose"
    >
      We were unable to receive payment from your client.
      As a result, we will need to restrict your ability to track time for this client starting from {{ date | formatDate('dd.LL.yyyy') }}.
    </global-notification>
  </div>
</template>

<script>
  import formatDate from '@/filters/formatDate';

  import GlobalNotification from './GlobalNotification.vue';

  export default {
    components: {
      GlobalNotification,
    },
    filters: {
      formatDate,
    },
    data() {
      return {
        isRestricted: false, // TO DO: Fetch from backend
        isRestrictedSoon: true, // TO DO: Fetch from backend
        date: new Date().toISOString(), // TO DO: Fetch from backend
      };
    },
    methods: {
      handleClose() {
        this.$emit('close');
      },
    },
  };
</script>
