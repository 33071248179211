<template>
  <div
    :class="[type]"
    class="message-wrapper"
  >
    <div
      class="message-icon"
      :class="{ 'with-title': !!title }"
    >
      <component
        :is="`gl-${type}-icon`"
        v-if="!$slots.icon"
      />
      <slot name="icon"></slot>
    </div>
    <div
      class="message-content"
      :class="{ 'with-title': !!title }"
    >
      <div
        v-if="title"
        class="message-title"
      >
        {{ title }}
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import GlWarningIcon from '@/components/icons/WarningIcon.vue';
  import GlDangerIcon from '@/components/icons/NotificationErrorIcon.vue';
  import GlInfoIcon from '@/components/icons/NotificationInfoIcon.vue';
  import GlSuccessIcon from '@/components/icons/NotificationSuccessIcon.vue';

  export default {
    components: {
      GlDangerIcon,
      GlWarningIcon,
      GlInfoIcon,
      GlSuccessIcon,
    },

    props: {
      type: {
        type: String,
        default: 'info',
        validator: val => ['warning', 'danger', 'info', 'success'].includes(val),
      },
      title: {
        type: String,
        default: '',
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .message-wrapper {
    @apply flex font-primary text-charade text-14 relative mt-2;
    padding: 16px;
    border-radius: 16px;

    &.warning {
      --icon-color: #ffa83a;
      border: 1px solid #ffa83a;
      background-color: #fefcf2;
    }

    &.danger {
      --icon-color: #dd2929;
      background-color: #fdf7f8;
      border: 1px solid #dd2929;
    }

    &.info {
      @apply bg-foundation-gray-2;
      --icon-color: #475baf;
      border: 1px solid #4163dd;
    }

    &.success {
      --icon-color: #1fc67e;
      border: 1px solid #1fc67e;
      background-color: #f5fbf5;
    }
  }
  .message-icon {
    @apply flex;
    margin-right: 16px;
    color: var(--icon-color);

    &:not(.with-title) {
      @apply items-center;
    }
  }
  .message-content {
    line-height: 20px;

    &:not(.with-title) {
      @apply flex items-center;
    }
  }
  .message-title {
    @apply text-16 font-semibold text-text-black;
    margin-bottom: 8px;
  }

  @media (max-width: 576px) {
    .message-wrapper {
      @apply text-12;
    }
  }
</style>
