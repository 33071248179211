<template>
  <div
    v-click-outside="handleClose"
    class="date-range"
  >
    <div>
      <slot
        :handleToggle="handleToggle"
        :handleClose="handleClose"
        :isOpened="isOpened"
      ></slot>
    </div>
    <transition name="fade">
      <gl-drop-down
        v-if="isOpened"
        is-fixed
        class="date-range__dropdown"
      >
        <div class="dropdown__body">
          <div class="date-range__inputs">
            <date-input
              label="Start date"
              :placeholder="inputPlaceholder"
              :value="dateRange.fromDate"
              @change="handleChangeFromDate"
            />
            <date-input
              label="End date"
              :placeholder="inputPlaceholder"
              :value="dateRange.toDate"
              @change="handleChangeToDate"
            />
          </div>
          <gl-date-picker
            v-model="dateRangeModel"
            placeholder="Select Date"
            label=""
            required
            range
            inline
            class="date-range__picker"
            @input="handleClose"
          />
        </div>
      </gl-drop-down>
    </transition>
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  import GlDatePicker from '../DatePicker/index.vue';
  import GlDropDown from '../DropDown.vue';

  import DateInput from './DateInput.vue';

  export default {
    components: {
      GlDatePicker,
      GlDropDown,
      DateInput,
    },
    model: {
      event: 'change',
      prop: 'dateRange',
    },
    props: {
      dateRange: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        isOpened: false,
      };
    },
    computed: {
      dateRangeModel: {
        get() {
          const { fromDate, toDate } = this.dateRange;
          return [fromDate, toDate];
        },
        set([fromDate, toDate]) {
          this.$emit('change', {
            fromDate,
            toDate,
          });
        },
      },
      inputPlaceholder() {
        return `E.g. ${moment().format('MMM D, YYYY')}`;
      },
    },
    methods: {
      handleChangeFromDate(value) {
        this.$emit('change', {
          fromDate: value,
          toDate: this.getEndDateAccordingToStartDate(value, this.dateRange.toDate),
        });
      },
      handleChangeToDate(value) {
        this.$emit('change', {
          fromDate: this.dateRange.fromDate,
          toDate: this.getEndDateAccordingToStartDate(this.dateRange.fromDate, value),
        });
      },
      getEndDateAccordingToStartDate(fromDate, toDate) {
        if (fromDate && toDate && fromDate > toDate) {
          return new Date(fromDate);
        }

        return toDate;
      },
      handleToggle() {
        this.isOpened = !this.isOpened;
      },
      handleClose() {
        this.isOpened = false;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .date-range {
    @apply relative;
    width: fit-content;
  }

  .date-range__dropdown.filter-dropdown {
    z-index: 1000;
    border-radius: 12px;
  }

  .date-range__inputs {
    @apply flex items-center justify-between;
    gap: 16px;
    padding: 16px;

    & >>> .input-wrapper {
      flex: 1;
    }
  }
  .date-range__picker {
    & >>> .mx-datepicker-main {
      border-radius: 0 0 12px 12px;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-leave {
    opacity: 0;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @media (max-width: 750px) {
    .date-range__picker {
      & >>> .mx-range-wrapper {
        & .mx-calendar.mx-calendar-panel-date:nth-child(2) {
          display: none;
        }
      }
    }

    .date-range__inputs {
      @apply flex-col items-stretch;
    }
  }
</style>
