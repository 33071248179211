<template>
  <div class="category-block">
    <div
      class="option-item category-option-item"
      @click="handleOptionChange"
    >
      <gl-checkbox
        :value="checkboxValue"
        :indeterminate="checkboxIndeterminate"
        class="option-item__checkbox"
        @keyboard-change="handleOptionChange"
      />

      <component
        :is="filterConfig.categoryOptionComponent"
        v-if="filterConfig.categoryOptionComponent"
        :option="categoryOption"
        :selected="checkboxValue"
      />
      <p
        v-else
        class="option-item__label"
      >
        {{ optionLabel }}
      </p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
  import _get from 'lodash/get';
  import GlCheckbox from 'uikit/components/Checkbox/index.vue';

  const categoryIconSelection = {
    ALL: 'all',
    SOME: 'some',
    NONE: 'none',
  };

  export default {
    components: {
      GlCheckbox,
    },
    props: {
      categoryOption: {
        type: [String, Object],
        required: true,
      },
      filterConfig: {
        type: Object,
        required: true,
      },
      filters: {
        type: Object,
        required: true,
      },
      labelKey: {
        type: String,
        required: true,
      },
      valueKey: {
        type: String,
        required: true,
      },
      returnObject: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        categoryIconSelection,
      };
    },
    computed: {
      filterKey() {
        return this.filterConfig.key;
      },
      isMultiple() {
        return this.filterConfig.multiple;
      },
      relatedFilter() {
        return this.filters[this.filterKey] || [];
      },
      isFirstOption() {
        return !this.relatedFilter.length;
      },
      optionLabel() {
        return _get(this.categoryOption, this.filterConfig.categoryLabelKey);
      },
      checkboxValue() {
        return [categoryIconSelection.ALL, categoryIconSelection.SOME].includes(this.categoryIconState);
      },
      checkboxIndeterminate() {
        return this.categoryIconState === categoryIconSelection.SOME;
      },
      categoryIconState() {
        const categoryItems = this.categoryOption[this.filterConfig.categoryItemsField];

        if (this.isMultiple) {
          const selectedOptions = categoryItems.filter(option => {
            const isInFilter = this.relatedFilter.some(filterValue => (this.returnObject ? filterValue[this.valueKey] === option[this.valueKey] : filterValue === option[this.valueKey]));
            return isInFilter;
          });

          if (selectedOptions.length === categoryItems.length) {
            return categoryIconSelection.ALL;
          }

          if (selectedOptions.length > 0) {
            return categoryIconSelection.SOME;
          }

          return categoryIconSelection.NONE;
        }

        const isOptionSelected = categoryItems.findIndex(option => {
          const isInFilter = this.returnObject ? this.relatedFilter[this.valueKey] === option[this.valueKey] : this.relatedFilter === option[this.valueKey];
          return isInFilter;
        });

        if (isOptionSelected >= 0 && categoryItems.length === 1) {
          return categoryIconSelection.ALL;
        } if (isOptionSelected >= 0) {
          return categoryIconSelection.SOME;
        }

        return categoryIconSelection.NONE;
      },
    },

    methods: {
      handleOptionChange() {
        const updatedFilter = this.getUpdatedFilter();
        this.$emit('change', { [this.filterKey]: updatedFilter });
      },
      getUpdatedFilter() {
        let updatedFilter;

        if (this.isMultiple) {
          updatedFilter = this.getMultipleUpdatedFilter();
        } else {
          updatedFilter = this.getSingleUpdatedFilter();
        }

        return updatedFilter;
      },
      getMultipleUpdatedFilter() {
        let updatedFilter;
        const categoryItems = this.categoryOption[this.filterConfig.categoryItemsField];

        switch (this.categoryIconState) {
          case categoryIconSelection.ALL: {
            const removeValueKeys = categoryItems.map(item => item[this.valueKey]);
            updatedFilter = this.relatedFilter.filter(value => !removeValueKeys.includes((this.returnObject ? value[this.valueKey] : value)));
            break;
          }
          case categoryIconSelection.SOME: {
            let missingValues = categoryItems.filter(item => this.relatedFilter.findIndex(filterItem => filterItem[this.valueKey] === item[this.valueKey]) === -1);
            missingValues = this.returnObject ? missingValues : missingValues.map(missingValue => missingValue[this.valueKey]);
            updatedFilter = [...this.relatedFilter, ...missingValues];
            break;
          }
          case categoryIconSelection.NONE: {
            const missingValues = this.returnObject ? categoryItems : categoryItems.map(missingValue => missingValue[this.valueKey]);
            updatedFilter = [...this.relatedFilter, ...missingValues];
            break;
          }
          default:
            break;
        }

        return updatedFilter;
      },
      getSingleUpdatedFilter() {
        let updatedFilter;
        const categoryItems = this.categoryOption[this.filterConfig.categoryItemsField];

        switch (this.categoryIconState) {
          case categoryIconSelection.ALL:
          case categoryIconSelection.SOME:
            updatedFilter = null;
            break;
          case categoryIconSelection.NONE: {
            const firstValue = this.returnObject ? categoryItems[0] : categoryItems[0][this.valueKey];
            updatedFilter = firstValue;
            break;
          }
          default:
            break;
        }

        return updatedFilter;
      },
    },
  };
</script>

<style lang="postcss" scoped>

.category-block {
  @apply flex flex-col gap-1;
}

.category-option-item {
  @apply bg-foundation-gray-3;

}

.option-item {
  @apply flex items-center;
  gap: 16px;
  padding: 8px;
  border-radius: 8px;
  transition: .1s ease background-color;
  cursor: pointer;

  &__label {
    @apply text-14 text-text-black;
  }

  &__checkbox:focus, &__checkbox:focus-visible  {
    outline: none;
  }

  &__checkbox {
    & >>> .checkbox {
      margin-right: 0;
      margin-top: 0;
    }
  }

  &:hover, &:focus-within {
    @apply bg-foundation-gray-2;

    & .category-item-component {
      @apply bg-white;
    }
  }
}
</style>
