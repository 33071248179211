<template>
  <div>
    <field-label
      v-if="label"
      :text="label"
      :required="required"
      :error="error"
    />
    <date-picker
      ref="datepicker"
      v-model="valueModel"
      v-click-outside="handleClickOutside"
      :lang="lang"
      :type="type"
      :clearable="clearable"
      :disabled="disabled"
      :format="dateFormat"
      :formatter="formatter"
      :placeholder="placeholder"
      :disabled-date="getDisabledDates"
      :append-to-body="appendToBody"
      :default-panel="defaultPanel"
      :default-value="defaultValue"
      :range="range"
      :popup-style="popupStyle"
      :inline="inline"
      @calendar-change="handleCalendarChange"
    >
      <template #icon-calendar>
        <div></div>
      </template>
      <template #input="{ props, events }">
        <gl-input
          class="datepicker-input"
          v-bind="props"
          :error="error"
          v-on="events"
        />
      </template>
    </date-picker>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker';
  import FieldLabel from 'uikit/components/FieldLabel.vue';
  import GlInput from 'uikit/components/inputs/Input.vue';

  const defaultDateFormat = 'DD MMM, YYYY';
  const defaultTimeFormat = 'h:mm a';

  export default {
    components: {
      DatePicker,
      FieldLabel,
      GlInput,
    },

    model: {
      event: 'input',
    },

    props: {
      value: {
        type: [Number, Object, String, Date, Array],
        default: () => {},
      },
      type: {
        type: String,
        default: 'date',
        validator: val => ['date', 'datetime', 'year', 'month', 'time', 'week'].includes(val),
      },
      error: {
        type: [String, Boolean],
        default: null,
      },
      label: {
        type: String,
        default: null,
      },
      placeholder: {
        type: String,
        default: null,
      },
      required: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      inline: {
        type: Boolean,
        default: false,
      },
      format: {
        type: String,
        default: defaultDateFormat,
      },
      timeFormat: {
        type: String,
        default: defaultTimeFormat,
      },
      formatter: {
        type: Object,
        default: null,
      },
      minDate: {
        type: [String, Array, Date],
        default: null,
      },
      maxDate: {
        type: [String, Array, Date],
        default: null,
      },
      range: {
        type: Boolean,
        deafult: false,
      },
      appendToBody: {
        type: Boolean,
        default: false,
      },
      popupStyle: {
        type: Object,
        default: () => ({}),
      },
      lang: {
        type: Object,
        default: () => ({}),
      },
      closeByClickOutside: {
        type: Boolean,
        default: true,
      },
      defaultPanel: {
        type: String,
        default: null,
      },
      defaultValue: {
        type: Date,
        default: () => new Date(),
      },
    },

    data() {
      return {
        defaultDateFormat,
        defaultTimeFormat,
      };
    },

    computed: {
      valueModel: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },

      dateFormat() {
        if (this.type === 'time') {
          return this.timeFormat;
        }
        if (this.type === 'datetime') {
          return `${this.format} ${this.timeFormat}`;
        }
        return this.format;
      },
    },

    methods: {
      getDisabledDates(date) {
        if (this.minDate && this.maxDate) {
          return date < this.minDate || date > this.maxDate;
        }

        if (this.minDate) {
          return date < this.minDate;
        }

        if (this.maxDate) {
          return date > this.maxDate;
        }

        return null;
      },
      handleClickOutside(event) {
        if (this.closeByClickOutside && !this.$el.contains(event.target) && this.type !== 'time' && this.type !== 'datetime') {
          this.closePopup();
        }
      },
      closePopup() {
        this.$refs.datepicker.closePopup();
      },
      handleCalendarChange(...args) {
        this.$emit('calendar-change', ...args);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .mx-datepicker {
    width: auto;
    display: block;

    & >>> .mx-input {
      display: contents;
    }
  }
</style>
