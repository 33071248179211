// @ts-check
import client, { Api } from './client';

const BASE_URL = '/contracts';

const checkPaymentEvent = (apiData, payment) => apiData.commandId === (payment.metadata && payment.metadata.command_id);

export default {
  getList(conditions) {
    return client.get([BASE_URL], conditions);
  },
  getContractors(conditions) {
    return client.get([BASE_URL, 'contractors'], conditions);
  },
  getEmployers(conditions) {
    return client.get([BASE_URL, 'employers'], conditions);
  },
  get(contractId) {
    return client.get([BASE_URL, contractId]);
  },
  /**
   * @param {string} contractId
   * @param {import('../../../api/contract/endpoints/milestones/add/schema').Schema} payload
   * @returns
   */
  addMilestone(contractId, payload) {
    return Api.waitForCommand(client.post([BASE_URL, contractId, 'milestones'], payload));
  },
  /**
   * @param {{
   *  contractId: string;
   *  milestoneId: string;
   * }} urlParams
   * @param {import('../../../api/contract/endpoints/milestones/update/schema').Schema} payload
   * @returns
   */
  updateMilestone(urlParams, payload) {
    const { contractId, milestoneId } = urlParams;
    const request = client.put([BASE_URL, contractId, 'milestones', milestoneId], payload);
    return Api.waitForCommand(request);
  },
  /**
   * @param {string} contractId
   * @param {string} milestoneId
   * @returns
   */
  deleteMilestone(contractId, milestoneId) {
    const request = client.delete([BASE_URL, contractId, 'milestones', milestoneId]);
    return Api.waitForCommand(request);
  },
  changeTeam(contractId, data) {
    return Api.waitForCommand(client.put([BASE_URL, contractId, 'team'], data));
  },
  /**
   * @param {{
   *  contractId: string;
   *  milestoneId: string;
   * }} urlParams
   * @param {import('../../../api/contract/endpoints/milestones/fund/schema').Schema} payload
   * @returns
   */
  fundMilestone(urlParams, payload) {
    const { contractId, milestoneId } = urlParams;
    const request = client.post([BASE_URL, contractId, 'milestones', milestoneId, 'fund'], payload);
    return Api.waitForEvent(
      request,
      {
        success: 'io:payment:succeeded',
        failure: 'io:payment:failed',
      },
      checkPaymentEvent,
    );
  },
  /**
   * @param {{
   *  contractId: string;
   *  milestoneId: string;
   * }} urlParams
   * @returns
   */
  startMilestone(urlParams) {
    const { contractId, milestoneId } = urlParams;
    const request = client.post([BASE_URL, contractId, 'milestones', milestoneId, 'start']);
    return Api.waitForCommand(request);
  },
  /**
   * @param {{
   *  contractId: string;
   *  milestoneId: string;
   * }} urlParams
   * @param {import('../../../api/contract/endpoints/milestones/declineRequestedPayment/schema').Schema} payload
   * @returns
   */
  declinePaymentRequest({ contractId, milestoneId }, payload) {
    const request = client.post([BASE_URL, contractId, 'milestones', milestoneId, 'decline-requested-payment'], payload);
    return Api.waitForCommand(request);
  },
  /**
   * @param {{
   *  contractId: string;
   *  milestoneId: string;
   * }} urlParams
   * @param {import('../../../api/contract/endpoints/milestones/requestPayment/schema').Schema} payload
   * @returns
   */
  requestPayment(urlParams, payload) {
    const { contractId, milestoneId } = urlParams;
    const request = client.post([BASE_URL, contractId, 'milestones', milestoneId, 'request-payment'], payload);
    return Api.waitForCommand(request);
  },
  /**
   * @param {{
   *  contractId: string;
   *  milestoneId: string;
   * }} urlParams
   * @returns
   */
  payForMilestone(urlParams) {
    const { contractId, milestoneId } = urlParams;
    const request = client.post([BASE_URL, contractId, 'milestones', milestoneId, 'pay']);
    return Api.waitForEvent(
      request,
      {
        success: 'io:payment:succeeded',
        failure: 'io:payment:failed',
      },
      checkPaymentEvent,
    );
  },
  count(conditions) {
    return client.get([BASE_URL], { count: true, ...conditions });
  },
  close: contractId => Api.waitForCommand(client.put([BASE_URL, contractId, 'close'])),
  pause: contractId => Api.waitForCommand(client.put([BASE_URL, contractId, 'pause'])),
  resume(contractId) {
    return Api.waitForCommand(client.put([BASE_URL, contractId, 'resume']));
  },
  updateCollaborators(contractId, payload) {
    return Api.waitForCommand(client.post([BASE_URL, contractId, 'collaborators'], payload));
  },
  getNotifications(contractId, conditions) {
    return client.get([BASE_URL, contractId, 'notifications'], conditions);
  },
  getFreelancerBillingHistory(contractId, conditions) {
    return client.get([BASE_URL, contractId, 'freelancer', 'billing-history'], conditions);
  },
  getEmployerBillingHistory(contractId, conditions) {
    return client.get([BASE_URL, contractId, 'client', 'billing-history'], conditions);
  },
  getManualInvoices(contractId, conditions) {
    return client.get([BASE_URL, contractId, 'manual-invoices'], conditions);
  },
  changeTitle(contractId, data) {
    return Api.waitForCommand(client.put([BASE_URL, contractId, 'title'], data));
  },
  changeRate(contractId, data) {
    return Api.waitForCommand(client.put([BASE_URL, contractId, 'rate'], data));
  },
  changeRateNow(contractId) {
    return Api.waitForCommand(client.post([BASE_URL, contractId, 'change-rate-now']));
  },
  cancelChangingRate(contractId) {
    return Api.waitForCommand(client.delete([BASE_URL, contractId, 'rate']));
  },
  changeClientFee(contractId, data) {
    return Api.waitForCommand(client.put([BASE_URL, contractId, 'client-fee'], data));
  },
  changeClientFeeNow(contractId) {
    return Api.waitForCommand(client.put([BASE_URL, contractId, 'change-client-fee-now']));
  },
  cancelChangingClientFee(contractId) {
    return Api.waitForCommand(client.delete([BASE_URL, contractId, 'client-fee']));
  },
  changeWeeklyLimit(contractId, data) {
    return Api.waitForCommand(client.put([BASE_URL, contractId, 'weekly-limit'], data));
  },
  changeWeeklyLimitNow(contractId) {
    return Api.waitForCommand(client.post([BASE_URL, contractId, 'change-weekly-limit-now']));
  },
  cancelChangingWeeklyLimit(contractId) {
    return Api.waitForCommand(client.delete([BASE_URL, contractId, 'weekly-limit']));
  },
  updateActivityCodesSettings(contractId, data) {
    return Api.waitForCommand(client.put([BASE_URL, contractId, 'activity-codes-settings'], data));
  },
  changeReviewPeriod(contractId, data) {
    return Api.waitForCommand(client.put([BASE_URL, contractId, 'review-period'], data));
  },
  updateEmployerSettings(contractId, data) {
    return Api.waitForCommand(client.post([BASE_URL, contractId, 'employer-settings'], data));
  },
  searchTags(conditions) {
    return client.get(['/tags', 'search'], conditions);
  },
  setTags(userId, data) {
    return Api.waitForCommand(client.put([BASE_URL, userId, 'tags'], data));
  },
  getScheduledPaymentList(contractId) {
    return client.get([BASE_URL, contractId, 'scheduled-payments']);
  },
  addScheduledPaymentRule(contractId, payload) {
    return Api.waitForCommand(client.post([BASE_URL, contractId, 'scheduled-payments'], payload));
  },
  deleteScheduledPaymentRule(contractId, payload) {
    return Api.waitForCommand(client.delete([BASE_URL, contractId, 'scheduled-payments'], payload));
  },
  deleteScheduledPayment(contractId, scheduledPaymentId, payload) {
    return Api.waitForCommand(client.delete([BASE_URL, contractId, 'scheduled-payments', scheduledPaymentId], payload));
  },
  updateScheduledPayment(contractId, scheduledPaymentId, payload) {
    return Api.waitForCommand(client.patch([BASE_URL, contractId, 'scheduled-payments', scheduledPaymentId], payload));
  },
  getFinances(contractId, params) {
    return client.get([BASE_URL, contractId, 'finance'], params);
  },
  settleAndRelease(contractId, data) {
    return Api.waitForCommand(client.put([BASE_URL, contractId, 'settle-release'], data));
  },
};
