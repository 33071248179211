import Vue from 'vue';

import { getErrorMessage } from '@/services/errors';

function showNotification(text, type, options) {
  Vue.notify({
    group: 'default',
    type,
    text,
    data: {},
    ...options,
  });
}

export default {
  showSuccess(message, options = {}) {
    const duration = 7000;
    showNotification(message, 'success', { duration, ...options });
  },

  showGolanceError(error, options = {}) {
    const errorMessage = getErrorMessage(error, options);

    this.showError(errorMessage, {
      ...options,
      ignoreDuplicates: true,
    });
  },

  showError(message, options = {}) {
    showNotification(message, 'danger', options);
  },

  showWarning(message, options = {}) {
    showNotification(message, 'warning', options);
  },

  showInfo(message, options = {}) {
    showNotification(message, 'info', options);
  },
};
