<template>
  <div class="date-picker">
    <div class="date-picker__select-block">
      <gl-rich-select
        :value="selectedPreset"
        label-text="Show for"
        :options="presets"
        :searchable="false"
        open-direction="bottom"
        track-by="type"
        label="label"
        class="date-picker__select"
        @input="selectPreset"
      />
      <div class="date-picker__inputs">
        <new-input-date
          :value="dateRange.start"
          @enter="handleStartDateEnter"
        />
        <div class="date-picker__separator"></div>
        <new-input-date
          :value="dateRange.end"
          @enter="handleEndDateEnter"
        />
      </div>
    </div>
    <div class="right-side">
      <v-date-picker
        ref="datepicker"
        v-model="dateRangeModel"
        :columns="isMobileView ? 1 : 2"
        :rows="isMobileView ? 2 : 1"
        :locale="locale"
        :max-date="maxDate"
        :min-date="minDate"
        :attributes="attributes"
        transition="none"
        :timezone="'UTC'"
        class="calendar"
        color="blue"
        is-range
        @input="selectCustomPreset"
        @update:from-page="setCurrentYearFromDatePicker"
      />
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import { mapGetters } from 'vuex';
  import { GlRichSelect } from '@/uikit/components';

  import _cloneDeep from 'lodash/cloneDeep';
  import _forEach from 'lodash/forEach';
  import _findIndex from 'lodash/findIndex';
  import VDatePicker from 'v-calendar/lib/components/date-picker.umd';
  import screenSizeService from 'uikit/utils/screenSize';

  import { compareByDay } from '../../../../../helpers/date';

  import NewInputDate from './DateInput.vue';

  const MIN_DATE = new Date(0);
  const MOBILE_BREAKPOINT = 768;

  const DEFAULT_DATE_RANGE = {
    start: new Date(),
    end: new Date(),
  };

  export default {
    components: {
      VDatePicker,
      GlRichSelect,
      NewInputDate,
    },
    model: {
      event: 'change',
      prop: 'dateRange',
    },
    props: {
      maxDate: {
        type: Date,
        default() {
          return moment.utc().endOf('day').toDate();
        },
      },
      minDate: {
        type: Date,
        default: () => MIN_DATE,
      },
      disablePresetsBeforeMaxDate: {
        type: Boolean,
        default: false,
      },
      dateRange: {
        type: Object,
        default: () => DEFAULT_DATE_RANGE,
      },
      presets: {
        type: Array,
        default: () => [],
      },
      selectedDate: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        locale: { id: 'en', firstDayOfWeek: 2, masks: { weekdays: 'WW' } },
        currentYearView: new Date().getFullYear(),
        selectedPreset: this.presets[0],
        screenSize: screenSizeService.getScreenSize(),
        attributes: [
          {
            key: 'today',
            highlight: {
              class: 'highlight-day',
            },
            dates: new Date(),
          },
        ],
      };
    },
    computed: {
      ...mapGetters(['currentTimezone']),
      isMobileView() {
        return this.screenSize.windowWidth < MOBILE_BREAKPOINT;
      },
      dateRangeModel: {
        set(dateRange) {
          const newDateRange = {
            start: moment.utc(dateRange.start).startOf('day').toDate(),
            end: moment.utc(dateRange.end).endOf('day').toDate(),
          };
          this.$emit('change', newDateRange);
          this.selectDateRange(newDateRange);
        },
        get() {
          return this.dateRange;
        },
      },
    },
    watch: {
      selectedDate(value) {
        if (value.start && value.end) {
          this.dateRangeModel = this.selectedDate;
        }
        this.preselectPreset();
      },
    },
    beforeDestroy() {
      screenSizeService.cleanup();
    },
    created() {
      const dateRange = this.dateRangeModel || DEFAULT_DATE_RANGE;
      if (this.selectedDate.start && this.selectedDate.end && !compareByDay(this.selectedDate, dateRange)) {
        this.dateRangeModel = this.selectedDate;
      }
    },
    mounted() {
      screenSizeService.init();
      this.moveViewToCurrentRange(this.selectedDate.start);
      this.setCurrentYearFromDatePicker();
      this.preselectPreset();
    },
    methods: {
      preselectPreset() {
        const presetIndex = _findIndex(this.presets, preset => this.checkPreset(preset, this.dateRangeModel));
        if (presetIndex > -1) {
          this.selectedPreset = this.presets[presetIndex];
        } else {
          this.selectedPreset = this.presets[this.presets.length - 1];
        }
      },
      checkPreset(preset, range) {
        let presetStart = moment(preset.value.start);
        let presetEnd = moment(preset.value.end);

        if (presetEnd.isAfter(moment(this.maxDate))) {
          presetEnd = moment(this.maxDate);
        }
        if (presetStart.isBefore(moment(this.minDate))) {
          presetStart = moment(this.minDate);
        }
        return compareByDay(range, { start: presetStart, end: presetEnd });
      },
      setCurrentYearFromDatePicker() {
        const { datepicker } = this.$refs;
        if (datepicker) {
          this.currentYearView = datepicker.$children[0].firstPage.year;
        }
      },
      moveByMonths(monthsNumber) {
        const { datepicker } = this.$refs;
        const datepickerCalendar = datepicker.$children[0];
        datepickerCalendar.move(monthsNumber);
      },
      async moveViewToCurrentRange(date) {
        if (moment(date).isValid()) {
          await this.$refs.datepicker.$children[0].move(moment(date).toDate());
          this.currentYearView = Number(moment(date).format('YYYY'));
        }
      },
      isPresetDisabled(preset) {
        if (preset.title === 'Custom') {
          return false;
        }

        return moment(this.maxDate).isBefore(moment(preset.dateRange.start));
      },
      handleStartDateEnter(date) {
        this.selectCustomPreset();
        let maxDate = this.dateRange.end;

        if (moment(date).isAfter(maxDate)) {
          maxDate = date;
        }

        const range = {
          start: moment.utc(date).toDate(),
          end: moment.utc(maxDate).toDate(),
        };
        this.setDateRange(range);
      },
      handleEndDateEnter(date) {
        this.selectCustomPreset();
        let minDate = this.dateRange.start;

        if (moment(date).isBefore(minDate)) {
          minDate = date;
        }

        const range = {
          start: moment.utc(minDate).toDate(),
          end: moment.utc(date).toDate(),
        };
        this.setDateRange(range);
      },
      selectPreset(preset) {
        this.selectedPreset = preset;
        this.setDateRange(_cloneDeep(preset.value));
      },
      selectCustomPreset() {
        this.selectedPreset = this.presets[this.presets.length - 1];
      },
      setDateRange(selectedRange) {
        _forEach(selectedRange, (rangeValue, rangeKey) => {
          if (moment.utc(this.maxDate).isBefore(rangeValue)) {
            selectedRange[rangeKey] = this.maxDate;
          }
          if (moment.utc(this.minDate).isAfter(rangeValue)) {
            selectedRange[rangeKey] = this.minDate;
          }
        });

        this.dateRangeModel = _cloneDeep(selectedRange);
        this.moveViewToCurrentRange(selectedRange.start);
      },
      selectDateRange(dateRange) {
        this.$emit('save', dateRange || this.dateRange);
      },
      resetDateRange() {
        this.selectCustomPreset();
        this.setDateRange(_cloneDeep(this.presets[0].value));
      },
      handleClose() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .date-picker {
    @apply bg-white flex flex-col;

    &__select-block {
      @apply flex items-center justify-between border-1 border-foundation-gray-4;
      min-width: 100%;
      padding: 24px;
      border-width: 0 0 1px 0;
    }

    &__select {
      min-width: 250px;
    }

    &__inputs {
      @apply flex items-center;
      align-self: end;
    }

    &__separator {
      @apply bg-black;
      height: 1px;
      width: 8px;
      margin: 0 8px;
    }

    & .right-side {
      @apply items-center;
      & .header {
        @apply bg-white w-full flex justify-between;
        height: 45px;
        padding: 20px 20px 6px 20px;
        & .year {
          @apply flex items-center text-charade;
          margin-right: 10px;
          & span {
            @apply font-primary font-normal text-12 text-black;
            margin: 0 20px;
            padding-top: 4px;
            width: 30px;
          }
          & .icon {
            @apply cursor-pointer;
          }
        }
        & .inputs {
          @apply flex items-center;
          & .separator {
            @apply bg-black;
            height: 1px;
            width: 8px;
            margin: 0 8px;
          }
        }
        & .close {
          @apply absolute;
          right: 10px;
          top: 5px;
        }
      }
      & .footer {
        @apply bg-white w-full flex justify-between items-center border-t border-foundation-gray-4;
        height: 65px;
        padding: 0 24px;
      }
      & >>> .calendar {
        @apply font-primary text-charade rounded-none border-0;
        padding: 10px;
        --blue-900: #252733;
        --blue-700: #2c3a80;
        --blue-600: #435aae;
        --blue-200: #edf3ff;
        --text-lg: 12px;
        --text-sm: 12px;
        --font-semibold: 400;
        --font-bold: 600;
        & .highlight-day {
          @apply border border-blue;
          border-radius: 50% !important;
        }
        & .vc-grid-container.vc-weeks {
          font-size: 12px !important;
          --font-semibold: 400;
        }
        & .vc-header {
          height: 30px;
          padding-top: 10px;
          margin-top: 3px;
          padding-bottom: 5px;
          & .vc-title-layout {
            & .vc-title-wrapper {
              & .vc-title {
                @apply font-semibold text-black text-14;
              }
            }
          }
        }
        & .vc-grid-container {
          grid-template-columns: repeat(2, minmax(215px, 1fr));
          gap: 5px;
          row-gap: 3px !important;
          padding-top: 5px;
        }
        & .vc-arrows-container {
          & .vc-arrow {
            padding: 7px 10px;
            & .vc-svg-icon {
              height: 16px;
              width: 10px;
              & path {
                fill: #252733;
              }
            }
          }
        }
        & .vc-popover-content {
          --gray-900: #252733;
          --gray-800: #435aae;
          --gray-700: #435aae;
          & .vc-grid-cell {
            & span {
              @apply pt-1 pb-px;
            }
          }
        }
        & .vc-weekday {
          @apply text-13 font-semibold text-text-black;
        }

        & .vc-day-layer {
          & .vc-highlight {
            height: 40px;
            width: 100%;
            border-radius: 8px !important;
          }

          & .vc-highlight-base-middle,
          & .vc-highlight-base-start,
          & .vc-highlight-base-end {
            border-radius: 0 !important;
          }

          & .vc-highlight-base-start,
          & .vc-highlight-base-end {
            background-color: #435aae !important;
          }
        }

        & span {
          @apply font-normal;
          font-size: 12px !important;
          &:focus:not(.is-active) {
            @apply bg-transparent;
          }
        }

        & .vc-day-layer.vc-day-box-center-center,
        & .vc-day-box-right-center,
        & .vc-day-box-left-center {
          height: 40px;
          width: 100%;
        }

        & .vc-day-box-left-center {
          border-radius: 8px 0 0 8px;
        }

        & .vc-day-box-right-center {
          border-radius: 0 8px 8px 0;
        }

        & .weekday-position-7,
        & .day-from-end-1 {
          & .vc-highlights {
            & .vc-highlight-base-start,
            & .vc-highlight-base-middle {
              border-top-right-radius: 12px !important;
              border-bottom-right-radius: 12px !important;
            }
          }
        }
        & .weekday-position-1,
        & .day-1 {
          & .vc-highlights {
            & .vc-highlight-base-middle,
            & .vc-highlight-base-end {
              border-top-left-radius: 12px !important;
              border-bottom-left-radius: 12px !important;
            }
          }
        }
        & .vc-day {
          max-height: 40px;
          & .vc-day-content {
            width: 100%;
            height: 40px;
          }

          & .vc-day-content:hover {
            border-radius: 8px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .date-picker {
      &__select-block {
        padding-top: 0px;
      }

      & .right-side {
        & >>> .calendar {
          min-width: 100%;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .date-picker {
      @apply flex-col;
      transform: none;

      & .right-side {
        & .header {
          @apply flex flex-col items-center;
          padding: 10px 10px 6px 10px;
          height: min-content;
          & .year {
            padding-bottom: 10px;
            & span {
              margin: 0 10px;
            }
          }
        }

        & >>> .calendar {
          @apply border-0;
          border: none;
          margin: 0 auto;
          & .vc-day {
            & .vc-day-content {
              width: 9vw;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .date-picker {
      &__select-block {
        @apply flex-col items-start justify-start;
      }

      &__inputs {
        @apply mt-2;
        align-self: start;
      }
    }
  }
</style>
