import _split from 'lodash/split';
import _forEach from 'lodash/forEach';

export default class LocalStorage {
  constructor(userId = 'guest') {
    this.userId = userId;
  }

  _buildUserKey(key) {
    return `${this.userId}_${key}`;
  }

  getItem(key) {
    const userKey = this._buildUserKey(key);
    const item = localStorage.getItem(userKey);

    if (!item) {
      return null;
    }

    return JSON.parse(item);
  }

  setItem(key, data) {
    const userKey = this._buildUserKey(key);
    localStorage.setItem(userKey, JSON.stringify(data));
  }

  removeItem(key) {
    const userKey = this._buildUserKey(key);
    localStorage.removeItem(userKey);
  }

  clear() {
    _forEach(localStorage, i => {
      if (_split(i, '_')[0] === this.userId) {
        localStorage.removeItem(i);
      }
    });
  }
}
