<template>
  <transition name="slide">
    <nav
      v-if="show"
      class="burger-menu"
    >
      <nav-menu-wrapper v-slot="{ navItems }">
        <ul class="burger-menu__list">
          <accordion-item
            v-for="item in navItems"
            :key="item.value"
            :open="expandedItem === item.value"
            :value="item.value"
            :label="item.label"
            :clickable="clickable(item)"
            @item-toggle="handleItemToggle(item.value, $event)"
          >
            <template v-if="item.list">
              <component :is="item.list" />
            </template>
          </accordion-item>
        </ul>
      </nav-menu-wrapper>
    </nav>
  </transition>
</template>

<script>
  import { mapGetters } from 'vuex';
  import _includes from 'lodash/includes';

  import NavMenuWrapper from './NavMenuWrapper.vue';
  import AccordionItem from './AccordionItem.vue';

  export default {
    components: {
      NavMenuWrapper,
      AccordionItem,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        expandedItem: null,
      };
    },
    computed: {
      ...mapGetters(['currentUser', 'isAuthorized']),
    },
    watch: {
      '$route.name'() {
        this.$emit('close');
      },
    },
    methods: {
      handleItemToggle(item, val) {
        if (!val) {
          this.expandedItem = null;
          return;
        }
        this.expandedItem = item;
      },
      clickable(item) {
        return _includes(['goWallet', 'jobManager', 'getHired', 'findFreelancer', 'freelancerJobManager', 'serviceMarketplace', 'serviceManager'], item.value);
      },
    },
  };
</script>

<style lang="postcss" scoped>
.burger-menu {
  @apply bg-white fixed;
  bottom: 0;
  flex: 1 1 auto;
  width: 100%;
  max-height: calc(100% - var(--main-menu-height));
  height: calc(100% - var(--main-menu-height));
  overflow: auto;
  & >>> .nav-menu__link {
    border-radius: 0;
  }
  & >>> .nav-menu__list {
    gap: 4px;
  }
}

.burger-menu__list {
  padding-left: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: all 0.5s ease-in, opacity 0.6s ease;
}

.slide-enter {
  transform: translate(-100%, 0);
}

.slide-leave-to {
  transform: translate(-100%, 0);
}

.slide-leave-to,
.slide-enter-active {
  opacity: 0;
}

.slide-enter-to {
  opacity: 1;
}

@media (min-width: 1130px) {
  .burger-menu {
    display: none;
  }
}
</style>
