<template>
  <!-- eslint-disable max-len -->
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="[{active: sort}, sort]"
  >
    <path
      d="M10.2114 14.122C10.2796 14.0937 10.3418 14.0512 10.3946 13.997L13.1701 11.0197C13.2219 10.9641 13.2629 10.8982 13.2909 10.8257C13.3189 10.7531 13.3333 10.6754 13.3333 10.5969C13.3333 10.4383 13.2746 10.2862 13.1701 10.1741C13.1183 10.1186 13.0569 10.0745 12.9893 10.0445C12.9216 10.0144 12.8492 9.99898 12.776 9.99898C12.6282 9.99898 12.4864 10.062 12.3819 10.1741L10.5556 12.1391L10.5556 6.4288C10.5556 6.27088 10.4971 6.11942 10.393 6.00775C10.2889 5.89608 10.1477 5.83334 10.0005 5.83334C9.85326 5.83334 9.71207 5.89608 9.60797 6.00775C9.50387 6.11942 9.44539 6.27088 9.44539 6.4288L9.44539 12.1391L7.61911 10.1741C7.56751 10.1183 7.50611 10.074 7.43847 10.0438C7.37083 10.0135 7.29827 9.99797 7.22499 9.99797C7.15171 9.99797 7.07916 10.0135 7.01151 10.0438C6.94387 10.074 6.88248 10.1183 6.83087 10.1741C6.77885 10.2295 6.73755 10.2953 6.70937 10.3679C6.68119 10.4404 6.66668 10.5183 6.66668 10.5969C6.66668 10.6755 6.68119 10.7533 6.70937 10.8259C6.73755 10.8984 6.77885 10.9643 6.83087 11.0197L9.60636 13.997C9.65916 14.0512 9.72141 14.0937 9.78955 14.122C9.92469 14.1816 10.0763 14.1816 10.2114 14.122Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      sort: {
        type: String,
        default: null,
      },
    },
  };
</script>

<style lang="postcss" scoped>
  svg {
    @apply text-icon-default-dark;
    flex-shrink: 0;
    cursor: pointer;
    transition: .3s ease all;
    &:hover {
      @apply text-icon-hover;
    }
    &.active {
      @apply text-icon-brand;
    }
    &.asc {
      transform: rotate(-180deg) scale(1.3);
    }
    &.desc {
      transform: scale(1.3);
    }
  }

</style>
