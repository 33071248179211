<template>
  <div
    class="active-filters"
    @click="$emit('click', $event)"
  >
    <template v-if="filtersApplied">
      <div
        v-for="(value, name) in filters"
        :key="name"
        class="active-filters"
        @click="$emit('click', $event)"
      >
        <active-filter
          v-if="!isFilterArray(value)"
          :filter="{[name]: value, label: value}"
          :filters-config="filtersConfig"
          @open="handleFilterOpen"
          @remove="handleFilterRemove"
        />
        <template v-else>
          <active-filter
            v-for="(innerValue, innerIndex) in value"
            :key="`${name}-${innerIndex}`"
            :filter="{[name]: innerValue, label: innerValue}"
            :filters-config="filtersConfig"
            @open="handleFilterOpen"
            @remove="handleFilterRemove"
          />
        </template>
      </div>
    </template>
    <div
      v-else
      class="active-filters__empty-block"
    >
      <div class="active-filters__line"></div>
      <p class="active-filters__empty-text">
        No filters applied
      </p>
    </div>
  </div>
</template>

<script>
  import _isArray from 'lodash/isArray';
  import _keys from 'lodash/keys';
  import ActiveFilter from './components/ActiveFilter.vue';

  export default {
    components: {
      ActiveFilter,
    },
    props: {
      filters: {
        type: Object,
        required: true,
      },
      filtersConfig: {
        type: Array,
        required: true,
      },
    },
    computed: {
      filtersApplied() {
        return _keys(this.filters).length;
      },
    },
    methods: {
      handleFilterOpen(filterIndex) {
        this.$emit('open', filterIndex);
      },
      handleFilterRemove(filter, config) {
        this.$emit('remove', filter, config);
      },
      isFilterArray(value) {
        return _isArray(value);
      },
    },
  };
</script>

<style lang="postcss" scoped>
.active-filters {
  @apply flex items-center;
  gap: 15px;
  flex-wrap: wrap;

  &__empty-block {
    @apply flex items-center;
    gap: 20px;
  }

  &__line {
    @apply bg-foundation-gray-4;
    height: 33px;
    width: 1px;
  }

  &__empty-text {
    @apply text-14 text-text-placeholder;
  }
}
</style>
