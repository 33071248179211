// import { getUserPermissions } from '../routerServices';

const Service = () => import(/* webpackChunkName: "service-page" */ '@/views/Services/Service');
const CreateService = () => import(/* webpackChunkName: "create-service-page" */ '@/views/Services/Create');
const ServiceCheckout = () => import(/* webpackChunkName: "create-service-page" */ '@/views/Services/Checkout');

const ServiceManager = () => import(/* webpackChunkName: "ServiceManager" */ '@/views/ServiceManager');
const ServiceManagerServices = () => import(/* webpackChunkName: "ServiceManager" */ '@/views/ServiceManager/Services');
const ServiceManagerThreads = () => import(/* webpackChunkName: "ServiceManager" */ '@/views/ServiceManager/Threads');

export default [
  {
    name: 'service',
    path: '/service',
    component: {
      render: h => h('router-view'),
    },
    beforeEnter: (to, from, next) => next({
      name: 'page-not-found',
    }),
    children: [
      {
        name: 'service.current',
        path: ':id',
        component: Service,
        meta: { requiresAuth: false },
      },
      {
        name: 'service.new',
        path: 'new',
        component: CreateService,
      },
      {
        name: 'service.pay',
        path: ':id/pay',
        component: ServiceCheckout,
      },
      {
        name: 'service.edit',
        path: ':id/edit',
        component: CreateService,
      },
    ],
  },
  {
    name: 'service-manager',
    path: '/service-manager',
    component: ServiceManager,
    beforeEnter: (to, from, next) => next({
      name: 'page-not-found',
    }), // const permissions = getUserPermissions();

    // if (!permissions.isFreelancer) {
    //  return next({
    //    name: 'access-denied',
    //  });
    // }

    // return next();

    children: [
      {
        name: 'service-manager.services',
        path: 'services',
        component: ServiceManagerServices,
      },
      {
        name: 'service-manager.threads',
        path: 'threads',
        component: ServiceManagerThreads,
      },
    ],
  },
];
