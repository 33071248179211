/* eslint-disable import/prefer-default-export */
const MILESTONE_STATUS = {
  'activation-failed': {
    value: 'activation-failed',
    label: 'Not Funded',
    color: '#6F7689',
  },
  initial: {
    value: 'initial',
    label: 'Not Funded',
    color: '#6F7689',
  },
  activated: {
    value: 'activated',
    label: 'Not Started',
    color: '#615CF6',
  },
  'in-progress': {
    value: 'in-progress',
    label: 'In Progress',
    color: '#009BCC',
  },
  'payment-requested': {
    value: 'payment-requested',
    label: 'Approval Required',
    color: '#FFA83A',
  },
  paid: {
    value: 'paid',
    label: 'Completed',
    color: '#1FC67E',
  },
};

const HISTORY_ITEM_STATUS = {
  pending: {
    value: 'pending',
    label: 'Approval Required',
  },
  declined: {
    value: 'declined',
    label: 'Sent for Rework',
  },
  approved: {
    value: 'approved',
    label: 'Approved',
  },
};

const MilestoneStatus = {
  ACTIVATION_FAILED: 'activation-failed',
  INITIAL: 'initial',
  ACTIVATED: 'activated',
  IN_PROGRESS: 'in-progress',
  PAYMENT_REQUESTED: 'payment-requested',
  PAID: 'paid',
};

const MilestoneStatusLabels = {
  [MilestoneStatus.ACTIVATION_FAILED]: 'Not Funded',
  [MilestoneStatus.INITIAL]: 'Not Funded',
  [MilestoneStatus.ACTIVATED]: 'Not Started',
  [MilestoneStatus.IN_PROGRESS]: 'In progress',
  [MilestoneStatus.PAYMENT_REQUESTED]: 'Approval Required',
  [MilestoneStatus.PAID]: 'Completed',
};

const THREAD_MESSAGE_STATUS = {
  CREATED: 'created',
  UPDATED: 'updated',
  DELETED: 'deleted',
  IN_PROGRESS: 'in-progress',
  ACTIVATED: 'activated',
  PAYMENT_REQUESTED: 'payment-requested',
  DECLINE_REQUESTED_PAYMENT: 'decline-requested-payment',
  PAID: 'paid',
  BONUS: 'bonus',
};

export {
  MILESTONE_STATUS, MilestoneStatus, MilestoneStatusLabels, HISTORY_ITEM_STATUS, THREAD_MESSAGE_STATUS,
};
