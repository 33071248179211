<template>
  <label class="checkbox-container">
    <input
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      :class="{ checked: value, disabled }"
      @change="handleChange"
    >
    <span class="checkbox">
      <gl-base-icon
        v-if="value"
        name="approve-tick"
        :size="24"
      />
    </span>

    <span
      v-if="label"
      class="checkbox-label"
    >
      {{ label }}
    </span>
  </label>
</template>

<script>
  import GlBaseIcon from '../icons/BaseIcon.vue';

  export default {
    components: {
      GlBaseIcon,
    },
    model: {
      event: 'change',
    },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: null,
      },
    },
    methods: {
      handleChange() {
        if (this.disabled) {
          return;
        }
        this.$emit('change', !this.value);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .checkbox-container {
    @apply flex items-center;
    gap: 8px;
    user-select: none;
  }

  input {
    appearance: none;
    margin-right: -8px;
  }

  .checkbox {
    @apply inline-flex justify-center items-center bg-white text-white border border-foundation-gray-4;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s;
    flex-shrink: 0;
  }

  input.disabled:focus + .checkbox,
  input.disabled + .checkbox:hover {
    @apply border-foundation-gray-6;
  }

  input.disabled + .checkbox,
  input.disabled:focus + .checkbox,
  input.disabled + .checkbox:hover {
    @apply border-foundation-gray-6 bg-foundation-gray-3;
    outline: none;
    cursor: not-allowed;
  }

  input.disabled.checked + .checkbox,
  input.disabled.checked:focus + .checkbox,
  input.disabled.checked + .checkbox:hover {
    @apply border-foundation-gray-6 bg-foundation-gray-6;
    outline: none;
  }

  input:focus + .checkbox,
  .checkbox:hover {
    @apply border-foundation-gray-6;
  }

  input.checked:focus + .checkbox,
  input.checked + .checkbox:hover {
    @apply bg-blue-liberty border-blue-liberty;
  }

  input.checked + .checkbox {
    @apply bg-blue-liberty border-blue-liberty;
  }

  .checkbox-label {
    @apply text-text-weak text-14;
    line-height: 20px;
    letter-spacing: 0.0025em;
  }
</style>
