<template>
  <div
    ref="scrollableBody"
    :class="{'not-scrollable': !scrollable}"
    :style="{ '--modal-body-height': bodyHeightStyle }"
    class="custom-scrollbar-container scrollable-body"
  >
    <div
      ref="body"
      class="body"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      scrollable: {
        type: Boolean,
        default: true,
      },
      smoothResize: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        resizeObserver: null,
        bodyHeight: null,
      };
    },
    computed: {
      bodyHeightStyle() {
        if (!this.bodyHeight) {
          return 'unset';
        }

        return `${this.bodyHeight}px`;
      },
    },
    mounted() {
      if (!this.smoothResize) {
        return;
      }

      this.resizeObserver = new ResizeObserver(this.handleBodyResize);
      this.resizeObserver.observe(this.$refs.body);
      this.handleBodyResize();
    },
    beforeDestroy() {
      this.resizeObserver?.disconnect();
    },
    methods: {
      handleBodyResize() {
        const bodyEl = this.$refs.body;
        this.bodyHeight = bodyEl.scrollHeight;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .body {
    @apply w-full;
    padding: 0 32px 32px;
    height: fit-content;
  }

  .scrollable-body {
    max-height: 70vh;
    transition: 0.25s ease height;
    height: var(--modal-body-height, unset);
    width: 100%;
    overflow: auto;

    &.not-scrollable {
      overflow: visible;
    }
  }

  @media (max-width: 676px) {
    .body {
      padding: 0 16px 16px;
    }
  }

  @media (max-height: 675px) {
    .scrollable-body {
      max-height: 60vh;
    }
  }

  @media (max-height: 400px) {
    .scrollable-body {
      max-height: 35vh;
    }
  }

  @media (max-height: 330px) {
    .scrollable-body {
      max-height: 20vh;
    }
  }
</style>
