const InboxPage = () => import(/* webpackChunkName: "Inbox", webpackPrefetch: true */ '@/views/Inbox');
const InboxPageThread = () => import(/* webpackChunkName: "Inbox", webpackPrefetch: true */ '@/views/Inbox/Thread');

export default [
  {
    name: 'inbox',
    path: '/inbox',
    component: InboxPage,
    meta: {
      hideFooter: true,
      hideScrollUp: true,
    },
    children: [
      {
        name: 'inbox.thread',
        path: 'thread/:threadId',
        props: route => ({ threadId: route.params.threadId, calendarToken: route.query.calendarToken, zoomToken: route.query.zoomToken }),
        component: InboxPageThread,
      },
    ],
  },
];
