<template>
  <div
    ref="dropdown"
    class="filter-dropdown"
    :class="{'fixed-dropdown': isFixed}"
  >
    <slot></slot>
  </div>
</template>

<script>
  import getScrollableParent from '../utils/getScrollableParentElement';

  export default {
    props: {
      referenceSelector: {
        type: String,
        default: null,
      },
      offsetX: {
        type: Number,
        default: 4,
      },
      offsetTop: {
        type: Number,
        default: 4,
      },
      direction: {
        type: String,
        default: 'right',
      },
      isFixed: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        scrollableParent: null,
      };
    },
    mounted() {
      this.recalculatePosition();
      window.addEventListener('resize', this.recalculatePosition);

      this.scrollableParent = getScrollableParent(this.getReferenceElement());

      if (this.scrollableParent) {
        this.scrollableParent.addEventListener('scroll', this.recalculatePosition);
      }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.recalculatePosition);

      if (this.scrollableParent) {
        this.scrollableParent.removeEventListener('scroll', this.recalculatePosition);
      }
    },
    methods: {
      getReferenceElement() {
        const { dropdown } = this.$refs;
        return this.referenceSelector ? (dropdown.closest(this.referenceSelector) || document.querySelector(this.referenceSelector)) : dropdown.parentElement;
      },
      recalculatePosition() {
        const { dropdown } = this.$refs;

        const referenceEl = this.getReferenceElement();
        const referenceBounds = referenceEl.getBoundingClientRect();

        const boundsContainer = document.body;
        const containerBounds = boundsContainer.getBoundingClientRect();

        if (this.isFixed) {
          this.calculateFixedPosition(dropdown, referenceBounds, containerBounds);
        } else {
          this.calculateAbsolutePosition(dropdown, referenceBounds, containerBounds);
        }
      },
      calculateAbsolutePosition(dropdown, referenceBounds, containerBounds) {
        const left = this.direction === 'right' ? 0 : 0 - dropdown.offsetWidth + referenceBounds.width;

        dropdown.style.left = `${left}px`;
        dropdown.style.top = `${referenceBounds.height + this.offsetTop}px`;
        const dropdownBounds = dropdown.getBoundingClientRect();

        let shift = 0;

        if (dropdownBounds.right > containerBounds.right) {
          shift = containerBounds.right - dropdownBounds.right;
          dropdown.style.left = `${left + shift - this.offsetX}px`;
        }

        if (dropdownBounds.left < containerBounds.left) {
          shift = containerBounds.left - dropdownBounds.left;
          dropdown.style.left = `${left + shift + this.offsetX}px`;
        }
      },
      calculateFixedPosition(dropdown, referenceBounds) {
        const left = this.direction === 'right' ? referenceBounds.left : 0 - dropdown.offsetWidth + referenceBounds.width;
        const top = referenceBounds.height + referenceBounds.top + this.offsetTop;

        dropdown.style.left = `${left}px`;
        dropdown.style.top = `${top}px`;
        const dropdownBounds = dropdown.getBoundingClientRect();

        let shiftX = 0;

        if (dropdownBounds.right > window.innerWidth) {
          shiftX = window.innerWidth - dropdownBounds.right;
          dropdown.style.left = `${left + shiftX - this.offsetX}px`;
        }

        if (dropdownBounds.left < 0) {
          shiftX = 0 - dropdownBounds.left;
          dropdown.style.left = `${left + shiftX + this.offsetX}px`;
        }

        let shiftY = 0;

        if (dropdownBounds.bottom > window.innerHeight) {
          shiftY = 0 - referenceBounds.height - dropdownBounds.height - this.offsetTop * 2;
          dropdown.style.top = `${top + shiftY}px`;
        }

        if (top + shiftY < 0) {
          dropdown.style.top = `${0 + this.offsetTop}px`;
        }
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .filter-dropdown {
    @apply bg-white shadow-dropdown-default;
    position: absolute;
    border-radius: 4px;

    &.fixed-dropdown {
      position: fixed;
    }
  }
</style>
