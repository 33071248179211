<template>
  <div
    class="modal-window"
    aria-modal="true"
    role="dialog"
  >
    <slot name="topControls"></slot>
    <div
      v-if="showCloseIcon"
      class="close-icon-container"
    >
      <slot name="closeButton">
        <close-button
          v-bind="$attrs"
          class="close-icon"
          @click.native="handleCloseIconClick"
        />
      </slot>
    </div>

    <modal-inner
      :scrollable="scrollable"
      class="modal-inner"
      @body-scroll="$emit('body-scroll', $event)"
    >
      <template #header>
        <slot name="header"></slot>
      </template>
      <template #body>
        <slot name="body"></slot>
      </template>
      <template #footer>
        <slot name="footer"></slot>
      </template>
    </modal-inner>
  </div>
</template>

<script>
  import CloseButton from '@/components/buttons/CloseButton.vue';
  import ModalInner from './ModalInner.vue';

  export default {
    components: {
      CloseButton,
      ModalInner,
    },
    props: {
      showCloseIcon: {
        type: Boolean,
        default: true,
      },
      scrollable: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      handleCloseIconClick() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .modal-window {
    @apply bg-white relative py-4;
    box-shadow: 0px 3px 5px 1px rgba(22, 32, 85, 0.2);
    border-radius: 16px;
  }

  .close-icon-container {
    @apply absolute;
    z-index: 42;
    top: 14px;
    right: 14px;
  }

  .close-icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
  }

  @media (min-width: 576px) {
    .modal-window {
      padding: 32px 0 24px;
    }
  }
</style>
