const defaultBackgroundImageUrl = 'https://res.cloudinary.com/golance/image/upload/v1665493937/app_new_ui/company-profile/default-background.png';
const defaultLogoUrl = 'https://res.cloudinary.com/golance/image/upload/v1679053586/app_new_ui/company-profile/company-logo.png';

const CreateCompanyStepsNames = {
  SelectType: 'selectType',
  CreateCompany: 'createCompany',
};

const CompanyType = {
  CLIENT_COMPANY: 'client',
  AGENCY: 'agency',
};

export {
  defaultBackgroundImageUrl, defaultLogoUrl, CreateCompanyStepsNames, CompanyType,
};

export const memberProfilePrivacy = {
  Public: 'public',
  Private: 'private',
};

export const memberRoles = {
  CompanyModerator: 'company_moderator',
  HiringManager: 'hiring_manager',
  SalesManager: 'sales_manager',
  CompanyOwner: 'company_owner',
  Freelancer: 'freelancer',
  FinancialManager: 'financial_manager',
};

export const clientRoles = {
  CompanyOwner: 'company_owner',
  HiringManager: 'hiring_manager',
  CompanyModerator: 'company_moderator',
  FinancialManager: 'financial_manager',
};

export const freelancerRoles = {
  CompanyOwner: 'company_owner',
  SalesManager: 'sales_manager',
  CompanyModerator: 'company_moderator',
  Freelancer: 'freelancer',
};

export const memberRoleLabels = {
  [memberRoles.HiringManager]: 'Hiring Manager',
  [memberRoles.CompanyModerator]: 'Company Moderator',
  [memberRoles.SalesManager]: 'Sales Manager',
  [memberRoles.Freelancer]: 'Freelancer',
  [memberRoles.FinancialManager]: 'Financial Manager',
};

export const agencyMemberRoleLabels = {
  [memberRoles.CompanyModerator]: 'Agency Moderator',
  [memberRoles.Freelancer]: 'Freelancer',
  [memberRoles.SalesManager]: 'Sales Manager',
};

export const clientCompanyRoles = {
  CompanyModerator: 'company_moderator',
  HiringManager: 'hiring_manager',
};

export const memberPermissions = {
  EditCompanyInfo: 'edit_company_information',
  InviteOrRemoveCompanyMembers: 'invite_or_remove_company_members',
  ChangeCompanyMembersRoles: 'change_company_members_roles',
  ManageTeams: 'manage_teams',
  ManageActivityCodes: 'manage_activity_codes',
  ManageJobs: 'manage_jobs',
  InviteFreelancersToApply: 'invite_freelancers_to_apply',
  ManageCandidates: 'manage_candidates',
  MakeOffersAndHire: 'make_offers_and_hire',
  ManageContracts: 'manage_contracts',
  SubmitFreelancerFeedback: 'submit_freelancer_feedback',
  ManageTeamMembers: 'manage_team_members',
  ManagePayments: 'manage_payments',
  ChangeExclusiveness: 'change_exclusiveness',
  ApplyOnJobsOnBehalfOfCompanyMember: 'apply_on_jobs_on_behalf_of_company_member',
  EndContracts: 'end_contracts',
  ManagePaymentMethods: 'manage_payment_methods',
  Freelancer: 'freelancer',
  AdministrativeControl: 'administrative_control',
  ApplyOnJobsOnBehalfOfAgencyMember: 'apply_on_jobs_on_behalf_of_agency_member',
  ClientInteractions: 'client_interactions',
  ViewInvoices: 'view_invoices',
  DownloadInvoices: 'download_invoices',
};

export const memberPermissionLabels = {
  [memberPermissions.EditCompanyInfo]: 'Edit company information',
  [memberPermissions.InviteOrRemoveCompanyMembers]: 'Invite or remove company members',
  [memberPermissions.ChangeCompanyMembersRoles]: "Change company members' roles",
  [memberPermissions.ManageTeams]: 'Manage teams',
  [memberPermissions.ManageActivityCodes]: 'Manage company activity codes/sets',
  [memberPermissions.ManageJobs]: 'Manage jobs (post, edit, close)',
  [memberPermissions.InviteFreelancersToApply]: 'Invite freelancers to apply',
  [memberPermissions.ManageCandidates]: 'Manage candidates',
  [memberPermissions.MakeOffersAndHire]: 'Make offers and hire',
  [memberPermissions.ManageContracts]: 'Manage contracts (create, update, end)',
  [memberPermissions.SubmitFreelancerFeedback]: 'Submit freelancer feedback',
  [memberPermissions.ManageTeamMembers]: 'Manage team members',
  [memberPermissions.ManagePayments]: 'Manage payments (pay invoices, send bonuses; pay milestones)',
  [memberPermissions.ChangeExclusiveness]: 'Change other exclusive member profiles',
  [memberPermissions.ApplyOnJobsOnBehalfOfCompanyMember]: 'Apply for a job on behalf of any company member',
  [memberPermissions.EndContracts]: 'End contracts',
  [memberPermissions.ManagePaymentMethods]: 'Manage company payment methods',
  [memberPermissions.Freelancer]: 'Can be hired only on behalf of the agency. Individual contributor, project-focused.',
  [memberPermissions.AdministrativeControl]: 'Administrative control, overall agency management.',
  [memberPermissions.ClientInteractions]: 'Client interactions, business development. Access to all contracts.',
  [memberPermissions.ApplyOnJobsOnBehalfOfAgencyMember]: 'Apply for a job on behalf of any agency member',
  [memberPermissions.ViewInvoices]: 'View invoices',
  [memberPermissions.DownloadInvoices]: 'Download invoices',
};

export const permissionsByRole = {
  [memberRoles.CompanyModerator]: [
    memberPermissions.EditCompanyInfo,
    memberPermissions.InviteOrRemoveCompanyMembers,
    memberPermissions.ChangeCompanyMembersRoles,
    memberPermissions.ManageTeams,
    memberPermissions.ManageActivityCodes,
    memberPermissions.ManagePaymentMethods,
  ],
  [memberRoles.HiringManager]: [
    memberPermissions.ManageJobs,
    memberPermissions.InviteFreelancersToApply,
    memberPermissions.ManageCandidates,
    memberPermissions.MakeOffersAndHire,
    memberPermissions.ManageContracts,
    memberPermissions.SubmitFreelancerFeedback,
    memberPermissions.ManageActivityCodes,
    memberPermissions.ManageTeamMembers,
    memberPermissions.ManagePayments,
  ],
  [memberRoles.SalesManager]: [
    memberPermissions.ChangeExclusiveness,
    memberPermissions.ApplyOnJobsOnBehalfOfCompanyMember,
    memberPermissions.EndContracts,
    memberPermissions.SubmitFreelancerFeedback,
  ],
  [memberRoles.Freelancer]: [
    memberPermissions.Freelancer,
  ],
  [memberRoles.FinancialManager]: [
    memberPermissions.ViewInvoices,
    memberPermissions.DownloadInvoices,
  ],
};

export const agencyPermissionsByRole = {
  [memberRoles.CompanyModerator]: [
    memberPermissions.AdministrativeControl,
  ],
  [memberRoles.SalesManager]: [
    memberPermissions.ClientInteractions,
    memberPermissions.ApplyOnJobsOnBehalfOfAgencyMember,
  ],
  [memberRoles.Freelancer]: [
    memberPermissions.Freelancer,
  ],
};
