import _isNumber from 'lodash/isNumber';

import currencyService from '@/services/currency';
import { cryptoCurrencyNames, cryptoCurrencyNamesWithNetworks } from '@/constants/currency';

export const currencyName = (currency, { withNetwork = false } = {}) => {
  const isCryptoCurrency = currencyService.isCryptoCurrency(currency);

  if (!isCryptoCurrency) {
    return currency;
  }

  const cryptoCurrencyName = withNetwork ? cryptoCurrencyNamesWithNetworks[currency] : cryptoCurrencyNames[currency];

  return cryptoCurrencyName || currency;
};

export default (value, {
  fractionDigits, numberOnly = false, omitZeroFraction = false, approx = false, currency = 'USD', convert = true,
} = {}) => {
  if (!value) {
    value = 0;
  }

  const isCryptoCurrency = currencyService.isCryptoCurrency(currency);

  let valueInDollars = !isCryptoCurrency && convert ? value / 100 : value;
  let digits = isCryptoCurrency && !Number.isInteger(valueInDollars) ? 8 : 2;
  let postfix = '';

  if (approx) {
    digits = 0;
    if (valueInDollars > 0) {
      if (valueInDollars < 1000) {
        if (valueInDollars < 100) {
          valueInDollars = 100;
        } else if (valueInDollars < 500) {
          valueInDollars = 500;
        } else {
          valueInDollars = 1000;
        }
      } else if (valueInDollars < 1000000) {
        valueInDollars = Math.floor(valueInDollars / 1000);
        postfix = 'k';
      } else {
        valueInDollars = Math.floor(valueInDollars / 1000000);
        postfix = 'M';
      }
    }
  }

  digits = fractionDigits || digits;

  const omitZeros = omitZeroFraction || (!Number.isInteger(valueInDollars) && isCryptoCurrency) || (isCryptoCurrency && valueInDollars === 0);

  if (Intl && Intl.NumberFormat) {
    return `${(!numberOnly && isCryptoCurrency) ? `${currencyName(currency)} ` : ''}${new Intl.NumberFormat('en', {
      style: (numberOnly || isCryptoCurrency) ? 'decimal' : 'currency',
      currency: isCryptoCurrency ? undefined : currency,
      maximumFractionDigits: digits,
      minimumFractionDigits: omitZeros ? 0 : digits,
    }).format(valueInDollars)}${postfix}`;
  }

  return `${currency} ${_isNumber(valueInDollars) ? valueInDollars.toFixed(digits) : ''}`;
};
