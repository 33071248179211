import { render, staticRenderFns } from "./IndicatorPill.vue?vue&type=template&id=2c208e33&scoped=true"
import script from "./IndicatorPill.vue?vue&type=script&lang=js"
export * from "./IndicatorPill.vue?vue&type=script&lang=js"
import style0 from "./IndicatorPill.vue?vue&type=style&index=0&id=2c208e33&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c208e33",
  null
  
)

export default component.exports