<template>
  <div class="empty-result">
    <slot>
      <empty-state-icon class="empty-result__icon" />
    </slot>

    <h2 class="empty-result__heading">
      {{ heading }}
    </h2>

    <div
      v-if="text"
      class="empty-result__text"
    >
      <slot name="text">
        {{ text }}
      </slot>
    </div>
  </div>
</template>

<script>
  import EmptyStateIcon from './EmptyStateIcon.vue';

  export default {
    components: {
      EmptyStateIcon,
    },

    props: {
      heading: {
        type: String,
        default: 'Sorry, no results found',
      },
      text: {
        type: String,
        default: 'Check the filters applied.',
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .empty-result {
    @apply flex flex-col justify-center items-center font-primary;
    padding: 48px 20px;

    & .empty-result__icon {
      max-width: 100%;
    }

    & .empty-result__heading {
      @apply font-bold text-24 text-text-black font-primary;
      line-height: 150%;
      margin-top: 32px;
      letter-spacing: 0.0025em;
      text-align: center;
    }

    & .empty-result__text {
      @apply font-normal text-16 text-text-black;
      line-height: 150%;
      margin-top: 12px;
      letter-spacing: 0.0015em;
    }
  }
</style>
