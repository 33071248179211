import users from '@/services/api/users';
import getNotificationLink from '@/services/notificationLink';

import { getCurrentUser } from '../routerServices';

export default [
  {
    name: 'notification', // Route to redirect to notification entity page
    path: '/notifications/:id',
    meta: { requiresAuth: true },

    beforeEnter: async (to, _, next) => {
      const user = getCurrentUser();
      const notiticationId = to.params?.id;
      const notification = await users.getAndReadNotification(user._id, notiticationId);

      if (notification.data.link) {
        window.location = notification.data.link;
        return;
      }

      const notificationLink = getNotificationLink(notification);

      next({
        ...notificationLink,
      });
    },
  },
];
