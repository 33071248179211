<template>
  <div
    class="header"
    :class="{'close-icon-shown': showCloseIcon}"
  >
    <div
      v-if="showBackIcon"
      class="header__back-button-container"
    >
      <gl-icon-button
        :size="40"
        icon-name="left-arrow-large"
        @click="$emit('back')"
      />
    </div>
    <div
      v-if="title || $slots.title"
      class="header__info"
    >
      <div class="header__title">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <div
        v-if="subtitle || $slots.subtitle"
        class="header__subtitle"
      >
        <slot name="subtitle">
          <span>{{ subtitle }}</span>
        </slot>
      </div>
    </div>
    <gl-icon-button
      v-if="showCloseIcon"
      :size="40"
      icon-name="close"
      class="close-button"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
  import GlIconButton from 'uikit/components/buttons/IconButton.vue';

  export default {
    components: {
      GlIconButton,
    },
    props: {
      title: {
        type: String,
        default: null,
      },
      subtitle: {
        type: String,
        default: null,
      },
      showBackIcon: {
        type: Boolean,
        default: false,
      },
      showCloseIcon: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="postcss" scoped>
 .header {
  @apply bg-white relative;
  z-index: 1;
  padding: 16px;
  border-radius: 16px 16px 0 0;

  &.close-icon-shown .header__title {
    padding-right: 20px;
  }
}

.header__info {
  padding: 16px;
  height: fit-content;
  flex-grow: 1;
}

.header__title {
  @apply text-black font-bold text-24;
  line-height: 150%;
  letter-spacing: 0.0025em;
}

.header__subtitle {
  @apply text-text-black text-16 font-normal;
  margin-top: 16px;
  line-height: 150%;
  letter-spacing: 0.0015em;
}

.close-button {
  @apply absolute;
  right: 16px;
  top: 16px;
}
</style>
