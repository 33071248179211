<template>
  <div
    class="checkbox-container"
    :class="{ disabled, error }"
  >
    <div
      class="checkbox"
      :class="{ checked, indeterminate }"
      :tabindex="disabled ? -1 : 0"
      @click="handleClick"
      @keypress.enter="handleEnter"
    >
      <gl-minus-square-icon v-if="indeterminate" />
      <gl-check-icon v-else-if="value" />
      <gl-square-icon v-else />
    </div>

    <div
      v-if="label || $slots.default"
      :title="label"
      class="info"
    >
      <span
        class="label"
        @click="handleClick"
      >
        <slot>{{ label }}</slot>
        <span
          v-if="required"
          class="text-red-error"
        > *</span>
      </span>
    </div>
  </div>
</template>

<script>
  import GlMinusSquareIcon from './MinusSquareIcon';
  import GlCheckIcon from './CheckedSquareIcon';
  import GlSquareIcon from './SquareIcon';

  export default {
    components: {
      GlMinusSquareIcon,
      GlCheckIcon,
      GlSquareIcon,
    },
    model: {
      event: 'change',
      prop: 'value',
    },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: null,
      },
      required: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      indeterminate: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      checked() {
        return this.value;
      },
    },

    methods: {
      handleEnter() {
        if (!this.disabled) {
          this.$emit('keyboard-change', !this.value);
          this.$emit('change', !this.value);
        }
      },
      handleClick() {
        if (!this.disabled) {
          this.$emit('change', !this.value);
        }
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .checkbox-container {
    @apply max-w-full flex items-start;

    & .checkbox {
      @apply flex justify-center items-center cursor-pointer text-foundation-gray-5;
      margin-right: 8px;
      min-height: 16px;
      min-width: 16px;
      height: 16px;
      width: 16px;
      margin-top: 2px;
      transition: 0.3s ease;

      &:hover {
        @apply text-foundation-gray-6;
      }
      &.checked,
      &:focus,
      &.indeterminate,
      &:focus-visible {
        @apply text-blue-liberty;
        outline: none;
      }
    }

    &.error .checkbox {
      @apply text-red-error;

      &.checked, &:hover, &:focus, &:focus-visible {
        @apply text-red-error;
      }
    }

    &.disabled .checkbox {
      &.checked {
        @apply text-foundation-gray-4;
      }
      &:hover {
        @apply text-foundation-gray-4;
      }
      &:focus,
      &:focus-visible {
        @apply text-foundation-gray-4;
        outline: none;
      }
    }

    & .info {
      @apply flex flex-col;
      & .label {
        @apply font-primary text-14 text-text-black;
        cursor: pointer;
        user-select: none;
        line-height: 20px;
        letter-spacing: 0.004em;
        padding-top: 0;
      }
      & .description {
        @apply font-primary text-14 text-comet;
        line-height: 20px;
        padding-top: 5px;
        max-width: 90%;
      }
    }

    &.disabled {
      & .checkbox,
      & .info .label {
        cursor: not-allowed;
      }
    }
  }
</style>
