import { getCurrentUser, getWalletHomePage, checkWalletPageAvailability } from '../routerServices';

const GoWallet = () => import(/* webpackChunkName: "GoWallet" */ '@/views/GoWallet');
const GoWalletDashboard = () => import(/* webpackChunkName: "GoWallet" */ '@/views/GoWallet/Dashboard');
const GoWalletAllTransactions = () => import(/* webpackChunkName: "GoWallet" */ '@/views/GoWallet/AllTransactions');

const GoWalletNew = () => import(/* webpackChunkName: "GoNewWallet" */ '@/views/NewGoWallet');

const WithdrawMethods = () => import(/* webpackChunkName: "NewWithdrawMethods" */ '@/views/NewGoWallet/components/PaymentMethods/components/withdrawal/WithdrawMethods');
const BillingMethods = () => import(/* webpackChunkName: "NewBillingMethods" */ '@/views/NewGoWallet/components/PaymentMethods/components/billing/BillingMethods');
const VirtualCards = () => import(/* webpackChunkName: "NewVirtualCards" */ '@/views/NewGoWallet/components/VirtualCards');
const Dashboard = () => import(/* webpackChunkName: "NewDashboard" */ '@/views/NewGoWallet/components/Dashboard');
const PaydayAdvance = () => import(/* webpackChunkName: "NewPaydayAdvance" */ '@/views/NewGoWallet/components/PaydayAdvance/PayDayAdvancePage');
const Transactions = () => import(/* webpackChunkName: "NewTransactions" */ '@/views/NewGoWallet/components/Transactions');
const PaymentRequests = () => import(/* webpackChunkName: "NewPaymentRequests" */ '@/views/NewGoWallet/components/PaymentRequests');
const ReceivedPaymentRequests = () => import(/* webpackChunkName: "NewReceivedPaymentRequests" */ '@/views/NewGoWallet/components/PaymentRequests/Received');
const SentPaymentRequests = () => import(/* webpackChunkName: "NewSentPaymentRequests" */ '@/views/NewGoWallet/components/PaymentRequests/Sent');
const Invoices = () => import(/* webpackChunkName: "NewInvoices" */ '@/views/NewGoWallet/components/Invoices/index');
const WalletPage = () => import(/* webpackChunkName: "SelectedWallet" */ '@/views/NewGoWallet/components/Dashboard/WalletPage');

const GoWalletPrintTransaction = () => import(/* webpackChunkName: "GoWalletPrint" */ '@/views/NewGoWallet/components/Transactions/components/PrintTransaction.vue');

export default [
  {
    name: 'gowallet-old',
    path: '/gowallet-old',
    component: GoWallet,
    redirect: () => ({ name: 'gowallet-old.dashboard' }),
    children: [
      {
        name: 'gowallet-old.dashboard',
        path: 'dashboard',
        component: GoWalletDashboard,
      },
      {
        name: 'gowallet-old.transactions',
        path: 'transactions',
        component: GoWalletAllTransactions,
      },
      {
        name: 'gowallet-old.payment-requests',
        path: 'payment-requests',
        component: PaymentRequests,
        beforeEnter: (to, from, next) => {
          const user = getCurrentUser();

          if (!user?.paymentRequestsSettings?.enabled) {
            return next({
              name: 'page-not-found',
            });
          }

          return next();
        },
      },
    ],
  },

  {
    name: 'gowallet',
    path: '/payments',
    component: GoWalletNew,
    redirect: () => getWalletHomePage(),
    beforeEnter: (to, from, next) => checkWalletPageAvailability(to, from, next),
    children: [
      {
        name: 'gowallet.dashboard',
        path: 'dashboard',
        component: Dashboard,
      },
      {
        name: 'gowallet.transactions',
        path: 'transactions',
        component: Transactions,
      },
      {
        name: 'gowallet.cards',
        path: 'cards',
        component: VirtualCards,
      },
      {
        name: 'gowallet.withdrawal-methods',
        path: 'withdrawal-methods',
        component: WithdrawMethods,
      },
      {
        name: 'gowallet.payment-methods',
        path: 'payment-methods',
        component: BillingMethods,
        props: route => ({ initAddingBankAccount: !!route.query.initAddingBankAccount }),
      },
      {
        name: 'gowallet.billing-methods',
        path: 'billing-methods',
        redirect: to => ({ name: 'gowallet.payment-methods', params: to.params }),
      },
      {
        name: 'gowallet.payment-requests',
        path: 'payment-requests',
        redirect: to => ({ name: 'gowallet.payment-requests.received', params: to.params }),
        component: PaymentRequests,
        beforeEnter: (to, from, next) => {
          const user = getCurrentUser();

          if (!user?.paymentRequestsSettings?.enabled) {
            return next({
              name: 'page-not-found',
            });
          }

          return next();
        },
        children: [
          {
            name: 'gowallet.payment-requests.received',
            path: 'received',
            component: ReceivedPaymentRequests,
          },
          {
            name: 'gowallet.payment-requests.sent',
            path: 'sent',
            component: SentPaymentRequests,
          },
        ],
      },
      {
        name: 'gowallet.payday-advance',
        path: 'payday-advance',
        component: PaydayAdvance,
      },
      {
        name: 'gowallet.invoices',
        path: 'invoices',
        component: Invoices,
      },
      {
        name: 'gowallet.wallet',
        path: 'wallet/:walletId',
        component: WalletPage,
      },
    ],
  },
  {
    name: 'gowallet-redirect',
    path: '/gowallet',
    redirect: () => ({ name: 'gowallet.dashboard' }),
    children: [
      {
        name: 'gowallet-redirect.tabs',
        path: ':tab',
        redirect: to => ({ name: `gowallet.${to.params.tab}` }),
      },
    ],
  },
  {
    name: 'print-transaction',
    path: '/print-transaction',
    component: GoWalletPrintTransaction,
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
];
