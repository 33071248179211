<template>
  <div class="scroll-observer"></div>
</template>

<script>
  export default {
    props: {
      options: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        observer: null,
      };
    },
    mounted() {
      const options = this.options || {};
      this.observer = new IntersectionObserver(([entry]) => {
        if (entry && entry.isIntersecting) {
          this.$emit('intersect');
        } else {
          this.$emit('hide');
        }
      }, options);

      this.observer.observe(this.$el);
    },
    destroyed() {
      this.observer.disconnect();
    },
  };
</script>

<style lang="postcss" scoped>
  .scroll-observer {
    height: 0;
    background: transparent;
  }
</style>
