import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _without from 'lodash/without';

import logger from '@/logger';

class SocketIo {
  static get eventNames() {
    return [
      'command:processed',
      'command:failed',
      'user:updated',
      'session:updated',
      'user:debitCardAdded',
      'user:createPaymentMethodFailed',
      'user:paymentMethodCreated',
      'user:paymentMethodUpdated',
      'user:paymentMethodVerificationSucceed',
      'user:paymentMethodVerificationFailed',
      'user:primaryPaymentMethodChanged',
      'user:primaryWithdrawalPaymentMethodChanged',
      'user:populateProfile',
      'favorites:updated',
      'portfolioItem:commentAdded',
      'job:updated',
      'company:updated',
      'company:createPaymentMethodFailed',
      'company:paymentMethodCreated',
      'company:paymentMethodUpdated',
      'company:paymentMethodChanged',
      'proposal:updated',
      'payment:succeeded',
      'payment:failed',
      'contract:updated',
      'contract:notification',
      'activityEvent:created',
      'transactionReport:created',
      'transactionReport:updated',
      'notification:created',
      'thread:updated',
      'proposal:created',
      'offer:created',
      'thread:newMessage',
      'thread:userLastActivityUpdated',
      'paymentRequest:updated',
      'message:previewableEntitiesUpdated',
      'invoice:singlePdfRendered',
      'invoice:singlePdfRenderingFailed',
      'invoice:created',
      'invoice:updated',
      'invoice:batchPdfRendered',
      'invoice:batchPdfRenderingFailed',
      'certificateOfEarnings:pdfRendered',
      'certificateOfEarnings:pdfRenderingFailed',
      'certificateOfEarnings:detailedPdfRendered',
      'certificateOfEarnings:detailedPdfRenderingFailed',
      'clientReceipt:pdfRendered',
      'clientReceipt:pdfRenderingFailed',
      'paymentRequest:pdfRendered',
      'paymentRequest:pdfRenderingFailed',
      'paymentRequest:receiptPdfRendered',
      'paymentRequest:receiptPdfRenderingFailed',
      'task:created',
      'task:updated',
    ];
  }

  init(socket) {
    this._socket = socket;
    this._subscriptions = {};
    this._registerEvents();
  }

  _registerEvents() {
    _forEach(SocketIo.eventNames, eventName => {
      this._socket.on(eventName, eventData => {
        const ioEventName = `io:${eventName}`;
        return this._handleSocketEvent(ioEventName, eventData);
      });
    });
  }

  _handleSocketEvent(eventName, data) {
    logger.log(`Vue socket received event: ${eventName}`);

    const eventHandlers = this._subscriptions[eventName];

    if (_isEmpty(eventHandlers)) {
      logger.log(`There is no event handler for event: ${eventName}`);
      return;
    }

    _forEach(eventHandlers, eventHandler => eventHandler(data.data, eventName));
  }

  on(eventName, handler) {
    this._subscriptions[eventName] = this._subscriptions[eventName] || [];
    this._subscriptions[eventName].push(handler);
  }

  once(eventName, handler) {
    const handlerWrapper = data => {
      handler(data.data, eventName);
      this.off(eventName, handler);
    };

    this.on(eventName, handlerWrapper);
  }

  off(eventName, handler) {
    this._subscriptions[eventName] = _without(this._subscriptions[eventName], handler);
  }

  subscribe(roomId) {
    return this._emit('subscribe', { roomId });
  }

  unsubscribe(roomId) {
    return this._emit('unsubscribe', { roomId });
  }

  _emit(eventName, data) {
    if (this._socket) {
      return this._socket.emit(eventName, data);
    }

    return null;
  }
}

export default new SocketIo();
