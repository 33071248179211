<template>
  <gl-regular-modal
    :show="show"
    :size="'xxs'"
    title="Customize your Table"
    subtitle="Choose columns you need. Change their order."
    @close="$emit('close')"
  >
    <template #body>
      <div class="edit-modal__body">
        <div class="edit-modal__top-actions">
          <gl-button
            variant="link"
            @click="handleSelectAll"
          >
            Select All
          </gl-button>
          <gl-button
            variant="link"
            @click="handleRemoveAll"
          >
            Remove All
          </gl-button>
        </div>
        <ul
          v-draggable="{ filter: '.freezed', onUpdate: handleOrderChange }"
          class="columns-list"
        >
          <li
            v-for="column in columns"
            :key="column.field"
            class="columns-list__column"
            :class="[{ freezed: column.main }, { hidden: column.hidden }]"
          >
            <gl-checkbox
              class="column__checkbox"
              :value="isColumnShown(column.field)"
              :disabled="column.main"
              :label="column.title"
              @change="handleColumnSelect(column.field, $event)"
            />
            <gl-base-icon
              v-if="!column.main"
              name="sort-list"
              :size="20"
              class="column__sort-icon drag-handle"
            />
          </li>
        </ul>
      </div>
    </template>
    <template #footer>
      <div class="edit-modal__footer">
        <gl-button
          variant="secondary"
          class="action-button"
          @click="$emit('close')"
        >
          Cancel
        </gl-button>
        <gl-button
          variant="primary"
          class="action-button save-button"
          @click="$emit('save', columns)"
        >
          Save
        </gl-button>
      </div>
    </template>
  </gl-regular-modal>
</template>

<script>
  import _cloneDeep from 'lodash/cloneDeep';
  import _find from 'lodash/find';
  import _forEach from 'lodash/forEach';

  import GlButton from 'uikit/components/buttons/Button.vue';
  import GlCheckbox from 'uikit/components/Checkbox/index.vue';
  import GlBaseIcon from 'uikit/icons/BaseIcon.vue';
  import GlRegularModal from '../Modal/index.vue';

  export default {
    components: {
      GlBaseIcon,
      GlRegularModal,
      GlButton,
      GlCheckbox,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      defaultColumns: {
        type: Array,
        required: true,
      },
      currentColumns: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        columns: [],
        selected: [],
      };
    },
    watch: {
      show(val) {
        if (val) {
          this.columns = _cloneDeep(this.currentColumns);
        }
      },
    },
    methods: {
      isColumnShown(field) {
        return !!_find(this.columns, col => col.field === field && col.shown);
      },
      handleColumnSelect(field, value) {
        const column = this.columns.find(col => col.field === field);
        column.shown = value;
      },
      handleSelectAll() {
        _forEach(this.columns, col => {
          if (col.hidden) return;
          col.shown = true;
        });
      },
      handleRemoveAll() {
        _forEach(this.columns, col => {
          if (col.hidden) return;
          col.shown = !!col.main;
        });
      },
      handleOrderChange({ oldIndex, newIndex }) {
        const columns = [...this.columns];
        const [splicedElement] = columns.splice(oldIndex, 1);
        columns.splice(newIndex, 0, splicedElement);
        this.columns = columns;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .edit-modal__body {
    width: 100%;
  }

  .edit-modal__top-actions {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .columns-list {
    overflow-y: auto;
    margin-top: 15px;
    box-sizing: border-box;
    padding-left: 0;
  }

  .columns-list__column {
    @apply border-moody-gray border flex;
    padding: 13px;
    height: 44px;
    border-radius: 8px;
    box-sizing: border-box;
    justify-content: space-between;
    flex-direction: row;
    text-align: center;
    &.freezed .column__sort-icon {
      opacity: 0.4;
      cursor: not-allowed;
      &:hover {
        @apply text-black;
      }
    }
    &.hidden {
      display: none;
    }
  }

  .columns-list__column + .columns-list__column {
    margin-top: 4px;
  }

  .column__checkbox {
    & >>> .info .label {
      padding-top: 0;
    }
  }

  .column__sort-icon.icon {
    @apply text-icon-default;

    transition: 0.3s ease color;
    cursor: grab;
    width: 20px;
    height: 20px;
    margin-top: -1px;

    &:hover {
      @apply text-icon-brand;
    }

    &:active {
      cursor: grabbing;
    }
  }

  .divider {
    margin-bottom: 1rem;
  }

  .edit-modal__footer {
    display: flex;
    justify-content: flex-end;
  }

  .save-button {
    margin-left: 10px;
  }
</style>
