<template>
  <div class="options-list">
    <options-search
      v-if="filterConfig.withSearch"
      :search="search"
      class="options-list__search"
      @input="handleSearchChange"
    />
    <div
      v-if="searchOptions.length"
      class="options-list__select-all-container"
    >
      <gl-button
        variant="link-button"
        size="small"
        @click="handleSelectAllClick"
      >
        {{ isAllSelected ? 'Unselect All' : 'Select All' }}
      </gl-button>
    </div>
    <div
      v-if="searchOptions.length"
      class="options-list__options simple-scroller"
    >
      <category-option-item
        v-for="categoryOption in searchOptions"
        :key="categoryOption._id"
        :category-option="categoryOption"
        :filter-config="filterConfig"
        :filters="filters"
        :label-key="labelKey"
        :value-key="valueKey"
        :return-object="returnObject"
        @change="handleOptionChange"
      >
        <div class="flex flex-col gap-1">
          <option-item
            v-for="(option, index) in categoryOption[filterConfig.categoryItemsField]"
            :key="index"
            :option="option"
            :filter-config="filterConfig"
            :filters="filters"
            :label-key="labelKey"
            :value-key="valueKey"
            :return-object="returnObject"
            :search-query="search"
            class="ml-5"
            @change="handleOptionChange"
          />
        </div>
      </category-option-item>
    </div>
    <gl-empty-results v-else />
  </div>
</template>

<script>
  import _cloneDeep from 'lodash/cloneDeep';
  import _get from 'lodash/get';
  import _uniqBy from 'lodash/uniqBy';
  import _differenceBy from 'lodash/differenceBy';
  import _flatMap from 'lodash/flatMap';
  import _intersectionBy from 'lodash/intersectionBy';

  import logger from '@/logger';

  import GlButton from 'uikit/components/buttons/Button.vue';
  import { GlEmptyResults } from 'uikit/components';

  import OptionItem from './OptionItem.vue';
  import CategoryOptionItem from './CategoryOptionItem.vue';

  import OptionsSearch from './OptionsSearch.vue';

  const DEFAULT_RECYCLE_OPTION_HEIGHT = 40;

  export default {
    components: {
      OptionItem,
      OptionsSearch,
      CategoryOptionItem,
      GlButton,
      GlEmptyResults,
    },
    props: {
      filters: {
        type: Object,
        required: true,
      },
      filterConfig: {
        type: Object,
        required: true,
      },
      labelKey: {
        type: String,
        default: 'name',
      },
      valueKey: {
        type: String,
        default: 'value',
      },
      returnObject: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        loading: false,
        search: '',
        fetchedOptions: [],
      };
    },

    computed: {
      isWithFetch() {
        return !!this.filterConfig.optionsFetcher;
      },
      searchOptions() {
        if (this.isWithFetch) {
          return this.filterConfig.optionsFetcher.fetchedOptions;
        }

        return this.getSearchOptions();
      },
      recycleOptionHeight() {
        return this.filterConfig.optionHeight || DEFAULT_RECYCLE_OPTION_HEIGHT;
      },
      appliedFilters() {
        const { key } = this.filterConfig;
        return [...(this.filters[key] || [])];
      },
      flatSearchOptions() {
        return _flatMap(this.searchOptions, this.filterConfig.categoryItemsField);
      },
      isAllSelected() {
        const appliedSearchedOptions = _intersectionBy(this.appliedFilters, this.flatSearchOptions, this.filterConfig.optionsValueKey);
        return this.flatSearchOptions.length && appliedSearchedOptions.length === this.flatSearchOptions.length;
      },
    },

    watch: {
      'filterConfig.key'() {
        if (this.isWithFetch) {
          this.handleOptionsFetch();
        } else {
          this.search = '';
        }
      },
    },

    methods: {
      getSearchOptions() {
        if (!this.search) {
          return _cloneDeep(this.filterConfig.options);
        }

        return this.filterConfig.options.flatMap(categoryOption => {
          if (_get(categoryOption, this.filterConfig.categoryLabelKey)?.toLowerCase()?.includes(this.search.toLowerCase())) {
            return [
              {
                ...categoryOption,
              },
            ];
          }

          const filteredOptions = categoryOption[this.filterConfig.categoryItemsField].filter(option => option[this.filterConfig.optionsLabelKey].toLowerCase().includes(this.search.toLowerCase()));

          if (filteredOptions.length) {
            return [
              {
                ...categoryOption,
                [this.filterConfig.categoryItemsField]: filteredOptions,
              },
            ];
          }

          return [];
        });
      },
      handleOptionChange(filter) {
        this.$emit('change', filter);
      },
      handleSearchChange(value) {
        this.search = value;

        if (this.isWithFetch) {
          this.handleOptionsFetch();
        }
      },
      async handleOptionsFetch() {
        this.loading = true;

        try {
          await this.filterConfig.optionsFetcher.fetch();
        } catch (error) {
          logger.error(error);
        } finally {
          this.loading = false;
        }
      },
      handleSelectAllClick() {
        if (this.isAllSelected) {
          this.removeValues(this.flatSearchOptions);
        } else {
          this.addValues(this.flatSearchOptions);
        }
      },
      removeValues(values) {
        const filter = _differenceBy(this.appliedFilters, values, this.filterConfig.optionsValueKey);

        this.changeFilter(filter);
      },
      addValues(values) {
        const filter = _uniqBy([...this.appliedFilters, ...values], this.filterConfig.optionsValueKey);

        this.changeFilter(filter);
      },
      changeFilter(values) {
        this.$emit('change', { [this.filterConfig.key]: values });
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .options-list {
    @apply flex flex-col;
    flex-grow: 1;

    &__search {
      padding: 24px 24px 8px;
      border-width: 0;

      & ~ .options-list__options {
        padding: 8px 16px 24px;
      }
    }

    &__select-all-container {
      @apply flex justify-end w-full;
      padding: 0 4px;
    }

    &__options {
      padding: 24px 16px;
      max-height: 360px;
      overflow: auto;

      &.simple-scroller {
        @apply flex flex-col;
        gap: 4px;
      }
    }
  }

  @media (max-width: 991px) {
    .options-list__search {
      padding-top: 0;

      & ~ .options-list__options {
        padding: 8px 16px 12px;
      }
    }

    .options-list__options {
      padding: 0 16px 12px;
    }
  }
</style>
