import { render, staticRenderFns } from "./RegularModalWindow.vue?vue&type=template&id=544d0a41&scoped=true"
import script from "./RegularModalWindow.vue?vue&type=script&lang=js"
export * from "./RegularModalWindow.vue?vue&type=script&lang=js"
import style0 from "./RegularModalWindow.vue?vue&type=style&index=0&id=544d0a41&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "544d0a41",
  null
  
)

export default component.exports