<template>
  <label class="search">
    <gl-input
      ref="input"
      v-bind="$attrs"
      :value="inputValue"
      :placeholder="placeholder"
      class="search__input"
      @input="handleInput"
      @change="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
    >
      <template #icon>
        <gl-base-icon
          name="search"
          width="16"
          height="16"
        />
      </template>
    </gl-input>
    <button
      v-if="inputValue && showClearIcon"
      class="search__delete-button"
      @click.stop="handleClearIconClick"
    >
      <gl-base-icon
        name="cross-circle"
        width="16"
        height="16"
        class="delete-button__icon"
      />
    </button>
  </label>
</template>

<script>
  import _debounce from 'lodash/debounce';
  import GlBaseIcon from '@/uikit/icons/BaseIcon.vue';
  import GlInput from '@/uikit/components/inputs/Input.vue';

  export default {
    components: {
      GlInput,
      GlBaseIcon,
    },
    model: {
      event: 'change',
    },
    props: {
      value: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: 'Search...',
      },
      showClearIcon: {
        type: Boolean,
        default: true,
      },
      delayTimeout: {
        type: Number,
        default: 700,
      },
    },
    data() {
      return {
        inputValue: this.value,

        handleDelayedChange: _debounce(this.delayedSearchChange, this.delayTimeout),
        handleDelayedInput: _debounce(this.delayedSearchInput, this.delayTimeout),
      };
    },
    watch: {
      value(val, old) {
        if (val !== old) this.inputValue = val;
      },
    },
    methods: {
      handleClearIconClick() {
        this.$emit('change', '');
        this.$emit('input', '');
        this.$emit('delayed-change', '');
        this.$emit('delayed-input', '');
        this.handleDelayedChange.cancel();
        this.handleDelayedInput.cancel();
        this.$refs.input.focus();
      },
      handleInput(val) {
        this.$emit('input', val);
        this.inputValue = val;
        this.handleDelayedInput(val);
      },
      handleChange(val) {
        this.$emit('change', val);
        this.handleDelayedChange(val);
      },
      delayedSearchChange(val) {
        this.$emit('delayed-change', val);
      },
      delayedSearchInput(val) {
        this.$emit('delayed-input', val);
      },
      handleBlur() {
        setTimeout(() => {
          const refInput = this.$refs.input;
          if (refInput?.$el?.querySelector('input') !== document.activeElement) {
            this.$emit('blur');
          }
        }, 200);
      },
      handleFocus() {
        this.$emit('focus');
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .search {
    @apply relative;
    flex-grow: 1;
  }

  .search__delete-button {
    @apply text-icon-default cursor-pointer;
    outline: none;
    height: 16px;
    width: 16px;
    position: absolute;
    right: 12px;
    top: 12px;

    &:hover,
    &:focus-visible {
      @apply text-icon-default-dark;
    }
  }

  .delete-button__icon {
    transition: 0.4s ease all;
  }
</style>
