let disabledCounter = 0;

export default (disable = true) => {
  const { body } = window.document;

  if (disable) {
    disabledCounter++;

    if (disabledCounter === 1) {
      body.classList.add('scroll-disabled');
    }
  } else {
    disabledCounter--;

    if (disabledCounter <= 0) {
      body.classList.remove('scroll-disabled');
      disabledCounter = 0;
    }
  }
};
