import moment from 'moment-timezone';

import config from '@/config';

import _includes from 'lodash/includes';
import _filter from 'lodash/fp/filter';
import _sortBy from 'lodash/fp/sortBy';
import _flow from 'lodash/fp/flow';
import _padStart from 'lodash/padStart';

import { BillingPaymentMethodStatus } from '@/constants/payment';

export const paymentMethodTypes = {
  braintreePaypalBilling: 'braintree_paypal_billing',
  braintreePaypalWithdrawal: 'braintree_paypal_withdrawal',
  braintreeCreditCard: 'braintree_credit_card',
  payoneerPrepaidCard: 'payoneer_prepaid_card',
  payoneerACH: 'payoneer_ach',
  tipaltiACH: 'tipalti_ach',
  tipaltiWireTransfer: 'tipalti_wire_transfer',
  tipaltiECheck: 'tipalti_echeck',
  plaidBankAccount: 'plaid_bank_account',
  transpay: 'transpay',
  nmiCreditCard: 'nmi_credit_card',
  responseCRMCreditCard: 'responsecrm_credit_card',
  transferwise: 'transferwise',
  currencyCloud: 'currency_cloud',
  bankAccount: 'bank_account', /** @deprecated */
  marqetaLifetime: 'marqeta_lifetime',
  bluebancInteract: 'bluebanc_interact_debit_card',
  pex: 'pex',
  fireblocks: 'fireblocks',
  stripe: 'stripe',
  nium: 'nium',
  bankAccountVault: 'bank_account_vault',
};

const paymentAllowedTypes = [
  paymentMethodTypes.braintreePaypalBilling,
  paymentMethodTypes.braintreeCreditCard,
  paymentMethodTypes.plaidBankAccount,
  paymentMethodTypes.nmiCreditCard,
  paymentMethodTypes.responseCRMCreditCard,
];

export const bankAccountWithdrawalTypes = [
  paymentMethodTypes.tipaltiACH,
  paymentMethodTypes.tipaltiWireTransfer,
  paymentMethodTypes.tipaltiECheck,
  paymentMethodTypes.transpay,
  paymentMethodTypes.transferwise,
  paymentMethodTypes.currencyCloud,
  paymentMethodTypes.bankAccount,
  paymentMethodTypes.bankAccountVault,
  paymentMethodTypes.nium,
];

export const digitalWalletWithdrawalTypes = [
  paymentMethodTypes.braintreePaypalWithdrawal,
  paymentMethodTypes.payoneerPrepaidCard,
  paymentMethodTypes.payoneerACH,
];

export const cryptoWithdrawalTypes = [
  paymentMethodTypes.fireblocks,
];

const withdrawAllowedTypes = [
  ...digitalWalletWithdrawalTypes,
  ...cryptoWithdrawalTypes,
  ...bankAccountWithdrawalTypes,
];

const creditCardPaymentTypes = [paymentMethodTypes.braintreeCreditCard, paymentMethodTypes.nmiCreditCard, paymentMethodTypes.responseCRMCreditCard, paymentMethodTypes.stripe];

const tipaltiPaymentMethods = [paymentMethodTypes.tipaltiACH, paymentMethodTypes.tipaltiWireTransfer, paymentMethodTypes.tipaltiECheck];
const payoneerPaymentMethods = [paymentMethodTypes.payoneerPrepaidCard, paymentMethodTypes.payoneerACH];

const isDepositPaymentMethod = paymentMethod => _includes(paymentAllowedTypes, paymentMethod.type);
const isWithdrawalPaymentMethod = paymentMethod => _includes(withdrawAllowedTypes, paymentMethod.type);
const isCreditCard = paymentMethod => _includes(creditCardPaymentTypes, paymentMethod.type);

const getPaymentAllowedPaymentMethods = (paymentMethods = []) => _flow(
  _filter(p => p.status === 'approved' && isDepositPaymentMethod(p)),
  _sortBy(p => (p.primary ? 0 : 1)),
)(paymentMethods);

const getWithdrawalPaymentMethods = (paymentMethods = []) => paymentMethods.filter(method => isWithdrawalPaymentMethod(method));
const getBillingPaymentMethods = (paymentMethods = []) => paymentMethods.filter(method => isDepositPaymentMethod(method));

const getWithdrawAllowedPaymentMethods = (paymentMethods = []) => _flow(
  _filter(p => p.status === 'approved' && isWithdrawalPaymentMethod(p)),
  _sortBy(p => (p.primaryWithdrawal ? 0 : 1)),
)(paymentMethods);

const getPaymentMethodExpirationDate = paymentMethod => {
  const { expirationMonth, expirationYear } = paymentMethod?.metadata || {};

  if (expirationMonth == null || expirationYear == null) {
    return null;
  }

  const expirationDate = moment(`${expirationYear}-${_padStart(expirationMonth, 2, '0')}`, 'YYYY-MM').endOf('month');

  return expirationDate.toDate();
};

const isBillingPaymentMethodExpired = paymentMethod => {
  const expirationDate = getPaymentMethodExpirationDate(paymentMethod);

  if (!expirationDate || paymentMethod.status !== BillingPaymentMethodStatus.APPROVED) {
    return false;
  }

  return moment(Date.now()).isAfter(expirationDate);
};

const aboutToExpireGap = {
  development: moment(Date.now()).endOf('month'),
  staging: moment(Date.now()).endOf('month'),
  production: moment(Date.now()).add(14, 'days').endOf('day'),
};

const isBillingPaymentMethodExpires = paymentMethod => {
  const expirationDate = getPaymentMethodExpirationDate(paymentMethod);

  if (!expirationDate || paymentMethod.status !== BillingPaymentMethodStatus.APPROVED) {
    return false;
  }

  const expirationGap = aboutToExpireGap[config.env];

  return moment(expirationDate).isBetween(moment(Date.now()), expirationGap, 'day', '[]');
};

const getExpiredBillingPaymentMethods = (paymentMethods = []) => paymentMethods.filter(method => {
  if (!isDepositPaymentMethod(method) || !isCreditCard(method)) {
    return false;
  }

  return isBillingPaymentMethodExpired(method);
});

const getBillingPaymentMethodsAboutToExpire = (paymentMethods = []) => paymentMethods.filter(method => {
  if (!isDepositPaymentMethod(method) || !isCreditCard(method)) {
    return false;
  }

  return isBillingPaymentMethodExpires(method);
});

export default {
  paymentAllowedTypes,
  withdrawAllowedTypes,
  creditCardPaymentTypes,
  tipaltiPaymentMethods,
  payoneerPaymentMethods,

  isDepositPaymentMethod,
  isWithdrawalPaymentMethod,
  isCreditCard,

  getExpiredBillingPaymentMethods,
  isBillingPaymentMethodExpired,
  isBillingPaymentMethodExpires,
  getBillingPaymentMethodsAboutToExpire,
  getPaymentMethodExpirationDate,

  getPaymentAllowedPaymentMethods,
  getWithdrawAllowedPaymentMethods,
  getWithdrawalPaymentMethods,
  getBillingPaymentMethods,
};
