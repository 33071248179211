<template>
  <div class="row-actions">
    <v-popover
      trigger="manual"
      :container="container"
      :offset="8"
      :handle-resize="false"
      popover-base-class="row-actions__popover"
      popover-inner-class="row-actions__popover-inner"
      placement="bottom-end"
      :popper-options="popperOptions"
      :open="isOpen"
      @hide="handleHide"
    >
      <span
        class="row-actions__icon"
        :class="{ opened: isOpen }"
        @click.stop.prevent="handleIconClick"
      >
        <slot
          v-if="$slots.actionTrigger"
          name="actionTrigger"
        ></slot>
        <more-button
          v-else
          :type="type"
          :disabled="disabled"
          :active="isOpen"
          :size="size"
        />
      </span>
      <template slot="popover">
        <div
          class="row-actions__popup"
          :class="{ opened: isOpen }"
          @click="handlePopoverClick"
        >
          <slot name="inlineActions"></slot>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
  import _merge from 'lodash/merge';
  import _includes from 'lodash/includes';

  import MoreButton from 'uikit/components/buttons/MoreButton.vue';

  export default {
    components: {
      MoreButton,
    },
    props: {
      container: {
        type: String,
        default: '.table-relative-container',
      },
      type: {
        type: String,
        default: 'default',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      popoverOptions: {
        type: Object,
        default: () => ({}),
      },
      size: {
        type: Number,
        default: 40,
        validator: val => _includes([16, 20, 24, 40, 48], val),
      },
    },
    data() {
      return {
        isOpen: false,
        popperOptions: {
          modifiers: {
            keepTogether: { enabled: false },
            arrow: { enabled: false },
            preventOverflow: {
              escapeWithReference: false,
            },
          },
        },
      };
    },
    created() {
      this.popperOptions = _merge(this.popperOptions, this.popoverOptions);
    },
    methods: {
      handleIconClick() {
        if (this.disabled) {
          this.handleHide();
          return;
        }

        this.isOpen = !this.isOpen;
      },
      handleHide() {
        this.isOpen = false;
      },
      handlePopoverClick() {
        this.isOpen = false;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .row-actions__icon {
    @apply flex justify-center items-center;
  }

  .row-actions__popup {
    @apply bg-white shadow-dropdown-default;
    overflow: hidden;
    padding: 8px 0;
    border-radius: 8px;
    min-width: fit-content;
    transition: 0.15s ease-in all;
    opacity: 0;

    &.opened {
      opacity: 1;
    }
  }
</style>
