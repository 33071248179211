import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import _find from 'lodash/find';

import userApi from '@/services/api/users';

import { getMessageContentString } from '@/views/Inbox/services';
import config from '@/config';
import vueUtils from './infrastructure/vueUtils';

export const browserNotifications = {
  init() {
    const { currentUser } = vueUtils.store.getters;

    const { vapidKey, ...firebaseConfig } = config.firebase;

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    getToken(messaging, { vapidKey })
      .then(currentToken => {
        if (currentToken) {
          if (!_find(currentUser.fcmTokens, t => t === currentToken)) {
            return userApi.addFcmToken(currentUser._id, currentToken);
          }
        }
      })
      .catch(error => {
        console.error('An error occurred while retrieving token.', error);
      });

    onMessage(messaging, payload => {
      const body = (payload.notification?.body || '').replace(/(<([^>]+)>)/gi, '');

      // eslint-disable-next-line no-new
      new Notification(payload.notification.title, {
        ...payload.notification,
        body,
      });
    });
  },
};

export const sendNewMessageNotification = message => {
  const icon = 'https://res.cloudinary.com/golance/image/upload/v1667474984/logo/golance-logo-icon-2022.png';
  const body = getMessageContentString(message);
  const data = {
    threadId: message.threadId,
  };
  const notification = new Notification('goLance New Message', {
    body,
    icon,
    data,
    tag: `new-message-${message.threadId}`,
  });

  notification.onclick = event => {
    window.open(`${config.rootUrl}/inbox/thread/${event.currentTarget.data.threadId}`, '_blank');
    notification.close();
  };
};
