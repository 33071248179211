import {
  getUserPermissions, getRouteToRedirectFromFreelancerDashboard, getCurrentUser, checkEvent,
} from '../routerServices';

const FreelancerProfile = () => import(/* webpackChunkName: "FreelancerProfile", webpackPrefetch: true */ '@/views/User/FreelancerProfile');
const FreelancerProfileOverview = () => import(/* webpackChunkName: "FreelancerProfileOverview", webpackPrefetch: true  */ '@/views/User/FreelancerProfile/Profile');
const FreelancerServices = () => import(/* webpackChunkName: "FreelancerServices", webpackPrefetch: true  */ '@/views/User/FreelancerProfile/FreelancerServices');
const FreelancerPortfolio = () => import(/* webpackChunkName: "FreelancerPortfolio", webpackPrefetch: true  */ '@/views/User/FreelancerProfile/Portfolio');
const FreelancerReviews = () => import(/* webpackChunkName: "FreelancerReviews", webpackPrefetch: true  */ '@/views/User/FreelancerProfile/Reviews');
const FreelancerPortfolioDetails = () => import(/* webpackChunkName: "FreelancerPortfolioDetails", webpackPrefetch: true  */ '@/views/User/FreelancerProfile/PortfolioDetails');

const FreelancerHome = () => import(/* webpackChunkName: "FreelancerHome", webpackPrefetch: true */ '@/views/User/FreelancerDashboard');
const FreelancerHomeProfileInsights = () => import(/* webpackChunkName: "FreelancerHome", webpackPrefetch: true */ '@/views/User/FreelancerDashboard/ProfileInsights');
const FreelancerHomeReviews = () => import(/* webpackChunkName: "FreelancerHome", webpackPrefetch: true */ '@/views/User/FreelancerDashboard/Reviews');
const FreelancerHomeSkillTests = () => import(/* webpackChunkName: "FreelancerHome", webpackPrefetch: true */ '@/views/User/FreelancerDashboard/SkillTests');

const FreelancerDashboard = () => import(/* webpackChunkName: "FreelancerDashboard" */ '@/views/Dashboard/Freelancer/index.vue');
const FreelancerDashboardHourlyContracts = () => import(/* webpackChunkName: "FreelancerDashboardHourlyContracts" */ '@/views/Dashboard/Freelancer/components/HourlyContracts/index.vue');
const FreelancerDashboardBonuses = () => import(/* webpackChunkName: "FreelancerDashboardBonuses" */ '@/views/Dashboard/Freelancer/components/Bonuses/index.vue');
const FreelancerDashboardMilestones = () => import(/* webpackChunkName: "FreelancerDashboardMilestones" */ '@/views/Dashboard/Freelancer/components/Milestones/index.vue');
const FreelancerDashboardScheduledPayments = () => import(/* webpackChunkName: "FreelancerDashboardScheduledPayments" */ '@/views/Dashboard/Freelancer/components/ScheduledPayments/index.vue');
const FreelancerDashboardAllTabs = () => import(/* webpackChunkName: "FreelancerDashboardAllTabs" */ '@/views/Dashboard/Freelancer/components/AllTabs/index.vue');

const JobManagerReports = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManagerReports/Freelancer');

const FreelancerJobManager = () => import(/* webpackChunkName: "FreelancerJobManager" */ '@/views/JobManager/Freelancer');
const FreelancerJobManagerJobsApplied = () => import(/* webpackChunkName: "FreelancerJobManager" */ '@/views/JobManager/Freelancer/JobsApplied');
const FreelancerJobManagerOffersReceived = () => import(/* webpackChunkName: "FreelancerJobManager" */ '@/views/JobManager/Freelancer/Offers/Offers.vue');
const FreelancerJobManagerHourlyContracts = () => import(/* webpackChunkName: "FreelancerJobManager" */ '@/views/JobManager/Freelancer/Contracts/Hourly.vue');
const FreelancerJobManagerFixedContracts = () => import(/* webpackChunkName: "FreelancerJobManager" */ '@/views/JobManager/Freelancer/Contracts/Fixed.vue');
const FreelanacerJobManagerReports = () => import(/* webpackChunkName: "FreelancerJobManager" */ '@/views/JobManager/Freelancer/Reports');

const FreelancerJobManagerTotalEarned = () => import(/* webpackChunkName: "FreelancerJobManagerReports" */ '@/views/JobManagerReports/Freelancer/TotalEarned');
const FreelancerJobManagerBonuses = () => import(/* webpackChunkName: "FreelancerJobManagerReports" */ '@/views/JobManagerReports/Freelancer/Bonuses');
const FreelancerJobManagerMilestones = () => import(/* webpackChunkName: "FreelancerJobManagerReports" */ '@/views/JobManagerReports/Freelancer/Milestones');
const FreelancerJobManagerScheduledPayments = () => import(/* webpackChunkName: "FreelancerJobManagerReports" */ '@/views/JobManagerReports/Freelancer/ScheduledPayments');
const FreelancerJobManagerReportsHourlyContracts = () => import(/* webpackChunkName: "FreelancerJobManagerReports" */ '@/views/JobManagerReports/Freelancer/HourlyContracts');
const FreelancerJobManagerContractTimesheets = () => import(/* webpackChunkName: "FreelancerJobManagerReports" */ '@/views/JobManagerReports/Freelancer/ContractTimesheet');
const FreelancerJobManagerAgencyReports = () => import(/* webpackChunkName: "FreelancerJobManagerReports" */ '@/views/JobManagerReports/Freelancer/Agency');

const FreelancerOnboarding = () => import(/* webpackChunkName: "FreelancerOnboarding" */ '@/views/FreelancerOnboarding/index.vue');

export default [
  {
    name: 'freelancer-home',
    path: '/freelancer/home',
    component: FreelancerHome,
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();

      if (!permissions.isFreelancer) {
        return next({ name: 'client-home' });
      }

      next();
    },
    redirect: () => ({ name: 'freelancer-home.info' }),
    children: [
      {
        name: 'freelancer-home.info',
        path: '',
        component: FreelancerHomeProfileInsights,
      },
      {
        name: 'freelancer-home.reviews',
        path: 'reviews',
        component: FreelancerHomeReviews,
      },
      {
        name: 'freelancer-home.skill-tests',
        path: 'skill-tests',
        component: FreelancerHomeSkillTests,
        beforeEnter: (to, from, next) => next({
          name: 'page-not-found',
        }),
      },
    ],
  },
  {
    name: 'freelancer-profile',
    path: '/freelancer/:userId',
    component: FreelancerProfile,
    props: route => ({ edit: String(route.query.edit) === 'true' }),
    meta: { requiresAuth: false },
    beforeEnter: async (to, from, next) => {
      await checkEvent('freelancer_profile_view');

      next();
    },
    redirect: to => ({ name: 'freelancer-profile.overview', query: to.query, params: to.params }),
    children: [
      {
        name: 'freelancer-profile.overview',
        path: '',
        component: FreelancerProfileOverview,
      },
      {
        name: 'freelancer-profile.old-overview',
        path: 'overview',
        redirect: to => ({ name: 'freelancer-profile.overview', query: to.query, params: to.params }),
      },
      {
        name: 'freelancer-profile.services',
        path: 'services',
        component: FreelancerServices,
        beforeEnter: (to, from, next) => next({
          name: 'page-not-found',
        }),
      },
      {
        name: 'freelancer-profile.projects',
        path: 'projects',
        component: FreelancerPortfolio,
      },
      {
        name: 'freelancer-profile.reviews',
        path: 'reviews',
        component: FreelancerReviews,
      },
    ],
  },
  {
    name: 'freelancer-portfolio-details',
    path: '/freelancer/:userId/portfolio-details/:id',
    component: FreelancerPortfolioDetails,
    meta: { requiresAuth: false },
  },
  {
    name: 'freelancer-dashboard',
    path: '/dashboard/freelancer',
    component: FreelancerDashboard,
    beforeEnter: (to, from, next) => next({
      query: to.query,
      ...getRouteToRedirectFromFreelancerDashboard(),
    }),
    redirect() {
      return { name: 'freelancer-dashboard.all-tabs' };
    },
    children: [
      {
        name: 'freelancer-dashboard.all-tabs',
        path: 'all-tabs',
        component: FreelancerDashboardAllTabs,
      },
      {
        name: 'freelancer-dashboard.hourly-contracts',
        path: 'hourly-contracts',
        component: FreelancerDashboardHourlyContracts,
      },
      {
        name: 'freelancer-dashboard.bonuses',
        path: 'bonuses',
        component: FreelancerDashboardBonuses,
      },
      {
        name: 'freelancer-dashboard.milestones',
        path: 'milestones',
        component: FreelancerDashboardMilestones,
      },
      {
        name: 'freelancer-dashboard.scheduled-payments',
        path: 'scheduled-payments',
        component: FreelancerDashboardScheduledPayments,
      },
    ],
  },

  {
    name: 'freelancer-job-manager',
    path: '/job-manager/freelancer',
    component: FreelancerJobManager,
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();

      if (!permissions.isFreelancer) {
        return next({
          name: 'job-manager',
        });
      }

      return next();
    },
    children: [
      {
        name: 'freelancer-job-manager.jobs-applied',
        path: 'jobs-applied',
        component: FreelancerJobManagerJobsApplied,
      },
      {
        name: 'freelancer-job-manager.offers-received',
        path: 'offers-received',
        component: FreelancerJobManagerOffersReceived,
      },
      {
        name: 'freelancer-job-manager.hourly-contracts',
        path: 'hourly-contracts',
        component: FreelancerJobManagerHourlyContracts,
      },
      {
        name: 'freelancer-job-manager.fixed-contracts',
        path: 'fixed-contracts',
        component: FreelancerJobManagerFixedContracts,
      },
      {
        name: 'freelancer-job-manager.reports',
        path: 'reports',
        component: FreelanacerJobManagerReports,
      },
    ],
  },
  {
    name: 'freelancer-job-manager-reports',
    path: '/job-manager/freelancer/reports',
    component: JobManagerReports,
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();

      if (!permissions.isFreelancer) {
        return next({
          name: 'access-denied',
        });
      }

      return next();
    },
    children: [
      {
        name: 'freelancer-job-manager-reports.total-earned',
        path: 'total-earned',
        component: FreelancerJobManagerTotalEarned,
      },
      {
        name: 'freelancer-job-manager-reports.hourly-contracts',
        path: 'hourly-contracts',
        component: FreelancerJobManagerReportsHourlyContracts,
      },
      {
        name: 'freelancer-job-manager-reports.milestones',
        path: 'milestones',
        component: FreelancerJobManagerMilestones,
      },
      {
        name: 'freelancer-job-manager-reports.scheduled-payments',
        path: 'scheduled-payments',
        component: FreelancerJobManagerScheduledPayments,
      },
      {
        name: 'freelancer-job-manager-reports.bonuses',
        path: 'bonuses',
        component: FreelancerJobManagerBonuses,
      },
      {
        name: 'freelancer-job-manager-reports.contract-timesheets',
        path: 'contract-timesheets/:contractId',
        component: FreelancerJobManagerContractTimesheets,
      },
      {
        name: 'freelancer-job-manager-reports.agency',
        path: 'agency',
        component: FreelancerJobManagerAgencyReports,
      },
    ],
  },
  {
    name: 'freelancer-onboarding',
    path: '/onboarding/freelancer',
    component: FreelancerOnboarding,
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();
      const user = getCurrentUser();

      const { onboarding } = user;

      if (!permissions.isFreelancer) {
        return next({
          name: 'access-denied',
        });
      }

      if (!onboarding || onboarding?.isCompleted) {
        return next({
          name: 'freelancer-profile.overview',
          params: { userId: user.username },
        });
      }

      return next();
    },
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
];
