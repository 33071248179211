import _concat from 'lodash/concat';
import _cloneDeep from 'lodash/cloneDeep';

import logger from '@/logger';

import jobApi from '@/services/api/jobs';
import reportsApi from '@/services/api/reports';
import userApi from '@/services/api/users';

import notifications from '@/services/notifications';

import activityFeed from './activityFeed';

const RECOMMENDED_USERS_LIMIT = 6;
const MIN_FETCH_DELAY = 450;

const defaultJobsStatisticsTotals = {
  views: {
    total: 0,
    target: 0,
  },
  proposals: {
    total: 0,
    target: 0,
    new: 0,
  },
};

const defaultRecommendedUsers = {
  users: [],
  totalCount: 0,
  page: 1,
};

const state = {
  postedJobs: {
    selectedJob: {
      job: {},
      statistics: {
        totals: _cloneDeep(defaultJobsStatisticsTotals),
        statsByDays: [],
      },
      recommendedUsers: _cloneDeep(defaultRecommendedUsers),
      isLoading: false,
    },
    jobs: [],
    isLoading: false,
    error: null,
  },

  currentWeek: {
    isLoading: false,
    isThresholdSectionExpanded: false,
    statistics: {},
    error: null,
  },

  lastWeek: {
    isLoading: false,
    statistics: {},
    error: null,
  },

  currentMilestones: {
    isLoading: false,
    statistics: {},
    contracts: [],
    error: null,
  },
};

const actions = {
  setSelectedJob({ commit }, job) {
    commit('setSelectedJob', job);
  },

  updateJobData({ commit }, job) {
    commit('setSelectedJob', job);
  },

  resetJobRecommendations({ commit }) {
    commit('resetJobRecommendations');
  },

  fetchInitialDashboardData({ dispatch }) {
    // eslint-disable-next-line no-promise-executor-return
    const minFetchDelayP = new Promise(resolve => setTimeout(resolve, MIN_FETCH_DELAY));

    return Promise.all([dispatch('fetchJobs'), dispatch('fetchCurrentWeek'), dispatch('fetchLastWeek'), dispatch('fetchCurrentMilestones'), minFetchDelayP]);
  },

  async fetchJobs({ commit }) {
    commit('setIsJobsLoading', true);

    try {
      const query = {
        status: 'active',
        offset: -1, // disable pagination
        _scope: 'client_dashboard',
      };

      const jobs = await jobApi.getAll(query);
      commit('setJobs', jobs);
      commit('setSelectedJob', jobs[0]);
    } catch (error) {
      commit('setJobsError', error);
      logger.error('Fetch jobs error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsJobsLoading', false);
    }
  },

  async fetchJobData({ commit, dispatch }, { id }) {
    commit('setIsJobStatisticsLoading', true);

    // eslint-disable-next-line no-promise-executor-return
    const minFetchDelayP = new Promise(resolve => setTimeout(resolve, MIN_FETCH_DELAY));

    await Promise.all([dispatch('fetchJob', id), dispatch('loadRecommendedUsers', id), dispatch('fetchJobStatistics', id), minFetchDelayP]);

    commit('setIsJobStatisticsLoading', false);
  },

  async fetchJob({ commit }, id) {
    try {
      const job = await jobApi.get(id);
      commit('setSelectedJob', job);
      return job;
    } catch (error) {
      commit('setJobsError', error);
      logger.error('Fetch job error', error);
      notifications.showGolanceError(error);
    }
  },

  async fetchJobStatistics({ commit }, id) {
    try {
      const statistics = await jobApi.getJobStatisticsForClient(id);
      commit('setJobStatistics', statistics);
      return statistics;
    } catch (error) {
      commit('setJobsError', error);
      logger.error('Fetch job statistics error', error);
      notifications.showGolanceError(error);
    }
  },

  async loadRecommendedUsers({ commit, dispatch }, id) {
    const recommendedUsers = await dispatch('fetchRecommendedUsers', id);
    commit('setJobRecommendedUsers', recommendedUsers);
  },

  async loadMoreRecommendedUsers({ commit, dispatch }, id) {
    commit('incrementJobRecommendationsPage');
    const recommendedUsers = await dispatch('fetchRecommendedUsers', id);
    commit('addJobRecommendedUsers', recommendedUsers);
  },

  async fetchRecommendedUsers({ commit, getters }, id) {
    try {
      const result = await userApi.getSuggestedGolancers({
        jobId: id,
        page: getters.postedJobs.selectedJob.recommendedUsers.page,
        limit: RECOMMENDED_USERS_LIMIT,
      });

      const recommendedUsers = { users: result.results, totalCount: result.totalCount };
      return recommendedUsers;
    } catch (error) {
      commit('setJobsError', error);
      logger.error('Fetch recommended users error', error);
      notifications.showGolanceError(error);
    }
  },

  async fetchCurrentWeek({ commit }) {
    commit('setIsCurrentWeekLoading', true);

    try {
      const currentWeek = await reportsApi.getClientCurrentWeekStatistics();
      commit('setCurrentWeek', currentWeek);
    } catch (error) {
      commit('setCurrentWeekError', error);
      logger.error('Fetch current week statistics error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsCurrentWeekLoading', false);
    }
  },

  async fetchLastWeek({ commit }) {
    commit('setIsLastWeekLoading', true);

    try {
      const lastWeek = await reportsApi.getClientLastWeekStatistics();
      commit('setLastWeek', lastWeek);
    } catch (error) {
      commit('setLastWeekError', error);
      logger.error('Fetch last week statistics error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsLastWeekLoading', false);
    }
  },

  async fetchCurrentMilestones({ commit }) {
    commit('setIsCurrentMilestonesLoading', true);

    try {
      const currentMilestones = await reportsApi.getClientCurrentMilestonesStatistics();
      commit('setCurrentMilestones', currentMilestones);
    } catch (error) {
      commit('setCurrentMilestonesError', error);
      logger.error('Fetch current milestones statistics error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsCurrentMilestonesLoading', false);
    }
  },
};

const mutations = {
  // Posted Jobs
  setIsJobsLoading({ postedJobs }, isLoading) {
    postedJobs.isLoading = isLoading;
  },
  setJobs({ postedJobs }, jobs) {
    postedJobs.jobs = jobs;
  },
  setJobsError({ postedJobs }, error) {
    postedJobs.error = error;
  },
  setSelectedJob({ postedJobs }, job) {
    postedJobs.selectedJob.job = job;
  },
  resetJobRecommendations({ postedJobs }) {
    postedJobs.selectedJob.recommendedUsers = _cloneDeep(defaultRecommendedUsers);
  },
  incrementJobRecommendationsPage({ postedJobs }) {
    postedJobs.selectedJob.recommendedUsers.page += 1;
  },
  setIsJobStatisticsLoading({ postedJobs }, isLoading) {
    postedJobs.selectedJob.isLoading = isLoading;
  },
  setJobStatistics({ postedJobs }, statistics) {
    postedJobs.selectedJob.statistics = statistics;
  },
  setJobRecommendedUsers({ postedJobs }, recommendedUsers) {
    postedJobs.selectedJob.recommendedUsers.users = recommendedUsers.users;
    postedJobs.selectedJob.recommendedUsers.totalCount = recommendedUsers.totalCount;
  },
  addJobRecommendedUsers({ postedJobs }, recommendedUsers) {
    const users = _concat(postedJobs.selectedJob.recommendedUsers.users, recommendedUsers.users);
    postedJobs.selectedJob.recommendedUsers.users = users;
    postedJobs.selectedJob.recommendedUsers.totalCount = recommendedUsers.totalCount;
  },

  // Current Week
  setIsCurrentWeekLoading({ currentWeek }, isLoading) {
    currentWeek.isLoading = isLoading;
  },
  setCurrentWeek({ currentWeek }, statistics) {
    currentWeek.statistics = statistics;
  },
  setCurrentWeekError({ currentWeek }, error) {
    currentWeek.error = error;
  },
  setIsThresholdSectionExpanded({ currentWeek }, isExpanded) {
    currentWeek.isThresholdSectionExpanded = isExpanded;
  },

  // Last Week
  setIsLastWeekLoading({ lastWeek }, isLoading) {
    lastWeek.isLoading = isLoading;
  },
  setLastWeek({ lastWeek }, statistics) {
    lastWeek.statistics = statistics;
  },
  setLastWeekError({ lastWeek }, error) {
    lastWeek.error = error;
  },

  // Current milestones
  setIsCurrentMilestonesLoading({ currentMilestones }, isLoading) {
    currentMilestones.isLoading = isLoading;
  },
  setCurrentMilestones({ currentMilestones }, { statistics, contracts }) {
    currentMilestones.statistics = statistics;
    currentMilestones.contracts = contracts;
  },
  setCurrentMilestonesError({ currentMilestones }, error) {
    currentMilestones.error = error;
  },
};

const getters = {
  postedJobs: ({ postedJobs }) => postedJobs,
  selectedJobData: ({ postedJobs }) => postedJobs.selectedJob,
  job: ({ postedJobs }) => postedJobs.selectedJob.job,

  currentWeek: ({ currentWeek }) => currentWeek,

  lastWeek: ({ lastWeek }) => lastWeek,

  currentMilestones: ({ currentMilestones }) => currentMilestones,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    activityFeed,
  },
};
