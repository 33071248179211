<template>
  <gl-icon-button
    class="edit-button"
    :size="24"
    icon-name="pencil"
    @click="$emit('click')"
  />
</template>

<script>
  import GlIconButton from 'uikit/components/buttons/IconButton.vue';

  export default {
    components: {
      GlIconButton,
    },
  };
</script>

<style lang="postcss" scoped>
  .edit-button.base-icon {
    @apply text-icon-dark;

    &:hover,
    &:active,
    &.active,
    &:focus-visible {
      @apply text-icon-brand;
    }
  }
</style>
