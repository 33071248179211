<template>
  <div
    ref="dropdown"
    tabindex="0"
    class="nav-list__dropdown"
    :class="{ active: isActive }"
    @focus="handleFocus"
    @focusout="handleFocusOut"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @click="handleTabClick"
  >
    <span
      v-if="!clickable"
      :title="label"
      class="dropdown__label"
    >
      {{ label }}
    </span>
    <router-link
      v-else
      :title="label"
      :to="to"
      class="dropdown__label"
    >
      {{ label }}
    </router-link>
    <transition
      v-if="!clickable"
      name="header-popup"
    >
      <div
        v-if="show"
        class="popup"
      >
        <header-popup
          @mousedown.prevent
          @click.native="handleClick"
        >
          <slot></slot>
        </header-popup>
      </div>
    </transition>
  </div>
</template>

<script>
  import HeaderPopup from './HeaderPopup.vue';

  import navStateRoutes from '../constants/navStateRoutes';

  export default {
    components: {
      HeaderPopup,
    },
    props: {
      value: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      offset: {
        type: [Number, String],
        default: 0,
      },
      clickable: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        show: false,
      };
    },
    computed: {
      isActive() {
        const currentOptionRoute = navStateRoutes[this.value];
        if (!currentOptionRoute) {
          return false;
        }

        if (currentOptionRoute.name) {
          return this.$route.matched.some(route => {
            const matchedRoutes = this.$router.resolve(currentOptionRoute)?.route?.matched || [];
            return !!matchedRoutes.find(r => r.name === route.name);
          });
        }

        return false;
      },
      to() {
        const linkParams = navStateRoutes[this.value];
        return { name: linkParams.name, params: linkParams.params || {} };
      },
    },
    methods: {
      handleFocusOut() {
        requestAnimationFrame(() => {
          if (!this.$refs.dropdown.contains(document.activeElement)) {
            this.show = false;
          }
        });
      },
      handleFocus() {
        this.show = true;
      },
      handleClick(ev) {
        if (ev.target.closest('a')) {
          this.$refs.dropdown.blur();
          this.show = false;
        }
      },
      handleTabClick(ev) {
        const link = ev.target.querySelector('a');

        if (link) {
          link.click();
        }
      },
      handleMouseLeave() {
        this.show = false;
        this.$refs.dropdown.blur();
      },
      handleMouseEnter() {
        this.show = true;
        this.$refs.dropdown.focus();
      },
    },
  };
</script>

<style lang="postcss" scoped>
.popup {
  @apply absolute bg-transparent;
  top: 40px;
  left: -12px;
  padding-top: 8px;
  z-index: 101;
  transition: 0.2s linear all;
}

.dropdown__label {
  @apply font-primary text-14 text-text-placeholder font-medium antialiased;
  transition: 0.2s ease all;
  &::before {
    @apply text-text-black font-semibold;
    content: attr(title);
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

.nav-list__dropdown {
  @apply relative;
  outline: none;
  padding: 12px 4px 10px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  line-height: 1.4em;
  margin: 0 12px;
  transition: 0.2s ease all;
  &::after {
    @apply inline-block w-full absolute;
    content: "";
    border-radius: 1px;
    left: 0;
    bottom: 0;
    transition: 0.2s ease all;
    opacity: 0;
  }
  &:hover,
  &.active {
    & .dropdown__label {
      @apply text-text-black;
    }
    &:after {
      @apply border-b border-blue-liberty;
      opacity: 1;
    }
  }
  &:focus-visible,
  &:focus,
  &:focus-within {
    & .dropdown__label {
      @apply text-text-black font-semibold;
      outline: none;
    }
    &:after {
      @apply border border-blue-liberty bg-blue-liberty;
      opacity: 1;
    }
  }
}

@media (min-width: 1131px) {
  .nav-list__dropdown {
    margin: 0 6px;
  }
}

@media (min-width: 1440px) {
  .nav-list__dropdown {
    margin: 0 12px;
  }
}
</style>
