<template>
  <vue-slider
    :value="value"
    class="slider"
    v-bind="sliderOptions"
    :interval="interval"
    :min="min"
    :max="max"
    :tooltip-formatter="tooltipFormatter"
    :disabled="disabled"
    @change="handleChange"
    @drag-end="handleDragEnd"
    @drag-start="handleDragStart"
  />
</template>

<script>
  import VueSlider from 'vue-slider-component';
  import _noop from 'lodash/noop';

  const defaultSliderOptions = {
    dotSize: 25,
    dotOptions: {
      style: {
        width: '25px',
        height: '25px',
        backgroundColor: '#4163DD',
        borderRadius: '100%',
        boxSizing: 'border-box',
      },
    },
    processStyle: {
      backgroundColor: '#4163DD',
      borderRadius: '4px',
    },
    railStyle: {
      backgroundColor: '#D9D9D9',
      borderRadius: '4px',
    },
    tooltip: 'none',
  };

  export default {
    components: {
      VueSlider,
    },
    props: {
      value: {
        type: Number,
        default: 0,
      },
      min: {
        type: Number,
        default: 0,
      },
      max: {
        type: Number,
        default: 0,
      },
      interval: {
        type: Number,
        default: 1,
      },
      options: {
        type: Object,
        default: _noop,
      },
      tooltipFormatter: {
        type: [String, Function],
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      sliderOptions() {
        return { ...defaultSliderOptions, ...this.options };
      },
    },
    methods: {
      handleChange(value) {
        this.$emit('input', value);
      },
      handleDragEnd() {
        this.$emit('drag-end');
      },
      handleDragStart() {
        this.$emit('drag-start');
      },
    },
  };
</script>
