<template>
  <div
    class="rounded-pill"
    :class="[size, { clickable: $listeners.click }]"
    @click="handleClick"
  >
    <slot> </slot>

    <gl-icon-button
      v-if="$listeners.close"
      :size="20"
      icon-name="close"
      class="ml-2"
      @click.stop="handleCloseClick"
    />
  </div>
</template>

<script>
  import _includes from 'lodash/includes';
  import GlIconButton from 'uikit/components/buttons/IconButton.vue';

  export default {
    components: {
      GlIconButton,
    },
    props: {
      size: {
        type: String,
        default: 'm',
        validator(value) {
          return _includes(['m', 's'], value);
        },
      },
    },
    methods: {
      handleCloseClick() {
        this.$emit('close');
      },
      handleClick() {
        this.$emit('click');
      },
    },
  };
</script>

<style lang="postcss" scoped>
.rounded-pill {
  @apply flex items-center bg-white-ghost text-text-black font-primary;

  transition: color 0.3s ease-out;
  width: fit-content;
  padding: 8px 16px;
  border-radius: 20px;
  letter-spacing: 0.0125em;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid transparent;
  max-height: 36px;

  &.clickable {
    @apply cursor-pointer;

    &:hover {
      @apply text-text-link;
    }
    &:active {
      border: 1px solid #3f56b6;
    }
  }

  &.s {
    padding: 4px 10px;
    max-height: 30px;
  }
}
</style>
