<template>
  <!-- eslint-disable max-len -->
  <header-icon
    :count-indicator="unreadInboxCount"
    name="messages-count"
  >
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 9.66669C16.9057 9.66669 15.822 9.88224 14.811 10.301C13.7999 10.7198 12.8813 11.3336 12.1075 12.1075C10.5447 13.6703 9.66668 15.7899 9.66668 18C9.65939 19.9243 10.3257 21.7905 11.55 23.275L9.88334 24.9417C9.76771 25.0589 9.68938 25.2077 9.65824 25.3693C9.62709 25.531 9.64453 25.6983 9.70834 25.85C9.77756 26 9.88976 26.126 10.0307 26.212C10.1717 26.2981 10.335 26.3403 10.5 26.3334H18C20.2101 26.3334 22.3298 25.4554 23.8926 23.8926C25.4554 22.3298 26.3333 20.2102 26.3333 18C26.3333 15.7899 25.4554 13.6703 23.8926 12.1075C22.3298 10.5447 20.2101 9.66669 18 9.66669ZM18 24.6667H12.5083L13.2833 23.8917C13.4386 23.7356 13.5257 23.5243 13.5257 23.3042C13.5257 23.084 13.4386 22.8728 13.2833 22.7167C12.1922 21.6267 11.5127 20.1921 11.3606 18.6573C11.2085 17.1226 11.5933 15.5825 12.4494 14.2996C13.3054 13.0167 14.5798 12.0703 16.0554 11.6217C17.531 11.173 19.1166 11.2499 20.5419 11.8391C21.9672 12.4284 23.1441 13.4936 23.872 14.8533C24.6 16.2129 24.8341 17.783 24.5343 19.2959C24.2345 20.8087 23.4194 22.1709 22.228 23.1502C21.0365 24.1295 19.5423 24.6655 18 24.6667Z"
        fill="currentColor"
      />
    </svg>
  </header-icon>
</template>

<script>
  import HeaderIcon from './HeaderIcon.vue';

  export default {
    components: {
      HeaderIcon,
    },
    props: {
      unreadInboxCount: {
        type: Number,
        default: 0,
      },
    },
  };
</script>
