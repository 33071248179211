import axios from 'axios';
import Cookies from 'js-cookie';
import { stringify } from 'qs';
import _forEach from 'lodash/forEach';
import _isString from 'lodash/isString';
import _constant from 'lodash/constant';
import _isArray from 'lodash/isArray';
import _toUpper from 'lodash/toUpper';
import vueUtils from '@/services/infrastructure/vueUtils';

import logger from '@/logger';

import socketIo from '@/services/infrastructure/socketIo';
import notifications from '@/services/notifications';

export class Api {
  constructor(rootUrl) {
    this.token = null;
    this.deviceToken = null;
    this.fcmToken = null;
    this.rootUrl = rootUrl;
  }

  buildUrl(relativeUrl, params = {}) {
    if (_isArray(relativeUrl)) {
      relativeUrl = relativeUrl.join('/');
    }

    const query = stringify(params, { arrayFormat: 'brackets' });
    return `${this.rootUrl || '/api/v1'}${relativeUrl}${query ? `?${query}` : ''}`;
  }

  static handleResponse(response) {
    return response.data;
  }

  static handleResponseError(error) {
    if (axios.isCancel(error)) {
      const errorData = {
        isRequestCanceled: true,
        error,
      };
      throw errorData;
    }
    switch (error?.response?.status) {
      case 400:
        throw error.response.data;
      case 401:
        if (error.response.data.error === 'invalid_token') {
          notifications.showGolanceError(error.response);
          // eslint-disable-next-line no-promise-executor-return
          const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

          return delay(3000).then(() => {
            window.location = '/auth/local/signout';
          });
        }
        vueUtils.router.push({ name: 'access-denied' });
        break;

      case 403:
        vueUtils.router.push({ name: 'access-denied' });
        break;
      case 404:
        vueUtils.router.push({ name: 'page-not-found' });
        break;
      default:
        break;
    }

    return Promise.reject(error?.response);
  }

  request(method, url, data, cancelToken, options = {}) {
    const fullUrl = method === 'get' || method === 'delete' ? this.buildUrl(url, data) : this.buildUrl(url);
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-GOLANCE-CSRF-PROTECTION': '1',
    };

    const accessToken = Cookies.get('golance_access_token');
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    if (this.deviceToken) {
      headers['X-Device-Token'] = this.deviceToken;
    }

    if (this.fcmToken) {
      headers['X-FCM-Token'] = this.fcmToken;
    }

    const axiosConfig = {
      url: fullUrl,
      method: _toUpper(method),
      headers,

      cancelToken,
      ...options,
    };

    if (method !== 'delete' && method !== 'get') {
      axiosConfig.data = data;
    }

    return axios(axiosConfig).then(Api.handleResponse).catch(Api.handleResponseError);
  }

  setToken(token) {
    this.token = token;

    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  }

  setDeviceToken(deviceToken) {
    this.deviceToken = deviceToken;
  }

  setFcmToken(fcmToken) {
    this.fcmToken = fcmToken;
  }

  // eslint-disable-next-line default-param-last
  get(url, params = {}, cancelToken) {
    return this.request('get', url, params, cancelToken);
  }

  // eslint-disable-next-line default-param-last
  getFile(method, url, data = {}, headers, cancelToken, options = {}) {
    const fullUrl = method === 'get' ? this.buildUrl(url, data) : this.buildUrl(url);
    const body = method === 'get' ? null : data;

    return axios({
      url: fullUrl,
      method: _toUpper(method),
      headers: {
        ...headers,
        Authorization: `Bearer ${Cookies.get('golance_access_token')}`,
      },
      data: body,
      cancelToken,
      ...options,
    })
      .then(response => response)
      .catch(Api.handleResponseError);
  }

  delete(url, params = {}) {
    return this.request('delete', url, params);
  }

  post(url, data, cancelToken, options) {
    return this.request('post', url, data || {}, cancelToken, options);
  }

  put(url, data = {}) {
    return this.request('put', url, data);
  }

  patch(url, data = {}) {
    return this.request('patch', url, data);
  }

  postFile(url, file, fields = {}) {
    const fullUrl = this.buildUrl(url);

    const body = new FormData();

    _forEach(fields, (value, key) => {
      body.append(key, value);
    });

    // XXX: image should be string or blob here, check why object is passed
    // https://developer.mozilla.org/en-US/docs/Web/API/FormData/append#Syntax
    // @ts-ignore
    body.append('file', file);

    return axios
      .post(fullUrl, body, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${Cookies.get('golance_access_token')}`,
        },
      })
      .then(Api.handleResponse)
      .catch(Api.handleResponseError);
  }

  postForm(url, data) {
    const fullUrl = this.buildUrl(url);
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-GOLANCE-CSRF-PROTECTION': '1',
    };

    const instance = axios.create({});

    instance.interceptors.request.use(
      req => {
        if (req.method === 'post') {
          req.headers['Content-Type'] = 'application/x-www-form-urlencoded';
          req.data = stringify(req.data);
        }

        return req;
      },
      error => Promise.reject(error),
    );

    const accessToken = Cookies.get('golance_access_token');
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    if (this.deviceToken) {
      headers['X-Device-Token'] = this.deviceToken;
    }

    if (this.fcmToken) {
      headers['X-FCM-Token'] = this.fcmToken;
    }

    return instance({
      url: fullUrl,
      method: 'post',
      headers,
      data,
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return { ...response.data, _response: response };
        }
        throw new Error({ ...response.data, _status: response.status });
      })
      .catch(err => {
        // eslint-disable-next-line no-throw-literal
        throw { ...err.response.data, _status: err.response.status };
      });
  }

  static waitForCommand(request, timeout = 10000) {
    let waitTimeout;
    let promiseResolve;
    let promiseReject;

    function handleSuccess(eventData) {
      request.then(apiResponse => {
        const { commandId, entity } = apiResponse;

        if (eventData.commandId === commandId && (!entity || entity === eventData.entity)) {
          clearTimeout(waitTimeout);
          unsubscribe();
          promiseResolve(apiResponse);
        }
      });
    }

    function handleFail(eventData) {
      request.then(apiResponse => {
        const { commandId } = apiResponse;

        if (eventData.commandId === commandId) {
          clearTimeout(waitTimeout);
          unsubscribe();
          promiseReject(apiResponse);
        }
      });
    }

    function unsubscribe() {
      socketIo.off('io:command:processed', handleSuccess);
      socketIo.off('io:command:failed', handleFail);
    }

    socketIo.on('io:command:processed', handleSuccess);
    socketIo.on('io:command:failed', handleFail);

    return new Promise((resolve, reject) => {
      request.then(
        apiResponse => {
          promiseResolve = resolve;
          promiseReject = reject;
          waitTimeout = setTimeout(() => {
            logger.log(`Command with id [${apiResponse.commandId}] within ${timeout / 1000} seconds, executing callback.`);

            unsubscribe();
            resolve();
          }, timeout);
        },
        err => {
          unsubscribe();
          reject(err);
        },
      );
    });
  }

  static waitForEvent(request, evts, evtChecker = _constant(true), timeout = 10000) {
    let successEvent;
    let failureEvent;
    let genericEvent;

    if (_isString(evts)) {
      genericEvent = evts;
    } else {
      successEvent = evts.success;
      failureEvent = evts.failure;
    }

    return new Promise((resolve, reject) => {
      request.then(
        apiResponse => {
          function unsubscribe() {
            if (genericEvent) {
              socketIo.off(genericEvent, handleGeneric);
            }

            if (successEvent) {
              socketIo.off(successEvent, handleSuccess);
            }

            if (failureEvent) {
              socketIo.off(failureEvent, handleFail);
            }
          }

          const waitTimeout = setTimeout(() => {
            logger.log(`Command is not received event [${genericEvent || successEvent}]  within ${timeout / 1000} seconds, executing callback.`);

            unsubscribe();
            resolve();
          }, timeout);

          function handleGeneric(data, evtName) {
            evtChecker(
              apiResponse,
              data,
              () => handleSuccess(data, evtName, false),
              () => handleFail(data, evtName, false),
            );
          }

          function handleSuccess(data, evtName, checkEvent = true) {
            if (!checkEvent || evtChecker(apiResponse, data)) {
              clearTimeout(waitTimeout);
              unsubscribe();
              resolve(data);
            }
          }

          function handleFail(data, evtName, checkEvent = true) {
            if (!checkEvent || evtChecker(apiResponse, data)) {
              clearTimeout(waitTimeout);
              unsubscribe();
              reject(data);
            }
          }

          if (genericEvent) {
            socketIo.on(genericEvent, handleGeneric);
          }

          if (successEvent) {
            socketIo.on(successEvent, handleSuccess);
          }

          if (failureEvent) {
            socketIo.on(failureEvent, handleFail);
          }
        },
        err => reject(err),
      );
    });
  }
}

export const cachedApiRequest = requestFunction => {
  let getPromise;

  const getData = () => {
    if (getPromise) {
      return getPromise;
    }

    getPromise = requestFunction();

    return getPromise;
  };

  return getData;
};

export default new Api();
