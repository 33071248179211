import _forEach from 'lodash/forEach';

import createRateExchanger from '@/services/exchangeRates';
import config from '@/config';
import logger from '@/logger';

export const getUserWallets = user => {
  const { wallets } = user;

  if (!user.isLimitedWalletEnabled) {
    return wallets;
  }

  return (
    wallets?.map(wallet => {
      if (!wallet.primary) {
        return wallet;
      }

      return {
        ...wallet,
        name: 'goLance Earnings',
      };
    }) ?? []
  );
};

export const getTotalBalanceInUSD = (user, exchangeRates) => {
  const rateExchanger = createRateExchanger({
    rates: exchangeRates,
    conversionConfig: config.paymentDefaults?.conversion,
  });
  let totalUSDBalance = 0;

  try {
    _forEach(user.wallets, wallet => {
      _forEach(wallet.balances?.available, (balance, currency) => {
        totalUSDBalance += rateExchanger.convert({
          amount: balance,
          sourceCurrency: currency,
          targetCurrency: 'USD',
        });
      });

      _forEach(wallet.cryptoAssets, asset => {
        totalUSDBalance
          += rateExchanger.convert({
            amount: +asset.balance,
            sourceCurrency: asset.currency,
            targetCurrency: 'USD',
          }) * 100; // Converting to cents
      });
    });
  } catch (error) {
    logger.error(error);
  }

  return totalUSDBalance;
};
