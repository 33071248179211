import _map from 'lodash/map';
import { Cloudinary } from '@cloudinary/url-gen';
import { thumbnail } from '@cloudinary/url-gen/actions/resize';

const paramsMap = {
  height: 'h',
  width: 'w',
  rotate: 'a',
};

const cld = new Cloudinary({
  cloud: {
    cloudName: 'golance',
  },
});

export default (id, params, mode = 'crop') => {
  if (!id) {
    return null;
  }

  const paramsString = _map(params, (val, key) => `${paramsMap[key] || key}_${val}`).join(',');

  return `https://res.cloudinary.com/golance/image/upload/c_${mode},${paramsString}/${id}.png`;
};

function getUrlWithPrevResizeParams(newUrl, prevParams) {
  const urlStartRegex = /(https?:\/\/res\.cloudinary\.com\/golance\/image\/upload\/)/;

  const startMatch = newUrl.match(urlStartRegex);

  if (!startMatch) {
    return newUrl;
  }

  return `${startMatch[0]}${prevParams}${newUrl.slice(startMatch[0].length)}`;
}

export function getImageThumbnailUrl(url, { width, height }) {
  const regex = /https?:\/\/res\.cloudinary\.com\/golance\/image\/upload\/(?<params>(?:(?:\w_(?:\w)+\/)?(?:\w|:)+,[^/]+\/)+|c_crop\/)?(?:(?<version>v\d+)\/)?(?<public_id>.+)/;

  const match = url.match(regex);

  const id = match?.groups?.public_id;

  if (!id) {
    return url;
  }

  const prevParams = match?.groups?.params || '';

  let cImg = cld.image(id);

  if (match?.groups?.version) {
    cImg = cImg.setVersion(match.groups.version.replace('v', ''));
  }

  let thumbnailResizePipe = thumbnail();

  if (width) {
    thumbnailResizePipe = thumbnailResizePipe.width(width);
  }

  if (height) {
    thumbnailResizePipe = thumbnailResizePipe.height(height);
  }

  cImg.resize(thumbnailResizePipe);

  return getUrlWithPrevResizeParams(cImg.toURL(), prevParams);
}
