import client from './client';

const BASE_URL = '/exchange-rates';

const exchangeRatesApi = {
  get() {
    return client.get([BASE_URL]);
  },
};

export default exchangeRatesApi;
