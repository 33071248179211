<template>
  <div
    class="table-relative-container"
    :class="{ 'with-expandable-rows': expandableRows }"
  >
    <div
      ref="container"
      class="table-scroll-container"
      :class="{ scrolled: isScrolled, editable }"
      @scroll="handleTableScroll"
    >
      <div
        class="table-component"
        :class="[theme, { compact }]"
      >
        <table-header
          ref="tableHeader"
          :columns="columns"
          :sorted-by="sortedBy"
          :selectable="selectable"
          :expandable-rows="expandableRows"
          :resizable="resizable"
          :selected-count="selectedCount"
          :page-records-count="pageRecordsCount"
          :inline-actions="inlineActions"
          :compact="compact"
          :editable="editable"
          :scrolled="isScrolled"
          :grid-columns-width="gridColumnsWidth"
          class="table__header"
          @sortChange="handleSortChange"
          @selectAll="handleSelectAllChange"
          @editStart="handleEditStart"
          @columnsResizeStart="handleColumnsResizeStart"
          @columnsResize="handleColumnsResize"
          @columnsResizeEnd="handleColumnsResizeEnd"
        >
          <template #header="scope">
            <slot
              name="header"
              v-bind="scope"
            ></slot>
          </template>
        </table-header>
        <table-rows
          ref="tableRows"
          :columns="columns"
          :data="data"
          :selectable="selectable"
          :editable="editable"
          :expandable-rows="expandableRows"
          :selected-records="selectedRecords"
          :inline-actions="inlineActions"
          :clickable-rows="clickableRows"
          :compact="compact"
          :grid-columns-width="gridColumnsWidth"
          :loading="loading"
          :loading-rows-count="loadingRowsCount"
          :scrolled="isScrolled"
          :sub-record-spacing="subRecordSpacing"
          @selectChange="handleRecordSelectChange"
          @rowClick="handleRowClick"
        >
          <template #emptyState>
            <slot name="emptyState"></slot>
          </template>
          <template #expandableRow="{ record, gridTemplateColumns, columnSizes }">
            <slot
              name="expandableRow"
              :record="record"
              :gridTemplateColumns="gridTemplateColumns"
              :columnSizes="columnSizes"
            ></slot>
          </template>
          <template
            #cell="scope"
          >
            <slot
              name="cell"
              v-bind="scope"
            ></slot>
          </template>
          <template
            v-if="$scopedSlots.expandIcon"
            #expandIcon="scope"
          >
            <slot
              name="expandIcon"
              v-bind="scope"
            ></slot>
          </template>
          <template #actionTrigger>
            <slot name="actionTrigger"></slot>
          </template>
          <template
            v-if="$scopedSlots.inlineActions"
            #inlineActions="scope"
          >
            <slot
              name="inlineActions"
              v-bind="scope"
            ></slot>
          </template>
          <template
            v-else-if="$scopedSlots.inlineActionButton"
            #inlineActionButton="{ record }"
          >
            <slot
              name="inlineActionButton"
              :record="record"
            ></slot>
          </template>
        </table-rows>
      </div>
    </div>
  </div>
</template>

<script>
  import _omit from 'lodash/omit';
  import _forEach from 'lodash/forEach';
  import _throttle from 'lodash/throttle';
  import _size from 'lodash/size';

  import TableRows from './TableRows.vue';
  import TableHeader from './TableHeader.vue';

  export default {
    components: {
      TableRows,
      TableHeader,
    },
    props: {
      columns: {
        type: Array,
        required: true,
      },
      allColumns: {
        type: Array,
        default: () => [],
      },
      data: {
        type: Array,
        default: () => [],
      },
      selectable: {
        type: Boolean,
        default: true,
      },
      editable: {
        type: Boolean,
        default: true,
      },
      resizable: {
        type: Boolean,
        default: false,
      },
      clickableRows: {
        type: Boolean,
        default: false,
      },
      inlineActions: {
        type: Boolean,
        default: false,
      },
      sortedBy: {
        type: Object,
        default: () => ({}),
      },
      selectedRecords: {
        type: Object,
        default: () => ({}),
      },
      selectedOnPageCount: {
        type: Number,
        default: null,
      },
      compact: {
        type: Boolean,
        default: false,
      },
      gridColumnsWidth: {
        type: String,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      loadingRowsCount: {
        type: Number,
        default: 5,
      },
      subRecordSpacing: {
        type: Number,
        default: 42,
      },
      theme: {
        type: String,
        default: 'default',
        validator: value => ['default', 'snow'].includes(value),
      },
    },
    data() {
      return {
        isScrolled: false,
        resizingGridColumnsWidth: null,
        intersectionObserver: null,
        intersectingRows: [],
      };
    },
    computed: {
      selectedCount() {
        return this.selectedOnPageCount ?? _size(this.selectedRecords);
      },
      pageRecordsCount() {
        return this.data.length;
      },
      columnsCount() {
        return this.columns.length;
      },
      expandableRows() {
        return this.data.some(record => Array.isArray(record?.$children));
      },
    },
    watch: {
      columnsCount(val, old) {
        if (val !== old) {
          this.$nextTick(() => {
            this.throttleCalculateIsScrolled();
          });
        }
      },
    },
    mounted() {
      window.addEventListener('resize', this.onResize);
      this.throttleCalculateIsScrolled();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
      this.intersectionObserver?.disconnect();
    },
    methods: {
      onResize() {
        this.throttleCalculateIsScrolled();
      },
      handleTableScroll() {
        this.throttleCalculateIsScrolled();
      },
      throttleCalculateIsScrolled: _throttle(
        function throttleCalculateIsScrolled() {
          const { container } = this.$refs;
          if (!container) {
            return;
          }
          this.isScrolled = container.offsetWidth + container.scrollLeft < container.scrollWidth - 1;
        },
        300,
        { trailing: true, leading: false },
      ),
      handleRecordSelectChange(record, value) {
        let selectedRecords;
        if (value) {
          selectedRecords = { ...this.selectedRecords, [record._id]: record };
        } else {
          selectedRecords = _omit(this.selectedRecords, [record._id]);
        }
        this.$emit('selectChange', selectedRecords, record._id, value);
      },
      handleSortChange(field, direction) {
        this.$emit('sortChange', { field, direction });
      },
      handleSelectAllChange(value) {
        const selectedRecords = { ...this.selectedRecords };
        _forEach(this.data, record => {
          if (value) {
            selectedRecords[record._id] = record;
          } else {
            delete selectedRecords[record._id];
          }
        });
        this.$emit('selectChange', selectedRecords);
      },
      handleRowClick(...args) {
        this.$emit('rowClick', ...args);
      },
      handleEditStart() {
        this.$emit('editStart');
      },
      handleColumnsResizeStart() {
        this.isScrolled = false;
        const tableRows = [...this.$refs.tableRows.$el.querySelectorAll(this.expandableRows ? '.content-row' : '.table__row')];

        this.intersectionObserver?.disconnect();
        this.intersectionObserver = new IntersectionObserver(
          entities => {
            entities.forEach(entity => {
              const index = tableRows.findIndex(row => row === entity.target);
              this.intersectingRows[index] = entity.isIntersecting ? entity.target : false;

              if (entity.isIntersecting && this.resizingGridColumnsWidth) {
                entity.target.style.setProperty('grid-template-columns', this.resizingGridColumnsWidth);
              }
            });
          },
          { rootMargin: '100px' },
        );

        tableRows.forEach(row => {
          this.intersectionObserver.observe(row);
        });
      },
      handleColumnsResize(gridTemplateColumns) {
        this.setGridTemplateColumns(gridTemplateColumns, [...this.intersectingRows]);
        this.resizingGridColumnsWidth = gridTemplateColumns;
      },
      setGridTemplateColumns(gridTemplateColumns, rows) {
        const tableHeader = this.$refs.tableHeader.$el;
        tableHeader.style.setProperty('grid-template-columns', gridTemplateColumns);

        if (!rows) {
          rows = [...this.$refs.tableRows.$el.querySelectorAll(this.expandableRows ? '.content-row' : '.table__row')];
        }

        rows.forEach(row => {
          if (!row) {
            return;
          }

          row.style.setProperty('grid-template-columns', gridTemplateColumns);
        });
      },
      handleColumnsResizeEnd(resizedColumns) {
        this.intersectionObserver?.disconnect();
        this.resizingGridColumnsWidth = null;
        this.intersectingRows = [];

        const allColumns = this.allColumns.length ? this.allColumns : this.columns;

        let columnsToChange = resizedColumns;

        if (allColumns.length !== resizedColumns.length) {
          columnsToChange = allColumns.map(col => resizedColumns.find(c => c.field === col.field) || col);
        }

        setTimeout(() => {
          this.handleColumnsChange(columnsToChange);
          this.setGridTemplateColumns(null);
          this.throttleCalculateIsScrolled();
        }, 100);
      },
      handleColumnsChange(columns) {
        this.$emit('columnsChange', columns);
      },
    },
  };
</script>
<style lang="postcss" scoped>
  .table-relative-container {
    position: relative;
  }
  .table-scroll-container {
    overflow-x: auto;
  }
  .table-component {
    @apply text-14;
    min-width: fit-content;
    position: relative;

    &.compact {
      border-radius: 12px;
    }

    &.snow {
      & .table__header {
        @apply border-b border-t border-foundation-gray-4;
        background-color: white;
        border-radius: 0;
        height: 56px;
        margin-bottom: 0;
      }

      & >>> .table__row {
        @apply border-foundation-gray-4;
        border-width: 0 0 1px 0;
        border-radius: 0;
        min-height: 52px;

        & + .table__row {
          margin-top: 0;
        }

        &.expandable {
          padding-bottom: 0;
        }

        & .total-row {
          display: none;
        }

        & .expandable-row .row__inline-actions-container {
          height: calc(100% - 2px);
          margin-bottom: 2px;
        }

        & .table-cell.remaining-cell {
          opacity: 1;
        }

        & .expandable-row__hidden-content:before {
          border: 0;
        }
      }
    }
  }
  .table__rows {
    position: relative;
    justify-content: flex-end;
  }
  .edit-table-button {
    position: absolute;
    z-index: 40;
    right: 28px;
    top: 10px;
  }
  @media (max-width: 576px) {
    .table-relative-container {
      position: static;
    }
    .table-scroll-container {
      overflow: visible;
    }
    .table-component {
      position: static;
      max-width: 100%;
      min-width: 100%;
      &.compact {
        @apply border-0;
      }
    }
    .edit-table-button {
      display: none;
    }

    .table-relative-container.with-expandable-rows {
      position: relative;

      & .table-scroll-container {
        overflow-x: auto;
      }

      & .table-component {
        min-width: fit-content;
        max-width: unset;
        position: relative;
      }
    }
  }
</style>
