import client, { Api } from './client';

const BASE_URL = '/recommendations';

const recommendationsApi = {
  getJobsForFreelancer() {
    return client.get([BASE_URL, 'jobs-for-freelancer']);
  },

  dismissJob(id) {
    const request = client.post([BASE_URL, 'jobs-for-freelancer', id, 'dismiss']);
    return Api.waitForCommand(request);
  },
};

export default recommendationsApi;
