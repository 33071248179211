import client, { Api } from './client';

const BASE_URL = '/in-app-survey';

export default {
  updateSurveyStatus(runId, status) {
    return Api.waitForCommand(client.put([BASE_URL, 'run', runId], { status }));
  },
  checkEvent(name) {
    return client.post([BASE_URL, 'trigger', 'check'], { name });
  },
};
