import DateComponent from '../filterLabelComponents/Date.vue';

export const dateFilterConfig = {
  title: 'Date',
  label: '',
  required: true,
  date: true,
  search: false,
  withSearch: false,
  options: [],
  optionsLabelKey: 'name',
  optionsValueKey: 'value',
  returnObject: false,
  multiple: false,
  icon: 'calendar',
  key: 'date',
  pillComponent: DateComponent,
  optionComponent: null,
};

export const searchFilterConfig = {
  title: 'Search',
  required: false,
  label: '',
  date: false,
  search: true,
  withSearch: false,
  options: [],
  optionsLabelKey: 'name',
  optionsValueKey: 'value',
  returnObject: false,
  multiple: false,
  icon: 'search',
  key: 'search',
  pillComponent: null,
  optionComponent: null,
};

export const exampleFilterConfig = {
  title: 'Example',
  label: '',
  required: false,
  date: false,
  search: false,
  withSearch: false,
  options: [],
  optionsFetcher: null,
  optionsLabelKey: 'name',
  optionsValueKey: 'value',
  returnObject: false,
  multiple: false,
  icon: 'user',
  key: 'example',
  pillComponent: null,
  optionComponent: null,
};

export const defaultFiltersConfig = [dateFilterConfig, searchFilterConfig, exampleFilterConfig];
