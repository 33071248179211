import { cryptoCurrencies, stableCryptoCurrencies } from './currency';

/* eslint-disable import/prefer-default-export */
const unstableCryptoCurrencies = cryptoCurrencies.filter(c => !stableCryptoCurrencies.includes(c));

const WITHDRAWAL_DELIVERY_TIME = {
  NO_DATA: 'no_data',
  STANDART: 'standard',
  SLOW: 'slow',
  POSSIBLY_SLOW: 'possibly_slow',
};

const WITHDRAWAL_TRANSACTION_STATUS = {
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  DECLINED: 'declined',
};

export {
  cryptoCurrencies, unstableCryptoCurrencies, WITHDRAWAL_DELIVERY_TIME, WITHDRAWAL_TRANSACTION_STATUS,
};
