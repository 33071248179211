<template>
  <button
    :disabled="disabled"
    type="button"
    class="base-icon"
    :class="[type, { active, medium: size <= 24, small: size <= 16 }]"
    :style="buttonStyle"
    :name="name"
    @click="$emit('click', $event)"
  >
    <slot :defaultIconSize="defaultIconSize">
      <gl-base-icon
        :name="iconName"
        :size="iconSize || defaultIconSize"
      />
    </slot>
  </button>
</template>

<script>
  import _includes from 'lodash/includes';

  import GlBaseIcon from '../../icons/BaseIcon.vue';

  export default {
    components: {
      GlBaseIcon,
    },
    props: {
      active: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'default',
        validator: val => _includes(['default', 'border', 'black'], val),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      size: {
        type: Number,
        default: 40,
        validator: val => _includes([16, 20, 24, 32, 36, 40, 48], val),
      },
      iconName: {
        type: String,
        default: 'box',
      },
      iconSize: {
        type: [Number, String],
        default: null,
      },
      name: {
        type: String,
        default: '',
      },
    },
    computed: {
      buttonStyle() {
        return {
          minWidth: `${this.size}px`,
          width: `${this.size}px`,
          height: `${this.size}px`,
        };
      },
      defaultIconSize() {
        const iconSizesMap = {
          16: 10,
          20: 12,
          24: 16,
          36: 20,
          40: 20,
          48: 26.7,
        };

        return iconSizesMap[this.size];
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .base-icon {
    @apply flex items-center justify-center text-icon-default-dark;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s ease all;
    outline: none;
    border: 1px solid transparent;
    min-width: 40px;
    height: 40px;

    &.small {
      border-radius: 5px;
    }

    &.medium {
      border-radius: 6px;
    }

    &:hover {
      @apply bg-foundation-gray-2 text-icon-brand;
    }

    &:active,
    &.active,
    &:focus-visible {
      @apply border-blue-liberty bg-white text-icon-brand;
      outline: none;
    }

    &:disabled,
    &:disabled:hover,
    &:disabled.active,
    &:disabled:active {
      @apply bg-white text-icon-disabled border-0;
      cursor: not-allowed;
    }

    &.border {
      @apply bg-white border-foundation-gray-4;

      &:hover {
        @apply border-foundation-gray-5;
      }

      &:active,
      &.active,
      &:focus-visible {
        @apply border-blue-liberty;
      }

      &:disabled,
      &:disabled:hover,
      &:disabled.active,
      &:disabled:active {
        @apply bg-white text-icon-disabled border-1 border-icon-disabled;
      }
    }

    &.black {
      @apply text-white border-0;
      background: rgba(36, 38, 46, 0.2);

      &:hover {
        @apply text-white border-0;
        background: rgba(36, 38, 46, 0.4);
      }

      &:active,
      &:focus-visible,
      &.active {
        @apply text-white border-0;
        background: rgba(36, 38, 46, 0.6);
      }

      &:disabled,
      &:disabled:hover,
      &:disabled.active,
      &:disabled:active {
        @apply text-icon-default border-0;
        background: rgba(36, 38, 46, 0.1);
      }
    }
  }
</style>
