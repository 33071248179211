const TimeOffPolicy = () => import(/* webpackChunkName: "time-off-policy" */ '@/views/TimeOff/Client/Policy');

const TimeOffPolicyDetails = () => import(/* webpackChunkName: "time-off-policy" */ '@/views/TimeOff/Client/Policy/Details');
const TimeOffPolicyContractors = () => import(/* webpackChunkName: "time-off-policy" */ '@/views/TimeOff/Client/Policy/Contractors');
const TimeOffPolicyActivityLog = () => import(/* webpackChunkName: "time-off-policy" */ '@/views/TimeOff/Client/Policy/ActivityLog');

export default [
  {
    name: 'time-off-policy',
    path: '/time-off/policy/:id',
    meta: { requiresAuth: true },
    component: TimeOffPolicy,
    redirect: () => ({ name: 'time-off-policy.details' }),

    children: [
      {
        name: 'time-off-policy.details',
        path: '',
        component: TimeOffPolicyDetails,
      },
      {
        name: 'time-off-policy.contractors',
        path: 'contractors',
        component: TimeOffPolicyContractors,
      },
      {
        name: 'time-off-policy.activity-log',
        path: 'activity-log',
        component: TimeOffPolicyActivityLog,
      },
    ],
  },
];
