import DrawerWrapper from '@/components/Drawer/DrawerWrapper.vue';
import { getCurrentUser } from '../routerServices';

const JobListMarketplace = () => import(/* webpackChunkName: "work", webpackPrefetch: true  */ '@/views/Marketplace/JobList');
const FreelancerListMarketplace = () => import(/* webpackChunkName: "hire", webpackPrefetch: true  */ '@/views/Marketplace/FreelancerList');
const ServicesListMarketplace = () => import(/* webpackChunkName: "service-marketplace", webpackPrefetch: true  */ '@/views/Marketplace/ServicesList');

const PublicAgencySummary = () => import(/* webpackChunkName: "public-agency-summary", webpackPrefetch: true */ '@/views/Company/Agency/PublicAgency/Summary');
const PublicAgencyProjects = () => import(/* webpackChunkName: "public-agency-projects", webpackPrefetch: true */ '@/views/Company/Agency/PublicAgency/Projects');

const AgencyDrawer = () => import(/* webpackChunkName: "agency-drawer", webpackPrefetch: true */ '@/views/Marketplace/FreelancerList/components/AgencyDrawer/index.vue');
const FreelancerDrawer = () => import(/* webpackChunkName: "freelancer-drawer", webpackPrefetch: true */ '@/views/Marketplace/FreelancerList/components/FreelancerDrawer/index.vue');

export default [
  {
    name: 'gowork',
    path: '/work',
    component: JobListMarketplace,
    beforeEnter: (to, from, next) => {
      const user = getCurrentUser();

      if (user?.marketplaceAccessDisabled) {
        return next({
          name: 'page-not-found',
        });
      }

      return next();
    },
    meta: { requiresAuth: false },
  },
  {
    name: 'gohire',
    path: '/hire',
    component: FreelancerListMarketplace,
    beforeEnter: (to, from, next) => {
      const user = getCurrentUser();

      if (user?.marketplaceAccessDisabled) {
        return next({
          name: 'page-not-found',
        });
      }

      return next();
    },
    meta: { requiresAuth: false },

    children: [
      {
        name: 'freelancer-details',
        path: 'freelancer/:id',
        component: {
          render(h) {
            return h(DrawerWrapper, {
              props: {
                drawerComponent: FreelancerDrawer,
              },
              on: {
                close: () => {
                  this.$emit('close');
                },
              },
            });
          },
        },
      },

      {
        name: 'agency-details',
        path: 'agency/:agencyId',
        children: [
          {
            name: 'agency-details.summary',
            path: 'summary',
            component: PublicAgencySummary,
          },
          {
            name: 'agency-details.projects',
            path: 'projects',
            component: PublicAgencyProjects,
          },
        ],
        component: {
          render(h) {
            return h(DrawerWrapper, {
              props: {
                drawerComponent: AgencyDrawer,
              },
              on: {
                close: () => {
                  this.$emit('close');
                },
              },
            });
          },
        },
      },
    ],
  },
  {
    name: 'service-marketplace',
    path: '/service-marketplace',
    component: ServicesListMarketplace,
    beforeEnter: (to, from, next) => {
      const user = getCurrentUser();

      if (user?.marketplaceAccessDisabled || true) {
        return next({
          name: 'page-not-found',
        });
      }

      return next();
    },
    meta: { requiresAuth: false },
  },
];
