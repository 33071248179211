<template>
  <button
    :class="[variant, size, { loading }]"
    :disabled="disabled"
    class="button"
    :name="name"
    @click="handleClick($event)"
  >
    <slot v-if="!loading"></slot>
    <slot
      v-else
      name="loader"
    >
      <gl-button-loader :variant="variant" />
    </slot>
  </button>
</template>

<script>
  import GlButtonLoader from '@/components/buttons/ButtonLoader.vue';

  import _includes from 'lodash/includes';

  export default {
    components: {
      GlButtonLoader,
    },
    props: {
      variant: {
        type: String,
        default: 'primary',
        validator(value) {
          return _includes(['primary', 'secondary', 'danger', 'success', 'danger-secondary', 'link-button', 'warning', 'link'], value);
          // Use type="link" for a simple link without padding and explicit width/height.
          // Use type="link-button" for a standard button styling with defined height, width, and padding.
        },
      },
      size: {
        type: String,
        default: 'medium',
        validator(value) {
          return _includes(['medium', 'small', 'large', 'huge'], value);
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      name: {
        type: String,
        default: '',
      },
    },
    methods: {
      handleClick(ev) {
        if (this.variant === 'link') {
          ev.preventDefault();
        }

        if (this.loading) {
          return;
        }

        this.$emit('click', ev);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .button {
    @apply antialiased font-primary text-14 text-white flex justify-center items-center outline-none font-medium;
    padding: 10px 20px;
    min-width: 72px;
    height: 40px;
    border-radius: 8px;
    transition: 0.3s;
    cursor: pointer;
    line-height: 1.43em;
    letter-spacing: 0.0125em;
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
    }
    &.small {
      @apply text-14;
      padding: 2px 12px;
    }
  }
  .primary {
    @apply bg-blue-liberty;
    &:hover {
      @apply bg-y-in-mn-blue;
    }
    &:focus-visible {
      @apply bg-blue-patric;
      outline: none;
    }
  }
  .primary:disabled,
  .primary[disabled] {
    @apply bg-foundation-gray-5;

    &.loading {
      padding-bottom: 8px;
    }
  }
  .secondary {
    @apply border border-foundation-gray-4 text-text-black font-medium bg-white;

    &:hover {
      @apply border-foundation-gray-5 text-blue-liberty;
    }

    &:focus-visible,
    &:active {
      @apply border-blue-liberty;
    }

    &:disabled,
    &[disabled] {
      @apply border-foundation-gray-5 text-foundation-gray-5;
    }
  }
  .danger {
    @apply bg-persian-red text-white;

    &:hover,
    &:focus-visible {
      @apply bg-red-carnelian;
    }

    &:disabled,
    &[disabled] {
      @apply bg-foundation-gray-5;
    }
  }
  .warning {
    @apply bg-icon-warning text-text-black;

    &:hover,
    &:focus-visible {
      background-color: #f89c28;
    }

    &:disabled,
    &[disabled] {
      @apply bg-foundation-gray-5 text-white;
    }
  }
  .success {
    @apply bg-green-emerald;

    &:hover {
      background: #46a383;
    }

    &:focus-visible {
      @apply border border-foundation-gray-5;
      background: #46a383;
    }

    &:disabled,
    &[disabled] {
      @apply bg-foundation-gray-5;
    }
  }
  .danger-secondary {
    @apply text-persian-red border border-persian-red;
    background: white;

    &:hover,
    &:focus-visible {
      @apply border-red-carnelian text-red-carnelian;
    }

    &.small {
      padding: 2px 12px;
    }
  }
  .danger-secondary:disabled,
  .danger-secondary[disabled] {
    @apply border-foundation-gray-5 text-foundation-gray-5;

    &.loading {
      padding-bottom: 8px;
    }
  }

  .button.link-button,
  .button.link {
    @apply text-text-link;
    &:hover,
    &:focus-visible {
      @apply text-text-hover underline;
    }
    &:active {
      @apply text-text-focus;
    }
    &:disabled,
    &[disabled] {
      @apply text-foundation-gray-5;
      text-decoration: none;
    }
  }

  .button.link {
    @apply inline-flex;
    padding: 0;
    width: fit-content;
    height: fit-content;
    min-width: fit-content;
  }

  .huge {
    padding-top: 7px;
  }
  .small-semi-bold {
    @apply text-12;
    padding-top: 7px;
  }
  .large {
    @apply text-14;
    padding: 0 16px;
    min-width: 130px;
  }
  .small {
    @apply text-12;
    padding: 5px 18px 4px 18px;
    min-width: auto;
    height: 28px;
  }

  @media (max-width: 440px) {
    button:not(.huge):not(.small-semi-bold):not(.medium):not(.link-button):not(.link) {
      @apply text-13;
      padding: 0 8px;
      min-width: auto;
    }
  }

  @media (max-width: 767px) {
    .button.medium:not(.link) {
      padding: 8px 16px;
    }
  }
</style>
