<template>
  <div
    v-if="error"
    class="error-text"
  >
    {{ error }}
  </div>
</template>

<script>
  export default {
    props: {
      error: {
        type: [String],
        default: null,
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .error-text {
    @apply text-text-error text-12;
    width: 100%;
    min-width: 150px;
    line-height: 18px;

    @media (max-width: 490px) {
      min-width: 100%;
    }
  }
</style>
