<template>
  <div
    class="expandable-container"
    :style="{ '--expanded-height': `${expandedHeight}px` }"
  >
    <div
      class="shown-content"
    >
      <slot name="shown"></slot>
    </div>
    <transition
      name="expand"
      @enter="handleTransitionEnter"
      @after-leave="$emit('after-leave', $event)"
    >
      <div
        v-if="!useVShow && open"
        class="expandable-content"
      >
        <slot name="expandable"></slot>
      </div>
      <div
        v-else-if="useVShow"
        v-show="open"
        class="expandable-content"
      >
        <slot name="expandable"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      useVShow: {
        type: Boolean,
        default: false,
      },
      initialExpandedHeight: {
        type: Number,
        default: 100,
      },
    },
    data() {
      return {
        expandedHeight: this.initialExpandedHeight,
      };
    },
    methods: {
      handleTransitionEnter(el) {
        this.expandedHeight = el.scrollHeight;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .expandable-content {
    transition: 0.25s ease all;
    height: fit-content;
    max-height: fit-content;
  }

  .expand-leave-active, .expand-enter-active {
    overflow: hidden;
  }

  .expand-leave-to, .expand-enter {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
  }

  .expand-enter-to, .expand-leave {
    max-height: var(--expanded-height);
    opacity: 1;
  }
</style>
