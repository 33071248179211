<template>
  <div class="filters-data">
    <template v-if="isDesktopFilter">
      <filters-list-desktop
        :selected-index="selectedIndex"
        :filters-configs="filtersConfig"
        @change="handleActiveChange"
      />
      <filter-data-desktop
        :filter-config="selectedFilter"
        :filters="filters"
        @change="handleFilterChange"
      />
    </template>
    <template v-else>
      <filters-accordion
        :filters-config="filtersConfig"
        :filters="filters"
        :selected-index="selectedIndex"
        @activeChange="handleActiveChange"
        @change="handleFilterChange"
      />
    </template>
  </div>
</template>

<script>
  import screenSizeService from 'uikit/utils/screenSize';

  import FiltersListDesktop from './components/FiltersList/index.vue';
  import FilterDataDesktop from './components/FilterData/index.vue';
  import FiltersAccordion from './components/FiltersAccordion/index.vue';

  export default {
    components: {
      FiltersListDesktop,
      FilterDataDesktop,
      FiltersAccordion,
    },
    props: {
      filters: {
        type: Object,
        required: true,
      },
      filtersConfig: {
        type: Array,
        required: true,
      },
      selectedIndex: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        filterResults: {},
        screenSize: screenSizeService.getScreenSize(),
      };
    },
    computed: {
      selectedFilter() {
        return this.filtersConfig[this.selectedIndex];
      },
      filtersComputed: {
        get() {
          return this.filterResults;
        },
        set(value) {
          this.filterResults = value;
          this.$emit('change', value);
        },
      },
      isDesktopFilter() {
        return this.screenSize.lg;
      },
    },
    mounted() {
      screenSizeService.init();
    },
    beforeDestroy() {
      screenSizeService.cleanup();
    },
    methods: {
      handleActiveChange(index) {
        this.$emit('activeChange', index);
      },
      handleFilterChange(data) {
        this.$emit('change', data);
      },
    },
  };
</script>

<style lang="postcss" scoped>
.filters-data {
  @apply flex bg-white border-1 border-foundation-gray-4;
  border-radius: 12px;
}
</style>
