<template>
  <div class="multi-select">
    <span
      v-if="label"
      class="multi-select__header"
    >
      {{ label }}
    </span>
    <gl-rich-select
      v-model="valueModel"
      v-bind="$attrs"
      :options="options"
      :searchable="false"
      :allow-empty="false"
      class="multi-select__input"
    />
  </div>
</template>

<script>
  import GlRichSelect from '@/uikit/components/RichSelect/RichSelect.vue';

  export default {
    components: {
      GlRichSelect,
    },
    model: {
      event: 'change',
      prop: 'value',
    },
    props: {
      value: {
        type: Number,
        required: true,
      },
      label: {
        type: String,
        default: '',
      },
      options: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      valueModel: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('change', val);
        },
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .multi-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .multi-select__header {
    @apply text-charade font-primary text-14;
    margin-right: 8px;
    cursor: default;
  }
  .multi-select__input {
    width: 80px;
    min-width: fit-content;
    & >>> .input {
      border-radius: 54px;
      background-color: #fff;
      border: none;
      height: 34px;
      margin-top: 0;
      font-size: 14px;
      & .dropdown-icon {
        margin-bottom: 1px;
      }
    }
    & >>> .select-cointainer .select-picker {
      flex-direction: row;
    }
    & >>> .multiselect__tags {
      background-color: transparent;
      & .multiselect__single {
        font-size: 14px;
        padding-right: 5px;
      }
    }
  }

  @media (max-width: 576px) {
    .multi-select {
      flex-direction: column;
      align-items: stretch;
      & .multi-select__input {
        height: 32px;
        & >>> .multiselect__single {
          font-size: 12px;
        }
      }
    }
  }
</style>
