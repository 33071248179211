<script>
  import _forEach from 'lodash/forEach';

  const MIN_COLUMN_SIZE = {
    main: '140px',
    default: '100px',
  };
  const COLUMN_SIZES = {
    selectable: '28px',
    inlineActions: '64px',
    editable: '44px',
    main: `minmax(${MIN_COLUMN_SIZE.main}, 2fr)`,
    default: `minmax(${MIN_COLUMN_SIZE.default}, 1fr)`,
  };

  export default {
    props: {
      columns: {
        type: Array,
        required: true,
      },
      gridColumnsWidth: {
        type: String,
        default: null,
      },
      selectable: {
        type: Boolean,
        default: true,
      },
      inlineActions: {
        type: Boolean,
        default: false,
      },
      editable: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      gridTemplateColumns() {
        if (this.gridColumnsWidth) {
          return this.gridColumnsWidth;
        }

        return this.calculateGridTemplateColumns(this.columns);
      },
      columnSizes() {
        return this.gridTemplateColumns.split(' ');
      },
    },
    methods: {
      calculateGridTemplateColumns(cols) {
        const sizes = [];
        const staticColumnRegex = /^\d+px/;
        let isAllColumnsStatic = true;

        if (this.selectable) {
          sizes.push(COLUMN_SIZES.selectable);
        }

        _forEach(cols, col => {
          const size = col.width || (col.main ? COLUMN_SIZES.main : COLUMN_SIZES.default);
          sizes.push(size);

          if (!size.match(staticColumnRegex)) {
            isAllColumnsStatic = false;
          }
        });

        if (this.inlineActions) {
          sizes.push(isAllColumnsStatic ? `minmax(${COLUMN_SIZES.inlineActions}, 1fr)` : COLUMN_SIZES.inlineActions);
        } else if (this.editable) {
          sizes.push(isAllColumnsStatic ? `minmax(${COLUMN_SIZES.editable}, 1fr)` : COLUMN_SIZES.editable);
        }

        return sizes.join(' ');
      },
    },
    render() {
      return this.$scopedSlots.default({
        gridTemplateColumns: this.gridTemplateColumns,
        columnSizes: this.columnSizes,
        calculateGridTemplateColumns: this.calculateGridTemplateColumns,
      });
    },
  };
</script>
