import { getUserPermissions } from '../routerServices';

const AgenciesList = () => import(/* webpackChunkName: "AgenciesList", webpackPrefetch: true */ '@/views/Company/Agency/List');
const AgencyHome = () => import(/* webpackChunkName: "AgenciesMembers", webpackPrefetch: true */ '@/views/Company/Agency/Agency/Home');
const AgencyHomeMembers = () => import(/* webpackChunkName: "AgenciesMembers", webpackPrefetch: true */ '@/views/Company/Agency/Agency/Members');

const PublicAgency = () => import(/* webpackChunkName: "AgenciesList", webpackPrefetch: true */ '@/views/Company/Agency/PublicAgency');
const PublicAgencySummary = () => import(/* webpackChunkName: "AgenciesList", webpackPrefetch: true */ '@/views/Company/Agency/PublicAgency/Summary');
const PublicAgencyProjects = () => import(/* webpackChunkName: "AgenciesList", webpackPrefetch: true */ '@/views/Company/Agency/PublicAgency/Projects');

const AgencyProjectsDetails = () => import(/* webpackChunkName: "AgencyProjectsDetails", webpackPrefetch: true */ '@/views/Company/Agency/PublicAgency/ProjectDetails');

export default [
  {
    name: 'agencies',
    path: '/agencies',
    component: AgenciesList,
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();
      if (!permissions.isFreelancer) {
        return next({
          name: 'page-not-found',
        });
      }

      return next();
    },
  },
  {
    name: 'public-agency',
    path: '/agencies/:agencyId',
    component: PublicAgency,
    redirect: to => ({ name: 'public-agency.summary', params: to.params, query: to.query }),
    props: route => ({ edit: String(route.query.edit) === 'true' }),
    meta: { requiresAuth: false },
    children: [
      {
        name: 'public-agency.summary',
        path: 'summary',
        component: PublicAgencySummary,
      },
      {
        name: 'public-agency.projects',
        path: 'projects',
        component: PublicAgencyProjects,
      },
    ],
  },
  {
    name: 'agency-home',
    path: '/agencies/:agencyId/home',
    component: AgencyHome,
    beforeEnter: (to, from, next) => {
      const agencyId = to.params?.agencyId;
      const permissions = getUserPermissions();

      if (!agencyId) {
        return next({ name: 'agencies' });
      }

      if (!permissions.isCompanyMember(agencyId)) {
        return next({
          name: 'public-agency.summary',
          params: { agencyId },
        });
      }

      return next();
    },
    children: [
      {
        name: 'agency-home.members',
        path: 'members',
        component: AgencyHomeMembers,
      },
    ],
  },
  {
    name: 'agency-project-details',
    path: '/agency/:agencyId/portfolio-details/:id',
    component: AgencyProjectsDetails,
    meta: { requiresAuth: false },
  },
];
