<template>
  <li
    :style="{ '--color': color }"
    class="nav-menu__option"
  >
    <component
      :is="shownComponent"
      :to="link"
      class="nav-menu__link"
      :href="href"
      :target="target"
      :class="{ active: isActive }"
    >
      <div class="option__icon-container">
        <slot name="icon"></slot>
      </div>
      <div class="option__content">
        <span class="option__label">
          {{ label }}
          <gl-num-indicator
            v-if="count"
            class="num-indicator"
          >
            {{ count }}
          </gl-num-indicator>
        </span>
        <span class="option__description">
          {{ description }}
        </span>
      </div>
    </component>
  </li>
</template>

<script>
  import _includes from 'lodash/includes';

  import { GlNumIndicator } from 'uikit/components';
  import { RouterLink } from 'vue-router';

  export default {
    components: {
      GlNumIndicator,
    },
    props: {
      color: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      link: {
        type: Object,
        default: null,
      },
      url: {
        type: String,
        default: null,
      },
      count: {
        type: Number,
        default: 0,
      },
      target: {
        type: String,
        default: '',
      },
    },
    computed: {
      isActive() {
        return _includes(this.$route.name, this.link?.name);
      },
      href() {
        if (this.link) {
          return this.$router.resolve(this.link).href;
        }
        return this.url;
      },
      shownComponent() {
        return this.link ? RouterLink : 'a';
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .nav-menu__option {
    list-style-type: none;
  }
  .nav-menu__link {
    @apply bg-white flex items-center font-primary;
    gap: 16px;
    padding: 12px;
    border-radius: 12px;
    cursor: pointer;
    transition: 0.2s ease-in all;
    outline: none;
    &:hover,
    &:focus,
    &:focus-visible,
    &.active {
      text-decoration: none;
      background-color: var(--color);
    }
  }
  .option__icon-container {
    @apply flex items-center justify-center;
    flex-shrink: 0;
    background-color: var(--color);
    border-radius: 12px;
    width: 40px;
    height: 40px;
  }

  .option__content {
    @apply flex flex-col items-start justify-start;
    gap: 4px;
  }

  .option__label {
    @apply font-primary text-text-black font-semibold text-14 antialiased inline-flex items-center;
    letter-spacing: 0.0025em;
    line-height: 1.4em;
  }

  .option__description {
    @apply text-12 text-text-placeholder;
    letter-spacing: 0.004em;
    line-height: 18px;
    text-align: left;
  }

  .num-indicator {
    margin-left: 4px;
  }
</style>
