import '@/styles/index.pcss';
import '@/styles/base.pcss';

import Vue from 'vue';
import VueRouter from 'vue-router';
import VueLocalStorage from 'vue-localstorage';
import VeeValidate from 'vee-validate';
// The lib uses UMD filesystem, which is ok but eslint arguing about it
// eslint-disable-next-line import/no-unresolved
import vClickOutside from 'v-click-outside';
import VueScrollTo from 'vue-scrollto';
import Meta from 'vue-meta';
import VTooltip from 'v-tooltip';
import Vuelidate from 'vuelidate';
import ScreenSize from '@/plugins/screenSize';
import VueMask from 'v-mask';
import Hotjar from 'vue-hotjar';
import PortalVue from 'portal-vue';
import Notifications from 'vue-notification';
import LoadingOverlay from 'vue-loading-overlay';

import { loadStripe } from '@stripe/stripe-js';

import * as Sentry from '@sentry/vue';

import _pick from 'lodash/pick';

import config from '@/config';
import store from '@/store';
import router from '@/router';
import vueUtils from '@/services/infrastructure/vueUtils';
import App from '@/App.vue';

import inputHtml from '@/directives/inputHtml';
import htmlAsText from '@/directives/htmlAsText';
import draggable from '@/directives/draggable';

import notificationsService from '@/services/notifications';

import HierarchicalSelectDropDown from '@/components/selects/HierarchicalSelectDropDown.vue';

import { DEFAULT_MODE as DEFAULT_VALIDATION_MODE } from '@/validation/modes';

vueUtils.init({ router, store });

const tooltipOptions = {
  disposeTimeout: 200,
  autoHide: false,
};

Vue.use(VueLocalStorage);
Vue.use(vClickOutside);
Vue.use(VueScrollTo);
Vue.use(Meta);
Vue.use(VTooltip, tooltipOptions);
Vue.use(Vuelidate);
Vue.use(ScreenSize);
Vue.use(VueMask);
Vue.use(VeeValidate);
Vue.use(Notifications);
Vue.component('LoadingOverlay', LoadingOverlay);
Vue.use(Hotjar, {
  id: config.hotjar.siteId,
  isProduction: config.isProduction,
});
Vue.use(PortalVue);
Vue.use(VueRouter);

Vue.directive('input-html', inputHtml);
Vue.directive('html-as-text', htmlAsText);
Vue.directive('draggable', draggable);

Vue.component('HierarchicalSelectDropDown', HierarchicalSelectDropDown);

VeeValidate.setMode(DEFAULT_VALIDATION_MODE, ({ errors }) => {
  if (errors.length) {
    return {
      on: ['input'],
      debounce: 350,
    };
  }

  return {
    on: ['change', 'blur'],
    debounce: 150,
  };
});

window.vueSharedServices = {
  notificationsService,
  router,
};

// XXX: find better place to dispatch actions on app initialization
const bootstrap = window._bootstrap || {};

if (bootstrap.user) {
  // default values in order to make missing settings reactive(need to have pointer change)
  // this settings will be set up by angular later
  if (!('dashboardSettings' in bootstrap.user)) bootstrap.user.dashboardSettings = null;
  if (!('reportsSettings' in bootstrap.user)) bootstrap.user.reportsSettings = null;
  store.dispatch('setCurrentUser', bootstrap.user);
}

if (config.sentry?.enabled) {
  Sentry.init({
    Vue,
    environment: config.env,
    release: config.buildSha,
    dsn: config.sentry.dsn_frontend,
    logErrors: config.isStaging,
    integrations: [new Sentry.Replay()],

    tracesSampleRate: 1.0,

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: ['Non-Error promise rejection captured'],

    beforeSend(event, hint) {
      if (hint.originalException === 'Timeout') return null;

      const frames = event.exception.values[0].stacktrace?.frames ?? [];

      console.log('frames', frames);

      if (frames.some(frame => frame.filename.startsWith('chrome-extension://'))) {
        return null;
      }

      const firebaseError = event.exception?.values[0]?.type === 'FirebaseError';
      if (firebaseError) {
        return null;
      }

      const user = window._bootstrap;

      if (user) {
        event.user = _pick(user, ['_id', 'email', 'firstName', 'lastName']);
      }

      return event;
    },
  });
}

// XXX: consider possible switch to more 'functional' app structure:
//      https://ssr.vuejs.org/en/structure.html

// eslint-disable-next-line no-new
new Vue({
  store,
  router,
  el: '#vue-app',
  render: h => h(App),
});

const stripe = await loadStripe(config.stripe.publishableKey);
window.Stripe = stripe;
