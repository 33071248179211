<template>
  <div
    class="table-layout"
    :class="[{ editable }, tableName]"
  >
    <edit-table-modal
      :show="isEditModalShown"
      :current-columns="columns"
      :default-columns="defaultColumns"
      @save="handleColumnsSave"
      @close="handleEditModalClose"
    />
    <div
      v-if="totalCount && withPagination"
      :class="{ loading }"
      class="top-controls table-controls"
    >
      <gl-rows-per-page
        v-model="rowsCountModel"
        :options="rowsCountOptions"
        class="pages-select"
      />
      <gl-pager
        v-model="currentPageModel"
        :pages-count="pagesCount"
        :rows-count="rowsCount"
        :total-count="totalCount"
        class="table-pager"
      />
    </div>
    <bulk-actions
      v-if="isBulkActionsShown"
      :count="selectedOnPageCount"
      :record-name="recordName"
      class="bulk-actions"
      :class="{ compact }"
    >
      <slot name="bulkActions"></slot>
    </bulk-actions>
    <simple-table
      :resizable="resizable"
      :columns="shownColumns"
      :all-columns="columns"
      :data="data"
      :selectable="selectable"
      :selected-records="selectedRecords"
      :selected-on-page-count="selectedOnPageCount"
      :editable="editable"
      :clickable-rows="areRowsClickable"
      :inline-actions="inlineActions"
      :sorted-by="sortedBy"
      :compact="compact"
      :grid-columns-width="gridColumnsWidth"
      :loading="loading"
      :loading-rows-count="loadingRowsCount"
      :sub-record-spacing="subRecordSpacing"
      :theme="theme"
      @sortChange="handleSortChange"
      @selectChange="handleSelectChange"
      @editStart="handleEditModalShow"
      @rowClick="handleRowClick"
      @columnsChange="handleColumnsChange"
    >
      <template #expandableRow="{ record, gridTemplateColumns, columnSizes }">
        <slot
          name="expandableRow"
          :record="record"
          :gridTemplateColumns="gridTemplateColumns"
          :columnSizes="columnSizes"
        ></slot>
      </template>
      <template
        #cell="scope"
      >
        <slot
          name="cell"
          v-bind="scope"
        ></slot>
      </template>
      <template
        v-if="$scopedSlots.expandIcon"
        #expandIcon="scope"
      >
        <slot
          name="expandIcon"
          v-bind="scope"
        ></slot>
      </template>
      <template #header="scope">
        <slot
          name="header"
          v-bind="scope"
        >
        </slot>
      </template>
      <template #actionTrigger>
        <slot name="actionTrigger"></slot>
      </template>
      <template
        v-if="$scopedSlots.inlineActions"
        #inlineActions="scope"
      >
        <slot
          name="inlineActions"
          v-bind="scope"
          :table-name="tableName"
        >
        </slot>
      </template>
      <template
        v-else-if="$scopedSlots.inlineActionButton"
        #inlineActionButton="{ record }"
      >
        <slot
          name="inlineActionButton"
          :record="record"
        >
        </slot>
      </template>
      <template #emptyState>
        <slot name="emptyState"></slot>
      </template>
    </simple-table>
    <div
      v-if="totalCount && withPagination"
      :class="{ loading }"
      class="bottom-controls table-controls"
    >
      <gl-rows-per-page
        v-model="rowsCountModel"
        :options="rowsCountOptions"
        class="pages-select"
      />
      <gl-pager
        v-model="currentPageModel"
        :pages-count="pagesCount"
        :rows-count="rowsCount"
        :total-count="totalCount"
        :max-shown-numbers="maxShownPageNumbers"
        class="table-pager"
      />
    </div>
  </div>
</template>

<script>
  import _filter from 'lodash/filter';
  import _size from 'lodash/size';
  import _find from 'lodash/find';
  import _uniqueId from 'lodash/uniqueId';

  import GlPager from 'uikit/components/Pager/index.vue';
  import GlRowsPerPage from 'uikit/components/Pager/RowsPerPage.vue';

  import BulkActions from './BulkActions.vue';
  import EditTableModal from './EditTableModal.vue';
  import SimpleTable from './SimpleTable.vue';

  export default {
    components: {
      BulkActions,
      EditTableModal,
      SimpleTable,
      GlRowsPerPage,
      GlPager,
    },
    provide() {
      return {
        tableName: this.tableName,
      };
    },
    props: {
      columns: {
        type: Array,
        required: true,
      },
      defaultColumns: {
        type: Array,
        required: true,
      },
      data: {
        type: Array,
        default: () => [],
      },
      selectable: {
        type: Boolean,
        default: true,
      },
      bulkActions: {
        type: Boolean,
        default: false,
      },
      editable: {
        type: Boolean,
        default: true,
      },
      resizable: {
        type: Boolean,
        default: false,
      },
      inlineActions: {
        type: Boolean,
        default: false,
      },
      selectedRecords: {
        type: Object,
        default: () => ({}),
      },
      sortedBy: {
        type: Object,
        default: () => ({}),
      },
      rowsCount: {
        type: Number,
        default: 0,
      },
      rowsCountOptions: {
        type: Array,
        default: () => [10, 25, 50, 100],
      },
      pagesCount: {
        type: Number,
        default: 0,
      },
      currentPage: {
        type: Number,
        default: 0,
      },
      totalCount: {
        type: Number,
        default: 0,
      },
      recordName: {
        type: String,
        default: null,
      },
      compact: {
        type: Boolean,
        default: false,
      },
      gridColumnsWidth: {
        type: String,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      loadingRowsCount: {
        type: Number,
        default: 6,
      },
      maxShownPageNumbers: {
        type: Number,
        default: 7,
      },
      withPagination: {
        type: Boolean,
        default: true,
      },
      name: {
        type: String,
        default: null,
      },
      subRecordSpacing: {
        type: Number,
        default: 42,
      },
      theme: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        isEditModalShown: false,
      };
    },
    computed: {
      shownColumns() {
        return _filter(this.columns, { shown: true });
      },
      selectedOnPageCount() {
        const selectedOnPage = _filter(this.selectedRecords, record => _find(this.data, { _id: record._id }));
        return _size(selectedOnPage);
      },
      rowsCountModel: {
        get() {
          return this.rowsCount;
        },
        set(val) {
          this.$emit('rowsCountChange', val);
        },
      },
      currentPageModel: {
        get() {
          return this.currentPage;
        },
        set(page) {
          this.$emit('pageChange', page);
        },
      },
      isBulkActionsShown() {
        return this.selectable && this.bulkActions && this.selectedOnPageCount;
      },
      areRowsClickable() {
        return !!this.$listeners.rowClick;
      },
      tableName() {
        return this.name || _uniqueId('table-');
      },
    },
    methods: {
      handleSelectChange(selectedRecords) {
        this.$emit('selectChange', selectedRecords);
      },
      handleSortChange(sortedBy) {
        this.$emit('sortChange', sortedBy);
      },
      handleEditModalShow() {
        this.isEditModalShown = true;
      },
      handleColumnsSave(columns) {
        this.handleColumnsChange(columns);
        this.isEditModalShown = false;
      },
      handleColumnsChange(columns) {
        this.$emit('columnsChange', columns);
      },
      handleEditModalClose() {
        this.isEditModalShown = false;
      },
      handleRowClick(...args) {
        this.$emit('rowClick', ...args);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .table-layout {
    position: relative;
  }
  .table-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 24px;
    &.top-controls {
      display: none;
    }
    &.loading {
      cursor: not-allowed;
    }
  }

  .bulk-actions.compact {
    margin-bottom: 18px;
  }

  .pages-select {
    flex-shrink: 0;
  }

  @media (max-width: 768px) {
    .table-controls {
      align-items: flex-end;
    }
    .pages-select,
    .table-pager {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 576px) {
    .table-controls.top-controls {
      display: flex;
      margin-top: 16px;
    }
  }
</style>
