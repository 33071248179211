<template>
  <div class="controlls">
    <gl-icon-button
      v-for="option in options"
      :key="option.value"
      :name="option.value"
      :icon-name="option.icon"
      :class="['button-common', { 'active-button': isActiveButton(option.value) }]"
      @click="handleClickButton(option.value)"
    />
  </div>
</template>

<script>
  import _every from 'lodash/every';

  import GlIconButton from 'uikit/components/buttons/IconButton.vue';

  export default {
    components: {
      GlIconButton,
    },
    props: {
      value: {
        type: String,
        default: null,
      },
      options: {
        type: Array,
        required: true,
        validator(options) {
          return _every(options, option => Boolean(option.value && option.icon));
        },
      },
    },
    methods: {
      handleClickButton(value) {
        this.$emit('on-change', value);
      },
      isActiveButton(value) {
        return this.value === value;
      },
    },
  };
</script>

<style lang="postcss" scoped>
.controlls {
  white-space: nowrap;
}

.button-common {
  @apply text-foundation-gray-6 border-foundation-gray-4;
  display: inline-flex;
  position: relative;
  width: 40px;
  height: 40px;

  border-radius: 0px;

  &:hover {
    z-index: 1;
  }

  &:active {
    border-right: 1px solid;
    border-left: 1px solid;
  }
}

.button-common:first-child {
  border-radius: 8px 0px 0px 8px;
  border-right-color: transparent;

  &:active {
    border-right: 1px solid;
  }
}

.button-common:not(:first-child):not(:last-child) {
  border-left-color: transparent;
  border-right-color: transparent;

  &:active {
    border: 1px solid;
  }
}

.button-common:last-child {
  border-radius: 0px 8px 8px 0px;
  border-left-color: transparent;

  &:active {
    border-left: 1px solid;
  }
}
.active-button {
  @apply text-blue-liberty;
}
</style>
