import _startsWith from 'lodash/startsWith';
import _replace from 'lodash/replace';

import config from '@/config';
import logger from '@/logger';

import vueUtils from '@/services/infrastructure/vueUtils';
import userApi from '@/services/api/users';
import inAppSurveyApi from '@/services/api/inAppSurvey';
import contractApi from '@/services/api/contracts';
import companyApi from '@/services/api/companies';

import { getContractLink } from '@/services/contract';
import { getCompanyLink } from '@/services/company';

import { UserRoles } from '@/constants/user';

import getWalletTabs from '@/views/NewGoWallet/services/walletConfig';

const NOT_FOUND_ROUTE = { name: 'page-not-found' };

export const getCurrentUser = () => vueUtils.store?.getters.currentUser;
export const getUserPermissions = () => vueUtils.store?.getters.permissions;

export const getRouteToRedirectFromHome = () => {
  const user = getCurrentUser();
  const permissions = getUserPermissions();

  if (!user?._id) {
    window.location.href = config.rootUrl;
    return;
  }

  if (permissions.isEmployer) {
    return { name: 'client-dashboard' };
  }

  if (permissions.isFreelancer) {
    return { name: 'freelancer-dashboard' };
  }

  return NOT_FOUND_ROUTE;
};

export const redirectIfNotAuthorized = (to, next) => {
  const isPublicRoute = to.matched.some(route => route.meta.requiresAuth === false);
  const isAuthorized = vueUtils.store?.getters.isAuthorized;

  if (!isPublicRoute && !isAuthorized) {
    const redirectUrl = `${config.loginUrl}?returnUrl=${encodeURIComponent(window.location)}`;
    window.location = redirectUrl;
    return;
  }

  next();
};

export const setPrevRoute = from => {
  vueUtils.store.commit('setPrevRoute', from);
};

export const getUserProfileRouteToRedirect = async userId => {
  try {
    const user = await userApi.get(userId);

    if (user.type.includes(UserRoles.Employer)) {
      return { name: 'client-profile.about', params: { userId: user.username } };
    }

    return { name: 'freelancer-profile.overview', params: { userId: user.username } };
  } catch (err) {
    return NOT_FOUND_ROUTE;
  }
};

export const checkEvent = async eventName => {
  const isAuthorized = vueUtils.store?.getters.isAuthorized;
  if (!isAuthorized) return;

  try {
    await inAppSurveyApi.checkEvent(eventName);
  } catch (err) {
    logger.error(err);
  }
};

export const getCompanyProfileRouteToRedirect = async companyId => {
  try {
    const company = await companyApi.getPublic(companyId);

    if (!company) {
      return NOT_FOUND_ROUTE;
    }

    return getCompanyLink(company);
  } catch (err) {
    return NOT_FOUND_ROUTE;
  }
};

export const redirectToCompaniesListRoute = (to, next) => {
  const permissions = getUserPermissions();

  const route = {
    name: 'companies',
    query: to.query,
  };

  if (permissions.isFreelancer) {
    route.name = 'agencies';
  }

  if (route.name === to.name) {
    return next();
  }

  return next(route);
};

export const getContractRouteToRedirect = async contractId => {
  try {
    const contract = await contractApi.get(contractId);

    if (!contract) {
      return NOT_FOUND_ROUTE;
    }

    return getContractLink(contract);
  } catch (err) {
    return NOT_FOUND_ROUTE;
  }
};

export const getRouteToRedirectFromFreelancerDashboard = () => {
  const user = getCurrentUser();
  const permissions = getUserPermissions();
  if (!permissions?.isFreelancer) {
    return {
      name: 'page-not-found',
    };
  }

  const activeContractsCount = user?.activeContractsCount?.asContractor;

  if (activeContractsCount == null || user?.marketplaceAccessDisabled) {
    return {
      name: 'freelancer-home.info',
    };
  }
};

export const getRouteToRedirectFromWelcome = (toPath, next) => {
  if (_startsWith(toPath, config.rootUrl)) {
    const relativeUrl = _replace(toPath, `${config.rootUrl}`, '');

    window.location = relativeUrl;

    return;
  }

  const permissions = getUserPermissions();

  if (permissions?.isFreelancer) {
    return next({
      name: 'freelancer-onboarding',
    });
  }

  return next({
    name: 'client-onboarding',
  });
};

export const getWalletHomePage = () => {
  const user = getCurrentUser();

  if (user.isLimitedWalletEnabled) {
    return { name: 'gowallet.transactions' };
  }

  return { name: 'gowallet.dashboard' };
};

export const checkWalletPageAvailability = (to, from, next) => {
  const availableTabs = getWalletTabs();

  const isPageAvailable = availableTabs.some(tab => tab.value === to.name) || to.name === 'gowallet';

  if (!isPageAvailable) {
    return next({
      name: availableTabs[0].value,
    });
  }

  return next();
};
