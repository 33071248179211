export default {
  star: () => import('./StarIcon.vue'),
  share: () => import('./ShareIcon.vue'),
  briefcase: () => import('./BriefCase.vue'),
  clock: () => import('./Clock.vue'),
  money: () => import('./Money.vue'),
  bookmark: () => import('./BookmarkIcon.vue'),
  'linkedin-colored': () => import('./LinkedinColoredIcon.vue'),
  'twitter-colored': () => import('./TwitterColoredIcon.vue'),
  'facebook-colored': () => import('./FacebookColoredIcon.vue'),
  'instagram-colored': () => import('./InstagramColoredIcon.vue'),
  'google-colored': () => import('./GoogleColoredIcon.vue'),
  'linkedin-black': () => import('./LinkedinBlackIcon.vue'),
  'twitter-black': () => import('./TwitterColoredIcon.vue'),
  'facebook-black': () => import('./FacebookBlackIcon.vue'),
  'instagram-black': () => import('./InstagramBlackIcon.vue'),
  linkedin: () => import('./LinkedinIcon.vue'),
  twitter: () => import('./TwitterIcon.vue'),
  facebook: () => import('./FacebookIcon.vue'),
  instagram: () => import('./InstagramIcon.vue'),
  link: () => import('./LinkIcon.vue'),
  'menu-dots-vertical': () => import('./MenuDotsVerticalIcon.vue'),
  'credit-card': () => import('./CreditCardFilledIcon.vue'),
  'credit-card2': () => import('./CreditCardIcon.vue'),
  close: () => import('./CloseIcon.vue'),
  filter: () => import('./FilterIcon.vue'),
  filledFilter: () => import('./FilterFilledIcon.vue'),
  'time-past': () => import('./TimePastIcon.vue'),
  pause: () => import('./PauseIcon.vue'),
  apps: () => import('./AppsIcon.vue'),
  'external-link': () => import('./ExternalLinkIcon.vue'),
  search: () => import('./SearchIcon.vue'),
  'cross-circle': () => import('./CrossCircleIcon.vue'),
  user: () => import('./UserIcon.vue'),
  cancel: () => import('./CancelIcon.vue'),
  pencil: () => import('./PencilIcon.vue'),
  'sort-down': () => import('./SortDown.vue'),
  'sort-up': () => import('./SortUp.vue'),
  play: () => import('./PlayIcon.vue'),
  'play-circle': () => import('./PlayCircleIcon.vue'),
  'play-circle-filled': () => import('./PlayCircleFilledIcon.vue'),
  'pause-circle-filled': () => import('./PauseCircleFilledIcon.vue'),
  envelope: () => import('./EnvelopeIcon.vue'),
  'solid-check-circle': () => import('./SolidCheckCircleIcon.vue'),
  'solid-exclamanation-circle': () => import('./SolidExclamationCircleIcon.vue'),
  'solid-exclamanation-octagon': () => import('./SolidExclamationOctagonIcon.vue'),
  'solid-exclamanation-triangle': () => import('./SolidExclamationTriangleIcon.vue'),
  'envelope-download': () => import('./EnvelopeDownloadIcon.vue'),
  mobile: () => import('./MobileIcon.vue'),
  trash: () => import('./TrashIcon.vue'),
  'trash-filled': () => import('./TrashFilledIcon.vue'),
  drag: () => import('./DragIcon.vue'),
  'add-mail': () => import('./AddMailIcon.vue'),
  'approved-mail': () => import('./ApprovedMailIcon.vue'),
  copy: () => import('./CopyIcon.vue'),
  'left-arrow-large': () => import('./LeftArrowLargeIcon.vue'),
  'left-arrow-long': () => import('./LeftArrowLongIcon.vue'),
  'left-arrow': () => import('./LeftArrowIcon.vue'),
  'left-arrow-small': () => import('./LeftArrowSmallIcon.vue'),
  'right-arrow-large': () => import('./RightArrowLargeIcon.vue'),
  'right-arrow': () => import('./RightArrowIcon.vue'),
  'right-arrow-small': () => import('./RightArrowSmallIcon.vue'),
  'down-arrow-large': () => import('./DownArrowLargeIcon.vue'),
  'up-arrow-large': () => import('./UpArrowLargeIcon.vue'),
  'up-arrow-small': () => import('./UpArrowSmallIcon.vue'),
  'down-arrow-small': () => import('./DownArrowSmallIcon.vue'),
  'down-arrow': () => import('./DownArrow.vue'),
  bars: () => import('./BarsIcon.vue'),
  'smartphone-filled': () => import('./SmartPhoneFilledIcon.vue'),
  'user-filled': () => import('./UserFilledIcon.vue'),
  'envelope-filled': () => import('./EnvelopeFilledIcon.vue'),
  globe: () => import('./GlobeIcon.vue'),
  cross: () => import('./CrossIcon.vue'),
  upload: () => import('./UploadIcon.vue'),
  building: () => import('./BuildingIcon.vue'),
  'time-quarter': () => import('./TimeQuarterIcon.vue'),
  'briefcase-filled': () => import('./BriefcaseFilledIcon.vue'),
  'money-filled': () => import('./MoneyFilledIcon.vue'),
  'writing-hand': () => import('./WritingHandIcon.vue'),
  refresh: () => import('./RefreshIcon.vue'),
  bank: () => import('./BankIcon.vue'),
  ethereum: () => import('./EthereumIcon.vue'),
  bitcoin: () => import('./BitcoinIcon.vue'),
  'tether-erc-20': () => import('./TetherERCIcon.vue'),
  'tether-trc-20': () => import('./TetherTRCIcon.vue'),
  tether: () => import('./TetherIcon.vue'),
  tron: () => import('./TronIcon.vue'),
  usdc: () => import('./UsdcoinIcon.vue'),
  crypto: () => import('./CryptoIcon.vue'),
  paypal: () => import('./PaypalIcon.vue'),
  payoneer: () => import('./PayoneerIcon.vue'),
  download: () => import('./DownloadIcon.vue'),
  plus: () => import('./PlusIcon.vue'),
  check: () => import('./CheckIcon.vue'),
  'bold-check': () => import('./BoldCheckIcon.vue'),
  'approve-tick': () => import('./ApproveTickIcon.vue'),
  withdrawal: () => import('./WithdrawalIcon.vue'),
  book: () => import('./BookIcon.vue'),
  'chart-bars': () => import('./ChartBarsIcon.vue'),
  'chart-pie': () => import('./ChartPieIcon.vue'),
  'chart-tree': () => import('./ChartTreeIcon.vue'),
  'chart-histogram': () => import('./ChartHistogramIcon.vue'),
  'chart-network': () => import('./ChartNetworkIcon.vue'),
  computer: () => import('./ComputerIcon.vue'),
  connection: () => import('./ConnectionIcon.vue'),
  document: () => import('./DocumentIcon.vue'),
  'document-signed': () => import('./DocumentSignedIcon.vue'),
  'empty-document': () => import('./EmptyDocumentIcon.vue'),
  'document-transparent': () => import('./DocumentTransparent.vue'),
  'document-download': () => import('./DocumentDownloadIcon.vue'),
  'document-add': () => import('./DocumentAddIcon.vue'),
  flower: () => import('./FlowerIcon.vue'),
  incognito: () => import('./IncognitoIcon.vue'),
  package: () => import('./PackageIcon.vue'),
  palette: () => import('./PaletteIcon.vue'),
  physics: () => import('./PhysicsIcon.vue'),
  pulse: () => import('./PulseIcon.vue'),
  wallet: () => import('./WalletIcon.vue'),
  'woman-head': () => import('./WomanHeadIcon.vue'),
  portrait: () => import('./PortraitIcon.vue'),
  text: () => import('./TextIcon.vue'),
  'location-alt': () => import('./LocationAltIcon.vue'),
  location: () => import('./LocationIcon.vue'),
  'video-camera': () => import('./VideoCameraIcon.vue'),
  picture: () => import('./PictureIcon.vue'),
  align: () => import('./AlignIcon.vue'),
  like: () => import('./LikeIcon.vue'),
  dollar: () => import('./DollarIcon.vue'),
  'clock-filled': () => import('./ClockFilled.vue'),
  'time-oclock-filled': () => import('./TimeOclockFilled.vue'),
  eye: () => import('./EyeIcon.vue'),
  'eye-stroke': () => import('./EyeStrokeIcon.vue'),
  'eye-close': () => import('./EyeCloseIcon.vue'),
  'eye-open': () => import('./EyeOpenIcon.vue'),
  'rounded-info': () => import('./RoundedInfoIcon.vue'),
  'filled-rounded-info': () => import('./FilledRoundedInfoIcon.vue'),
  flag: () => import('./FlagIcon.vue'),
  gift: () => import('./GiftIcon.vue'),
  gift2: () => import('./GiftIcon2.vue'),
  'arrow-up-right': () => import('./ArrowUpRight.vue'),
  'arrow-down-right': () => import('./ArrowDownRight.vue'),
  'sort-list': () => import('./SortList.vue'),
  receipt: () => import('./ReceiptIcon.vue'),
  keyboard: () => import('./KeyboardIcon.vue'),
  mouse: () => import('./MouseIcon.vue'),
  'user-time': () => import('./UserTime.vue'),
  'time-quarter-past': () => import('./TimeQuarterPast.vue'),
  'time-quarter-past2': () => import('./TimeQuarterPast2.vue'),
  'time-quarter-to-filled': () => import('./TimeQuarterToFilledIcon.vue'),
  'time-quarter-to': () => import('./TimeQuarterToIcon.vue'),
  'time-stroke': () => import('./TimeStrokeIcon.vue'),
  question: () => import('./QuestionIcon.vue'),
  'upper-right-arrow': () => import('./UpperRightArrow.vue'),
  'flame-filled': () => import('./FlameFilledIcon.vue'),
  flame: () => import('./FlameIcon.vue'),
  'mode-portrait': () => import('./ModePortraitIcon.vue'),
  'video-filled': () => import('./VideoFilledIcon.vue'),
  're-record': () => import('./ReRecordIcon.vue'),
  'paypal-withdraw': () => import('./PaypalWithdrawIcon'),
  mastercard: () => import('./MasterCardIcon.vue'),
  visa: () => import('./VisaIcon'),
  amex: () => import('./AmexIcon.vue'),
  billing: () => import('./BillingIcon'),
  attach: () => import('./AttachIcon.vue'),
  expand: () => import('./ExpandIcon.vue'),
  'screenshot-missing': () => import('./ScreenshotMissingIcon.vue'),
  screenshot: () => import('./ScreenshotIcon.vue'),
  euro: () => import('./EuroIcon.vue'),
  remove: () => import('./RemoveIcon.vue'),
  primary: () => import('./PrimaryIcon'),
  'sr-align-left': () => import('./AlignLeftIcon.vue'),
  clip: () => import('./ClipIcon.vue'),
  'list-check': () => import('./ListCheckIcon.vue'),
  'paper-plane': () => import('./PaperPlaneIcon.vue'),
  calendar: () => import('./Calendar.vue'),
  calendly: () => import('./CalendlyIcon.vue'),
  'calendar-empty': () => import('./CalendarEmpty.vue'),
  dropdown: () => import('./DropDown.vue'),
  edit: () => import('./EditIcon.vue'),
  emptyView: () => import('./EmptyViewIcon.vue'),
  emptyImage: () => import('./EmptyImageIcon.vue'),
  hyperlink: () => import('./HyperlinkIcon.vue'),
  time: () => import('./TimeIcon.vue'),
  person: () => import('./Person.vue'),
  company: () => import('./Company.vue'),
  histogram: () => import('./HistogramIcon.vue'),
  'speaker-notes': () => import('./SpeakerNotes.vue'),
  marker: () => import('./MarkerIcon.vue'),
  'go-lance': () => import('./GoLanceIcon.vue'),
  'pdf-file': () => import('./PdfFile.vue'),
  'wallet-blue': () => import('./WalletBlueIcon.vue'),
  'move-money': () => import('./MoveMoney.vue'),
  'send-money': () => import('./SendMoney.vue'),
  'send-money2': () => import('./SendMoney2.vue'),
  'money-withdrawal': () => import('./MoneyWithdrawal.vue'),
  gem: () => import('./GemIcon.vue'),
  crown: () => import('./CrownIcon.vue'),
  badge: () => import('./BadgeIcon.vue'),
  send: () => import('./SendIcon.vue'),
  spy: () => import('./SpyIcon.vue'),
  photo: () => import('./PhotoIcon.vue'),
  video: () => import('./VideoIcon.vue'),
  loom: () => import('./LoomIcon.vue'),
  'test-result': () => import('./TestResultIcon.vue'),
  'magic-wand': () => import('./MagicWandIcon.vue'),
  planet: () => import('./PlanetIcon.vue'),
  'google-meet': () => import('./GoogleMeetIcon.vue'),
  'zoom-icon': () => import('./ZoomColoredIcon.vue'),
  'office-teams': () => import('./MicrosoftOfficeTeamsIcon.vue'),
  'pencil-filled': () => import('./PencilFilled.vue'),
  'comment-filled': () => import('./CommentFilled.vue'),
  message: () => import('./MessageIcon.vue'),
  'folder-network': () => import('./FolderNetworkIcon.vue'),
  tag: () => import('./TagIcon.vue'),
  tag2: () => import('./TagIcon2.vue'),
  pdf: () => import('./PdfIcon.vue'),
  list: () => import('./ListIcon.vue'),
  'clipboard-check': () => import('./ClipboardCheck.vue'),
  'clock-circle': () => import('./ClockCircle.vue'),
  'flag-stroke': () => import('./FlagStrokeIcon.vue'),
  global: () => import('./GlobalIcon.vue'),
  'short-text': () => import('./ShortTextIcon.vue'),
  'money-bag': () => import('./MoneyBagIcon.vue'),
  dart: () => import('./DartIcon.vue'),
  linear: () => import('./LinearIcon.vue'),
  users: () => import('./UsersIcon.vue'),
  suitcase: () => import('./SuitcaseIcon.vue'),
  'money-round': () => import('./RoundMoneyIcon.vue'),
  stars: () => import('./StarsIcon.vue'),
  'button-loader': () => import('./ButtonLoaderIcon.vue'),
  'online-support': () => import('./OnlineSupportIcon.vue'),
  'money-withdraw': () => import('./MoneyWithdrawIcon.vue'),
  'flexible-contracts': () => import('./FlexibleContractsIcon.vue'),
  'flat-fee': () => import('./FlatFeeIcon.vue'),
  'dollar-small': () => import('./DollarSmallIcon.vue'),
  'x-black': () => import('./XBlackIcon.vue'),
  'x-white': () => import('./XWhiteIcon.vue'),
  office: () => import('./OfficeIcon.vue'),
  fork: () => import('./ForkIcon.vue'),
  'skill-autocomplete': () => import('./SkillAutocompleteIcon.vue'),
  'add-money': () => import('./AddMoneyIcon.vue'),
  'times-rounded': () => import('./TimesRoundedIcon.vue'),
  'check-mark-empty': () => import('./CheckMarkEmptyIcon.vue'),
  'check-mark': () => import('./CheckMarkIcon.vue'),
  'warning-circle': () => import('./WarningCircleIcon.vue'),
  'ok-stroke': () => import('./OkStrokeIcon.vue'),
  'exclamation-triangle': () => import('./ExclamationTriangleIcon.vue'),
  'exclamation-octagon': () => import('./ExclamationOctagonIcon.vue'),
  'info-circle': () => import('./InfoCircleIcon.vue'),
  'double-small-right-arrow': () => import('./DoubleSmallRight.vue'),
  redo: () => import('./RedoIcon.vue'),
  confetti: () => import('./ConfettiIcon.vue'),
  info: () => import('./InfoIcon.vue'),
  'chat-arrow-grow': () => import('./ChatArrowGrowIcon.vue'),
  headset: () => import('./HeadsetIcon.vue'),
  'cad-file': () => import('./CadFileIcon.vue'),
  sales: () => import('./SalesIcon.vue'),
  'pallete-unfilled': () => import('./PaletteUnfilledIcon.vue'),
  'software-dev': () => import('./SoftwareDevIcon.vue'),
  'bank-unfilled': () => import('./BankUnfilledIcon.vue'),
  'concave-star': () => import('./ConcaveStarIcon.vue'),
  certificate: () => import('./CertificateIcon.vue'),
  'golance-logo': () => import('./GolanceLogoIcon.vue'),
  tick: () => import('./TickIcon.vue'),
  help: () => import('./HelpIcon.vue'),
  lock: () => import('./LockFilled.vue'),
  'high-score': () => import('./HighScoreIcon.vue'),
  'medium-score': () => import('./MediumScoreIcon.vue'),
  'low-score': () => import('./LowScoreIcon.vue'),
  'circle-status': () => import('./CircleStatus.vue'),
  'circle-status-stroke': () => import('./CircleStatusStroke.vue'),
  'subdirectory-arrow': () => import('./SubdirectoryArrowIcon.vue'),
  'chat-bubble': () => import('./ChatBubbleIcon.vue'),
  'full-screen': () => import('./FullScreenIcon.vue'),
  'side-bar': () => import('./SideBarIcon.vue'),
  holiday: () => import('./HolidayIcon.vue'),
  'pills-jar': () => import('./PillsJar.vue'),
  messages: () => import('./MessagesIcon.vue'),
  'check-circle': () => import('./CheckCircleIcon.vue'),
  'close-circle': () => import('./CloseCircleIcon.vue'),
  bag: () => import('./BagIcon.vue'),
  'user-rounded': () => import('./UserRoundedIcon.vue'),
  'calendar-date': () => import('./CalendarDateIcon.vue'),
  'time-history-3': () => import('./TimeHistory3Icon.vue'),
  'file-text': () => import('./FileTextIcon.vue'),
  'arrow-action-forward': () => import('./ArrowActionForwardIcon.vue'),
  'round-arrow-left': () => import('./RoundArrowLeftIcon.vue'),
};
