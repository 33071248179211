<template>
  <router-link
    :to="linkPath"
    class="thread-link"
  >
    <thread-preview
      :thread="thread"
      update-by-sockets
      class="thread-link__list-item"
    />
  </router-link>
</template>

<script>
  import { mapGetters } from 'vuex';
  import _sortBy from 'lodash/sortBy';
  import _filter from 'lodash/filter';
  import _map from 'lodash/map';
  import _find from 'lodash/find';

  import fullNameFilter from '@/filters/fullName';
  import truncateFilter from '@/filters/truncate';
  import formatDate from '@/filters/formatDate';

  import ThreadPreview from '@/views/Inbox/components/ThreadPreview';

  export default {
    components: {
      ThreadPreview,
    },
    props: {
      thread: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters(['currentUser', 'currentTimezone']),
      threadUsers() {
        const filtered = _filter(this.thread.users, u => u.isListed && u._id !== this.currentUser._id && u.state !== 'archived' && u.state !== 'hidden');
        return _sortBy(filtered, u => this.thread.mainParticipant && u._id !== this.thread.mainParticipant._id);
      },
      threadUserNames() {
        if (this.threadUsers.length === 1) {
          return fullNameFilter(this.threadUsers[0]);
        }
        return _map(this.threadUsers, 'firstName').join(', ');
      },
      isUnread() {
        return this.currentUserThreadInfo?.state === 'unread';
      },
      currentUserThreadInfo() {
        return _find(this.thread.users, { _id: this.currentUser._id });
      },
      threadLastMessageContent() {
        const lastContent = this.currentUserThreadInfo?.lastMessageContent || '';
        if (!lastContent && this.currentUserThreadInfo.lastMessageDate) {
          return 'Attachment';
        }
        return truncateFilter(lastContent, 100);
      },
      lastActivityDate() {
        return formatDate(this.thread.lastActivityDate, 'MMM dd yyyy \'at\' h:mm a', this.currentTimezone);
      },
      linkPath() {
        return {
          name: 'inbox.thread',
          params: {
            threadId: this.thread._id,
          },
        };
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .thread-link {
    outline: none;
    cursor: pointer;
    position: relative;

     &:after {
      @apply border-b border-foundation-gray-3 absolute;
      content: '';
      width: 100%;
      bottom: -1px;
      right: 16px;
      transition: .3s ease;
    }

    &__list-item {
      @apply border border-transparent;
      padding: 16px 24px;
      border-radius: unset;
      transition: .3s ease;
      outline: none;
      width: 100%;

      &:hover, &:focus-visible {
        @apply bg-foundation-gray-2;
      }
    }

    &:hover, &:focus-visible {
      & .thread-preview {
        @apply bg-foundation-gray-2;
      }
    }
  }

  .thread-preview {
    transition: all ease .3s;
  }
</style>
