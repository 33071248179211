<template>
  <div
    class="table-cell"
    :class="[column.type, { main: column.main, sortable: column.sort }]"
  >
    <gl-skeleton-loader
      v-if="loading"
      class="table-cell__loading"
    />
    <template v-else>
      <slot
        name="cell"
        :field="column.field"
      >
        {{ fieldValue }}
      </slot>
    </template>
  </div>
</template>

<script>
  import _get from 'lodash/get';
  import GlSkeletonLoader from 'uikit/components/skeletons/SkeletonLoader.vue';

  export default {
    components: {
      GlSkeletonLoader,
    },
    props: {
      column: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      fieldValue() {
        return _get(this.record, this.column.field);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .table-cell {
    @apply text-text-black;
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
    width: 100%;
    padding: 0 12px;

    &.number {
      display: flex;
      justify-content: flex-end;

      &.sortable {
        padding-right: 32px;
      }
    }

    &.centered {
      display: flex;
      justify-content: center;
    }
  }

  .table-cell__loading {
    width: 100%;
    height: 20px;
  }
</style>
