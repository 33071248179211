import _get from 'lodash/get';

import store from '@/store/index';

class CaptchaService {
  constructor({ recaptcha }) {
    this.recaptcha = recaptcha;
  }

  async init() {
    await this.recaptcha.$recaptchaLoaded();
  }

  async showBadge() {
    await this.recaptcha.$recaptchaLoaded();
    this.recaptcha.$recaptchaInstance.showBadge();
  }

  async hideBadge() {
    await this.recaptcha.$recaptchaLoaded();
    this.recaptcha.$recaptchaInstance.hideBadge();
  }

  async executeRecaptcha(basePayload, reCaptchaAction) {
    await this.recaptcha.$recaptchaLoaded();
    const isCaptchaEnabled = _get(store.getters.currentUser, 'settings.isCaptchaEnabled', true);
    const isUserAdmin = store.getters.currentUser.isAdmin;

    let captchaToken;
    if (isCaptchaEnabled && !isUserAdmin) {
      captchaToken = await this.recaptcha.$recaptchaInstance.execute(reCaptchaAction);
    }

    return {
      ...basePayload,
      captchaToken,
    };
  }
}

export default CaptchaService;
