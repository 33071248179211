/* eslint-disable import/prefer-default-export */
import store from '@/store';

/**
 * @param {Object} proposal
 * @param {string} proposal._id - The ID of the proposal.
 */
const getProposalLink = proposal => {
  const { permissions } = store.getters;

  if (permissions.isEmployer) {
    return {
      name: 'proposal',
      params: {
        proposalId: proposal._id,
      },
    };
  }

  return {
    name: 'freelancer-job-manager.jobs-applied',
    query: {
      proposalId: proposal._id,
    },
  };
};

export { getProposalLink };
