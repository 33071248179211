<template>
  <header-icon
    ref="header-icon"
    name="search"
    @click.native="$emit('click', $event)"
  >
    <magnifier-icon />
  </header-icon>
</template>

<script>
  import MagnifierIcon from '@/components/icons/MagnifierIcon.vue';
  import HeaderIcon from './icons/HeaderIcon.vue';

  export default {
    components: {
      MagnifierIcon,
      HeaderIcon,
    },
    props: {
      notificationsCount: {
        type: Number,
        default: 0,
      },
    },
    methods: {
      blur() {
        this.$refs['header-icon'].$el.blur();
      },
    },
  };
</script>
