<template>
  <gl-input
    v-model="date"
    v-bind="$attrs"
    :error="!isValidDate"
    type="text"
    @change="submitDate(date)"
  >
    <template #icon>
      <gl-base-icon
        name="calendar-empty"
        :size="16"
        color="#6F7689"
      />
    </template>
  </gl-input>
</template>

<script>
  import moment from 'moment-timezone';

  import GlInput from '../inputs/Input.vue';
  import GlBaseIcon from '../../icons/BaseIcon.vue';

  const DEFAULT_FORMAT = 'MMM D, YYYY';

  export default {
    components: {
      GlInput,
      GlBaseIcon,
    },
    props: {
      value: {
        type: [Date, null],
        default: null,
      },
    },
    data() {
      return {
        date: '',
        isValidDate: true,
      };
    },
    watch: {
      value: {
        deep: true,
        handler(val) {
          this.formatDate(val);
          this.isValidDate = true;
        },
      },
    },
    created() {
      this.formatDate(this.value);
    },
    methods: {
      formatDate(val) {
        if (!val) {
          this.date = '';
          return;
        }

        this.date = moment(val).format(DEFAULT_FORMAT);
      },
      submitDate(val) {
        if (!val) {
          this.$emit('change', null);
          return;
        }

        const momentDate = moment(val, DEFAULT_FORMAT);
        if (momentDate.isValid()) {
          this.isValidDate = true;
          this.$emit('change', momentDate.toDate());
        } else {
          this.isValidDate = false;
        }
      },
    },
  };
</script>
