import { getCurrentUser, getUserPermissions } from '../routerServices';

const EditJob = () => import(/* webpackChunkName: "job" */ '@/views/Job/EditJobForm/index');
const PublicJob = () => import(/* webpackChunkName: "job" */ '@/views/Job/PublicJob');

const OwnJob = () => import(/* webpackChunkName: "ownJob" */ '@/views/Job/OwnJob');
const OwnJobPending = () => import(/* webpackChunkName: "ownJob" */ '@/views/Job/OwnJob/Pending');
const OwnJobInReview = () => import(/* webpackChunkName: "ownJob" */ '@/views/Job/OwnJob/InReview');
const OwnJobShortlisted = () => import(/* webpackChunkName: "ownJob" */ '@/views/Job/OwnJob/Shortlisted');
const OwnJobInterview = () => import(/* webpackChunkName: "ownJob" */ '@/views/Job/OwnJob/Interview');
const OwnJobOffersSent = () => import(/* webpackChunkName: "ownJob" */ '@/views/Job/OwnJob/OffersSent');
const OwnJobHired = () => import(/* webpackChunkName: "ownJob" */ '@/views/Job/OwnJob/Hired');
const OwnJobDeclined = () => import(/* webpackChunkName: "ownJob" */ '@/views/Job/OwnJob/Declined');

export default [
  {
    name: 'job-public',
    path: '/jobs/:jobId/:jobSlug?',
    component: PublicJob,
    meta: { requiresAuth: false },
  },
  {
    name: 'job-own',
    path: '/jobs/own/:jobId/:jobSlug?',
    component: OwnJob,
    redirect: to => ({ name: 'job-own.pending', params: to.params, query: to.query }),
    children: [
      {
        name: 'job-own.pending',
        path: 'pending',
        component: OwnJobPending,
      },
      {
        name: 'job-own.in-review',
        path: 'in-review',
        component: OwnJobInReview,
      },
      {
        name: 'job-own.shortlisted',
        path: 'shortlisted',
        component: OwnJobShortlisted,
      },
      {
        name: 'job-own.interview',
        path: 'interview',
        component: OwnJobInterview,
      },
      {
        name: 'job-own.offers-sent',
        path: 'offers-sent',
        component: OwnJobOffersSent,
      },
      {
        name: 'job-own.hired',
        path: 'hired',
        component: OwnJobHired,
      },
      {
        name: 'job-own.declined',
        path: 'declined',
        component: OwnJobDeclined,
      },
    ],
  },
  {
    name: 'edit-job',
    path: '/jobs/:jobId/edit',
    component: EditJob,
    beforeEnter: (to, from, next) => {
      const user = getCurrentUser();
      const permissions = getUserPermissions();

      if (user?.postJobDisabled || user?.status === 'freezed' || permissions.isFreelancer) {
        return next({
          name: 'home',
        });
      }

      return next();
    },
  },
];
