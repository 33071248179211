<template>
  <div
    :title="listedUsersNames"
    class="thread-preview"
  >
    <thread-preview-context
      v-if="showContextLink"
      :entity="threadData.entity"
      class="thread-preview__main-context"
      @click.stop
    />
    <div class="thread-preview__info">
      <thread-preview-avatar
        :user="expandedThread.mainParticipant || {}"
        :counter="listedUsers.length - 1"
        size="44"
        class="thread-preview__logo"
      />
      <div class="thread-preview__text">
        <span class="thread-preview__main-info">
          <span
            :title="listedUsersNames"
            class="thread-preview__name"
          >
            {{ listedUsersNames }}
          </span>
          <span
            v-if="expandedThread.lastMessageDate"
            class="thread-preview__date"
          >
            {{ expandedThread.lastMessageDate | timeAgoExpanded(false) }}
          </span>
        </span>
        <span
          v-if="!showContextLink"
          :title="threadContext"
          class="thread-preview__context"
        >
          {{ threadContext }}
        </span>
        <div class="thread-preview__messages-info">
          <span
            v-if="(lastMessageUser || expandedThread.lastMessageDate) && !lastMessageSystem"
            class="thread-preview__last-message"
            :class="{ unread: !!unreadMessagesCounter }"
          >
            <user-avatar
              v-if="lastMessageUser"
              :user="lastMessageUser"
              size="16"
            />
            <span
              v-if="lastMessageContent"
              :title="lastMessageContent | text | unescapeHtml"
              class="last-message__content"
              v-html="lastMessageContentPrepared"
            ></span>
            <thread-preview-non-text-content
              v-else-if="!lastMessageContent"
              :last-message-non-text-content="lastMessageNonTextContent"
            />
          </span>
          <span
            v-else-if="lastMessageSystem"
            class="thread-preview__last-message"
          >
            <user-avatar
              v-if="lastMessageUser"
              :user="lastMessageUser"
              :title="lastMessageUser | fullName | unescapeHtml"
              size="16"
            />
            <span
              class="last-message__content"
            >{{ lastMessageHeading }}</span>
          </span>
          <span
            v-else
            class="thread_preview__no-messages"
          >
            No messages
          </span>
          <span
            v-if="unreadMessagesCounter"
            class="thread-preview__unread-counter"
          >
            {{ unreadMessagesCounter }}
          </span>
        </div>
        <span
          v-if="threadData.closed"
          class="thread-preview__closed-label thread-preview__pill"
        >
          Conversation was closed
        </span>
        <span
          v-else-if="!isCurrentUserListed"
          class="thread-preview__viewer-label  thread-preview__pill"
        >
          Viewer
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import { getThreadPreviewInfo } from '@/services/thread';
  import socketIo from '@/services/infrastructure/socketIo';

  import fullName from '@/filters/fullName';
  import text from '@/filters/text';
  import truncate from '@/filters/truncate';
  import { timeAgoExpanded } from '@/filters/timeAgo';

  import unescapeHtml from '@/services/unescapeHtml';
  import sanitizer from '@/services/sanitizer';
  import { ThreadEntityNames } from '@/constants/threads';

  import UserAvatar from '@/components/Profile/ProfileInfoUserAvatar.vue';
  import ThreadPreviewAvatar from './ThreadPreviewAvatar.vue';
  import ThreadPreviewContext from './ThreadPreviewContext.vue';
  import ThreadPreviewNonTextContent from './ThreadPreviewNonTextContent.vue';

  export default {
    filters: {
      fullName,
      text,
      truncate,
      timeAgoExpanded,
      unescapeHtml,
    },
    components: {
      UserAvatar,
      ThreadPreviewAvatar,
      ThreadPreviewContext,
      ThreadPreviewNonTextContent,
    },
    props: {
      thread: {
        type: Object,
        required: true,
      },
      showContextLink: {
        type: Boolean,
        default: false,
      },
      updateBySockets: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        threadData: this.thread,
      };
    },
    computed: {
      ...mapGetters(['currentUser']),
      expandedThread() {
        return getThreadPreviewInfo(this.threadData);
      },
      listedUsers() {
        return this.expandedThread.listedUsers;
      },
      lastMessageUser() {
        return this.expandedThread.lastUser;
      },
      lastMessageSystem() {
        return this.expandedThread.lastExpandedMessage?.isSystem;
      },
      lastMessageHeading() {
        return this.expandedThread.lastExpandedMessage?.heading;
      },
      listedUsersNames() {
        if (this.expandedThread.listedUsers.length === 1) {
          return unescapeHtml(fullName(this.expandedThread.mainParticipant));
        }

        return this.expandedThread.listedUsersNames;
      },
      threadContext() {
        const titlePart = this.threadData.entity?.title ? `: ${unescapeHtml(this.thread.entity.title)}` : '';
        const titleEntityName = ThreadEntityNames[this.threadData.entity?.type];
        return `${titleEntityName || ''}${titlePart}`;
      },
      unreadMessagesCounter() {
        return this.expandedThread.currentUser?.unreadMessagesCounter;
      },
      isCurrentUserListed() {
        return this.expandedThread.currentUser?.isListed;
      },
      lastMessageContent() {
        return this.expandedThread.lastMessageContent;
      },
      lastMessageContentPrepared() {
        if (!this.lastMessageContent) {
          return '';
        }
        return truncate(sanitizer.sanitizeHtml(unescapeHtml(this.lastMessageContent) || this.lastMessageContent), { size: 80 });
      },
      lastMessageNonTextContent() {
        return this.expandedThread.lastMessageNonTextContent;
      },
    },
    watch: {
      thread(data) {
        this.threadData = data;
      },
    },
    created() {
      if (!this.updateBySockets) {
        return;
      }

      socketIo.on('io:thread:updated', this.handleThreadUpdate);
    },
    beforeDestroy() {
      socketIo.off('io:thread:updated', this.handleThreadUpdate);
    },
    methods: {
      handleThreadUpdate(data) {
        if (data._id !== this.threadData._id) {
          return;
        }

        this.threadData = {
          ...this.threadData,
          ...data,
        };
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .thread-preview {
    transition: .3s ease all;
    text-align: left;
  }

  .thread-preview__info {
    @apply flex items-start;
    gap: 21px;
    text-align: left;
  }

  .thread-preview__main-context {
    margin-bottom: 10px;
  }

  .thread-preview__logo {
    flex-shrink: 0;
  }

  .thread-preview__text {
    @apply flex flex-col;
    flex: 1 1 100px;
    width: 0;
  }

  .thread-preview__main-info {
    @apply flex justify-between items-center;
    gap: 24px;
  }

  .thread-preview__name {
    @apply text-text-black text-14 font-semibold truncate;
    line-height: 20px;
    letter-spacing: 0.035px;
  }

  .thread-preview__date {
    @apply text-text-black text-12;
    line-height: 150%;
    letter-spacing: 0.048px;
    flex-shrink: 0;
  }

  .thread-preview__messages-info {
    @apply flex justify-between;
    gap: 4px;
  }

  .thread-preview__last-message {
    @apply text-text-placeholder text-14 truncate flex items-center;
    gap: 6px;
    line-height: 20px;

    &.unread {
      @apply text-text-black;
    }
  }

  .thread_preview__no-messages {
    @apply text-text-placeholder text-14 truncate;
  }

  .thread-preview__context {
    @apply text-text-placeholder text-12 truncate;
    margin-bottom: 8px;
    line-height: 150%;
    letter-spacing: 0.048px;
  }

  .last-message__content {
    @apply truncate;
  }

  .message-content__attach {
    @apply inline-flex items-center;
    gap: 4px;
    vertical-align: middle;
  }

  .thread-preview__unread-counter {
    @apply bg-royal-blue flex items-center justify-center text-white text-12;
    min-width: 22px;
    height: 22px;
    border-radius: 22px;
    padding: 4px;
    line-height: 150%;
    letter-spacing: 0.048px;
    font-kerning: none;
    flex-shrink: 0;
  }

  .thread-preview__pill {
    @apply text-white text-12;
    display: inline-block;
    width: fit-content;
    border-radius: 8px;
    padding: 2px 8px;
    margin-top: 8px;
    line-height: 150%;
    letter-spacing: 0.048px;
    font-kerning: none;
  }

  .thread-preview__viewer-label {
    @apply bg-icon-dark;
  }

  .thread-preview__closed-label {
    @apply bg-icon-default-dark font-medium;
  }
</style>
