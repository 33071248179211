<template>
  <div class="options-search">
    <gl-search
      v-model="searchValue"
      @input="handleSearch"
    />
  </div>
</template>

<script>
  import _debounce from 'lodash/debounce';
  import GlSearch from '@/uikit/components/Search/index.vue';

  export default {
    components: {
      GlSearch,
    },
    props: {
      search: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        searchValue: '',
      };
    },
    watch: {
      search(val) {
        this.searchValue = val;
      },
    },
    methods: {
      handleSearch: _debounce(function updateSearch(value) {
        this.$emit('input', value);
      }, 300),
    },
  };
</script>

<style lang="postcss" scoped>
.options-search {
  @apply flex items-center justify-center border-1 border-foundation-gray-4;
  min-width: 100%;
  padding: 0 24px 24px;
  border-width: 0 0 1px 0;
}

</style>
