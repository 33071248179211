import { getUserProfileRouteToRedirect } from '../routerServices';

export default [
  {
    name: 'user', // Route to redirect to client or freelancer profile
    path: '/users/:userId',
    meta: { requiresAuth: false },
    beforeEnter: async (to, from, next) => {
      const profileRoute = await getUserProfileRouteToRedirect(to.params?.userId);
      next({
        query: to.query,
        ...profileRoute,
      });
    },
  },
];
