<template>
  <a
    :class="[variant, size, { loading }]"
    :disabled="disabled"
    :href="href"
    :target="target"
    class="gl-link"
  >
    <slot></slot>
  </a>
</template>

<script>
  import _includes from 'lodash/includes';

  export default {
    props: {
      href: {
        type: String,
        default: '#',
      },
      variant: {
        type: String,
        default: 'link',
        validator(value) {
          return _includes(['primary', 'secondary', 'danger', 'success', 'danger-secondary', 'link'], value);
        },
      },
      size: {
        type: String,
        default: 'medium',
        validator(value) {
          return _includes(['medium', 'small', 'large', 'huge'], value);
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      target: {
        type: String,
        validator(value) {
          return _includes(['_blank', '_self', '_parent', '_top'], value);
        },
        default: '_self',
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .gl-link {
    @apply antialiased font-primary text-14 text-white flex justify-center items-center outline-none font-medium;
    padding: 8px 20px;
    min-width: 72px;
    height: 40px;
    border-radius: 8px;
    transition: 0.3s;
    cursor: pointer;
    line-height: 1.43em;
    letter-spacing: 0.0125em;
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
    }
    &.small {
      @apply text-14;
      padding: 2px 12px;
    }
  }
  .primary {
    @apply bg-blue-liberty;
    &:hover {
      @apply bg-y-in-mn-blue;
    }
    &:focus-visible {
      @apply bg-blue-patric;
      outline: none;
    }
  }
  .primary:disabled,
  .primary[disabled] {
    @apply bg-foundation-gray-5;

    &.loading {
      padding-bottom: 8px;
    }
  }
  .secondary {
    @apply border border-foundation-gray-4 text-text-black font-medium bg-white;

    &:hover {
      @apply border-foundation-gray-5 text-blue-liberty;
    }

    &:focus-visible,
    &:active {
      @apply border-blue-liberty;
    }

    &:disabled,
    &[disabled] {
      @apply border-foundation-gray-5 text-foundation-gray-5;
    }
  }
  .danger {
    @apply bg-persian-red text-white;

    &:hover,
    &:focus-visible {
      @apply bg-red-carnelian;
    }

    &:disabled,
    &[disabled] {
      @apply bg-foundation-gray-5;
    }
  }
  .success {
    @apply bg-green-emerald;

    &:hover {
      background: #46a383;
    }

    &:focus-visible {
      @apply border border-foundation-gray-5;
      background: #46a383;
    }

    &:disabled,
    &[disabled] {
      @apply bg-foundation-gray-5;
    }
  }
  .danger-secondary {
    @apply text-persian-red border border-persian-red;
    background: white;

    &:hover,
    &:focus-visible {
      @apply border-red-carnelian text-red-carnelian;
    }

    &.small {
      padding: 6px 18px 0 18px;
    }
  }
  .danger-secondary:disabled,
  .danger-secondary[disabled] {
    @apply border-foundation-gray-5 text-foundation-gray-5;

    &.loading {
      padding-bottom: 8px;
    }
  }

  .gl-link.link {
    @apply text-text-link;

    &.medium,
    &.small,
    &.large,
    &.huge {
      padding: 0;
      min-width: unset;
    }

    &:hover,
    &:focus-visible {
      @apply text-text-hover;
      text-decoration: underline;
    }

    &:active {
      @apply text-text-focus;
    }

    &:disabled,
    &[disabled] {
      @apply text-foundation-gray-5;
    }
  }

  .huge {
    padding-top: 7px;
  }
  .small-semi-bold {
    @apply text-12;
    padding-top: 7px;
  }
  .large {
    @apply text-14;
    min-width: 130px;
  }
  .small {
    @apply text-12;
    padding: 5px 18px 4px 18px;
    min-width: auto;
    height: 28px;
  }

  @media (max-width: 440px) {
    button:not(.huge):not(.small-semi-bold):not(.medium):not(.link) {
      @apply text-13;
      padding: 0 8px;
      min-width: auto;
    }
  }

  @media (max-width: 767px) {
    .gl-link.medium {
      padding: 8px 16px;
    }
  }
</style>
