import _constant from 'lodash/constant';
import client, { Api } from './client';

const BASE_URL = '/reports';

export default {
  getOldClients() {
    return client.get([BASE_URL, 'client', 'clients-obsolete']);
  },
  getOldContracts(filters) {
    return client.get([BASE_URL, 'client', 'contracts-obsolete'], filters);
  },

  getCertificate() {
    return client.getFile('get', [BASE_URL, 'certificates', 'pdf'], null, null, null, { responseType: 'arraybuffer' });
  },

  getContracts(filters) {
    return client.get([BASE_URL, 'client', 'contracts'], filters);
  },
  getClients() {
    return client.get([BASE_URL, 'client', 'clients']);
  },
  getTimesheets(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'timesheets', 'list'], filters, cancelToken);
  },
  getDetailedTimesheets(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'timesheets', 'detailed'], filters, cancelToken);
  },
  getClientTimesheetsCsvExport(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'timesheets', 'list', 'csv'], filters, cancelToken);
  },
  getClientTaskTimesheets(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'timesheets', 'task'], filters, cancelToken);
  },
  getFreelancerTimesheetsCsvExport(filters, cancelToken) {
    return client.get([BASE_URL, 'freelancer', 'timesheets', 'list', 'csv'], filters, cancelToken);
  },
  getClientDetailedTimesheetsCsvExport(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'timesheets', 'detailed', 'csv'], filters, cancelToken);
  },
  getFreelancerDetailedTimesheetsCsvExport(filters, cancelToken) {
    return client.get([BASE_URL, 'freelancer', 'timesheets', 'detailed', 'csv'], filters, cancelToken);
  },
  getFreelancerTaskTimesheets(filters, cancelToken) {
    return client.get([BASE_URL, 'freelancer', 'timesheets', 'task'], filters, cancelToken);
  },
  getOldTimesheets(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'timesheets-obsolete'], filters, cancelToken);
  },
  getTimesheetsBillableAmountsByClient(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'timesheets', 'billable-amount-by-client'], filters, cancelToken);
  },
  getOldDetailedTimesheet(conditions) {
    return client.get([BASE_URL, 'client', 'timesheets', 'detailed-obsolete'], conditions);
  },

  getInvoices(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'invoices'], filters, cancelToken);
  },
  getDetailedInvoice(filters) {
    return client.get([BASE_URL, 'client', 'invoices', 'detailed'], filters);
  },

  getBonuses(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'bonuses'], filters, cancelToken);
  },
  getBonusesCsvExport(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'bonuses', 'csv'], filters, cancelToken);
  },
  getOldBonuses(filters) {
    return client.get([BASE_URL, 'client', 'bonuses-obsolete'], filters);
  },
  getFreelancerBonuses(filters) {
    return client.get([BASE_URL, 'freelancer', 'dashboard', 'bonuses'], filters);
  },

  getClientCurrentWeekStatistics() {
    return client.get([BASE_URL, 'client', 'dashboard', 'current-week']);
  },
  getClientLastWeekStatistics() {
    return client.get([BASE_URL, 'client', 'dashboard', 'last-week']);
  },
  getClientCurrentMilestonesStatistics() {
    return client.get([BASE_URL, 'client', 'dashboard', 'current-milestones']);
  },

  getFreelancerCurrentWeekStatistics() {
    return client.get([BASE_URL, 'freelancer', 'dashboard', 'current-week']);
  },

  getFreelancerPreviousWeeksStatistics(params) {
    return client.get([BASE_URL, 'freelancer', 'dashboard', 'previous-weeks'], params);
  },

  getFreelancerProfileStatistics() {
    return client.get([BASE_URL, 'freelancer', 'dashboard', 'profile-statistics']);
  },

  getMilestones(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'milestones'], filters, cancelToken);
  },
  getFreelancerMilestones(filters) {
    return client.get([BASE_URL, 'freelancer', 'dashboard', 'milestones'], filters);
  },

  getScheduledPayments(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'scheduled-payments'], filters, cancelToken);
  },

  getFreelancerScheduledPayments(filters) {
    return client.get([BASE_URL, 'freelancer', 'dashboard', 'scheduled-payments'], filters);
  },

  getTotalPaid(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'summary'], filters, cancelToken);
  },
  getFreelancerTotalEarned(filters) {
    return client.get([BASE_URL, 'freelancer', 'summary'], filters);
  },

  getTotalPaidCsvExport(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'summary', 'csv'], filters, cancelToken);
  },
  getMilestonesCsvExport(filters, cancelToken) {
    return client.get([BASE_URL, 'client', 'milestones', 'csv'], filters, cancelToken);
  },

  getTransactions(filter) {
    return client.get([BASE_URL, 'transactions'], filter);
  },

  getTransactionsInCSV(filter) {
    return client.getFile('get', [BASE_URL, 'transactions', 'csv'], filter, { withHeaders: ['X-Filename'], 'X-Api-Version': '2022-01-19' });
  },

  getTransactionTypeFilters(params) {
    return client.get([BASE_URL, 'transactions', 'transaction-types'], params);
  },

  getClientReceiptPdf(payload) {
    return Api.waitForEvent(client.post([BASE_URL, 'receipt', 'pdf'], payload), {
      success: 'io:clientReceipt:pdfRendered',
      failure: 'io:clientReceipt:pdfRenderingFailed',
    });
  },

  getClientContracts(params) {
    return client.get([BASE_URL, 'client', 'dashboard', 'contracts'], params);
  },

  getFreelancerContracts(params) {
    return client.get([BASE_URL, 'freelancer', 'dashboard', 'contracts'], params);
  },

  getChargeInvoices(params) {
    return client.get([BASE_URL, 'charge-invoices'], params);
  },
  getChargeInvoice(params) {
    return client.get([BASE_URL, 'charge-invoices', 'invoice'], params);
  },

  getWeeklyActivity(params) {
    return client.get([BASE_URL, 'client', 'weekly-activity'], params);
  },

  getFreelancerWeeklyActivity(params) {
    return client.get([BASE_URL, 'freelancer', 'weekly-activity'], params);
  },

  getReferrals(params) {
    return client.get([BASE_URL, 'referrals'], params);
  },

  getReferralsTotal() {
    return client.get([BASE_URL, 'referrals', 'total']);
  },

  getReferralTransactions(referralId, params) {
    return client.get([BASE_URL, 'referrals', referralId], params);
  },

  getClientContractWeeklyActivity(contractId, params) {
    return client.get([BASE_URL, 'client', 'contract', contractId, 'weekly-activity'], params);
  },

  getFreelancerContractWeeklyActivity(contractId, params) {
    return client.get([BASE_URL, 'freelancer', 'contract', contractId, 'weekly-activity'], params);
  },

  getContractReports(params) {
    return client.get([BASE_URL, 'contracts'], params);
  },
  getTransaction(transactionId, params) {
    return client.get(`${BASE_URL}/transactions/${transactionId}`, params);
  },

  getClientContractSummary(contractId, params) {
    return client.get([BASE_URL, 'client', 'contract', contractId, 'summary'], params);
  },

  getClientContractSpendingsByWeeks(contractId, params) {
    return client.get([BASE_URL, 'client', 'contract', contractId, 'spendings-by-week'], params);
  },

  getFreelancerContractSummary(contractId, params) {
    return client.get([BASE_URL, 'freelancer', 'contract', contractId, 'summary'], params);
  },

  getFreelancerContractEarningsByWeeks(contractId, params) {
    return client.get([BASE_URL, 'freelancer', 'contract', contractId, 'earnings-by-week'], params);
  },

  getFreelancerPaymentEvents(params) {
    return client.get([BASE_URL, 'freelancer', 'dashboard', 'payment-calendar'], params);
  },

  getFreelancerTimesheets(filters, cancelToken) {
    return client.get([BASE_URL, 'freelancer', 'timesheets', 'list'], filters, cancelToken);
  },
  getFreelancerDetailedTimesheets(filters, cancelToken) {
    return client.get([BASE_URL, 'freelancer', 'timesheets', 'detailed'], filters, cancelToken);
  },

  getFreelancerScheduledPaymentsReports(filters) {
    return client.get([BASE_URL, 'freelancer', 'scheduled-payments'], filters);
  },
  getFreelancerMilestonesReports(filters) {
    return client.get([BASE_URL, 'freelancer', 'milestones'], filters);
  },
  getFreelancerBonusesReports(filters) {
    return client.get([BASE_URL, 'freelancer', 'bonuses'], filters);
  },
  getFreelancerAgencyReports(filters) {
    return client.get([BASE_URL, 'freelancer', 'agency'], filters);
  },

  getCertificateEarnings(filters) {
    return Api.waitForEvent(client.post([BASE_URL, 'certificate-earnings', 'pdf'], filters), {
      success: 'io:certificateOfEarnings:pdfRendered',
      failure: 'io:certificateOfEarnings:pdfRenderingFailed',
    }, _constant(true), 30000);
  },

  getCertificateEarningsDetailed(filters) {
    return Api.waitForEvent(client.post([BASE_URL, 'certificate-earnings-detailed', 'pdf'], filters), {
      success: 'io:certificateOfEarnings:detailedPdfRendered',
      failure: 'io:certificateOfEarnings:detailedPdfRenderingFailed',
    }, _constant(true), 30000);
  },

  getInvoiceTimesheet(filters) {
    return client.get([BASE_URL, 'timesheet'], filters);
  },

  getVirtualCardsFilterOptions() {
    return client.get([BASE_URL, 'transactions', 'debit-cards']);
  },

  saveReportExportFields(payload) {
    return Api.waitForCommand(client.post([BASE_URL, 'client', 'export-fields', 'save'], payload));
  },
  getReportExportFields(payload) {
    return client.get([BASE_URL, 'client', 'export-fields'], payload);
  },
};
