<template>
  <gl-icon-button
    class="icon"
    :name="name"
  >
    <slot></slot>

    <div
      v-if="countIndicator"
      class="unread"
    >
      {{ count }}
    </div>
  </gl-icon-button>
</template>

<script>
  import { GlIconButton } from 'uikit/components';

  const MAX_SHOWN_COUNT = 999;

  export default {
    components: {
      GlIconButton,
    },
    props: {
      countIndicator: {
        type: Number,
        default: 0,
      },
      name: {
        type: String,
        default: '',
      },
    },
    computed: {
      count() {
        return Math.min(this.countIndicator, MAX_SHOWN_COUNT);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .icon {
    @apply relative;
    width: 36px;
    height: 36px;
    & .unread {
      @apply font-primary absolute bg-red-fire-opal flex justify-center items-center font-semibold text-white;
      border: 1px solid white;
      line-height: 150%;
      font-size: 10px;
      height: 18px;
      min-width: 18px;
      border-radius: 18px;
      left: 17px;
      top: 3px;
      padding: 0 3px;
    }
  }
</style>
