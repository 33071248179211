<template>
  <div
    id="modal-scroll-container"
    class="custom-scrollbar-container"
    :class="{ 'modal-scroll-container': scrollable, 'modal-fixed-container': !scrollable }"
    @scroll="$emit('body-scroll', $event)"
  >
    <div
      v-if="$slots.header"
      class="modal-header"
    >
      <slot name="header"></slot>
    </div>

    <div class="modal-body">
      <slot name="body"></slot>
    </div>

    <div
      v-if="$slots.footer"
      class="modal-footer"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      scrollable: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .modal-fixed-container {
    box-sizing: border-box;
    overflow: hidden;
  }

  .modal-scroll-container {
    box-sizing: border-box;
    overflow: auto;
    max-height: 80vh;
    @apply px-4;
  }

  .modal-body {
    @apply flex flex-col;

    @screen lg {
      @apply flex flex-row;

      & >>> .modal-section-main,
      & >>> .modal-section-side {
        @apply min-w-0;

        & + .modal-section-side {
          @apply mt-0;
        }
      }
    }
  }

  @media (min-width: 576px) {
    .modal-scroll-container {
      padding: 0 28px;
    }
  }
</style>
