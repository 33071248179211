<template>
  <portal to="modal">
    <gl-regular-modal
      :z-index="1000"
      :show="isVisible"
      :scrollable="false"
      is-on-icon-close-only
      v-bind="$attrs"
      size="l"
      disable-portal
      @close="handleClose"
      v-on="$listeners"
    >
      <template #body>
        <div ref="typeformContainer"></div>
      </template>
    </gl-regular-modal>
  </portal>
</template>

<script>
  import { createWidget } from '@typeform/embed';
  import { mapGetters } from 'vuex';
  import logger from '@/logger';
  import socketIo from '@/services/infrastructure/socketIo';

  import surveyApi from '@/services/api/inAppSurvey';

  import { GlRegularModal } from 'uikit/components';

  export default {
    components: {
      GlRegularModal,
    },
    data() {
      return {
        isVisible: false,
        sessionStorage: null,
      };
    },
    computed: {
      ...mapGetters(['currentUser']),
      surveyStatus() {
        return this.currentUser.typeformSurvey?.status;
      },
      formId() {
        const regExp = /\/to\/([^/?#]+)/;
        return this.currentUser.typeformSurvey?.formUrl?.match(regExp)[1];
      },
      runId() {
        return this.currentUser.typeformSurvey?.runId;
      },
    },
    watch: {
      'currentUser.typeformSurvey'(val) {
        if (val) {
          this.showWidget();
        }
      },
    },
    async mounted() {
      socketIo.on('io:user:updated', this.handleUserUpdate);

      if (!this.formId) {
        return;
      }

      this.showWidget();
    },

    destroyed() {
      socketIo.off('io:user:updated', this.handleUserUpdate);
    },

    methods: {
      handleUserUpdate(userData) {
        if (!userData.typeformSurvey) {
          this.isVisible = false;
        }
      },
      async showWidget() {
        if (this.surveyStatus === 'pending') {
          this.isVisible = true;
        } else {
          return;
        }

        await this.$nextTick();

        createWidget(this.formId, {
          container: this.$refs.typeformContainer,
          hidden: {
            user_id: this.currentUser._id,
          },
          onSubmit: this.handleSubmit,
          onReady: this.handleReady,
          autoResize: true,
          hideHeaders: true,
          hideFooter: true,
          iframeProps: {
            style: 'width:100%;height:100%;',
          },
        });
      },
      handleReady() {
        this.changeSurveyStatus('viewed');
      },
      async handleClose() {
        await this.$nextTick();
        this.isVisible = false;
        this.changeSurveyStatus('declined');
      },
      async handleSubmit() {
        this.changeSurveyStatus('answered');
        this.isVisible = false;
      },
      async changeSurveyStatus(status) {
        try {
          await surveyApi.updateSurveyStatus(this.runId, status);
        } catch (error) {
          logger.error('Failed to update run status', error);
        }
      },
    },
  };
</script>
