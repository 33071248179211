import client from './client';

const BASE_URL = '/activity-feed';

export default {
  getCalendarEvents(query) {
    return client.get([BASE_URL, 'calendar-events'], query);
  },
  getUpcomingCalendarEvents(query) {
    return client.get([BASE_URL, 'calendar-events', 'upcoming'], query);
  },
  getActivityEvents(query) {
    return client.get([BASE_URL, 'activity-events'], query);
  },
};
