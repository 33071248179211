import { Validator } from 'vee-validate';
import _includes from 'lodash/includes';

Validator.extend('unique', {
  validate: (value, list) => {
    if (!value) return true;

    return !_includes(list, value?._id || value);
  },

  getMessage(field) {
    return `Should be unique ${field}`;
  },
});
