import { render, staticRenderFns } from "./ThreadPreview.vue?vue&type=template&id=32ea48ca&scoped=true"
import script from "./ThreadPreview.vue?vue&type=script&lang=js"
export * from "./ThreadPreview.vue?vue&type=script&lang=js"
import style0 from "./ThreadPreview.vue?vue&type=style&index=0&id=32ea48ca&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ea48ca",
  null
  
)

export default component.exports