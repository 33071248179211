import contracts from './contracts';
import jobs from './jobs';
import activityFeed from './activityFeed';

const actions = {
  fetchInitialData({ dispatch }) {
    return Promise.all([
      dispatch('dashboard/freelancer/contracts/fetchCurrentWeek', null, { root: true }),
      dispatch('dashboard/freelancer/contracts/fetchPreviousWeeks', null, { root: true }),
      dispatch('dashboard/freelancer/jobs/fetchRecommendedJobs', null, { root: true }),
      dispatch('dashboard/freelancer/jobs/fetchProfileStatistics', null, { root: true }),
    ]);
  },
};

const getters = {
  isCurrentWeekShown: state => {
    const { currentWeek } = state.contracts;
    const { totalStatistics } = currentWeek.statistics;
    return !currentWeek.isLoading && (!!totalStatistics?.activeContractsCount || !!totalStatistics?.billableMinutes);
  },

  isPreviousWeeksShown: state => {
    const { currentWeek, previousWeeks } = state.contracts;
    const { underReview, securityPeriod } = previousWeeks.statistics;
    return !currentWeek.isLoading && !previousWeeks.isLoading && (!!underReview?.minutes || !!securityPeriod?.minutes);
  },

  isContractsShown: (_, getters) => getters.isCurrentWeekShown || getters.isPreviousWeeksShown,

  isContractsLoading: state => {
    const { currentWeek, previousWeeks } = state.contracts;
    return currentWeek.isLoading || previousWeeks.isLoading;
  },

  isProfileInfoShown: (state, getters) => {
    const { profileStatistics } = state.jobs;
    return !getters.isContractsLoading && !profileStatistics.isLoading;
  },

  isRecommendedJobsShown: (state, getters) => {
    const { profileStatistics, recommendedJobs } = state.jobs;
    return !getters.isContractsLoading && !profileStatistics.isLoading && !recommendedJobs.isLoading;
  },

  isJobSearchShown: (_, getters) => getters.isProfileInfoShown || getters.isRecommendedJobsShown,

  isJobSearchLoading: state => {
    const { profileStatistics, recommendedJobs } = state.jobs;
    return profileStatistics.isLoading || recommendedJobs.isLoading;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  modules: {
    contracts,
    jobs,
    activityFeed,
  },
};
