/* eslint-disable import/prefer-default-export */

import _has from 'lodash/has';
import _get from 'lodash/get';
import _isString from 'lodash/isString';

const defaultErrorMessage = 'An unexpected error has occurred. Please contact support or try again later.';

const getErrorMessage = (error, options = {}) => {
  if (!error) {
    return defaultErrorMessage;
  }

  if (error.data) {
    error = error.data;
  }

  let errorMessage;

  if (_has(error, 'errors.null.0.message')) {
    errorMessage = error.errors.null[0].message;
  } else if (_has(error, 'errors.0.msg')) {
    errorMessage = error.errors[0].msg;
  } else if (_has(error, 'errors.global.0.message')) {
    errorMessage = error.errors.global[0].message;
  } else if (_isString(error.error)) {
    errorMessage = error.error;
  } else if (_isString(error.error_text)) {
    errorMessage = error.error_text;
  } else if (_isString(error)) {
    errorMessage = error;
  } else if (error.errorMessage) {
    // eslint-disable-next-line prefer-destructuring
    errorMessage = error.errorMessage;
  } else {
    errorMessage = _get(options, 'defaultMessage', defaultErrorMessage);
  }

  if (errorMessage === 'invalid_token') {
    errorMessage = 'Your session has timed out';
  }

  return errorMessage;
};

export { getErrorMessage };
