<template>
  <gl-icon-button
    :size="40"
    class="expand-icon-button"
    @click="$emit('click', $event)"
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="expand-icon-button__icon"
      :class="{minus: expanded, plus: !expanded }"
    >
      <line
        class="vertical-line"
        x1="10"
        y1="16"
        x2="10"
        y2="4"
        stroke="#4163DD"
        stroke-linecap="round"
        stroke-width="1.5"
      />
      <line
        x1="16"
        y1="10"
        x2="4"
        y2="10"
        stroke="#4163DD"
        stroke-linecap="round"
        stroke-width="1.5"
      />
    </svg>
  </gl-icon-button>
</template>

<script>
  import GlIconButton from 'uikit/components/buttons/IconButton.vue';

  export default {
    components: {
      GlIconButton,
    },
    props: {
      expanded: {
        type: Boolean,
        default: false,
      },
    },

  };
</script>

<style lang="postcss" scoped>

  .expand-icon-button__icon.minus {
    & .vertical-line {
      transform: rotate(90deg);
    }
  }

  line {
    transition: all 0.3s;
    transform: rotate(0);
    transform-origin: 50% 50%;
  }

</style>
