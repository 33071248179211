<template>
  <button
    class="filter-item"
    :class="{selected: isSelected}"
    @click="handleClick"
  >
    <gl-icon
      :name="filter.icon"
      :size="18"
      :class="{selected: isSelected}"
      class="filter-item__filter-icon"
    />
    <p class="filter-item__title">
      {{ filter.title }}
    </p>
    <gl-icon
      :size="15"
      name="right-arrow-large"
      class="filter-item__arrow-icon"
      :class="{'selected': isSelected}"
    />
  </button>
</template>

<script>
  import GlIcon from '@/uikit/icons/BaseIcon.vue';

  export default {
    components: {
      GlIcon,
    },
    props: {
      filter: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
      selectedIndex: {
        type: Number,
        required: true,
      },
    },
    computed: {
      isSelected() {
        return this.index === this.selectedIndex;
      },
    },
    methods: {
      handleClick() {
        if (!this.isSelected) {
          this.$emit('click', this.index);
        }
      },
    },
  };
</script>

<style lang="postcss" scoped>
.filter-item {
  @apply flex bg-white border-1 border-white items-center;
  padding: 12px 14px;
  gap: 10px;
  min-width: 100%;
  border-radius: 8px;
  transition: .2s ease all;

  &__title {
    @apply text-14 font-medium text-text-black;
    transition: .2s ease all;

    &.selected {
      @apply text-blue-liberty;
    }
  }

  &__filter-icon {
    @apply text-icon-default;
    transition: .2s ease all;

    &.selected {
      @apply text-blue-liberty;
    }
  }

  &__arrow-icon {
    opacity: 0;
    @apply text-text-placeholder;
    transition: .2s ease all;
    margin-left: auto;

    &.selected {
      @apply text-blue-liberty;
      opacity: 1;
    }
  }

  &.selected {
    @apply bg-foundation-gray-2 ;
    border-color: #3F56B6 !important;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    @apply border-foundation-gray-4;
  }

  &:hover .filter-item__arrow-icon {
    opacity: 1;
  }
}
</style>
