<template>
  <div class="wrapper">
    <template v-if="shownTextWithMediaContent">
      <p
        class="text"
        v-html="shownTextWithMediaContent"
      ></p>
      <span
        class="text"
        v-html="shownTextAfterMediaContent"
      ></span>
    </template>

    <span
      v-else
      class="text"
      v-html="shownText"
    ></span>

    <span v-if="isShownToggle && truncate && !isHTML">...</span>

    <template v-if="isShownToggle && text.length >= length">
      <gl-button
        v-if="!$scopedSlots.button"
        variant="link"
        @click="handleToggle"
      >
        {{ truncate ? clamp : less }}
      </gl-button>

      <template v-else>
        <slot
          name="toggler"
          :events="{ handleClickShowMore }"
          :truncated="truncate"
        >
        </slot>
      </template>
    </template>
  </div>
</template>

<script>
  import _includes from 'lodash/includes';
  import _last from 'lodash/last';

  import htmlText from '@/services/htmlText';

  import unescapeHtml from '@/services/unescapeHtml';

  import truncateHtml from 'truncate-html';
  import GlButton from '../buttons/Button.vue';

  export default {
    components: {
      GlButton,
    },
    props: {
      truncated: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        required: true,
      },
      clamp: {
        type: String,
        default: 'read more',
      },
      length: {
        type: Number,
        default: 180,
      },
      less: {
        type: String,
        default: 'show less',
      },
      type: {
        type: String,
        default: 'text',
        validator(value) {
          return _includes(['text', 'html'], value);
        },
      },
    },
    data() {
      return {
        truncate: this.truncated,
      };
    },
    computed: {
      isHTML() {
        return this.type === 'html';
      },
      textLength() {
        if (this.isHTML) {
          return htmlText.countCharacters(this.text);
        }
        return this.text.length;
      },
      isShownToggle() {
        return this.textLength > this.length;
      },
      truncatedText() {
        if (this.text) {
          if (this.isHTML) {
            return truncateHtml(htmlText.clearText(this.text), this.length, { decodeEntities: true });
          }

          return unescapeHtml(this.text.toString()).substring(0, this.length);
        }
        return '';
      },
      truncatedDecodedText() {
        if (this.text) {
          if (this.isHTML) {
            return truncateHtml(this.text, this.length);
          }

          return unescapeHtml(this.text.toString()).substring(0, this.length);
        }
        return '';
      },
      shownText() {
        return this.truncate ? this.truncatedText : this.text;
      },
      shownTextAfterMediaContent() {
        return this.truncate ? this.truncatedTextAfterMediaContent : this.fullTextAfterMediaContent;
      },
      shownTextWithMediaContent() {
        if (!this.isHTML) {
          return null;
        }

        const iFrameLength = this.getIFrameLength(this.truncatedText);

        if (iFrameLength) {
          return this.truncatedText.substring(0, iFrameLength);
        }
        return null;
      },

      truncatedTextAfterMediaContent() {
        if (!this.shownTextWithMediaContent) {
          return null;
        }
        return this.truncatedText.substring(this.shownTextWithMediaContent.length);
      },

      fullTextAfterMediaContent() {
        if (!this.shownTextWithMediaContent) {
          return null;
        }

        const iFrameTextLength = this.getIFrameLength(this.text);
        const iFrameTruncatedTextLength = this.getIFrameLength(this.truncatedDecodedText);

        if (iFrameTextLength === iFrameTruncatedTextLength) {
          return this.text.substring(this.getIFrameLength(this.text), this.text.length);
        }

        return truncateHtml(this.text, this.text.length).substring(iFrameTruncatedTextLength, this.text.length);
      },
    },
    methods: {
      getIFrameLength(text) {
        if (!this.isHTML) {
          return null;
        }
        const regex = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g;

        const results = text.match(regex);

        const lastIFrame = _last(results);

        if (lastIFrame) {
          const iFrameStartIndex = text.lastIndexOf(lastIFrame);

          return iFrameStartIndex + lastIFrame.length;
        }
        return null;
      },
      handleToggle() {
        this.truncate = !this.truncate;
        this.$emit('toggle', this.truncate);
      },
      handleClickShowMore() {
        this.truncate = !this.truncate;
        this.$emit('click', this.truncate);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .wrapper {
    @apply h-auto overflow-hidden;
    word-break: break-word;

    & >>> .text {
      & * {
        @apply font-primary;
        color: inherit;
      }

      & h1 {
        @apply text-24 font-semibold;
        line-height: 36px;
      }

      & h2 {
        @apply text-22 font-semibold;
        line-height: 36px;
      }

      & h3 {
        @apply text-20 font-semibold;
        line-height: 30px;
      }

      & h4 {
        @apply text-18 font-semibold;
        line-height: 28px;
      }

      & h5 {
        @apply text-16 font-semibold;
        line-height: 24px;
      }

      & h6 {
        @apply text-14 font-semibold;
        line-height: 20px;
      }

      & ol li {
        @apply text-14;
        line-height: 20px;
        list-style-type: decimal;
        list-style-position: inside;
      }

      & ul li {
        @apply text-14;
        line-height: 20px;
        list-style-type: disc;
        list-style-position: inside;
      }

      & a {
        transition: 0.3s ease;
        &:hover {
          text-decoration: underline;
        }
      }

      & >>> p:last-child {
        display: inline;
      }
      & >>> blockquote {
        display: none;
      }
    }
  }
</style>
