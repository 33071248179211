<template>
  <button
    class="review-rate-button"
    type="button"
    @click="$emit('click', $event)"
  >
    <review-rate-icon
      :value="stars"
      :size="24"
    />
  </button>
</template>

<script>
  import ReviewRateIcon from './ReviewRateIcon.vue';

  export default {
    components: {
      ReviewRateIcon,
    },
    props: {
      stars: {
        type: Number,
        default: 1,
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .review-rate-button {
    @apply border border-foundation-gray-4 bg-white flex items-center justify-center;
    border-radius: 50%;
    padding: 14px;
    flex: 0 0 52px;
    transition: .3s ease;

    &:hover, &:focus-visible {
      @apply border-royal-blue;
    }
  }
</style>
