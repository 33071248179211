import { getUserPermissions } from '../routerServices';

const Account = () => import(/* webpackChunkName: "UserAccount", webpackPrefetch: true */ '@/views/User/Account');
const AccountSettings = () => import(/* webpackChunkName: "UserAccountSettings", webpackPrefetch: true */ '@/views/User/Account/Settings');
const AccountSecurity = () => import(/* webpackChunkName: "UserAccountSecurity", webpackPrefetch: true */ '@/views/User/Account/Security');
const AccountNotifications = () => import(/* webpackChunkName: "UserAccountNotifications", webpackPrefetch: true */ '@/views/User/Account/Notifications');
const AccountTaxInformation = () => import(/* webpackChunkName: "UserAccountNotifications", webpackPrefetch: true */ '@/views/User/Account/TaxInformation');

export default [
  {
    name: 'account-settings',
    path: '/account',
    component: Account,
    children: [
      {
        name: 'account-settings.settings',
        path: 'settings',
        component: AccountSettings,
      },
      {
        name: 'account-settings.security',
        path: 'security',
        component: AccountSecurity,
      },
      {
        name: 'account-settings.notifications',
        path: 'notifications',
        component: AccountNotifications,
      },
      {
        name: 'account-settings.tax-information',
        path: 'tax-information',
        component: AccountTaxInformation,
        beforeEnter: (to, from, next) => {
          const permissions = getUserPermissions();
          if (!permissions.isFreelancer) {
            return next({
              name: 'account-settings.settings',
            });
          }

          return next();
        },
      },
    ],
  },
];
