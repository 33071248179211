<template>
  <div class="message">
    <div class="message__container">
      <div class="message__info">
        <span class="message__label">
          {{ label }}
        </span>
        <slot>
          <span
            class="message__content"
            v-html="content"
          >
          </span>
        </slot>
        <span class="message__date">
          {{ date }}
        </span>
      </div>
      <span
        v-if="isUnread"
        class="message__unread-circle"
      ></span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: '',
      },
      content: {
        type: String,
        required: true,
      },
      date: {
        type: String,
        required: true,
      },
      isUnread: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        skeleton: {
          type: 'rect',
          color: '#F0F1F5',
          animation: 'wave',
          rounded: true,
          radius: 4,
        },
      };
    },
  };
</script>

<style lang="postcss" scoped>
  .message {
    @apply border-b border-foundation-gray-2 font-primary;
    padding: 12px 0;
    line-height: 1.4em;
  }

  .message__container {
    @apply flex justify-between relative items-center;
    gap: 16px;
    padding: 0 24px 12px;
  }

  .message__info {
    @apply flex flex-col items-start;
    overflow: hidden;
    gap: 4px;
  }

  .message__content {
    @apply text-text-black text-14 font-primary;
    max-width: 100%;
  }

  .message__unread-circle {
    @apply bg-text-link inline-block absolute;
    top: 5px;
    right: 24px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }

  .message__label {
    @apply font-semibold text-14 text-text-black;
    white-space: nowrap;
    letter-spacing: 0.0025em;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .message__date {
    @apply text-text-placeholder text-12;
    letter-spacing: 0.004em;
  }

  .message__icon {
    flex-shrink: 0;
  }
</style>
