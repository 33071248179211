<template>
  <gl-icon-button
    :icon-name="iconName"
    name="burger-menu"
    @click.native="handleClick"
  />
</template>

<script>
  import { GlIconButton } from 'uikit/components';

  export default {
    components: {
      GlIconButton,
    },
    model: {
      prop: 'open',
      event: 'change',
    },
    props: {
      open: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      iconName() {
        return this.open ? 'close' : 'bars';
      },
    },
    methods: {
      handleClick() {
        this.$emit('change', !this.open);
      },
    },
  };
</script>
