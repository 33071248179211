<template>
  <span class="message-content__attach">
    <gl-base-icon
      :name="iconName"
      :size="16"
      class="attach__icon"
    />
    <span
      :title="previewName"
      class="truncate"
    >
      {{ previewName }}
    </span>
  </span>
</template>

<script>
  import { mapGetters } from 'vuex';

  import { GlBaseIcon } from 'uikit/components';

  import pluralize from '@/filters/pluralize';

  export default {
    components: {
      GlBaseIcon,
    },
    props: {
      lastMessageNonTextContent: {
        type: Object,
        default: null,
      },
    },
    computed: {
      ...mapGetters(['currentUser']),
      iconName() {
        const defaultIcon = 'attach';

        const iconKindMap = {
          image: 'photo',
          video: 'video',
        };

        if (this.lastMessageNonTextContent?.count === 1) {
          return iconKindMap[this.lastMessageNonTextContent?.kind] || defaultIcon;
        }

        return defaultIcon;
      },
      previewName() {
        const defaultName = 'Attachment';
        const count = this.lastMessageNonTextContent?.count;
        if (count > 1) {
          return `${count} ${pluralize(count, defaultName)}`;
        }

        return this.lastMessageNonTextContent?.name || defaultName;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .message-content__attach {
    @apply inline-flex items-center;
    gap: 6px;
    vertical-align: middle;
    overflow: hidden;

    & .attach__icon {
      flex-shrink: 0;
    }
  }
</style>
