const acceptedAttachmentFiles = '.jpg, .jpeg, .png, .gif, .tiff, .svg, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .pdf, .mp3, .ogg, .wav, .aac, .mp4, .webm, .ogg, .mov';
const defaultCompanyLogoUrl = 'https://res.cloudinary.com/golance/image/upload/v1679053586/app_new_ui/company-profile/company-logo.png';
const maxStoredSearchQueries = 5;
const modalTransformDuration = 300;
const screenSizes = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  mobile: 360,
  tablet: 768,
  desktop: 1024,
  largeDesktop: 1440,
};
const sidebarWidth = {
  expanded: 190,
  shrunk: 48,
};
const overflowContainerBreakpoint = 925;

export {
  acceptedAttachmentFiles, defaultCompanyLogoUrl, maxStoredSearchQueries, modalTransformDuration, screenSizes, sidebarWidth, overflowContainerBreakpoint,
};
