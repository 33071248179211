<script>
  import { mapGetters } from 'vuex';
  import _reject from 'lodash/reject';
  import _includes from 'lodash/includes';

  import ResourcesList from './ResourcesList.vue';

  const freelancerNavItems = {
    jobManager: {
      value: 'freelancerJobManager',
      label: 'Jobs Manager',
    },
    // serviceManager: {
    //  value: 'serviceManager',
    //  label: 'Services Manager',
    // },
    goWallet: {
      value: 'goWallet',
      label: 'Payments',
    },
    getHired: {
      value: 'getHired',
      label: 'Get Hired',
    },
    resources: {
      value: 'resources',
      label: 'Resources',
      list: ResourcesList,
    },
  };

  const clientNavItems = {
    jobManager: {
      value: 'jobManager',
      label: 'Jobs Manager',
    },
    goWallet: {
      value: 'goWallet',
      label: 'Payments',
    },
    findFreelancer: {
      value: 'findFreelancer',
      label: 'Find Freelancers',
    },
    // serviceMarketplace: {
    //  value: 'serviceMarketplace',
    //  label: 'Project Catalog',
    // },
    resources: {
      value: 'resources',
      label: 'Resources',
      list: ResourcesList,
    },
  };

  const unauthorizedNavItems = {
    findFreelancer: {
      value: 'findFreelancer',
      label: 'Find Freelancers',
    },
    // serviceMarketplace: {
    //  value: 'serviceMarketplace',
    //  label: 'Project Catalog',
    // },
    getHired: {
      value: 'getHired',
      label: 'Get Hired',
    },
    resources: {
      value: 'resources',
      label: 'Resources',
      list: ResourcesList,
    },
  };

  const authorizedRoutes = [freelancerNavItems.jobManager.value, freelancerNavItems.goWallet.value, clientNavItems.jobManager.value, clientNavItems.goWallet.value];
  const marketplaceRoutes = [clientNavItems.findFreelancer, freelancerNavItems.getHired];

  export default {
    computed: {
      ...mapGetters(['currentUser', 'isAuthorized', 'permissions']),
      isMarketplaceAccessDisabled() {
        return this.isAuthorized && this.currentUser?.marketplaceAccessDisabled;
      },
      currentNavItems() {
        if (this.isOnlyFreelancer) return freelancerNavItems;
        if (this.isOnlyEmployer) return clientNavItems;
        return unauthorizedNavItems;
      },
      isOnlyFreelancer() {
        return this.permissions.isFreelancer && !this.permissions.isEmployer;
      },
      isOnlyEmployer() {
        return !this.permissions.isFreelancer && this.permissions.isEmployer;
      },
      navItems() {
        let items = this.currentNavItems;

        if (this.isMarketplaceAccessDisabled) {
          items = _reject(items, item => _includes(marketplaceRoutes, item.value));
        }

        if (!this.isAuthorized) {
          items = _reject(items, item => _includes(authorizedRoutes, item.value));
        }

        return items;
      },
    },

    render() {
      return this.$scopedSlots.default({
        navItems: this.navItems,
      });
    },
  };
</script>
