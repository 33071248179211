<template>
  <button
    v-bind="$attrs"
    type="button"
    class="icon-button"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </button>
</template>

<script>
  export default {};
</script>

<style lang="postcss" scoped>
.icon-button {
  @apply text-icon-default flex justify-center items-center;
  outline: none;

  &:hover:not(:disabled) {
    @apply text-icon-hover;
  }

  &:disabled {
    @apply text-icon-default;
    cursor: default;
  }
}
</style>
