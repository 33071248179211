// eslint-disable-next-line import/prefer-default-export
export const screenSizes = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  mobile: 360,
  tablet: 768,
  desktop: 1024,
  largeDesktop: 1440,
};
