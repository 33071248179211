<template>
  <transition name="header-popup">
    <header-popup
      v-if="show"
      class="popup"
    >
      <h4 class="popup__header">
        Notifications
        <gl-num-indicator v-if="currentUser.unreadNotificationsCount">
          {{ currentUser.unreadNotificationsCount }}
        </gl-num-indicator>
        <gl-button
          :disabled="!currentUser.unreadNotificationsCount"
          class="popup__button"
          variant="link-button"
          size="small"
          @click="handleAllRead"
        >
          Mark all as read
        </gl-button>
      </h4>
      <div
        ref="scrollContainer"
        class="scrollbar-container"
      >
        <div
          v-if="loading"
          class="notifications-list"
        >
          <message-preview-skeleton
            v-for="num in preloadedCount"
            :key="num"
          />
        </div>
        <template v-else-if="notifications.length">
          <div class="notifications-list">
            <notification-preview
              v-for="notification in notifications"
              :key="notification._id"
              :notification="notification"
              @click.native="handlePreviewClick(notification)"
            />
            <template v-if="loadingNew">
              <message-preview-skeleton
                v-for="num in 3"
                :key="num"
              />
            </template>
          </div>
          <scroll-observer
            v-if="notifications.length < totalCount"
            :options="{ root: $refs.scrollContainer, rootMargin: '100px' }"
            @intersect="handleLoadMoreNotifications"
          />
        </template>
        <div
          v-else
          class="popup__empty-state"
        >
          No notifications available
        </div>
      </div>
    </header-popup>
  </transition>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import _find from 'lodash/find';

  import { GlNumIndicator, GlButton } from 'uikit/components';

  import logger from '@/logger';
  import notifications from '@/services/notifications';
  import config from '@/config';

  import usersApi from '@/services/api/users';

  import socketIo from '@/services/infrastructure/socketIo';

  import { downloadFileFromUrl } from '@/services/files';

  import fullName from '@/filters/fullName';

  import ScrollObserver from '@/components/ScrollObserver.vue';

  import HeaderPopup from './HeaderPopup.vue';
  import NotificationPreview from './NotificationPreview.vue';
  import MessagePreviewSkeleton from './MessagePreviewSkeleton.vue';

  export default {
    components: {
      GlButton,
      HeaderPopup,
      GlNumIndicator,
      NotificationPreview,
      ScrollObserver,
      MessagePreviewSkeleton,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        notifications: [],
        preloadedCount: 8,
        totalCount: 0,
        loading: false,
        loadingNew: false,
        page: 0,
      };
    },
    computed: {
      ...mapGetters(['currentUser']),
    },
    watch: {
      async show(val) {
        if (!val) {
          socketIo.off('io:notification:created', this.handleNotificationCreated);
          return;
        }

        this.page = 1;
        this.loading = true;
        this.notifications = await this.fetchNotifications();
        this.loading = false;

        socketIo.on('io:notification:created', this.handleNotificationCreated);
      },
    },
    destroyed() {
      socketIo.off('io:notification:created', this.handleNotificationCreated);
    },
    methods: {
      ...mapMutations(['setModalData']),
      async handleLoadMoreNotifications() {
        if (this.loadingNew) return;

        this.page += 1;
        this.loadingNew = true;
        const notificationsData = await this.fetchNotifications();
        this.notifications = [...this.notifications, ...notificationsData];
        this.loadingNew = false;
      },
      async fetchNotifications() {
        try {
          const response = await usersApi.getNotifications(this.currentUser._id, {
            pageNumber: this.page,
            itemsPerPage: this.preloadedCount,
            markAsRead: true,
          });

          this.totalCount = response.totalCount;
          return response.results;
        } catch (err) {
          logger.log(err);
        }
      },
      handleNotificationCreated(notification) {
        if (_find(this.notifications, { _id: notification._id })) {
          return;
        }
        this.notifications = [notification, ...this.notifications];
        this.totalCount++;
        usersApi.readNotification(this.currentUser._id, notification._id);
      },
      handlePreviewClick(notification) {
        this.close();

        const { type, data } = notification;

        if (type === 'proposal-interview-created') {
          const modalData = {
            path: 'interviewConfirmation',

            payload: {
              show: true,
              payload: data,
            },
          };
          this.setModalData(modalData);
        } else if (type === 'proposal-interview-reminder-for-client' || type === 'proposal-interview-reminder-for-freelancer') {
          const { job, applicant } = data;
          const modalData = {
            path: 'interviewReminder',
            payload: {
              show: true,
              payload: data,
              title: type === 'proposal-interview-reminder-for-freelancer' ? `Your interview for ${job.name} is in 10 minutes` : `Your interview with ${fullName(applicant)} will start in 10 minutes`,
            },
          };
          this.setModalData(modalData);
        } else if (['invoice-batch-pdf-created', 'receipt-batch-pdf-created', 'invoice-receipt-batch-pdf-created', 'freelancer-invoice-batch-pdf-created'].includes(type)) {
          this.handleInvoiceBulkPdfDownload(data);
        }
      },
      handleInvoiceBulkPdfDownload(data) {
        const { url, expiresOn } = data.urlData;
        const path = 'invoices';

        if (new Date() > new Date(expiresOn)) {
          notifications.showWarning(`The link is expired. <a href="${config.rootUrl}/payments/${path}" target="_parent">Go here</a> to generate again.`);
        } else {
          downloadFileFromUrl(url);
        }
      },
      async handleAllRead() {
        this.loading = true;
        try {
          await usersApi.readAllNotifications(this.currentUser._id);
        } catch (err) {
          logger.log(err);
        }
        this.loading = false;
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .scrollbar-container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
  }

  .popup {
    @apply absolute bg-white;
    top: 42px;
    right: 0;
    z-index: 101;
    transition: 0.2s linear all;
    line-height: 1.4em;
    padding: 12px 0;

    &__button.small {
      padding: 0;
      margin-left: auto;
    }
  }

  .popup__header {
    @apply flex items-center font-semibold text-text-black text-16 font-primary;
    letter-spacing: 0.0015em;
    padding: 12px 24px;
  }

  .popup__empty-state {
    @apply text-foundation-gray text-14 font-primary;
    letter-spacing: 0.0025em;
    text-align: center;
    line-height: 1.4em;
    padding: 12px 24px;
  }

  .popup__link-wrapper {
    padding: 12px 24px;
    margin-top: 4px;
    & a {
      @apply font-primary text-text-link text-14 font-medium;
      -webkit-font-smoothing: antialiased;
      letter-spacing: 0.0125em;
      outline: none;
      &:hover,
      &:focus-visible {
        @apply text-text-focus;
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 440px) {
    .popup {
      right: -48px;
    }
  }

  @media (max-height: 580px) {
    .scrollbar-container {
      max-height: 55vh;
    }
  }

  @media (max-height: 370px) {
    .scrollbar-container {
      max-height: 40vh;
    }
  }
</style>
