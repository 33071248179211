import Vue from 'vue';
import store from '@/store';

export default {
  install() {
    Object.defineProperty(Vue.prototype, '$screenSize', {
      get() { return store.state.app.screenSize; },
    });
  },
};
