<template>
  <div
    class="widget"
    :class="{ loading }"
  >
    <gl-skeleton-loader
      v-if="loading"
      class="loader"
    />

    <template v-else>
      <div
        v-if="$slots.header || header"
        class="header"
      >
        <span class="header__info">
          <span class="header__title">
            <slot name="header">
              {{ header }}
            </slot>
          </span>
          <gl-num-indicator v-if="count">
            {{ count }}
          </gl-num-indicator>
        </span>
        <template v-if="$slots.headerActions || headerActionTitle">
          <slot name="headerActions">
            <gl-button
              variant="link"
              class="header__action-title"
              @click="handleHeaderActionClick"
            >
              {{ headerActionTitle }}
            </gl-button>
          </slot>
        </template>
      </div>
      <div class="content">
        <slot> </slot>
      </div>
    </template>
  </div>
</template>

<script>
  import GlNumIndicator from 'uikit/components/NumIndicator/NumIndicator.vue';
  import GlSkeletonLoader from 'uikit/components/skeletons/SkeletonLoader.vue';
  import GlButton from 'uikit/components/buttons/Button.vue';

  export default {
    components: {
      GlButton,
      GlNumIndicator,
      GlSkeletonLoader,
    },
    props: {
      header: {
        type: String,
        default: null,
      },
      headerActionTitle: {
        type: String,
        default: null,
      },
      count: {
        type: [Number, String],
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleHeaderActionClick() {
        this.$emit('header-action-click');
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .widget {
    @apply bg-white font-primary flex flex-col;
    padding: 12px;
    border-radius: 20px;

    &.loading {
      padding: 0;
    }

    & >>> div.loading {
      height: 100%;
    }
  }

  .header {
    @apply flex justify-between items-center;
    margin-bottom: 8px;
    gap: 12px;
    padding: 12px;
  }

  .header__info {
    @apply flex items-center;
    gap: 4px;
  }

  .header__title {
    @apply font-semibold text-20 text-text-black inline-flex items-center font-primary;
    line-height: 150%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
  }

  .header__action-title {
    flex-shrink: 1;
  }

  .loader.loading {
    border-radius: 20px;
  }
</style>
