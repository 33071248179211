export const IndicatorPillColorNames = {
  SUCCESS_GREEN: 'success-green',
  ERROR_RED: 'error-red',
  ORANGE: 'orange',
  YELLOW: 'yellow',
  DEFAULT_DARK: 'default-dark',
  BLUE: 'blue',
  LIGHT_GRAY: 'light-gray',
  OCEAN_GREEN: 'ocean-green',
  SLATE_GRAY: 'slate-gray',
  ICON_BRAND: 'icon-brand',
};

export const IndicatorPillColors = {
  [IndicatorPillColorNames.DEFAULT_DARK]: {
    bg: '#6F7689',
    text: 'white',
  },
  [IndicatorPillColorNames.YELLOW]: {
    bg: '#FFDD2D',
    text: 'black',
  },
  [IndicatorPillColorNames.ORANGE]: {
    bg: '#FFA83A',
    text: 'white',
  },
  [IndicatorPillColorNames.BLUE]: {
    bg: '#615CF6',
    text: 'white',
  },
  [IndicatorPillColorNames.SUCCESS_GREEN]: {
    bg: '#1FC67E',
    text: 'white',
  },
  [IndicatorPillColorNames.ERROR_RED]: {
    bg: '#DD2929',
    text: 'white',
  },
  [IndicatorPillColorNames.LIGHT_GRAY]: {
    bg: '#E3E4E9',
    text: 'black',
  },
  [IndicatorPillColorNames.OCEAN_GREEN]: {
    bg: '#4CB488',
    text: 'white',
  },
  [IndicatorPillColorNames.SLATE_GRAY]: {
    bg: '#717685',
    text: 'white',
  },
  [IndicatorPillColorNames.ICON_BRAND]: {
    bg: '#475BAF',
    text: 'white',
  },
};
