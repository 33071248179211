// Reccuring payment type
const PAYMENT_TYPE_CYCLE = {
  weekly: {
    value: 'weekly',
    label: 'Weekly',
    unit: 'week',
    number: 1,
  },
  'bi-weekly': {
    value: 'bi-weekly',
    label: 'Bi-weekly',
    unit: 'week',
    number: 2,
  },
  monthly: {
    value: 'monthly',
    label: 'Monthly',
    unit: 'month',
    number: 1,
  },
};

const END_PERIOD_TYPES = {
  on: { value: 'on', label: 'On', condition: 'end_date' },
  after: { value: 'after', label: 'After', condition: 'payments_number' },
};

const INITIAL_RECCURING_PAYMENTS_FORM_DATA = {
  paymentCycle: null,
  paymentAmount: null,
  firstPaymentDate: null,
  firstPaymentAmount: null,
  endPeriodType: null,
  endPeriodDate: null,
  occurrencesCount: null,
};

export { PAYMENT_TYPE_CYCLE, END_PERIOD_TYPES, INITIAL_RECCURING_PAYMENTS_FORM_DATA };
