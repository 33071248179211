<template>
  <div
    class="list-wrapper"
    :class="{ mobile: isCompactMode }"
    @mouseleave="handleMouseLeave"
  >
    <div
      class="list-border-container"
      :class="{ invisible: isCompactMode && hoveredItem }"
    >
      <div
        v-if="isCompactMode && parentOption"
        class="mobile-back-step"
        @click="$emit('backStep')"
      >
        <arrow-left-icon
          v-if="parentOption"
          class="arrow-icon"
        />
        {{ parentOption && parentOption[nameField] }}
      </div>
      <ul
        ref="list"
        class="dropdown-list"
      >
        <li
          v-for="item in data"
          :key="item[idField]"
          class="list-item"
          :class="{ hovered: hoveredItem === item }"
          :title="item[nameField]"
          @mouseenter="handleMouseEnter(item)"
          @click="handleClick(item)"
        >
          {{ item[nameField] }}
          <arrow-right-icon
            v-if="item[childrenField]"
            class="arrow-icon"
          />
        </li>
      </ul>
    </div>
    <transition :name="isCompactMode ? 'none' : 'fade'">
      <hierarchical-select-drop-down
        v-if="hoveredItem && hoveredItem[childrenField]"
        class="nested-dropdown-list"
        :style="{ '--parent-width': `${listWidth}px` }"
        :data="hoveredItem[childrenField]"
        :name-field="nameField"
        :children-field="childrenField"
        :parent-option="hoveredItem"
        @select="handleSelectChild(hoveredItem, $event)"
        @backStep="handleBackStep"
      />
    </transition>
  </div>
</template>

<script>
  import _flatten from 'lodash/flatten';

  import screenSize from '@/mixins/screenSize';

  import ArrowLeftIcon from 'uikit/components/icons/ArrowLeftIcon.vue';
  import ArrowRightIcon from 'uikit/components/icons/ArrowRightIcon.vue';

  export default {
    components: {
      ArrowLeftIcon,
      ArrowRightIcon,
    },
    mixins: [screenSize],
    props: {
      data: {
        type: Array,
        required: true,
      },
      parentOption: {
        type: Object,
        default: null,
      },
      childrenField: {
        type: String,
        required: true,
      },
      nameField: {
        type: String,
        required: true,
      },
      idField: {
        type: String,
        default: 'id',
      },
      compactMode: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        hoveredItem: null,
        listWidth: 400,
      };
    },
    computed: {
      isCompactMode() {
        return !this.$screenSize.md || this.compactMode;
      },
    },
    methods: {
      onResize() {
        this.listWidth = this.$refs.list.getBoundingClientRect().width;
      },
      handleMouseEnter(item) {
        if (this.isCompactMode) {
          return;
        }
        this.hoveredItem = item;
      },
      handleMouseLeave() {
        if (this.isCompactMode) {
          return;
        }
        this.hoveredItem = null;
      },
      handleClick(item) {
        if (this.isCompactMode) {
          this.hoveredItem = item;
        }
        if (item[this.childrenField]) {
          return;
        }
        this.$emit('select', [item]);
      },
      handleSelectChild(...args) {
        const values = _flatten(args);
        this.hoveredItem = null;
        this.$emit('select', values);
      },
      handleBackStep() {
        this.hoveredItem = null;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .nested-dropdown-list {
    &.list-wrapper {
      height: 100%;
      padding-left: 8px;
      top: 0;
      left: calc(var(--parent-width, 200px) - 1px);
      width: var(--parent-width, 200px);
      &.mobile {
        left: 0;
        padding-left: 0;
      }
    }
  }

  .list-wrapper {
    position: absolute;
  }

  .list-border-container {
    @apply shadow-dropdown-default;
    border-radius: 12px;
    padding: 0;
  }

  .dropdown-list {
    @apply bg-white;
    position: relative;
    min-width: 100px;
    width: 100%;
    list-style-type: none;
    padding: 8px 0;
    max-height: calc(47px * 5);
    overflow-y: auto;
    border-radius: 12px;
  }

  .list-item {
    @apply bg-white font-primary font-normal text-black relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    padding: 16px;
    transition: background-color 0.2s ease;
    cursor: pointer;
    &.hovered,
    &:hover {
      @apply bg-foundation-gray-2;
    }

    & .arrow-icon {
      position: absolute;
      right: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .mobile-back-step {
    @apply bg-blue-beau border-fog border-b font-primary font-normal text-black;
    cursor: pointer;
    white-space: nowrap;
    font-size: 14px;
    padding: 13px 15px;
    & .arrow-icon {
      @apply text-blue;
      padding-right: 14px;
    }
  }
</style>
