<template>
  <label class="toggle-container">
    <span
      v-if="leftLabel"
      class="toggle-label"
    >
      {{ leftLabel }}
    </span>

    <input
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      :class="{ checked: value, disabled }"
      @change="handleToggleChange"
    >
    <span class="toggle"></span>

    <span
      v-if="label"
      class="toggle-label"
    >
      {{ label }}
    </span>
  </label>
</template>

<script>
  export default {
    model: {
      event: 'change',
    },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: null,
      },
      leftLabel: {
        type: String,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleToggleChange() {
        if (this.disabled || this.loading) {
          return;
        }
        this.$emit('change', !this.value);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .toggle-container {
    @apply flex items-center;
    gap: 8px;
    user-select: none;
  }

  input {
    appearance: none;
    margin-right: -8px;
  }

  .toggle {
    @apply bg-white;
    box-shadow: 0 0 0 1px inset #e3e4e9;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: 40px;
    height: 24px;
    cursor: pointer;
    transition: 0.4s;
    border-radius: 16px;
    flex-shrink: 0;
  }

  .toggle:before {
    @apply bg-foundation-gray-4;
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:focus + .toggle,
  .toggle:hover {
    box-shadow: 0 0 0 1px inset #dadce3;
    &:before {
      @apply bg-foundation-gray-5;
    }
  }

  input:focus-visible + .toggle {
    box-shadow: 0 0 0 1px inset #3f56b6;
    &:before {
      @apply bg-foundation-gray-5;
    }
  }

  input.checked:focus + .toggle,
  input.checked + .toggle:hover {
    @apply bg-y-in-mn-blue;
    box-shadow: none;
    &:before {
      @apply bg-white;
    }
  }

  input.checked + .toggle {
    @apply bg-blue-liberty;
    box-shadow: none;
    &:before {
      @apply bg-white;
      transform: translateX(16px);
    }
  }

  input.disabled + .toggle,
  input.disabled:focus + .toggle,
  input.disabled + .toggle:hover {
    @apply bg-foundation-gray-2;
    outline: none;
    cursor: not-allowed;
    box-shadow: 0 0 0 1px inset #c6c8d2;

    &:before {
      @apply bg-foundation-gray-6;
    }
  }

  input.disabled.checked + .toggle,
  input.disabled.checked:focus + .toggle,
  input.disabled.checked + .toggle:hover {
    @apply bg-foundation-gray-6;
    box-shadow: 0 0 0 1px inset #c6c8d2;
    outline: none;
    &:before {
      @apply bg-white;
    }
  }

  .toggle-label {
    @apply text-text-weak text-14;
    line-height: 20px;
    letter-spacing: 0.0025em;
  }
</style>
