import _includes from 'lodash/includes';

export default {
  guessProvider(url) {
    if (this.getYoutubeVideoId(url)) {
      return 'youtube';
    }
    if (this.getVimeoVideoId(url)) {
      return 'vimeo';
    }
    if (this.isSoundcloud(url)) {
      return 'soundcloud';
    }
  },
  getYoutubeVideoId(youtubeUrl) {
    const youtubeRegexp = /^((https?:\/\/)?((www\.)(?!m\.))?)?(m\.)?youtu(be\.com\/watch\?v=|\.be\/)([\w\-_]*)(?:&(?:amp;)?‌​[\w?‌​=]*)?/;
    const matches = youtubeUrl.match(youtubeRegexp);

    if (matches) {
      return matches[matches.length - 1];
    }

    return null;
  },
  getVimeoVideoId(vimeoUrl) {
    // eslint-disable-next-line max-len
    const vimeoRegex = /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/[\w-]+\/|album\/\d+\/video\/|groups\/\w+\/videos\/|ondemand\/[\w-]+\/|showcase\/[\w-]+\/video\/)?(\d+)(?:\/([a-zA-Z0-9_-]+))?(?:\?share=copy)?$/;

    const matches = vimeoUrl.match(vimeoRegex);

    if (matches) {
      const [, videoId, segmendId] = matches;

      if (segmendId) {
        return `${videoId}/${segmendId}`;
      }

      return videoId;
    }

    return null;
  },
  isSoundcloud(soundcloudUrl) {
    const soundcloudRegexp = /^((https?:\/\/)?((www\.)(?!m\.))?)?(m\.)?soundcloud.com\/[a-zA-Z0-9\-._]+(\/)+[a-zA-Z0-9\-.]+/;
    return soundcloudUrl.match(soundcloudRegexp) !== null;
  },
  adjustSoundCloudUrl(soundcloudUrl) {
    const startsWithHttp = soundcloudUrl.match(/^https?:\/\//);

    if (!startsWithHttp) {
      return `http://${soundcloudUrl}`;
    }

    return soundcloudUrl;
  },
  embeddedUrl({ provider, id }, options = {}) {
    const autoPlay = options.autoPlay || false;

    switch (provider) {
      case 'youtube':
        return `https://www.youtube.com/embed/${id}?rel=0&autoplay=${autoPlay ? 1 : 0}`;
      case 'vimeo': {
        const [videoId, segmendId] = id.split('/');

        if (segmendId) {
          return `https://player.vimeo.com/video/${videoId}?h=${segmendId}&loop=1&autoplay=${autoPlay ? 1 : 0}`;
        }
        return `https://player.vimeo.com/video/${id}?loop=1&autoplay=${autoPlay ? 1 : 0}`;
      }
      case 'soundcloud':
        return `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${id}&auto_play=${autoPlay}&hide_related=false&show_comments=true&show_user=true&show_reposts=false&visual=true`;
      default:
        return null;
    }
  },
  getUrl({ provider, id }) {
    switch (provider) {
      case 'youtube':
        return `https://www.youtube.com/watch?v=${id}`;
      case 'vimeo':
        return `https://vimeo.com/${id}`;
      default:
        return null;
    }
  },
  getMediaThumbnail({ provider, id }) {
    switch (provider) {
      case 'youtube':
        return `https://img.youtube.com/vi/${id}/mqdefault.jpg`;
      case 'soundcloud':
        return 'https://res.cloudinary.com/golance/image/upload/c_scale,w_70/v1489418241/soundcloud-icon_kxprzr.png';
      case 'vimeo':
        return 'https://res.cloudinary.com/golance/image/upload/c_scale,w_70/v1489418759/vimeo-icon_fvra42.png';
      default:
        return null;
    }
  },
  isPreviewable(type) {
    return _includes(['pdf', 'word', 'powerpoint', 'excel', 'text', 'video'], type);
  },
};
