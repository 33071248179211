<template>
  <footer
    ref="footer"
    class="footer"
  >
    <div class="footer-top">
      <div class="footer-content">
        <div class="content-follow">
          <a
            :href="landingUrl"
            class="app-logo-link"
          >
            <app-logo
              type="big-white"
              :width="140"
              :height="30"
            />
          </a>
          <div class="social">
            <a
              :href="socialLinks.twitter"
              rel="noopener"
              target="_blank"
            >
              <gl-twitter-icon />
            </a>
            <a
              :href="socialLinks.instagram"
              rel="noopener"
              target="_blank"
            >
              <gl-instagram-icon />
            </a>
            <a
              :href="socialLinks.linkedIn"
              rel="noopener"
              target="_blank"
            >
              <gl-linkedin-icon />
            </a>
            <a
              :href="socialLinks.youtube"
              rel="noopener"
              target="_blank"
            >
              <gl-youtube-icon />
            </a>
          </div>
        </div>
        <div class="content-links">
          <div
            v-for="(column, columnIndex) in linkBlocks"
            :key="columnIndex"
            class="column-links"
          >
            <gl-link-container
              v-for="(section, index) in column"
              :key="index"
              :text-title="section.textTitle"
              :link-title="section.linkTitle"
              :links="section.links"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="footer-copyright">
      <div class="copyright-wrapper">
        <p class="copyright">
          &copy; {{ currentYear }} goLance
        </p>
        <div class="copyright-links">
          <a
            :href="privacyPolicyUrl"
            target="_blank"
          > Privacy Policy </a>
          <a
            :href="gdprUrl"
            target="_blank"
          > GDPR </a>
          <a
            :href="termsOfServiceUrl"
            target="_blank"
          > Terms of Service </a>
          <!-- <a
            :href="cardholderAgreementUrl"
            target="_blank"
          > Cardholder Agreement </a> -->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import config from '@/config';
  import formatDate from '@/filters/formatDate';
  import GlLinkContainer from '@/components/layout/FooterLinkContainer.vue';
  import GlTwitterIcon from '@/components/icons/footer/TwitterIcon.vue';
  import GlLinkedinIcon from '@/components/icons/footer/LinkedinIcon.vue';
  import GlYoutubeIcon from '@/components/icons/footer/YoutubeIcon.vue';
  import GlInstagramIcon from '@/components/icons/footer/InstagramIcon.vue';
  import AppLogo from '@/components/AppLogo.vue';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      GlLinkContainer,
      GlTwitterIcon,
      GlLinkedinIcon,
      GlYoutubeIcon,
      GlInstagramIcon,
      AppLogo,
    },
    data: () => ({
      landingUrl: config.landingUrl,
      socialLinks: {
        twitter: 'https://twitter.com/golanceofficial',
        linkedIn: 'https://www.linkedin.com/company/golance/',
        youtube: 'https://www.youtube.com/@golance_',
        instagram: 'https://www.instagram.com/golanceofficial/',
      },
    }),
    computed: {
      ...mapGetters(['currentUser']),
      marketplaceAccessDisabled() {
        return this.currentUser && this.currentUser.marketplaceAccessDisabled;
      },
      privacyPolicyUrl() {
        return `${config.landingUrl}/privacy-policy`;
      },
      gdprUrl() {
        return `${config.landingUrl}/gdpr`;
      },
      termsOfServiceUrl() {
        return `${config.landingUrl}/terms-of-service`;
      },
      cardholderAgreementUrl() {
        return `${config.landingUrl}/cardholder-agreement`;
      },
      linkBlocks() {
        return [
          [
            {
              linkTitle: { name: 'Platform', url: `${config.landingUrl}/platform`, target: '_blank' },
              links: [
                { name: 'Platform for clients', url: `${config.landingUrl}/platform#P-for-C`, target: '_blank' },
                { name: 'Platform for freelancers', url: `${config.landingUrl}/platform#P-for-F`, target: '_blank' },
                { name: 'Time tracking', url: `${config.landingUrl}/platform#gometer`, target: '_blank' },
              ],
            },
            {
              textTitle: 'Services',
              links: [
                { name: 'Managed Development', url: `${config.landingUrl}/services`, target: '_blank' },
                { name: 'Staff Augmentation', url: `${config.landingUrl}/staff-augmentation`, target: '_blank' },
                { name: 'MVP Development', url: `${config.landingUrl}/mvp-development`, target: '_blank' },
                { name: 'Full-Time Talent', url: `${config.landingUrl}/full-time-talent-dev`, target: '_blank' },
                { name: 'AI Development', url: `${config.landingUrl}/ai`, target: '_blank' },
              ],
            },
          ],
          [
            {
              textTitle: 'Resources',
              links: [
                { name: 'Blog', url: 'https://blog.golance.com', target: '_blank' },
                { name: 'Podcast', url: `${config.landingUrl}/podcast`, target: '_blank' },
                { name: 'About goLance', url: `${config.landingUrl}/about`, target: '_blank' },
                { name: 'Why goLance', url: `${config.landingUrl}/why-golance`, target: '_blank' },
                { name: 'Success stories', url: `${config.landingUrl}/success-stories`, target: '_blank' },
                { name: 'Help Center', url: config.helpCenterUrl, target: '_blank' },
                { name: 'Affiliate Program', url: `${config.landingUrl}/affiliate`, target: '_blank' },
              ],
            },
          ],
          [
            {
              textTitle: 'Process',
              links: [
                { name: 'Find Freelancers', url: `${config.landingUrl}/hire`, show: !this.marketplaceAccessDisabled },
                { name: 'Project Catalog', url: `${config.landingUrl}/service-marketplace`, show: false },
                { name: 'Get Hired', url: `${config.landingUrl}/work`, show: !this.marketplaceAccessDisabled },
                { name: 'Download goMeter', url: `${config.landingUrl}/gometer`, target: '_blank' },
                { name: 'Security', url: `${config.landingUrl}/security`, target: '_blank' },
                { name: 'Log in', url: `${config.landingUrl}/login`, target: '_blank' },
                { name: 'Contact us', url: `${config.landingUrl}/contact-us`, target: '_blank' },
              ],
            },
          ],
          [
            {
              textTitle: 'Case studies',
              links: [
                { name: 'Deliveright', url: `${config.landingUrl}/success-stories-pages/deliveright`, target: '_blank' },
                { name: 'HealthBird', url: `${config.landingUrl}/success-stories-pages/healthbird`, target: '_blank' },
                { name: 'Arellson Agency', url: `${config.landingUrl}/success-stories-pages/arellson-agency`, target: '_blank' },
                { name: 'Atomic Webzone Agency', url: `${config.landingUrl}/success-stories-pages/awz`, target: '_blank' },
                { name: 'Necensus', url: `${config.landingUrl}/success-stories-pages/necensus`, target: '_blank' },
                { name: 'Paralect', url: `${config.landingUrl}/success-stories-pages/paralect`, target: '_blank' },
                { name: 'Set Financial', url: `${config.landingUrl}/success-stories-pages/set-financial`, target: '_blank' },
                { name: 'White Eagle Technologies', url: `${config.landingUrl}/success-stories-pages/white-eagle-technologies`, target: '_blank' },
                { name: 'zeVez', url: `${config.landingUrl}/success-stories-pages/zevez`, target: '_blank' },
              ],
            },
          ],
        ];
      },
      currentYear() {
        return formatDate(new Date(), 'yyyy');
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .footer {
    @apply flex flex-col items-center w-full font-primary;
    background: linear-gradient(100.7deg, #3a4e8b 7.2%, #2c3a80 89.59%);
    padding-top: 50px;

    & .app-logo-link {
      @apply inline-flex;
      height: auto;
      width: 136px;
    }

    & .footer-top {
      overflow: visible;
      width: 100%;
      max-width: 1200px;
      margin-top: 0px;
      margin-right: auto;
      margin-left: auto;
      padding-right: 16px;
      padding-left: 16px;
      margin-bottom: 100px;

      & .footer-content {
        @apply flex flex-row;
        grid-column-gap: 30px;

        & .content-follow {
          flex-basis: 200px;

          & .social {
            width: 200px;
            margin-top: 22px;

            & a {
              margin-right: 6px;
            }
          }
        }

        & .column-links {
          @apply flex flex-col;
          gap: 32px;
        }

        & .content-links {
          display: grid;
          padding-right: 16px;
          padding-left: 16px;
          grid-auto-columns: 1fr;
          grid-column-gap: 16px;
          grid-row-gap: 0px;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: auto;
          width: 100%;
          max-width: 1200px;
          min-width: auto;

          & div {
            justify-self: center;
          }

          & div:first-child {
            justify-self: start;
          }

          & div:last-child {
            justify-self: end;
          }
        }
      }
    }

    & .footer-copyright {
      @apply bg-white-ghost pt-4 pb-8 w-full;
      color: #454c52;
      padding: 30px 40px;

      & .copyright-wrapper {
        display: flex;
        width: 100%;
        max-width: 1200px;
        margin-top: 0px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 16px;
        padding-left: 16px;
      }

      & .copyright {
        @apply text-14 font-normal;
        line-height: 20px;
        margin-right: 0px;
        margin-left: 0px;
        padding-right: 40px;
        border-right: 1px solid #d3d6d9;
      }

      & .copyright-links {
        @apply flex;
        & a {
          @apply font-normal;
          color: #454c52;
          margin-right: 40px;
          margin-left: 40px;
          max-width: 100%;
          display: inline-block;
          text-align: center;
        }
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1051px) {
    .content-links {
      & div:nth-child(2),
      & div:nth-child(3) {
        & >>> .link-container {
          min-width: 150px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .footer {
      & .footer-top {
        padding-right: 40px;
        padding-left: 40px;

        & .footer-content {
          & .content-links {
            display: grid;
            grid-auto-columns: 1fr;
            grid-column-gap: 16px;
            grid-row-gap: 60px;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto;

            & div:nth-child(2),
            & div:last-child {
              justify-self: start;
            }

            & div:nth-child(3) {
              justify-self: start;
            }
          }
        }
      }
      & .footer-copyright {
        & .copyright-wrapper {
          padding-right: 0px;
          padding-left: 0px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .footer {
      & .footer-copyright {
        padding: 16px;
        & .copyright-links {
          display: grid;
          width: 100%;
          grid-column-gap: 16px;
          grid-row-gap: 16px;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto auto;
          & a {
            margin-right: 20px;
            margin-left: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 516px) {
    .footer {
      padding-top: 30px;
      & .footer-top {
        padding-right: 16px;
        padding-left: 16px;

        & .footer-content {
          flex-direction: column;
          grid-row-gap: 30px;

          & .content-links {
            padding-left: 0px;
            grid-column-gap: 20px;
            grid-row-gap: 40px;
            grid-template-columns: 1fr 1fr;

            & div {
              justify-self: start;
            }

            & div:nth-child(2) {
              justify-self: start;
            }
          }

          & .content-follow {
            flex-basis: 0px;
          }
        }
      }
      & .footer-copyright {
        & .copyright {
          margin-bottom: 10px;
          padding-right: 10px;
          padding-left: 0px;
          text-align: left;
        }
        & .copyright-wrapper {
          display: flex;
          grid-row-gap: 16px;
          flex-direction: column;
        }
        & .copyright-links {
          padding-left: 0px;
          justify-items: start;
          grid-auto-columns: 1fr;
          grid-column-gap: 5px;
          & a {
            margin-right: 0px;
            margin-bottom: 0px;
            margin-left: 0px;
            text-align: left;
          }
        }
      }
    }
  }
</style>
