<template>
  <component
    :is="iconComponent"
    class="icon"
    :style="{ '--icon-width': `${size || width}px`, '--icon-height': `${size || height}px`, '--icon-color': color }"
    v-bind="$attrs"
  />
</template>

<script>
  import _includes from 'lodash/includes';
  import _keys from 'lodash/keys';

  import icons from './store';

  export default {
    props: {
      name: {
        type: String,
        required: true,
        validator: value => _includes(_keys(icons), value),
      },
      size: {
        type: [Number, String],
        default: null,
      },
      width: {
        type: [Number, String],
        default: 24,
      },
      height: {
        type: [Number, String],
        default: 24,
      },
      color: {
        type: String,
        default: 'inherit',
      },
    },
    computed: {
      iconComponent() {
        return icons[this.name];
      },
    },
  };
</script>

<style lang="postcss" scoped>
.icon {
  height: var(--icon-height);
  width: var(--icon-width);
  min-width: var(--icon-width);
  color: var(--icon-color);
}
</style>
