<template>
  <table-component
    v-if="data.length"
    v-bind="$attrs"
    :data="data"
    :editable="false"
    :compact="true"
    :selectable="selectable"
    :clickable-rows="areRowsClickable"
    class="modal-table"
    v-on="$listeners"
  >
    <template
      slot="cell"
      slot-scope="{ field, record }"
    >
      <slot
        name="cell"
        :field="field"
        :record="record"
      ></slot>
    </template>
    <template #inlineActionButton="{ record }">
      <slot
        name="inlineActionButton"
        :record="record"
      ></slot>
    </template>
  </table-component>
  <div
    v-else
    class="empty-state"
  >
    Nothing Is found
  </div>
</template>

<script>
  import TableComponent from './SimpleTable.vue';

  export default {
    components: {
      TableComponent,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      selectable: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      areRowsClickable() {
        return !!this.$listeners.rowClick;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .modal-table {
    width: 100%;
    margin-bottom: 1px;
    & >>> .table-component {
      border-radius: 4px;
      overflow: hidden;

      & .table__rows {
        @apply border border-t-0;
        border-radius: 0 0 12px 12px;
      }
    }
  }

  .empty-state {
    @apply text-center font-primary text-14;
    padding-top: 16px;
  }

  @media (max-width: 576px) {
    .modal-table {
      & >>> .table-component {
        & .table__rows {
          @apply border;
          overflow: hidden;
          border-radius: 12px;
        }

        & .table-cell.compact {
          & + .table-cell {
            margin-top: 12px;
          }

          & .cell__heading {
            @apply text-12;
          }

          & .cell__content {
            @apply text-12;
          }
        }

        & .table__row.compact {
          @apply border-t border-foundation-gray-4;
          border-radius: 0;
          margin-top: 0;

          &:first-child {
            @apply border-t-0;
          }
        }
      }
    }

    .empty-state {
      @apply text-12;
    }
  }
</style>
