<template>
  <div v-click-outside="handleCloseProfileModal">
    <button
      :class="{ active: isProfilePopUpShown }"
      class="profile"
      name="profile"
      @click="handleToggleProfilePopup"
    >
      <img
        :src="currentUser | avatarUrl(35)"
        :alt="currentUser.firstName"
        class="profile-picture"
        width="35"
        height="35"
        loading="lazy"
      >
    </button>

    <pop-up
      :show="isProfilePopUpShown"
      :current-user="currentUser"
      :is-freelancer="isFreelancer"
      class="pop-up"
      @close="handleCloseProfileModal"
    />
  </div>
</template>

<script>
  import avatarUrl from '@/filters/avatarUrl';

  import PopUp from './ProfilePopUp.vue';

  export default {
    filters: {
      avatarUrl,
    },
    components: {
      PopUp,
    },
    props: {
      currentUser: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        isProfilePopUpShown: false,
      };
    },
    computed: {
      isFreelancer() {
        return this.currentUser?.type?.includes('freelancer');
      },
    },
    methods: {
      handleToggleProfilePopup() {
        this.isProfilePopUpShown = !this.isProfilePopUpShown;
      },
      handleCloseProfileModal() {
        this.isProfilePopUpShown = false;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .profile {
    @apply font-primary border border-white flex items-center cursor-pointer relative;
    box-sizing: border-box;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    overflow: hidden;
    outline: none;
    transition: 0.3s ease all;
    &:hover,
    &:focus-visible,
    &.active {
      @apply border border-white;
      outline: none;
      box-shadow: 0 0 0px 1px #3f56b6;
    }
    & .profile-picture {
      @apply bg-foundation-gray-2;
      height: 35px;
      width: 35px;
    }
  }
</style>
