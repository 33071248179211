export default function getScrollableParent(element) {
  if (element == null) {
    return window;
  }

  if (element === document.body) {
    return window;
  }

  if (element.scrollHeight > element.clientHeight) {
    return element;
  }

  return getScrollableParent(element.parentElement);
}
