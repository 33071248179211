import client from './client';
import freelancer from './freelancer';

export default {
  namespaced: true,
  modules: {
    client,
    freelancer,
  },
};
