export const fullWalletTabs = [
  { title: 'Dashboard', value: 'gowallet.dashboard' },
  { title: 'Transactions', value: 'gowallet.transactions' },
  { title: 'Cards', value: 'gowallet.cards' },
  { title: 'Withdrawal Methods', value: 'gowallet.withdrawal-methods' },
  { title: 'Payment Methods', value: 'gowallet.payment-methods' },

  { title: 'Payment Requests', value: 'gowallet.payment-requests' },
  { title: 'Payday Advance', value: 'gowallet.payday-advance' },
  { title: 'Invoices', value: 'gowallet.invoices' },
];

export const paymentRequestsTabNames = {
  ReceivedRequests: 'gowallet.payment-requests.received',
  SentRequests: 'gowallet.payment-requests.sent',
};

export const walletPage = {
  title: 'Wallet',
  value: 'gowallet.wallet',
};

export const paymentRequestsTabs = [
  {
    title: 'Requests Received',
    value: paymentRequestsTabNames.ReceivedRequests,
  },
  {
    title: 'Requests Sent',
    value: paymentRequestsTabNames.SentRequests,
  },
];

export const lightWalletFreelancerTabs = [
  { title: 'Earning History', value: 'gowallet.transactions' },
  { title: 'Withdrawal Methods', value: 'gowallet.withdrawal-methods' },
  { title: 'Payday Advance', value: 'gowallet.payday-advance' },
  { title: 'Invoices', value: 'gowallet.invoices' },
];

export const lightWalletClientTabs = [
  { title: 'Billing History', value: 'gowallet.transactions' },
  { title: 'Payment Methods', value: 'gowallet.payment-methods' },
  { title: 'Invoices', value: 'gowallet.invoices' },
];
