import Sortable from 'sortablejs';

export default {
  inserted(el, { value }) {
    if (value === false) {
      return;
    }

    let options = typeof value === 'string' ? { handle: value } : value;

    // filter selector only disables dragging of filtered elements
    // it still possible to change its position while dragging another element
    // this hack prevents moving while dragging over filtered element
    if (options && options.filter) {
      const disableFreezedSort = {
        onMove(evt) {
          const freezedClass = options.filter.replace('.', '');
          return (!evt.related || !evt.related.classList.contains(freezedClass));
        },
      };
      options = { ...options, ...disableFreezedSort };
    }

    return new Sortable(el,
      options || {},
    );
  },
};
