<template>
  <button
    class="filters-button"
    @click="handleClick"
  >
    <gl-icon
      :size="16"
      name="filledFilter"
      class="filters-button__filter-icon"
    />
    <p class="filters-button__title">
      Filters
    </p>
    <div
      class="filters-button__count-block"
      :class="{active: !!filtersCount }"
    >
      <p
        class="filters-button__count-value"
      >
        {{ filtersCount }}
      </p>
    </div>
    <gl-icon
      :size="12"
      :class="{opened: isOpened}"
      name="right-arrow-large"
      class="filters-button__arrow-icon"
    />
  </button>
</template>

<script>
  import GlIcon from '@/uikit/icons/BaseIcon.vue';
  import _keys from 'lodash/keys';

  export default {
    components: {
      GlIcon,
    },
    props: {
      isOpened: {
        type: Boolean,
        default: false,
      },
      filters: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
      };
    },
    computed: {
      filtersCount() {
        return _keys(this.filters).length;
      },
    },
    methods: {
      handleClick() {
        this.$emit('click');
      },
    },
  };
</script>

<style lang="postcss" scoped>
.filters-button {
  @apply flex bg-white items-center;
  gap: 5px;
  padding: 8px 16px;
  border-radius: 8px;

  &__filter-icon {
    @apply text-icon-default mr-1;
  }

  &__title {
    @apply text-14 font-medium text-text-black;
  }

  &__count-block {
    @apply flex items-center justify-center bg-icon-default mx-1;
    padding:  2px 7px 1px;
    height: 20px;
    min-width: 20px;
    width: max-content;
    border-radius: 16px;
    transition: .3s ease all;

    &.active {
      @apply bg-icon-blue;
    }
  }

  &__count-value {
    @apply text-14 font-semibold text-white;
  }

  &__arrow-icon {
    @apply text-text-black;
    transform: rotate(90deg);
    transition: .3s ease all;

    &.opened {
      transform: rotate(270deg);
    }
  }

  &:focus {
    outline: none;
  }
}
</style>
