<template>
  <img
    class="review-rate-emoji-icon"
    :src="imageLink"
    alt="emoji"
    :width="size"
    :height="size"
  >
</template>

<script>
  const valueLinkMap = {
    1: 'https://res.cloudinary.com/golance/image/upload/v1714565552/app_new_ui/emoji-icons/Frowning_Face-min.png',
    2: 'https://res.cloudinary.com/golance/image/upload/v1714565553/app_new_ui/emoji-icons/Unamused_Face-min.png',
    3: 'https://res.cloudinary.com/golance/image/upload/v1714565553/app_new_ui/emoji-icons/Neutral_Face-min.png',
    4: 'https://res.cloudinary.com/golance/image/upload/v1714565553/app_new_ui/emoji-icons/Smiling_Face_With_Smiling_Eyes-min.png',
    5: 'https://res.cloudinary.com/golance/image/upload/v1714565553/app_new_ui/emoji-icons/Star_Struck-min.png',
  };

  export default {
    components: {
    },
    props: {
      value: {
        type: [Number, String],
        default: 1,
      },
      size: {
        type: Number,
        default: 32,
      },
    },
    computed: {
      imageLink() {
        return valueLinkMap[this.value];
      },
    },
  };
</script>
