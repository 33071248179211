<template>
  <div
    class="select-cointainer"
    :class="{ 'select-disabled': disabled }"
  >
    <gl-field-label
      v-if="labelText"
      :text="labelText"
      :required="required"
      :error="error"
      :note="note"
      :note-container="noteContainer"
      class="label-text"
    />
    <div
      v-if="description"
      class="input-description"
    >
      {{ description }}
    </div>

    <div :class="['input', { 'input-error': error }, { 'input-disabled': disabled }, { 'hide-no-options-message': hideNoOptionsMessage }, { focused: isOpened }, width && `input-width-${width}`]">
      <gl-base-icon
        v-if="withSearchIcon"
        name="search"
        :width="16"
        :height="16"
        color="#C6C8D2"
        class="ml-3"
      />
      <multi-select
        :id="id"
        ref="multiselect"
        :value="value"
        :width="width"
        :options="options"
        :loading="loading"
        :disabled="disabled"
        :allow-empty="allowEmpty"
        :searchable="searchable"
        :select-label="selectLabel"
        :deselect-label="allowEmpty ? deselectLabel : ''"
        :selected-label="selectedLabel"
        :track-by="trackBy"
        :label="label"
        :placeholder="placeholder"
        :internal-search="internalSearch"
        :group-values="groupValues"
        :group-label="groupLabel"
        :group-select="groupSelect"
        :multiple="multiple"
        :close-on-select="!multiple"
        :clear-on-select="!multiple"
        :block-keys="blockKeys"
        :max-height="maxHeight"
        :options-limit="optionsLimit"
        :open-direction="openDirection"
        class="select-picker"
        :class="{ 'fixed-dropdown': isFixed && resizableParent }"
        @input="handleInput"
        @select="handleSelect"
        @search-change="handleSearchChange"
        @remove="handleRemove"
        @focusin.native="handleFocusIn"
        @focusout.native="handleFocusOut"
        @open="handleOpen"
        @close="handleClose"
      >
        <template
          slot="caret"
          slot-scope="scope"
        >
          <div
            v-if="!$scopedSlots.caret"
            :class="{ active: isOpened, invisible: loading }"
            class="dropdown-icon"
            @mousedown.prevent.stop="scope.toggle"
          >
            <gl-base-icon
              name="down-arrow-large"
              :size="16"
            />
          </div>
          <div
            v-if="$scopedSlots.caret"
            :class="{ active: isOpened, invisible: loading }"
            class="dropdown-icon"
          >
            <slot
              v-bind="scope"
              name="caret"
            ></slot>
          </div>
        </template>

        <template
          v-if="$scopedSlots.placeholder"
          slot="placeholder"
          slot-scope="scope"
        >
          <slot
            v-bind="scope"
            name="placeholder"
          ></slot>
        </template>
        <template
          v-if="$scopedSlots.singleLabel"
          slot="singleLabel"
          slot-scope="scope"
        >
          <slot
            v-bind="scope"
            name="singleLabel"
          ></slot>
        </template>

        <template
          v-if="$scopedSlots.selection"
          slot="selection"
          slot-scope="scope"
        >
          <slot
            v-bind="scope"
            name="selection"
          ></slot>
        </template>

        <template
          v-if="$scopedSlots.option"
          slot="option"
          slot-scope="scope"
        >
          <slot
            v-bind="scope"
            name="option"
          ></slot>
        </template>
        <template
          v-if="$scopedSlots.beforeList"
          #beforeList="scope"
        >
          <slot
            v-bind="scope"
            name="beforeList"
          ></slot>
        </template>
        <template
          v-if="$scopedSlots.afterList"
          slot="afterList"
          slot-scope="scope"
        >
          <slot
            v-bind="scope"
            name="afterList"
          ></slot>
        </template>
        <template
          slot="noResult"
          slot-scope="scope"
        >
          <slot
            v-bind="scope"
            name="noResult"
          >
            Nothing is found
          </slot>
        </template>
        <template
          slot="noOptions"
          slot-scope="scope"
        >
          <slot
            v-bind="scope"
            name="noOptions"
          >
            Nothing is found
          </slot>
        </template>
        <template
          v-if="$scopedSlots.placeholder"
          slot="placeholder"
          slot-scope="scope"
        >
          <slot
            v-bind="scope"
            name="placeholder"
          ></slot>
        </template>
      </multi-select>
    </div>
    <div
      v-if="typeof error === 'string' && error"
      class="error-text"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
  import _includes from 'lodash/includes';
  import _isEmpty from 'lodash/isEmpty';
  import _noop from 'lodash/noop';
  import _size from 'lodash/size';

  import MultiSelect from 'vue-multiselect';
  import GlBaseIcon from 'uikit/icons/BaseIcon.vue';
  import GlFieldLabel from 'uikit/components/FieldLabel.vue';

  import screenSizeService from 'uikit/utils/screenSize';

  export default {
    components: {
      MultiSelect,
      GlBaseIcon,
      GlFieldLabel,
    },

    model: {
      event: 'input',
    },

    props: {
      width: {
        type: String,
        default: 'full',
        validator(value) {
          return _includes(['xs', 'md', 'full'], value);
        },
      },
      value: {
        type: [Number, Object, String, Array],
        default: _noop,
      },
      options: {
        type: Array,
        default: () => [],
      },
      id: {
        type: String,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      error: {
        type: [Boolean, String],
        default: null,
      },
      allowEmpty: {
        type: Boolean,
        default: false,
      },
      searchable: {
        type: Boolean,
        default: true,
      },
      selectLabel: {
        type: String,
        default: '',
      },
      deselectLabel: {
        type: String,
        default: 'Clear',
      },
      selectedLabel: {
        type: String,
        default: '',
      },
      trackBy: {
        type: String,
        default: null,
      },
      labelText: {
        type: String,
        default: null,
      },
      label: {
        type: String,
        default: null,
      },
      placeholder: {
        type: String,
        default: 'Select option',
      },
      singleLabel: {
        type: Comment,
        default: null,
      },
      internalSearch: {
        type: Boolean,
        default: true,
      },
      hideNoOptions: {
        type: Boolean,
        default: false,
      },
      groupValues: {
        type: String,
        default: null,
      },
      groupLabel: {
        type: String,
        default: null,
      },
      groupSelect: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      description: {
        type: String,
        default: null,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      blockKeys: {
        type: Array,
        default: () => [],
      },
      maxHeight: {
        type: Number,
        default: 300,
      },
      optionsLimit: {
        type: Number,
        default: 300,
      },
      minSearchSymbolsCount: {
        type: Number,
        default: 0,
      },
      openDirection: {
        type: String,
        default: 'auto',
      },
      fixedDropdown: {
        type: Boolean,
        default: false,
      },
      scrollableParentSelector: {
        type: String,
        default: 'body',
      },
      note: {
        type: String,
        default: '',
      },
      noteContainer: {
        type: String,
        default: 'body',
      },
      withSearchIcon: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        isOpened: false,
        searchQuery: '',
        scrollableParent: null,
        resizableParent: null,
        resizeObserver: null,
        screenSize: screenSizeService.getScreenSize(),
      };
    },
    computed: {
      isMobileScreen() {
        return !this.screenSize.tablet;
      },
      hideNoOptionsMessage() {
        if (this.loading) {
          return true;
        }

        return this.hideNoOptions && _isEmpty(this.options) && _size(this.searchQuery) < this.minSearchSymbolsCount;
      },
      isFixed() {
        return !this.isMobileScreen && this.fixedDropdown;
      },
    },
    mounted() {
      screenSizeService.init();
    },
    beforeDestroy() {
      screenSizeService.cleanup();
      this.resizeObserver?.disconnect();
      this.scrollableParent?.removeEventListener('scroll', this.repositionDropDown);
    },
    methods: {
      handleInput(value, id) {
        this.$emit('input', value, id);
      },
      handleSelect(selectedOption, id) {
        this.$emit('select', selectedOption, id);
      },
      handleSearchChange(searchQuery, id) {
        this.searchQuery = searchQuery;
        this.$emit('search-change', searchQuery, id);
      },
      handleRemove(removedOption, id) {
        this.$emit('remove', removedOption, id);
      },
      handleFocusIn() {
        this.$emit('focusin');
      },
      handleFocusOut() {
        this.$emit('focusout');
      },
      async handleOpen(id) {
        this.$emit('open', id);
        this.isOpened = true;

        await this.$nextTick();
        if (this.isFixed) {
          this.resizableParent = this.$el.closest(this.scrollableParentSelector);

          if (!this.resizableParent) {
            return;
          }

          this.scrollableParent = this.resizableParent === document.body ? document : this.resizableParent;
          this.resizeObserver = new ResizeObserver(this.repositionDropDown);
          this.resizeObserver.observe(this.resizableParent);
          this.resizeObserver.observe(this.$el);

          this.repositionDropDown();
          this.scrollableParent.addEventListener('scroll', this.repositionDropDown);
        }
      },
      handleClose(value, id) {
        this.$emit('close', value, id);
        this.isOpened = false;
        this.resizeObserver?.disconnect();
        this.scrollableParent?.removeEventListener('scroll', this.repositionDropDown);
      },
      repositionDropDown() {
        if (!this.isOpened) {
          return;
        }

        const resizableParentRect = this.resizableParent.getBoundingClientRect();
        const ref = this.$refs.multiselect;
        const {
          top, height, bottom, left,
        } = ref.$el.getBoundingClientRect();

        const offset = 8;
        const { list } = ref.$refs;

        let listDirection = 'bottom';
        const listTopPosition = top + height + offset;
        const listLeftPosition = left;
        const listWidth = ref.$el.clientWidth;
        list.style.bottom = 'auto';
        list.style.top = `${listTopPosition}px`;

        if (listTopPosition + list.offsetHeight > window.innerHeight) {
          const listBottomPosition = window.innerHeight - top + offset;
          listDirection = 'top';
          list.style.top = 'auto';
          list.style.bottom = `${listBottomPosition}px`;
        }

        list.style.width = this.withSearchIcon ? `${listWidth + 30}px` : `${listWidth}px`;
        list.style.position = 'fixed';
        list.style.left = this.withSearchIcon ? `${listLeftPosition - 30}px` : `${listLeftPosition}px`;

        if ((resizableParentRect.top > top + height && listDirection === 'bottom') || (resizableParentRect.bottom < bottom - height && listDirection === 'top')) {
          list.style.opacity = 0;
          list.style.height = 0;
          list.style.padding = 0;
        } else {
          list.style.opacity = null;
          list.style.height = null;
          list.style.padding = null;
        }
      },
      activate() {
        this.$refs.multiselect.activate();
      },
      deactivate() {
        this.$refs.multiselect.deactivate();
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .select-cointainer {
    @apply font-primary text-14 text-black font-normal relative;
    min-width: 0;

    &.select-disabled {
      cursor: not-allowed;
    }

    & .select-picker.fixed-dropdown >>> .multiselect__content-wrapper {
      transition: 0.3s ease opacity;
      max-width: 90vw;
      min-width: 50px;
    }

    & >>> .multiselect__tags {
      @apply font-primary flex items-center p-0;
      background-color: transparent;
      border: none;
      min-height: auto;
      max-height: 36px;
      height: 30px;
      overflow: hidden;
      max-width: calc(100% - 30px);

      & span.multiselect__single {
        @apply text-14 text-text-black bg-transparent m-0;
        font-weight: 500;
        overflow-x: hidden;
        overflow-y: auto;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 8px 12px 8px 12px;
        cursor: pointer;

        & * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      & .multiselect__placeholder,
      & .multiselect__input {
        @apply font-primary text-14 text-text-placeholder font-normal m-0 max-w-full max-h-full;
        padding: 1px 12px 0 12px;
        line-height: 1.4em;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      & .multiselect__placeholder {
        @apply flex items-center;
        gap: 10px;
        & .placeholder {
          gap: 12px;
        }
      }
      & .multiselect__input {
        &::placeholder {
          @apply text-text-placeholder opacity-100;
          width: 100%;
        }
      }
      & .multiselect__spinner {
        height: 98%;
        width: 29px;
        right: 5px;
        &:before,
        &:after {
          border-top-color: #435aae;
        }
      }
    }
    & .hide-no-options-message >>> .multiselect__content-wrapper {
      @apply hidden;
    }
    & >>> .multiselect__content-wrapper {
      @apply min-w-full shadow-dropdown-default;
      padding: 8px 0;
      max-width: 320px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      top: calc(100% + 12px);
      border-radius: 12px;
      border: none;
      & .multiselect__content {
        @apply max-w-full;
      }
      &.multiselect-leave-active .multiselect__content,
      &.multiselect-leave-to .multiselect__content {
        @apply max-w-full;
        & .multiselect__element {
          & .multiselect__option--selected {
            &:after {
              display: none;
            }
          }
        }
      }
    }
    & >>> .multiselect--above .multiselect__content-wrapper {
      bottom: calc(100% + 8px);
      top: auto;
      border-radius: 12px;
    }
    & >>> .multiselect--active:not(.multiselect--above) .multiselect__current,
    & >>> .multiselect--active:not(.multiselect--above) .multiselect__input,
    & >>> .multiselect--active:not(.multiselect--above) .multiselect__tags {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    & .select-picker {
      @apply relative text-text-black text-14;
      min-height: auto;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      &:focus {
        @apply border-blue-liberty;
      }

      & .dropdown-icon {
        @apply text-icon-default relative cursor-pointer ml-2 flex justify-center items-center;
        z-index: 20;
        right: 12px;

        & .icon {
          transition: 0.3s;
        }
        &.active {
          & .icon {
            @apply text-icon-hover;
            transform: rotate(-180deg);
          }
        }
      }

      & >>> .multiselect__element {
        & .multiselect__option {
          @apply bg-white text-text-black;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 16px;
          transition: 0.3s ease;
        }

        & .multiselect__option--selected {
          @apply bg-foundation-gray-2 font-normal;
          &:after {
            @apply inline-flex items-center justify-center;
            background: #ff6a6a;
            content: attr(data-deselect);
            color: white;
            line-height: 48px;
          }
        }
        & .multiselect__option--highlight {
          @apply bg-foundation-gray-2;
        }
      }
      & .option {
        @apply flex items-center max-w-full text-14;
        & .checkbox {
          @apply flex justify-center items-center;
          margin-right: 12px;
          min-height: 16px;
          min-width: 16px;
          height: 16px;
          width: 16px;
          border: 1px solid #b8bdd7;
          border-radius: 2px;
        }
        & .active {
          @apply bg-blue border border-blue;
        }
      }
    }
    & >>> .multiselect--active {
      & .multiselect__tags {
        @apply border-none;
        width: 96%;
      }
    }
    & .input-width-xs {
      width: 180px;
    }
    & .input-width-md {
      width: 215px;
    }
    & .input-width-full {
      width: 100%;
    }

    & .input {
      @apply flex flex-1 border items-center border-foundation-gray-4;
      border-radius: 8px;
      max-height: 40px;
      height: 40px;
      transition: 0.2s ease border;
      &:hover {
        @apply border-foundation-gray-6;
      }
      &.focused {
        @apply border-royal-blue;
      }
    }

    & .input-error,
    & .input-error:hover {
      @apply border-persian-red;
    }

    & .input-disabled {
      @apply border-foundation-gray-4 bg-foundation-gray-3 pointer-events-none;

      cursor: not-allowed;

      & .multiselect--disabled {
        @apply bg-foundation-gray-3;
      }

      & >>> .multiselect__tags {
        background: transparent;
        max-width: 100%;
      }
    }

    & .with-search-icon {
    }
  }

  .label-text {
    max-width: 100%;
  }

  .input-description {
    @apply font-primary text-base text-comet block text-12 mb-2;
  }

  .error-text {
    @apply font-primary text-text-error text-12 mt-1;
    line-height: 18px;
  }
</style>
