import sanitizer from '@/services/sanitizer';
import unescapeHtml from '@/services/unescapeHtml';

const getInputElement = el => {
  if (el.tagName.toLowerCase() === 'textarea' || el.tagName.toLowerCase() === 'input') {
    return el;
  }

  return el.getElementsByTagName('INPUT')[0] || el.getElementsByTagName('TEXTAREA')[0];
};

export default {
  inserted(el, binding, vnode) {
    const input = getInputElement(el);

    input.oninput = () => {
      vnode.context[binding.expression] = sanitizer.sanitizeHtml(input.value);
    };

    input.value = unescapeHtml(binding.value);
  },
};
