<template>
  <label
    v-if="text"
    class="input-label"
  >
    {{ text }}
    <span
      v-if="required"
      class="required"
    >*</span>
    <gl-base-icon
      v-if="note"
      v-tooltip="{
        content: note,
        container: noteContainer,
        classes: 'tooltip-white',
        trigger: 'hover',
        offset: 5,
        position: 'top-end',
        html: true,
      }"
      name="filled-rounded-info"
      size="12"
      color="#6F7689"
    />
  </label>
</template>

<script>
  import GlBaseIcon from '../icons/BaseIcon.vue';

  export default {
    components: {
      GlBaseIcon,
    },
    props: {
      text: {
        type: String,
        required: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      note: {
        type: String,
        default: '',
      },
      noteContainer: {
        type: String,
        default: null,
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .input-label {
    @apply inline-flex items-center font-primary text-base text-text-weak text-14 mb-1;
    line-height: 20px;
    letter-spacing: 0.0025em;
    width: max-content;
    gap: 4px;
  }
  .required {
    @apply text-red;
  }
</style>
