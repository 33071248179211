import _round from 'lodash/round';
import _includes from 'lodash/includes';

// @todo: Take from config instead
import { cryptoCurrencies } from '@/constants/withdrawal';

export default {
  // XXX: 16.4 * 100 = 1639.9999999999998
  //      16.4 * 10 * 10 = 1640
  toCents(dollars) {
    return _round(dollars * 10 * 10);
  },
  toDollars(cents) {
    return _round(cents / 100, 2);
  },
  isCryptoCurrency(currency) {
    return _includes(cryptoCurrencies, currency);
  },
};
