import stringContainsUrl from '@/services/stringContainsUrl';
import htmlText from '@/services/htmlText';
import currencyFilter from '@/filters/currency';
import formatDate from '@/filters/formatDate';

function transformToCurrencyValue(val, currency = 'USD') {
  return currencyFilter(val, { convert: false, currency });
}

export const amountValidation = {
  max_value: (fieldName, value) => (fieldName ? `${fieldName} can't be more than ${transformToCurrencyValue(...value)}` : `Maximum amount is ${transformToCurrencyValue(...value)}`),
  min_value: (fieldName, value) => (fieldName ? `${fieldName} can't be less than ${transformToCurrencyValue(...value)}` : `Minimum amount is ${transformToCurrencyValue(...value)}`),
};

export default {
  custom: {
    Amount: amountValidation,
    'Payment Amount': amountValidation,
    'First Payment Amount': amountValidation,
    Attachments: {
      required: () => 'At least 1 attachment is required',
    },
    Skills: {
      required: () => 'At least 1 skill is required',
    },
    Rate: {
      required: 'Please enter subcontract rate',
      max_value: (_, value) => `Subcontract rate should not exceed offer rate ($${value}/hr)`,
      min_value: (_, value) => `Subcontract rate should be more than $${value}/hr`,
    },
    'Ends After': {
      max_value: (_, [number, cycleName]) => `The maximum number of payments in a ${cycleName} cycle is ${number}`,
    },
    'Confirm Tax Form': {
      requiredBool: () => 'Confirm the accuracy of your information',
    },
  },
  messages: {
    min: (_, value) => `Must be at least ${value} characters`,
    max: (_, value) => `Must be ${value} characters or fewer`,
    max_value: (fieldName, value) => `${fieldName} can't be more than ${value}`,
    min_value: (fieldName, value) => `${fieldName} can't be less than ${value}`,
    required: fieldName => `${fieldName} is required`,
    is: fieldName => `${fieldName} is not valid`,
    email: () => 'Email is not valid',
    alpha_dash: fieldName => `${fieldName} must contain alphabetic characters, numbers, dashes or underscores`,
    url: fieldName => `${fieldName} is not valid URL`,
    regex: fieldName => `${fieldName} field format is invalid`,
  },
  rules: {
    maxHtml: {
      getMessage: (_, value) => `Must be ${value} characters or fewer`,
      validate: (value, args) => {
        const [maxCount] = args;
        const characterCount = htmlText.countCharacters(value);
        return characterCount <= maxCount;
      },
    },
    minHtml: {
      getMessage: (_, value) => `Must be ${value} characters or greater`,
      validate: (value, args) => {
        const [minCount] = args;
        const characterCount = htmlText.countCharacters(value);
        return characterCount >= minCount;
      },
    },
    requiredHtml: {
      getMessage: fieldName => `${fieldName} is required`,
      validate: value => {
        const characterCount = htmlText.countCharacters(value);
        return !!characterCount;
      },
    },
    requiredBool: {
      getMessage: fieldName => `${fieldName} is required`,
      validate: value => value,
    },
    minDate: {
      getMessage: (_, value) => `Must be since ${formatDate(value)}`,
      validate: (value, args) => {
        const [minDate] = args;
        return new Date(value) >= new Date(minDate);
      },
    },
    maxDate: {
      getMessage: (_, value) => `Must not be later than ${formatDate(value)}`,
      validate: (value, args) => {
        const [maxDate] = args;
        return new Date(maxDate) >= new Date(value);
      },
    },
    withoutUrl: {
      validate: value => !stringContainsUrl(value),
      getMessage: fieldName => `${fieldName} should not contain urls`,
    },
    strictRegexp: {
      validate: (value, rules) => {
        const regexp = new RegExp(rules?.pattern || rules);

        const match = value.match(regexp);

        return match?.[0] === value;
      },
      getMessage: (fieldName, rules) => rules?.message || `${fieldName} field format is invalid`,
    },
    alphanumeric: {
      getMessage: fieldName => `Please enter a valid alphanumeric ${fieldName}`,
      validate: value => {
        const regex = /^[A-Za-zА-Яа-я0-9-]+$/;
        return regex.test(value);
      },
    },
    twoDecimal: {
      getMessage: fieldName => `Please enter a valid ${fieldName} (up to two decimal places)`,
      validate: value => {
        const regex = /^\d+(\.\d{1,2})?$/;
        return regex.test(value);
      },
    },
  },
};
