<template>
  <gl-icon-button
    v-bind="$attrs"
    :size="size"
    :icon-name="icon"
    :icon-size="iconSize"
    name="close"
    @click="$emit('click', $event)"
  />
</template>

<script>
  import _includes from 'lodash/includes';
  import GlIconButton from 'uikit/components/buttons/IconButton.vue';

  export default {
    components: {
      GlIconButton,
    },
    props: {
      size: {
        type: [Number, String],
        default: 24,
      },
      icon: {
        type: String,
        default: 'cancel',
        validator(value) {
          return _includes(['close', 'cancel'], value);
        },
      },
      iconSize: {
        type: Number,
        default: 20,
      },
    },
  };
</script>
