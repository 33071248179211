import { render, staticRenderFns } from "./HeaderPopup.vue?vue&type=template&id=5b0911f1&scoped=true"
var script = {}
import style0 from "./HeaderPopup.vue?vue&type=style&index=0&id=5b0911f1&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b0911f1",
  null
  
)

export default component.exports