<template>
  <modal-window
    :show-close-icon="false"
    :scrollable="false"
    class="regular-modal-window"
  >
    <template #topControls>
      <slot name="topControls"></slot>
    </template>
    <template #header>
      <modal-header
        :title="title"
        :subtitle="subtitle"
        :show-back-icon="showBackIcon"
        :show-close-icon="showCloseIcon"
        @close="$emit('close')"
        @back="$emit('back')"
      >
        <template #title>
          <slot name="title"></slot>
        </template>
        <template #subtitle>
          <slot name="subtitle"></slot>
        </template>
      </modal-header>
    </template>
    <template #body>
      <modal-body
        v-if="$slots.body"
        :scrollable="scrollable"
        :smooth-resize="smoothResize"
      >
        <slot name="body"></slot>
      </modal-body>
    </template>
    <template #footer>
      <modal-footer v-if="$slots.footer">
        <slot name="footer"></slot>
      </modal-footer>
    </template>
  </modal-window>
</template>

<script>
  import ModalWindow from './ModalWindow.vue';
  import ModalHeader from './RegularModalHeader.vue';
  import ModalBody from './RegularModalBody.vue';
  import ModalFooter from './RegularModalFooter.vue';

  export default {
    components: {
      ModalWindow,
      ModalHeader,
      ModalBody,
      ModalFooter,
    },
    props: {
      showCloseIcon: {
        type: Boolean,
        default: true,
      },
      scrollable: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
        default: null,
      },
      subtitle: {
        type: String,
        default: null,
      },
      showBackIcon: {
        type: Boolean,
        default: false,
      },
      smoothResize: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .regular-modal-window.modal-window {
    padding: 0;
    @apply font-primary;
  }
</style>
