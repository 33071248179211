<template>
  <table-wrapper
    v-slot="{ gridTemplateColumns }"
    ref="wrapper"
    :grid-columns-width="gridColumnsWidth"
    :selectable="selectable"
    :expandable-rows="expandableRows"
    :inline-actions="inlineActions"
    :columns="columns"
    :editable="editable"
  >
    <div
      ref="header"
      class="header"
      :style="{ '--table-col-width': gridTemplateColumns }"
      :class="{ compact, 'editable-columns': editable, 'with-actions': inlineActions, scrolled, 'with-expandable-rows': expandableRows }"
    >
      <div
        v-if="selectable"
        class="header__select-action"
      >
        <gl-checkbox
          :value="isSelected"
          :indeterminate="isIndeterminate"
          @change="$emit('selectAll', $event)"
        />
      </div>
      <div
        v-for="({ title, main, field, sort, type, note, headerIcon }, idx) in columns"
        ref="cells"
        :key="field"
        class="header__cell"
        :class="[type || '', { main: main, first: idx === 0, resizable, resizing: cellBeingResized.field === field }]"
      >
        <slot
          name="header"
          :field="field"
          :title="title"
          :type="type"
          :icon="headerIcon"
          :sort-enabled="sort"
          :sort="sortedBy.field === field ? sortedBy.direction : null"
          :sort-change-handler="handleSortChange"
        >
          <column-header
            :title="title"
            :type="type"
            :icon="headerIcon"
            :note="note"
            :sort-enabled="sort"
            :sort="sortedBy.field === field ? sortedBy.direction : null"
            @sortChange="handleSortChange($event, field)"
          />
        </slot>
        <span
          v-if="resizable"
          class="cell__resize-handle resize-handle"
          @mousedown="handleResizeHandleMouseDown(field, $event)"
          @dblclick="handleResizeHandleDblClick(field, $event)"
        ></span>
      </div>
      <div
        v-if="inlineActions && !editable"
        class="header__inline-actions"
      ></div>
      <div
        v-if="editable"
        class="header__edit-table"
        :class="{ 'with-inline-actions': inlineActions }"
      >
        <edit-table-button
          class="edit-table__button"
          @click="$emit('editStart')"
        />
      </div>
    </div>
  </table-wrapper>
</template>

<script>
  import _throttle from 'lodash/throttle';
  import GlCheckbox from 'uikit/components/Checkbox/index.vue';

  import ColumnHeader from './ColumnHeader.vue';
  import TableWrapper from './TableWrapper.vue';
  import EditTableButton from './EditTableButton.vue';

  const DEFAULT_CELL_BEING_RESIZED = {
    index: -1,
    node: null,
    field: null,
  };

  const DEFAULT_MIN_WIDTH = 80;
  const DEFAULT_MAX_WIDTH = 600;

  export default {
    components: {
      GlCheckbox,
      ColumnHeader,
      TableWrapper,
      EditTableButton,
    },
    props: {
      columns: {
        type: Array,
        required: true,
      },
      sortedBy: {
        type: Object,
        default: () => ({}),
      },
      selectable: {
        type: Boolean,
        default: true,
      },
      expandableRows: {
        type: Boolean,
        default: false,
      },
      editable: {
        type: Boolean,
        default: true,
      },
      resizable: {
        type: Boolean,
        default: false,
      },
      scrolled: {
        type: Boolean,
        default: false,
      },
      inlineActions: {
        type: [Boolean, Array],
        default: false,
      },
      selectedCount: {
        type: Number,
        default: 0,
      },
      pageRecordsCount: {
        type: Number,
        default: 0,
      },
      compact: {
        type: Boolean,
        default: false,
      },
      gridColumnsWidth: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        cellBeingResized: { ...DEFAULT_CELL_BEING_RESIZED },
        resizedColumns: null,
      };
    },
    computed: {
      isSelected() {
        return !!this.selectedCount;
      },
      isIndeterminate() {
        return !!(this.selectedCount && this.selectedCount < this.pageRecordsCount);
      },
    },
    methods: {
      handleSortChange(val, field) {
        this.$emit('sortChange', field, val);
      },
      handleResizeHandleMouseDown(field, ev) {
        this.$emit('columnsResizeStart');

        this.cellBeingResized = {
          field,
          index: this.columns.findIndex(col => col.field === field),
          node: ev.target.parentNode,
        };

        window.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener('mouseup', this.handleMouseUp);
        window.addEventListener('blur', this.handleMouseUp);
      },
      handleMouseUp() {
        this.cellBeingResized = { ...DEFAULT_CELL_BEING_RESIZED };

        if (this.resizedColumns) {
          this.$emit('columnsResizeEnd', this.resizedColumns);
          this.resizedColumns = null;
        }

        window.removeEventListener('mousemove', this.handleMouseMove);
        window.removeEventListener('mouseup', this.handleMouseUp);
        window.removeEventListener('blur', this.handleMouseUp);
      },
      handleMouseMove: _throttle(function handleMouseMove(ev) {
        if (!this.cellBeingResized.node) {
          return;
        }

        const { x } = this.cellBeingResized.node.getBoundingClientRect();

        const newWidth = ev.clientX - x;

        const resizedColumns = this.calculateResizedColumns(newWidth, this.cellBeingResized.field);
        this.resizedColumns = resizedColumns;
        const gridTemplateColumns = this.$refs.wrapper.calculateGridTemplateColumns(resizedColumns);

        this.$emit('columnsResize', gridTemplateColumns);
      }, 100),
      calculateResizedColumns(newResizingCellWidth, resizingCellField) {
        const staticColumnRegex = /^\d+px/;
        const { header } = this.$refs;
        const headerCells = header.querySelectorAll('.header__cell');

        const adjustedColumns = this.columns.map((col, i) => {
          let { width } = col;

          if (!(col.width || '').match(staticColumnRegex)) {
            const columnNode = headerCells[i];
            width = `${columnNode.offsetWidth}px`;
          }

          if (col.field === resizingCellField) {
            const minWidth = col.minWidth || DEFAULT_MIN_WIDTH;
            const maxWidth = col.maxWidth || DEFAULT_MAX_WIDTH;
            const adjustedWidth = Math.round(Math.min(Math.max(newResizingCellWidth, minWidth), maxWidth));

            width = `${adjustedWidth}px`;
          }

          return {
            ...col,
            width,
          };
        });

        return adjustedColumns;
      },
      handleResizeHandleDblClick(field, ev) {
        const headerCellElement = ev.target.parentNode;
        const tableComponent = headerCellElement.closest('.table-component');
        const tableRows = tableComponent.querySelector('.table__rows');

        const index = this.columns.findIndex(col => col.field === field);

        const resizingCells = tableRows ? tableRows.querySelectorAll(`.table-cell:nth-child(${index + 1})`) : [];

        const dataCellWidths = [...resizingCells].map(el => this.getResizingCellMaxWidth(el));
        const headerCellWidth = this.getResizingCellMaxWidth(headerCellElement);

        const widths = [headerCellWidth, ...dataCellWidths];

        const maxWidth = Math.max(...widths);

        const resizedColumns = this.calculateResizedColumns(maxWidth, field);
        this.$emit('columnsResizeEnd', resizedColumns);
      },
      getResizingCellMaxWidth(el) {
        const clone = el.cloneNode(true);
        clone.style.position = 'absolute';
        clone.style.opacity = 0;
        clone.style.width = 'fit-content';

        el.parentElement.appendChild(clone);

        const width = clone.scrollWidth + 5; // added just a little bit of space here

        el.parentElement.removeChild(clone);

        return width;
      },
    },
  };
</script>

<style lang="postcss" scoped>
.header {
  @apply font-primary bg-foundation-gray-4 font-semibold text-14 relative;
  display: grid;
  grid-template-columns: var(--table-col-width);
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 8px;
  height: 44px;
  border-radius: 12px;
  justify-content: flex-start;
  transition: .1s linear;

  &.with-actions, &.editable-columns, &.editable-columns.with-expandable-rows {
    padding-right: 8px;
  }

  &.with-expandable-rows {
    padding: 0 24px 0 4px;

    & .header__cell.first {
      padding-left: 56px;
    }
  }

  &.compact {
    border-radius: 12px 12px 0px 0px;
    margin-bottom: 0;
  }
}

.header__cell {
  transition: .3s linear;
  -webkit-font-smoothing: antialiased;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 12px;

  &.number {
    justify-content: flex-end;
  }

  &.centered {
    justify-content: center;
  }

  &.resizable:hover {
    & .cell__resize-handle {
      @apply bg-foundation-gray-6;
      transform: scale(0.5, 1);
      border-radius: 0;
    }
  }

  &.resizable.resizing {
    & .cell__resize-handle {
      @apply bg-royal-blue;
      transform: scale(0.5, 1);
      border-radius: 0;
    }
  }
}

.cell__resize-handle {
  background: #d3d5dc;
  position: absolute;
  right: 0;
  width: 8px;
  transform: scale(0.3, 0.6);
  border-radius: 4px;
  height: 100%;
  cursor: col-resize;
  transition: .3s ease;
  user-select: none;
}

.header__select-action {
  flex: 0 0 28px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 13px;
}

.header__inline-actions {
  width: 64px;
  height: 100%;
}

.header__edit-table {
  @apply flex items-center justify-center;
  position: absolute;
  right: 8px;
  top: 0;
  background: inherit;
  width: 44px;
  height: 100%;

  &.with-inline-actions {
    width: 64px;
  }
}

.header.scrolled .header__edit-table {
  @apply justify-center;
  position: sticky;
  box-shadow: -4px 0px 7px -2px rgba(0, 0, 0, 0.12);
  animation: sticky .3s ease forwards;
}

@keyframes sticky {
  0% {
    right: -64px;
  }

  100% {
    right: -1px;
  }
}

@media screen and (max-width: 576px) {
  .header {
    display: none;

    &.with-expandable-rows {
      display: grid;
    }
  }
}
</style>
