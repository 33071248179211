<template>
  <div
    class="global-notifications"
  >
    <billing-method-expiration
      v-if="!isBillingMethodExpirationHidden"
      @close="handleClose('billingMethodExpiration')"
    />
    <freelancer-time-tracking-restriction
      v-if="!isTimeTrackingRestrictionHidden"
      @close="handleClose('timeTrackingRestriction')"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import SessionStorageService from '@/services/infrastructure/sessionStorageService';

  import BillingMethodExpiration from './BillingMethodExpiration.vue';
  import FreelancerTimeTrackingRestriction from './FreelancerTimeTrackingRestriction.vue';

  const storageKeys = {
    timeTrackingRestriction: 'freelancer_time_tracking_restriction_notification',
    billingMethodExpiration: 'billing_method_expiration',
  };

  const billingMethodsPage = 'gowallet.payment-methods';

  export default {
    components: {
      BillingMethodExpiration,
      FreelancerTimeTrackingRestriction,
    },
    data() {
      return {
        sessionStorage: null,
        hiddenByUser: {
          timeTrackingRestriction: false,
          billingMethodExpiration: false,
        },
      };
    },
    computed: {
      ...mapGetters(['currentUser', 'permissions']),
      isTimeTrackingRestrictionHidden() {
        const isNotificationShown = false; // TO DO: Fetch from backend
        return !isNotificationShown || !this.permissions.isFreelancer || this.hiddenByUser.timeTrackingRestriction;
      },
      isBillingMethodExpirationHidden() {
        return (this.$route.name === billingMethodsPage) || this.hiddenByUser.billingMethodExpiration;
      },
    },
    created() {
      this.sessionStorage = new SessionStorageService(this.currentUser._id);

      Object.entries(storageKeys).forEach(([key, value]) => {
        this.hiddenByUser[key] = this.sessionStorage.getItem(value) || false;
      });
    },
    methods: {
      handleClose(key) {
        this.sessionStorage.setItem(storageKeys[key], true);
        this.hiddenByUser[key] = true;
      },
    },
  };
</script>
