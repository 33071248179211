<template>
  <router-link
    v-if="to"
    :to="to"
    :target="target"
    class="info-pill link clickable"
    :class="[size, color]"
    @click="handleClick"
  >
    <slot></slot>
  </router-link>
  <div
    v-else
    class="info-pill"
    :class="[size, color, { clickable: !!$listeners.click }]"
    @click="handleClick"
  >
    <slot></slot>
  </div>
</template>

<script>
  import _includes from 'lodash/includes';

  export default {
    props: {
      to: {
        type: Object,
        default: null,
      },
      target: {
        type: String,
        default: '_blank',
      },
      size: {
        type: String,
        default: 'm',
        validator(value) {
          return _includes(['m', 's'], value);
        },
      },
      color: {
        type: String,
        default: 'gray',
        validator(value) {
          return _includes(['gray', 'white'], value);
        },
      },
    },
    methods: {
      handleClick(ev) {
        this.$emit('click', ev);
      },
    },
  };
</script>

<style lang="postcss" scoped>
.info-pill {
  @apply inline-flex items-center bg-foundation-gray-2 font-primary font-medium text-14 text-text-black;
  vertical-align: middle;
  padding: 4px 10px;
  border-radius: 8px;
  line-height: 20px;
  width: fit-content;
  transition: 0.3s ease all;

  &.white {
    @apply bg-white;
  }

  &.clickable {
    @apply cursor-pointer;

    &:hover {
      @apply text-text-link;
    }
  }

  &.s {
    padding: 2px 8px;
  }
}
</style>
