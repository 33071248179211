<template>
  <div class="link-container">
    <div
      v-if="textTitle"
      class="title"
    >
      {{ textTitle }}
    </div>
    <a
      v-else
      class="title"
      :target="linkTitle.target || '_self'"
      :href="linkTitle.url"
    >
      {{ linkTitle.name }}
    </a>
    <a
      v-for="(link, index) in preparedLinks"
      :key="index"
      :target="link.target || '_self'"
      :href="link.url"
      class="link"
    >
      {{ link.name }}
    </a>
  </div>
</template>

<script>
  import _filter from 'lodash/filter';

  export default {
    props: {
      textTitle: {
        type: String,
        required: false,
        default: null,
      },
      links: {
        type: Array,
        required: true,
      },
      linkTitle: {
        type: Object,
        required: false,
        default: null,
      },
    },
    computed: {
      preparedLinks() {
        return _filter(this.links, item => !('show' in item) || item.show);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .link-container {
    @apply flex flex-col;
    min-width: 180px;
    margin-top: 10px;

    & .title {
      @apply text-white text-16 font-semibold;
      line-height: 24px;
      margin-bottom: 18px;
    }

    & .link {
      @apply text-14 text-fog;
      margin-bottom: 16px;
      line-height: 26px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 991px) {
    .link-container {
      max-width: 180px;
      min-width: auto;
    }
  }
</style>
