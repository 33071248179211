const CRYPTO_CURRENCY = {
  BTC: 'BTC',
  ETH: 'ETH',
  USDC: 'USDC',
  USDT: 'USDT',
  USDT_TRC20: 'USDT_TRC20',
};

const cryptoCurrencies = Object.values(CRYPTO_CURRENCY);

const cryptoCurrencyNames = {
  BTC: 'BTC',
  ETH: 'ETH',
  USDC: 'USDC',
  USDT: 'USDT',
  USDT_TRC20: 'USDT',
};

const cryptoCurrencyNamesWithNetworks = {
  BTC: 'BTC',
  ETH: 'ETH',
  USDC: 'USDC (ERC-20)',
  USDT: 'USDT (ERC-20)',
  USDT_TRC20: 'USDT (TRC-20)',
};

const cryptoCurrencyFullNames = {
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  USDC: 'US Dollar Coin (ERC-20)',
  USDT: 'Tether USD (ERC-20)',
  USDT_TRC20: 'Tether USD (TRC-20)',
};

const cryptoCurrencyNetworks = {
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  USDC: 'Ethereum (ERC-20)',
  USDT: 'Ethereum (ERC-20)',
  USDT_TRC20: 'Tron (TRC-20)',
};

const cryptoCurrencyIcons = {
  BTC: 'bitcoin',
  ETH: 'ethereum',
  USDC: 'usdc',
  USDT: 'tether-erc-20',
  USDT_TRC20: 'tether-trc-20',
};

const stableCryptoCurrencies = ['USDC', 'USDT', 'USDT_TRC20'];

export {
  CRYPTO_CURRENCY,
  cryptoCurrencies,
  cryptoCurrencyFullNames,
  cryptoCurrencyNetworks,
  stableCryptoCurrencies,
  cryptoCurrencyNames,
  cryptoCurrencyNamesWithNetworks,
  cryptoCurrencyIcons,
};
