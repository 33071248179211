export default {
  goWallet: { name: 'gowallet' },
  jobManager: { name: 'job-manager' },
  freelancerJobManager: { name: 'freelancer-job-manager' },
  findFreelancer: {
    name: 'gohire',
  },
  serviceMarketplace: {
    name: 'service-marketplace',
  },
  serviceManager: {
    name: 'service-manager.services',
  },
  getHired: {
    name: 'gowork',
  },
};
