import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import config from '@/config';

export default s3 => {
  if (_isString(s3)) {
    return `${config.S3BucketUrl}${s3}`;
  }

  if (_isObject(s3)) {
    if (s3.bucket) {
      return `https://${s3.bucket}.s3.amazonaws.com/${s3.key}`;
    }

    return `${config.S3BucketUrl}${s3.key}`;
  }

  return '';
};
