import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import client from './client';

const BASE_URL = '/files';

export default {
  getYoutubeVideo: async id => {
    const response = await client.get([BASE_URL, 'youtube', id]);
    const video = response.data.items[0];

    if (video) {
      const thumbnails = video.snippet.thumbnails || {};
      const thumbVersion = _find(['maxres', 'standart', 'high', 'medium', 'default'], version => thumbnails[version]);

      const url = thumbnails[thumbVersion];
      const { title } = video.snippet;

      return { url, title };
    }
    return {};
  },
  getVimeoVideo: async id => {
    const response = await client.get([BASE_URL, 'vimeo', id]);

    const thumbnailUrl = response.thumbnail_url;
    const { title } = response;

    return { thumbnailUrl, title };
  },
  getSoundcloudAudio: async soundcloudTrackUrl => {
    try {
      // Right holders have the option to disable API requests
      let response = await client.get([BASE_URL, 'soundcloud'], { soundcloudTrackUrl });

      if (response.kind && response.kind === 'playlist' && !_isEmpty(response.tracks)) {
        response = response.tracks[0];
      }

      const waveformUrl = response.waveform_url;
      const { title } = response;
      const { id } = response;

      return { waveformUrl, title, id };
    } catch (error) {
      return {};
    }
  },

  uploadFileFromUrl: (fileUrl, fileName) => client.post([BASE_URL, 'upload'], { fileUrl, fileName }),

  uploadFile: file => client.postFile([BASE_URL, 'upload'], file),
};
