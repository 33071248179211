import { getUserPermissions, getCompanyProfileRouteToRedirect, redirectToCompaniesListRoute } from '../routerServices';

const PublicCompany = () => import(/* webpackChunkName: "PublicCompany" */ '@/views/Company/ClientCompany/PublicCompany');
const PublicCompanyOpenJobs = () => import(/* webpackChunkName: "PublicCompany" */ '@/views/Company/ClientCompany/PublicCompany/OpenJobs');
const PublicCompanyReviews = () => import(/* webpackChunkName: "PublicCompany" */ '@/views/Company/ClientCompany/PublicCompany/Reviews');

const MyCompanies = () => import(/* webpackChunkName: "MyCompanies" */ '@/views/Company/ClientCompany/MyCompanies');
const CompanyHome = () => import(/* webpackChunkName: "CompanyHome" */ '@/views/Company/ClientCompany/Home');
const CompanyHomeOverview = () => import(/* webpackChunkName: "CompanyHome" */ '@/views/Company/ClientCompany/Home/Overview');
const CompanyHomeMembers = () => import(/* webpackChunkName: "CompanyHome" */ '@/views/Company/ClientCompany/Home/Members');
const CompanyHomeTeams = () => import(/* webpackChunkName: "CompanyHome" */ '@/views/Company/ClientCompany/Home/Teams');
const CompanyHomeFinance = () => import(/* webpackChunkName: "CompanyHome" */ '@/views/Company/ClientCompany/Home/Finance');
const CompanyHomeContracts = () => import(/* webpackChunkName: "CompanyHome" */ '@/views/Company/ClientCompany/Home/Contracts');
const CompanyHomeContractsAll = () => import(/* webpackChunkName: "CompanyHome" */ '@/views/Company/ClientCompany/Home/Contracts/All');
const CompanyHomeContractsFixed = () => import(/* webpackChunkName: "CompanyHome" */ '@/views/Company/ClientCompany/Home/Contracts/Fixed');
const CompanyHomeContractsHourly = () => import(/* webpackChunkName: "CompanyHome" */ '@/views/Company/ClientCompany/Home/Contracts/Hourly');
const CompanyHomeOffers = () => import(/* webpackChunkName: "CompanyHome" */ '@/views/Company/ClientCompany/Home/Offers');
const CompanyHomeJobs = () => import(/* webpackChunkName: "CompanyHome" */ '@/views/Company/ClientCompany/Home/Jobs');
const CompanyHomeActivityCodesManagement = () => import(/* webpackChunkName: "ActivityCodes" */ '@/views/Company/ClientCompany/Home/ActivityCodes');
const CompanyHomeActivityCodes = () => import(/* webpackChunkName: "ActivityCodes" */ '@/views/Company/ClientCompany/Home/ActivityCodes/ActivityCodes');
const CompanyHomeActivitySets = () => import(/* webpackChunkName: "ActivityCodes" */ '@/views/Company/ClientCompany/Home/ActivityCodes/ActivitySets');
const CompanyHomeReviews = () => import(/* webpackChunkName: "CompanyHome" */ '@/views/Company/ClientCompany/Home/Reviews');
const CompanyHomeTimeOffPolicies = () => import(/* webpackChunkName: "CompanyHome" */ '@/views/Company/ClientCompany/Home/TimeOffPolicies');

const CompanyTeam = () => import(/* webpackChunkName: "CompanyTeam" */ '@/views/Team');
const CompanyInvitation = () => import(/* webpackChunkName: "CompanyInvitation", webpackPrefetch: true */ '@/views/CompanyInvitation');

export default [
  {
    name: 'companies',
    path: '/companies',
    component: MyCompanies,
    beforeEnter: (to, from, next) => redirectToCompaniesListRoute(to, next),
  },
  {
    name: 'company', // Route to redirect to agency or client company
    path: '/company/:companyId',
    meta: { requiresAuth: false },
    beforeEnter: async (to, from, next) => {
      const companyRoute = await getCompanyProfileRouteToRedirect(to.params?.companyId);
      next({
        query: to.query,
        ...companyRoute,
      });
    },
  },
  {
    name: 'public-company',
    path: '/companies/:companyId',
    component: PublicCompany,
    props: route => ({ edit: String(route.query.edit) === 'true' }),
    meta: { requiresAuth: false },
    children: [
      {
        name: 'public-company.old-about',
        path: 'about',
        redirect: to => ({ name: 'public-company', params: to.params, query: to.query }),
      },
      {
        name: 'public-company.open-jobs',
        path: 'open-jobs',
        component: PublicCompanyOpenJobs,
      },
      {
        name: 'public-company.reviews',
        path: 'reviews',
        component: PublicCompanyReviews,
      },
    ],
  },

  {
    name: 'company-home',
    path: '/companies/:companyId/home',
    component: CompanyHome,
    beforeEnter: (to, from, next) => {
      const companyId = to.params?.companyId;
      const permissions = getUserPermissions();

      if (!companyId) {
        return next({ name: 'companies' });
      }

      if (!permissions.isCompanyMember(companyId)) {
        return next({
          name: 'public-company',
          params: { companyId },
        });
      }

      return next();
    },
    redirect: to => ({ name: 'company-home.overview', params: to.params }),
    children: [
      {
        name: 'company-home.overview',
        path: '',
        component: CompanyHomeOverview,
      },
      {
        name: 'company-home.old-overview',
        path: 'overview',
        redirect: to => ({ name: 'company-home.overview', params: to.params }),
      },
      {
        name: 'company-home.members',
        path: 'members',
        component: CompanyHomeMembers,
      },
      {
        name: 'company-home.teams',
        path: 'teams',
        component: CompanyHomeTeams,
      },
      {
        name: 'company-home.finance',
        path: 'finance',
        component: CompanyHomeFinance,
      },
      {
        name: 'company-home.time-off-policies',
        path: 'policies',
        component: CompanyHomeTimeOffPolicies,
      },
      {
        name: 'company-home.contracts',
        path: 'contracts',
        component: CompanyHomeContracts,
        children: [
          {
            name: 'company-home.contracts.all',
            path: 'all',
            component: CompanyHomeContractsAll,
          },
          {
            name: 'company-home.contracts.hourly',
            path: 'hourly',
            component: CompanyHomeContractsHourly,
          },
          {
            name: 'company-home.contracts.fixed',
            path: 'fixed',
            component: CompanyHomeContractsFixed,
          },
        ],
      },
      {
        name: 'company-home.offers',
        path: 'offers',
        component: CompanyHomeOffers,
      },
      {
        name: 'company-home.jobs',
        path: 'jobs',
        component: CompanyHomeJobs,
      },
      {
        name: 'company-home.activity-management',
        path: 'activity-management',
        component: CompanyHomeActivityCodesManagement,
        beforeEnter: (to, from, next) => {
          const companyId = to.params?.companyId;
          const permissions = getUserPermissions();

          if (!permissions.canManageActivityCodes(companyId)) {
            return next({
              name: 'company-home.overview',
              params: { companyId },
            });
          }

          return next();
        },
        children: [
          {
            name: 'company-home.activity-management.codes',
            path: 'codes',
            component: CompanyHomeActivityCodes,
          },
          {
            name: 'company-home.activity-management.sets',
            path: 'sets',
            component: CompanyHomeActivitySets,
          },
        ],
      },
      {
        name: 'company-home.reviews',
        path: 'reviews',
        component: CompanyHomeReviews,
      },
    ],
  },

  {
    name: 'company-team',
    path: '/companies/:companyId/teams/:teamId',
    component: CompanyTeam,
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();
      const companyId = to.params?.companyId;
      const teamId = to.params?.teamId;

      if (!teamId || !companyId || !permissions.isCompanyMember(companyId)) {
        return next({ name: 'page-not-found' });
      }

      return next();
    },
  },
  {
    name: 'invitation-to-company',
    path: '/invitation-to-company/:invitationId',
    component: CompanyInvitation,
  },
];
