<template>
  <span
    class="inline-flex items-center"
    :title="label"
  >
    <span
      class="circle"
      :style="styles"
    ></span>
    <span class="status-label">
      {{ label }}
    </span>
  </span>
</template>

<script>
  import _keys from 'lodash/keys';

  import { IndicatorPillColors, IndicatorPillColorNames } from '@/uikit/constants/pills';

  export default {
    props: {
      label: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: IndicatorPillColorNames.DEFAULT_DARK,
        validator: val => _keys(IndicatorPillColors).includes(val),
      },
    },
    computed: {
      styles() {
        return {
          '--circle-color': IndicatorPillColors[this.color].bg,
        };
      },
    },
  };
</script>

<style lang="postcss" scoped>
.circle {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: var(--circle-color);
  flex-shrink: 0;
}

.status-label {
  @apply capitalize text-text-black truncate;
}
</style>
