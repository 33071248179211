<template>
  <gl-skeleton-loader
    ref="expandableRow"
    class="expandable-row-container"
    :class="{ 'white-bg': isWhiteBg, collapsed: !isExpanded, expanded: isExpanded, scrolled, 'animation-active': isAnimationActive }"
    :style="{'--expanded-height': expandedHeight}"
    :loading="loading && !isRecordEmpty"
    @animationend.native="handleAnimationEnd"
  >
    <div
      :class="{ collapsed: !isExpanded, clickable }"
      class="expandable-row expandable-row__content content-row"
      @click="handleRowClick"
    >
      <expandable-table-cell
        v-for="(column, idx) in columns"
        :key="column.field"
        :column="column"
        :record="record"
        :loading="loading && isRecordEmpty"
        :class="{ 'remaining-cell': idx > 0, 'first-cell': idx === 0 }"
      >
        <template #cell>
          <div
            v-if="idx === 0"
            class="cell-expand-container"
            :style="{'--main-cell-margin-left': marginLeft}"
          >
            <slot
              name="expandIcon"
              :field="column.field"
              :record="record"
              :expanded="isExpanded"
              :expandable="isExpandable"
              :toggle="handleToggleExpand"
            >
              <expand-icon-button
                v-if="isExpandable"
                :size="40"
                :expanded="isExpanded"
                @click="handleToggleExpand"
              />
              <div
                v-else
                class="row__empty-rectangle"
              ></div>
            </slot>
            <slot
              name="cell"
              :field="column.field"
              :record="record"
              :isChild="false"
            >
              {{ record[column.field] }}
            </slot>
          </div>
          <slot
            v-else
            name="cell"
            :field="column.field"
            :record="record"
            :isChild="false"
          >
          </slot>
        </template>
      </expandable-table-cell>
      <div
        v-if="inlineActions"
        class="row__inline-actions-container"
      >
        <inline-actions
          v-if="$scopedSlots.inlineActions"
          class="row__inline-actions"
          :container="`.${tableName}`"
        >
          <template #actionTrigger>
            <slot name="actionTrigger"></slot>
          </template>
          <template #inlineActions>
            <slot
              name="inlineActions"
              :record="record"
            ></slot>
          </template>
        </inline-actions>
        <slot
          v-else-if="$slots.inlineActionButton"
          name="inlineActionButton"
        ></slot>
      </div>
    </div>
    <template v-if="isExpandingContentShown">
      <expandable-table-row
        v-for="childRecord in record.$children"
        :key="childRecord._id"
        :columns="columns"
        :record="childRecord"
        :inline-actions="inlineActions"
        :scrolled="scrolled"
        :loading="loading"
        :grid-template-columns="gridTemplateColumns"
        :spacing="spacing + 1"
        :sub-record-spacing="subRecordSpacing"
        class="expandable-row__hidden-content"
        @click="handleSubRowClick(childRecord, record)"
      >
        <template #cell="{ field, record: $record, total }">
          <slot
            name="cell"
            :field="field"
            :total="total"
            :record="$record || childRecord"
            :isChild="true"
            :parent="record"
          >
          </slot>
        </template>
        <template
          v-if="$scopedSlots.inlineActions"
          #inlineActions
        >
          <slot
            name="inlineActions"
            :record="childRecord"
            :parent="record"
          ></slot>
        </template>
      </expandable-table-row>
      <total-table-row
        :columns="columns"
        :record="record"
        :loading="loading"
        :grid-template-columns="gridTemplateColumns"
        :spacing="spacing + 1"
      >
        <template #cell="{ field, total, record: $record }">
          <slot
            name="cell"
            :field="field"
            :record="$record || record"
            :total="total"
          >
          </slot>
        </template>
      </total-table-row>
    </template>
  </gl-skeleton-loader>
</template>

<script>
  import _isEmpty from 'lodash/isEmpty';

  import GlSkeletonLoader from 'uikit/components/skeletons/SkeletonLoader.vue';
  import InlineActions from 'uikit/components/InlineActions';

  import ExpandIconButton from './ExpandIconButton.vue';
  import ExpandableTableCell from './ExpandableTableCell.vue';
  import TotalTableRow from './TotalTableRow.vue';

  export default {
    // eslint-disable-next-line vue/component-definition-name-casing
    name: 'expandable-table-row',
    components: {
      GlSkeletonLoader,
      ExpandIconButton,
      ExpandableTableCell,
      TotalTableRow,
      InlineActions,
    },

    inject: ['tableName'],

    props: {
      record: {
        type: Object,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      gridTemplateColumns: {
        type: String,
        required: true,
      },
      columns: {
        type: Array,
        required: true,
      },
      spacing: {
        type: Number,
        default: 0,
      },
      inlineActions: {
        type: Boolean,
        default: false,
      },
      scrolled: {
        type: Boolean,
        default: false,
      },
      subRecordSpacing: {
        type: Number,
        default: 42,
      },
      clickable: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        expanded: false,
        expandedHeight: 'fit-content',
        isAnimationActive: false,
      };
    },
    computed: {
      isRecordEmpty() {
        return _isEmpty(this.record);
      },
      isWhiteBg() {
        return this.isRecordEmpty === this.loading;
      },
      isExpandable() {
        return Array.isArray(this.record?.$children) && !!this.record.$children.length;
      },
      isExpanded() {
        return this.expanded && this.isExpandable;
      },
      marginLeft() {
        return `${this.spacing * this.subRecordSpacing}px`;
      },
      isExpandingContentShown() {
        if (!this.isExpandable) {
          return false;
        }

        return this.expanded || (this.isAnimationActive);
      },
    },
    methods: {
      handleRowClick() {
        this.$emit('click', this.record);
      },
      handleSubRowClick(record, parent) {
        this.$emit('click', record, parent);
      },
      async handleToggleExpand() {
        const el = this.$refs.expandableRow?.$el;

        this.isAnimationActive = true;

        await this.$nextTick();

        if (this.expanded) {
          this.expandedHeight = `${el.scrollHeight}px`;
          this.expanded = !this.expanded;
        } else {
          this.expanded = !this.expanded;
          await this.$nextTick();
          this.expandedHeight = `${el.scrollHeight}px`;
        }
      },
      handleAnimationEnd() {
        this.expandedHeight = 'fit-content';
        this.isAnimationActive = false;
      },
    },
  };
</script>

<style lang="postcss" scoped>
.expandable-row-container {
  --collapsed-height: 46px;

  min-height: var(--collapsed-height, fit-content);
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: 12px;
  transition: .3s linear max-height;
  height: fit-content;
  grid-template-columns: 1fr;
  transition: max-height 0.3s;

  &.white-bg {
    @apply bg-white;
  }

  &.collapsed {
    max-height: var(--collapsed-height, fit-content);
    display: grid;
    animation: collapse .3s ease forwards;

    & .expandable-row__content {
      height: 100%;
    }
  }

  & .expandable-row__content {
    min-height: var(--collapsed-height, fit-content);

    &.clickable {
      cursor: pointer;

      &:hover {
        @apply border-foundation-gray-4 bg-foundation-gray-1;
      }
    }
  }

  &.expanded {
    animation: expand .3s ease forwards;

    & > .expandable-row__content {
      & >>> .cell-expand-container {
        & > * {
          &:last-child {
            white-space: normal;
            padding: 10px 0;
          }
        }
      }
    }

    & > .expandable-row__content > .remaining-cell {
      opacity: 0;
    }
  }

  &.animation-active {
    overflow: hidden;

    &.scrolled .row__inline-actions-container {
      opacity: 0;
    }
  }

  & .remaining-cell {
    transition: opacity .2s ease;
    padding: 0 12px;
  }

  & .first-cell {
    padding-left: 0;
  }

  &.loading {
    box-shadow: 0 0 8px 1px inset #ffffffb3;
    pointer-events: none;

    & > * {
      opacity: 0.2;
      transition: opacity .3s ease;
    }
  }
}

.expandable-row__content {
  align-items: center;
  display: grid;
  grid-template-columns: var(--table-col-width);
  justify-content: flex-start;
  gap: 0;
  transition: .1s linear;
}

.cell-expand-container {
  @apply flex items-center;
  gap: 16px;
  margin-left: var(--main-cell-margin-left);
}

.row__empty-rectangle {
  flex: 0 0 40px;
}

.expandable-row__hidden-content {
  border-radius: 0;
  position: relative;

  &:before {
    @apply border-t border-foundation-gray-3 absolute;
    width: calc(100% - 52px);
    content: '';
    right: 0px;
  }
}

.expandable-row-container.scrolled .row__inline-actions-container {
  position: sticky;
  right: 0;
  z-index: 2;
  box-shadow: -4px 0px 7px -2px rgba(0, 0, 0, 0.12);
  animation: sticky 0.3s ease forwards;
}

.row__inline-actions-container {
  @apply flex items-center justify-end;
  background: white;
  height: 100%;
}

.row__inline-actions {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

@keyframes sticky {
  0% {
    right: -64px;
  }

  100% {
    right: -1px;
  }
}

@keyframes expand {
  0% {
    max-height: var(--collapsed-height, fit-content);
  }

  100% {
    max-height: var(--expanded-height, fit-content);
  }
}

@keyframes collapse {
  0% {
    max-height: var(--expanded-height, fit-content);
  }

  100% {
    max-height: var(--collapsed-height, fit-content);
  }
}
</style>
