import config from '@/config';
import logger from '@/logger';

import fbPixelService from '@/services/fbpixel';
import LocalStorage from '@/services/infrastructure/localStorageService';
import fullName from '@/filters/fullName';

import { getCurrentUser, getRouteToRedirectFromWelcome } from '../routerServices';

export default [
  {
    name: 'welcome',
    path: '/welcome',
    beforeEnter: (to, _, next) => {
      const user = getCurrentUser();

      if (user) {
        const ls = new LocalStorage(user._id);

        try {
          const fbpixelSignupStorageKey = `${user._id}_signup_fbpixel`;

          if (!ls.getItem(fbpixelSignupStorageKey)) {
            fbPixelService.track('signup', {
              _id: user._id,
              link: `${config.rootUrl}/users/${user.username}`,
              fullName: fullName(user),
            });

            ls.setItem(fbpixelSignupStorageKey, true);
          }
        } catch (err) {
          logger.error(err);
        }
      }

      getRouteToRedirectFromWelcome(to.query.to, next);
    },
  },
];
