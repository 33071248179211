import { ThreadMessageMetadataTypes, ThreadEntities } from '@/constants/threads';
import { memberRoles, freelancerRoles, agencyMemberRoleLabels } from '@/constants/company';
import pluralize from '@/filters/pluralize';
import text from '@/filters/text';
import _includes from 'lodash/includes';
import Permissions from '@/services/infrastructure/permissions';

export const getMessageContentString = message => {
  if (message.content && ![ThreadMessageMetadataTypes.LOOM].includes(message.metadata?.type)) {
    const textMessage = text(message.content);
    return textMessage.length > 20 ? `${textMessage.slice(0, 20)}...` : textMessage;
  }

  if (message.metadata?.type === ThreadMessageMetadataTypes.LOOM) {
    return 'Loom recording';
  }

  const totalFilesCount = message.files.length + message.previewableEntities.length;
  return pluralize(totalFilesCount, 'Attachment');
};

const getTitleByCompany = (companies, hiringCompany, hiringTitle, salesTitle) => {
  const isHiringCompany = companies?.find(company => company._id === hiringCompany?._id);

  if (isHiringCompany) {
    return hiringTitle;
  }

  return salesTitle;
};

const getAgencyMemberRole = (user, company) => {
  const permissions = new Permissions(user);

  if (permissions.isCompanyOwner(company)) {
    return 'Agency Owner';
  }

  if (permissions.isSalesManager(company)) {
    return agencyMemberRoleLabels[freelancerRoles.SalesManager];
  }

  return 'Agency Member';
};

export const getUserRoleTitle = (user, thread) => {
  const isEmployer = _includes(user.type, 'employer');
  const hiringCompany = thread.companies?.find(company => company.roles.includes(memberRoles.HiringManager));

  switch (thread.entity.type) {
    case ThreadEntities.PAYMENT: {
      if (thread.entity?.receiver?._id === user._id) {
        return 'Recipient';
      }
      return 'Sender';
    }
    case ThreadEntities.DISPUTE: {
      if (isEmployer && user.isMainParticipant) {
        return 'Client';
      }

      if (user.isMainParticipant) {
        return 'Contractor';
      }

      return 'goLance Admin';
    }
    case ThreadEntities.CONTRACT: {
      if (isEmployer && user.isMainParticipant) {
        return 'Client';
      }

      if (user.isMainParticipant) {
        return 'Contractor';
      }

      return getTitleByCompany(user.companies, hiringCompany, 'Client', 'Contractor');
    }
    case ThreadEntities.AGENCY_REQUEST: {
      const isCompanyMember = user.companies.find(company => company._id === thread.entity?._id);

      if (isCompanyMember) {
        return getAgencyMemberRole(user, thread.entity);
      }

      return 'Client';
    }
    default: {
      if (isEmployer && user.isMainParticipant) {
        return 'Client';
      }

      if (user.isMainParticipant) {
        return 'Freelancer';
      }

      return getTitleByCompany(user.companies, hiringCompany, 'Client', 'Freelancer');
    }
  }
};
