<template>
  <nav-menu-wrapper v-slot="{ navItems }">
    <nav class="nav-menu">
      <navigation-dropdown
        v-for="item in navItems"
        :key="item.value"
        :value="item.value"
        :label="item.label"
        :clickable="!item.list"
      >
        <template v-if="item.list">
          <component
            :is="item.list"
            @children-count="handleChildrenCount"
          />
        </template>
      </navigation-dropdown>
    </nav>
  </nav-menu-wrapper>
</template>

<script>
  import { mapGetters } from 'vuex';

  import NavMenuWrapper from './NavMenuWrapper.vue';
  import NavigationDropdown from './NavigationDropdown.vue';
  import ResourcesList from './ResourcesList.vue';

  export default {
    components: {
      NavMenuWrapper,
      NavigationDropdown,
      ResourcesList,
    },
    data() {
      return {
        navigationOptionsCount: 0,
      };
    },
    computed: {
      ...mapGetters(['screenSize']),
    },
    methods: {
      handleChildrenCount(count) {
        this.navigationOptionsCount = count;
      },
    },
  };
</script>

<style lang="postcss" scoped>
.nav-menu {
  @apply flex;
  user-select: none;
  padding-left: 0;
}
</style>
