<template>
  <div
    class="separator"
    :class="type"
  ></div>
</template>

<script>
  import _includes from 'lodash/includes';

  export default {
    props: {
      type: {
        type: String,
        default: 'horizontal',
        validator(value) {
          return _includes(['horizontal', 'vertical'], value);
        },
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .separator {
    @apply border-foundation-gray-3;

    &.horizontal {
      @apply border-b;
    }

    &.vertical {
      @apply border-l;
    }
  }
</style>
