<template>
  <div class="line">
    <div
      class="filled-line line"
      :style="{ width: fillWidth }"
    ></div>
  </div>
</template>

<script>
  export default {
    props: {
      fillWidth: {
        type: [Number, String],
        default: 0,
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .line {
    @apply bg-white-ghost;
    width: 100%;
    height: 10px;

    border-radius: 16px;
  }

  .filled-line {
    @apply bg-yellow-orange;
    height: inherit;
    width: 0;
  }
</style>
