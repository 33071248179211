/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/vue';

import _isString from 'lodash/isString';
import _isError from 'lodash/isError';
import _pick from 'lodash/pick';

import { getErrorMessage } from '@/services/errors';

class SentryError extends Error {
  constructor(error, message, type = 'error') {
    super();

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SentryError);
    }

    this.name = _isString(message) ? message : getErrorMessage(error);
    this.message = getErrorMessage(error);

    Sentry.addBreadcrumb({
      category: 'data',
      message: getErrorMessage(error),
      data: error,
      type,
    });
  }
}

function captureException(args) {
  const [err, errorMessage] = args.reverse();
  const { user } = window._bootstrap;

  const options = {
    extra: {
      additionalInfo: err,
    },
  };

  if (user) {
    options.user = _pick(user, ['_id', 'email', 'firstName', 'lastName']);
  }

  Sentry.captureException(_isError(err) ? err : new SentryError(err, errorMessage), options);
}

export { SentryError, captureException };
