export default {
  router: null,
  store: null,
  init({
    router,
    store,
  }) {
    this.router = router;
    this.store = store;
  },
};
