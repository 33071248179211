import _each from 'lodash/forEach';

import {
  screenSizes,
} from '@/services/infrastructure/constants';

export default {
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },

  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      const windowWidth = window.innerWidth;
      this.setPredefinedWidth(windowWidth);
    },
    setPredefinedWidth(windowWidth) {
      const screenSize = {};
      _each(screenSizes, (width, size) => {
        screenSize[size] = windowWidth >= width;
      });

      screenSize.windowWidth = windowWidth;
      this.$store.commit('setScreenSize', screenSize);
    },
  },
};
