<template>
  <div class="week-switcher">
    <gl-icon-button
      icon-name="left-arrow-large"
      type="border"
      :disabled="disabled || prevDisabed"
      @click="handlePrevWeek"
    />
    <date-picker
      ref="datepicker"
      :value="selectedDate"
      :lang="lang"
      :clearable="false"
      :disabled-date="getDisabledDates"
      class="week-switcher__datepicker"
      type="week"
      popup-class="week-switcher__datepicker"
      @input="handleDateInput"
      @open="handlePanelOpen"
      @close="handlePanelClose"
    >
      <template #icon-calendar>
        <gl-icon
          :size="16"
          :class="{ rotated: isDatepickerOpen }"
          class="week-switcher__arrow"
          name="down-arrow-large"
        />
      </template>
      <template #input="{ events }">
        <gl-input
          :value="shownDate"
          class="week-switcher__date"
          v-on="events"
        />
      </template>
    </date-picker>
    <gl-icon-button
      icon-name="right-arrow-large"
      type="border"
      :disabled="disabled || nextDisabed"
      @click="handleNextWeek"
    />
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import DatePicker from 'vue2-datepicker';
  import GlIcon from 'uikit/icons/BaseIcon.vue';

  import GlIconButton from 'uikit/components/buttons/IconButton.vue';
  import GlInput from 'uikit/components/inputs/Input.vue';

  const defaultLang = {
    formatLocale: {
      firstDayOfWeek: 1,
    },
    monthBeforeYear: false,
  };

  export default {
    components: {
      GlIconButton,
      DatePicker,
      GlInput,
      GlIcon,
    },
    props: {
      date: {
        type: Object,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      maxDate: {
        type: [String, Date],
        default: () => new Date(),
      },
      minDate: {
        type: [String, Date],
        default: () => new Date(0),
      },
      nextDisabed: {
        type: Boolean,
        default: false,
      },
      prevDisabed: {
        type: Boolean,
        default: false,
      },
      lang: {
        type: Object,
        default: () => defaultLang,
      },
      timezone: {
        type: String,
        default: 'Etc/UTC',
      },
    },
    data() {
      return {
        isDatepickerOpen: false,
      };
    },
    computed: {
      shownDate() {
        const fromDate = moment.tz(this.date.fromDate, this.timezone).format('DD');
        const toDate = moment.tz(this.date.toDate, this.timezone).format('DD MMM, YYYY');

        return `${fromDate} - ${toDate}`;
      },
      selectedDate() {
        return new Date(this.date.fromDate);
      },
    },
    methods: {
      handlePrevWeek() {
        const date = moment.tz(this.selectedDate, this.timezone).subtract(1, 'weeks');
        this.changeWeek(date);
      },
      handleNextWeek() {
        const date = moment.tz(this.selectedDate, this.timezone).add(1, 'weeks');
        this.changeWeek(date);
      },
      handleDateInput(date) {
        const dateWithoutTime = moment(date).format('YYYY-MM-DD');
        const correctedDate = moment.tz(dateWithoutTime, this.timezone);

        this.changeWeek(correctedDate);
      },
      changeWeek(date) {
        const week = {
          fromDate: date.startOf('isoweek').toISOString(),
          toDate: date.endOf('isoweek').toISOString(),
        };

        this.$emit('change', week);
      },
      getDisabledDates(date) {
        if (this.minDate && this.maxDate) {
          return date < this.minDate || date > this.maxDate;
        }

        if (this.minDate) {
          return date < this.minDate;
        }

        if (this.maxDate) {
          return date > this.maxDate;
        }

        return null;
      },
      handlePanelOpen() {
        this.isDatepickerOpen = true;
      },
      handlePanelClose() {
        this.isDatepickerOpen = false;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .week-switcher {
    @apply flex items-center;
    gap: 4px;

    &__datepicker {
      width: 160px;
    }

    &__date {
      @apply bg-white flex items-center justify-center;
      height: 40px;
      width: fit-content;
    }

    &__arrow {
      @apply text-icon-default;
      transition: 0.2s ease all;
    }
  }

  .rotated {
    transform: rotate(180deg);
  }
</style>
