<template>
  <img
    :src="iconSrc"
    alt="goLance logo"
    :width="width"
    :height="height"
  >
</template>

<script>
  import { getImageThumbnailUrl } from '@/services/cloudinaryImageUrl';

  import _includes from 'lodash/includes';
  import _keys from 'lodash/keys';

  const icons = {
    'big-white': 'https://res.cloudinary.com/golance/image/upload/c_scale,w_422/v1667470488/logo/golance-logo-big-white-2022.png',
    'big-black': 'https://res.cloudinary.com/golance/image/upload/v1734081764/logo/golance-logo.svg',
    'small-white-rounded': 'https://res.cloudinary.com/golance/image/upload/c_scale,w_128/v1667470488/logo/golance-logo-small-rounded-white-2022.png',
    'small-black-rounded': 'https://res.cloudinary.com/golance/image/upload/c_scale,w_128/v1667470488/logo/golance-logo-small-rounded-black-2022.png',
    'small-white-rectangle': 'https://res.cloudinary.com/golance/image/upload/c_scale,w_128/v1667470488/logo/golance-logo-small-rectangle-white-2022.png',
    'small-black-rectangle': 'https://res.cloudinary.com/golance/image/upload/c_scale,w_128/v1667470488/logo/golance-logo-small-rectangle-black-2022.png',
    icon: 'https://res.cloudinary.com/golance/image/upload/v1734081891/logo/mobile-logo.svg',
  };

  export default {
    props: {
      type: {
        type: String,
        validator: val => _includes(_keys(icons), val),
        required: true,
      },
      width: {
        type: Number,
        default: undefined,
      },
      height: {
        type: Number,
        default: undefined,
      },
    },
    computed: {
      iconSrc() {
        const current = icons[this.type];

        if (this.width && this.height) {
          return getImageThumbnailUrl(current, { width: this.width, height: this.height });
        }

        return current;
      },
    },
  };
</script>
