<template>
  <header class="header">
    <div class="header-controls">
      <div class="left-side">
        <burger-menu-button
          v-model="isMobileMenuOpenModel"
          class="burger-menu"
        />
        <router-link
          :to="{ name: 'home' }"
          class="desktop"
        >
          <div
            v-if="isChristmas"
            ref="christmas-logo"
          >
            <snow-fall
              :count="30"
              :width="100"
              :height="40"
            />
            <golance-christmas-icon class="logo christmas-logo" />
          </div>

          <app-logo
            v-else
            type="big-black"
            class="logo"
            :width="140"
            :height="30"
          />
        </router-link>
        <router-link
          :to="{ name: 'home' }"
          class="mobile"
        >
          <div v-if="isChristmas">
            <snow-fall
              :count="20"
              :width="40"
              :height="45"
            />
            <golance-christmas-mobile-icon
              ref="christmas-logo"
              class="logo christmas-logo"
              @click.native="toRoute('home')"
            />
          </div>
          <app-logo
            v-else
            type="icon"
            :width="30"
            :height="30"
            class="logo"
          />
        </router-link>
        <nav-menu class="nav-menu" />
        <search-button @click="handleSearchButtonClick" />
      </div>

      <div
        v-if="isAuthorized"
        class="right-side authorized"
      >
        <add-manual-time-button
          v-if="isManualTimeButtonShown"
          variant="secondary"
          resizable
          class="add-manual-time-button"
          @click="handleAddManualTime"
        />
        <gl-button
          v-if="isHireNowShown"
          variant="secondary"
          size="large"
          class="create-offer button desktop"
          :class="{ 'additional-margin': !isPostJobShown }"
          name="hire"
          @click="handleHireNowClick"
        >
          Hire
        </gl-button>
        <gl-button
          v-if="isPostJobShown"
          variant="primary"
          size="large"
          class="post-job button desktop"
          @click="openPostJobPage"
        >
          Post Job
        </gl-button>
        <div class="header-icons">
          <div v-click-outside="handleMessagesClose">
            <message-icon
              :unread-inbox-count="currentUser.unreadInboxCount"
              @click.native="handleMessagesClick"
            />
            <messages-popup
              :show="showMessages"
              @close="handleMessagesClose"
            />
          </div>
          <div v-click-outside="handleNotificationsClose">
            <notification-icon
              :notifications-count="notificationsCount"
              @click.native="handleNotificationsClick"
            />
            <notifications-popup
              :show="isNotificationsShown"
              @close="handleNotificationsClose"
            />
          </div>
        </div>

        <profile :current-user="currentUser" />
      </div>

      <div
        v-else
        class="right-side unauthorized"
      >
        <gl-button
          variant="link"
          class="link"
          @click="openLink(landingUrls.howItWorks)"
        >
          How It Works
        </gl-button>
        <gl-button
          variant="secondary"
          class="sign-up"
          name="sign-up"
          @click.native="openLink(landingUrls.signup)"
        >
          Sign Up
        </gl-button>
        <gl-button
          variant="primary"
          class="log-in"
          name="log-in"
          @click.native="openLink(landingUrls.login)"
        >
          Log In
        </gl-button>
      </div>
    </div>
    <search-block
      ref="search-block"
      :show="showSearch"
      class="search-block"
      @close="handleSearchClose"
    />
    <global-notifications />
  </header>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import _random from 'lodash/random';
  import _get from 'lodash/get';

  import config from '@/config';

  import GolanceChristmasIcon from '@/components/icons/GoLanceChristmasIcon.vue';
  import GolanceChristmasMobileIcon from '@/components/icons/GoLanceChristmasMobileIcon.vue';
  import AppLogo from '@/components/AppLogo.vue';
  import AddManualTimeButton from '@/components/buttons/AddManualTimeButton.vue';
  import analytics, { eventNames, eventProperties } from '@/services/analytics';

  import { GlButton } from 'uikit/components';
  import Profile from './components/Profile.vue';
  import SnowFall from './components/SnowFall.vue';
  import NavMenu from './components/NavigationMenu.vue';
  import MessageIcon from './components/icons/MessageIcon.vue';
  import NotificationIcon from './components/icons/NotificationIcon.vue';
  import SearchButton from './components/SearchButton.vue';
  import SearchBlock from './components/SearchBlock.vue';
  import GlobalNotifications from './components/GlobalNotifications/index.vue';
  import BurgerMenuButton from './components/BurgerMenuButton.vue';
  import MessagesPopup from './components/ThreadsPopup.vue';
  import NotificationsPopup from './components/NotificationsPopup.vue';

  export default {
    components: {
      AppLogo,
      NotificationIcon,
      MessageIcon,
      Profile,
      GlButton,
      GolanceChristmasIcon,
      GolanceChristmasMobileIcon,
      SnowFall,
      NavMenu,
      SearchButton,
      SearchBlock,
      GlobalNotifications,
      BurgerMenuButton,
      MessagesPopup,
      NotificationsPopup,
      AddManualTimeButton,
    },
    props: {
      isMobileMenuOpen: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        landingUrls: {
          howItWorks: config.howItWorks,
          login: config.loginUrl,
          signup: config.signupUrl,
        },
        isChristmas: false,
        showSearch: false,
        showMessages: false,
      };
    },
    computed: {
      ...mapGetters(['currentUser', 'isAuthorized', 'permissions', 'isNotificationsShown']),
      isEmployer() {
        return this.permissions.isEmployer;
      },
      isFreelancer() {
        return this.permissions.isFreelancer;
      },
      isManualTimeButtonShown() {
        return this.isFreelancer && this.currentUser.isManualTimeContractsExists;
      },
      isSearchBarShown() {
        return !this.currentUser?.marketplaceAccessDisabled;
      },
      isPostJobShown() {
        return this.isEmployer && !this.currentUser?.postJobDisabled;
      },
      isHireNowShown() {
        return this.isEmployer;
      },
      notificationsCount() {
        return this.currentUser?.unreadNotificationsCount;
      },
      isMobileMenuOpenModel: {
        get() {
          return this.isMobileMenuOpen;
        },
        set(val) {
          this.$emit('mobile-menu-toggle', val);
        },
      },
    },
    watch: {
      '$route.query'(query) {
        if (query.notifications) {
          this.setNotificationsVisibility(true);
        }
      },
    },
    mounted() {
      const notificationsQueryParam = _get(this.$route, 'query.notifications');
      if (notificationsQueryParam) {
        this.setNotificationsVisibility(true);
      }
    },
    methods: {
      ...mapMutations(['setNotificationsVisibility', 'setModalData']),
      toRoute(route, params, query = {}) {
        this.$router.push({ name: route, params, query });
      },
      openLink(link) {
        window.open(link, '_blank');
      },
      async openPostJobPage() {
        const modalData = {
          path: 'postNewJob',
          payload: {
            show: true,
          },
        };
        analytics.track(eventNames.jobs.newJobFormOpened, {
          [eventProperties.newJobOpened.name]: eventProperties.newJobOpened.values.navBar,
        });
        this.setModalData(modalData);
      },
      handleAddManualTime() {
        const modalData = {
          path: 'addManualTime',
          payload: {
            show: true,
          },
        };
        this.setModalData(modalData);
      },
      async handleSearchButtonClick() {
        this.showSearch = !this.showSearch;

        if (this.showSearch) {
          await this.$nextTick();
          this.$refs['search-block'].focus();
        } else {
          document.activeElement.blur();
        }
      },
      handleMessagesClick() {
        this.showMessages = !this.showMessages;
      },
      handleMessagesClose() {
        this.showMessages = false;
      },
      handleNotificationsClick() {
        this.showMessages = false;
        this.setNotificationsVisibility(!this.isNotificationsShown);
      },
      handleNotificationsClose() {
        this.setNotificationsVisibility(false);
      },
      handleSearchClose() {
        this.showSearch = false;
      },
      handleHireNowClick() {
        const modalData = {
          path: 'createOfferModal',
          payload: {
            show: true,
          },
        };
        this.setModalData(modalData);
      },
      getSnowStyle(elementWidth, wrapperHeight) {
        return {
          animationDuration: `${_random(4, 9)}s`,
          animationDelay: `${_random(2, 6)}s`,
          '--left-start': `${_random(5, elementWidth)}px`,
          '--left-end': `${_random(5, elementWidth)}px`,
          '--bottom-end': `${wrapperHeight}px`,
        };
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .header-controls {
    @apply flex justify-between;
    height: 60px;
    padding: 0 40px;
  }

  .search-block {
    padding: 0 40px;
  }

  .header {
    @apply flex-col font-primary bg-white flex justify-between items-stretch relative;
    --padding-text-top: 5px;
    width: 100%;
    box-shadow: 0px 2px 4px -2px rgb(24 39 75 / 12%), 0px 4px 4px -2px rgb(24 39 75 / 8%);
    & .left-side {
      @apply flex items-center;
      padding: 12px 0;
      & .burger-menu {
        @apply hidden;
        margin-right: 16px;
        flex-shrink: 0;
      }
      & .logo {
        @apply self-center;
        height: 28px;
        width: auto;
        &::before {
          @apply hidden;
        }
      }
      & .mobile {
        @apply hidden self-center;
        flex-shrink: 0;
        margin-right: 16px;
        & .logo {
          height: 28px;
        }
        & .logo.christmas-logo {
          width: 36px;
          height: 26px;
        }
      }
      & .desktop {
        @apply flex self-center;
        margin-right: 16px;
        & .logo.christmas-logo {
          width: 90px;
          height: 22px;
        }
      }
    }
    & .nav-menu {
      margin-left: 8px;
    }
    & .header-icons {
      @apply flex relative;
      min-width: fit-content;
      width: fit-content;
      margin-right: 16px;
      gap: 4px;
    }

    & .add-manual-time-button {
      margin-right: 4px;
    }

    & .right-side {
      @apply flex items-center relative justify-end;
      padding: 8px 0;
      padding-left: 10px;
      & .post-job,
      & .additional-margin {
        margin-right: 32px;
      }
    }
    & .unauthorized {
      padding: 12px 0;
      & .link {
        @apply text-14;
      }
      & .sign-up {
        width: 100px;
        margin-left: 16px;
      }
      & .log-in {
        width: 84px;
        margin-left: 4px;
      }
    }
    & .create-offer {
      margin-right: 4px;
    }
  }

  .create-offer,
  .post-job {
    min-width: 100px;
    width: 100px;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 1200px) {
    .header {
      & .right-side {
        & .post-job,
        & .additional-margin {
          margin-right: 16px;
        }
      }

      & .search-block,
      & .header-controls {
        padding: 0 16px;
      }
    }
  }

  @media (max-width: 1130px) {
    .header {
      & .nav-menu {
        @apply hidden;
      }
      & .left-side {
        & .burger-menu {
          @apply flex;
        }
      }
    }
  }

  @media (max-width: 870px) {
    .header {
      & .left-side {
        & .mobile {
          @apply flex;
        }
        & .desktop {
          @apply hidden;
        }
      }
    }
  }
  @media (max-width: 580px) {
    .header {
      & .authorized {
        @apply flex;
        & .desktop {
          @apply hidden;
        }
        & .balance {
          margin-left: auto;
        }
      }
      & .header-icons {
        margin-right: 4px;
      }

      & .search-block,
      & .header-controls {
        padding: 0 10px;
      }
    }
  }
  @media (max-width: 490px) {
    .header {
      & .unauthorized {
        & .link {
          display: none;
        }
      }
    }
  }
</style>
