<template>
  <div class="contract-closed-notification">
    <div>
      Your recently completed a contract
      <router-link
        :to="contractRoute"
        class="contract-closed-notification__link"
        @click.stop
      >
        {{ notification.data.contract.title }}
      </router-link>
      with
      <router-link
        :to="otherPartyRoute"
        class="contract-closed-notification__link"
        @click.stop
      >
        {{ otherPartyName }}
      </router-link>
      We’d like to hear about how things went.
    </div>
    <div
      v-if="showReviewInfo"
      class="contract-closed-notification__rate-buttons"
    >
      <review-rate-button
        v-for="num in 5"
        :key="num"
        :stars="num"
        @click.stop="handleRateButtonClick(num)"
      />
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  import { getUserProfileLink } from '@/services/user';

  import fullName from '@/filters/fullName';
  import ReviewRateButton from '@/components/Contract/ReviewRateButton.vue';

  export default {
    components: {
      ReviewRateButton,
    },
    filters: {
      fullName,
    },
    props: {
      notification: {
        type: Object,
        required: true,
      },
    },
    computed: {
      contractRoute() {
        return {
          name: 'contract',
          params: {
            contractId: this.notification.data.contractId,
          },
          query: {
            leaveReview: true,
          },
        };
      },
      otherPartyRoute() {
        return getUserProfileLink(this.notification.data.otherParty);
      },
      otherPartyName() {
        return this.notification.data.otherParty.name || fullName(this.notification.data.otherParty);
      },
      showReviewInfo() {
        return this.notification?.data?.isEligibleToFeedback && moment(this.notification?.data?.feedbackExpireAt).diff(moment(), 'seconds') > 0;
      },
    },
    methods: {
      handleRateButtonClick(stars) {
        const route = {
          ...this.contractRoute,
          query: {
            ...this.contractRoute.query,
            reviewStars: stars,
          },
        };

        this.$router.push(route)
          .catch(() => {
            // don't care
          });
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .contract-closed-notification {
    @apply text-text-black text-14 font-primary;
    max-width: 100%;

    &__link {
      &:hover {
        text-decoration: underline;
      }
    }

    &__rate-buttons {
      @apply flex;
      gap: 2px;
      margin-top: 12px;
    }
  }
</style>
