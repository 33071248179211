const CreatePaymentRequest = () => import(/* webpackChunkName: "CreatePaymentRequest" */ '@/views/PaymentRequest/Create');
const PaymentRequestCheckout = () => import(/* webpackChunkName: "PaymentRequestCheckout" */ '@/views/PaymentRequest/Checkout');

export default [
  {
    name: 'payment-requests-new',
    path: '/payment-requests/new',
    component: CreatePaymentRequest,
  },
  {
    name: 'payment-requests-pay',
    path: '/payment-requests/:id/pay',
    component: PaymentRequestCheckout,
    meta: { requiresAuth: false },
  },
];
