<template>
  <div class="contract-closed-notification">
    <div>
      Contract
      <router-link
        :to="contractRoute"
        class="contract-closed-notification__link"
        @click.stop
      >
        {{ notification.data.contractTitle }}
      </router-link>
      was closed.
      <template v-if="showReviewInfo">
        Let us know what you liked and what could be improved about the contractor's work.
      </template>
    </div>
    <div
      v-if="showReviewInfo"
      class="contract-closed-notification__rate-buttons"
    >
      <review-rate-button
        v-for="num in 5"
        :key="num"
        :stars="num"
        @click.stop="handleRateButtonClick(num)"
      />
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import ReviewRateButton from '@/components/Contract/ReviewRateButton.vue';

  export default {
    components: {
      ReviewRateButton,
    },
    props: {
      notification: {
        type: Object,
        required: true,
      },
    },
    computed: {
      contractRoute() {
        return {
          name: 'contract',
          params: {
            contractId: this.notification.data.contractId,
          },
          query: {
            leaveReview: true,
          },
        };
      },
      showReviewInfo() {
        return this.notification?.data?.isEligibleToFeedback && moment(this.notification?.data?.feedbackExpireAt).diff(moment(), 'seconds') > 0;
      },
    },
    methods: {
      handleRateButtonClick(stars) {
        const route = {
          ...this.contractRoute,
          query: {
            ...this.contractRoute.query,
            reviewStars: stars,
          },
        };

        this.$router.push(route)
          .catch(() => {
            // don't care
          });
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .contract-closed-notification {
    @apply text-text-black text-14 font-primary;
    max-width: 100%;

    &__link {
      &:hover {
        text-decoration: underline;
      }
    }

    &__rate-buttons {
      @apply flex;
      gap: 2px;
      margin-top: 12px;
    }
  }
</style>
