<template>
  <transition name="slide">
    <div
      v-if="show"
      v-click-outside="handleClickOutside"
      class="search-wrapper"
    >
      <div
        ref="search-block"
        class="search"
      >
        <input
          ref="search-input"
          v-model="searchValue"
          :placeholder="selectedSearchOption.placeholder"
          :spellcheck="false"
          class="search-field"
          @keypress.enter="handleSearch"
        >
        <button
          v-if="searchValue"
          class="clear-icon"
          name="clear"
          @mousedown.prevent
          @click="handleClearIconClick"
        >
          <times-icon />
        </button>
      </div>

      <div class="flex items-center">
        <div class="separator"></div>

        <gl-rich-select
          v-model="selectedSearchOption"
          class="search-type"
          :options="searchOptions"
          placeholder=""
          label="label"
          track-by="value"
          :searchable="false"
        />
      </div>
    </div>
  </transition>
</template>

<script>
  import TimesIcon from '@/components/icons/InputClearIcon.vue';

  import { GlRichSelect } from '@/uikit/components';

  const searchOptions = [
    {
      value: 'freelancers',
      placeholder: 'Search by categories, work and services',
      path: 'gohire',
      label: 'Freelancers',
    },
    {
      value: 'jobs',
      placeholder: 'Find Jobs',
      path: 'gowork',
      label: 'Jobs',
    },
  ];

  export default {
    components: {
      TimesIcon,
      GlRichSelect,
    },
    props: {
      searchBy: {
        type: String,
        default: searchOptions[0].value,
      },
      show: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isMultiselectOpen: false,
        searchValue: null,
        searchFieldOpen: false,
        searchOptions,
        selectedSearchOption: searchOptions[0],
      };
    },
    watch: {
      show(val) {
        if (!val) {
          return;
        }
        this.searchValue = null;
      },
    },
    methods: {
      handleSearch() {
        this.$router.push({
          name: this.selectedSearchOption.path,
          params: {},
          query: { query: this.searchValue },
        });
        this.close();
      },
      handleClearIconClick() {
        this.searchValue = null;
      },
      handleClickOutside() {
        if (!this.$refs['search-block'].contains(document.activeElement)) {
          this.close();
        }
      },
      changeselectedSearchOption(val) {
        this.selectedSearchOption = val;
      },
      focus() {
        this.$refs['search-input'].focus();
      },
      close() {
        this.$refs['search-input'].blur();
        this.$emit('close');
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .search-wrapper {
    @apply flex items-center justify-between;
    will-change: auto;
    padding: 16px 0;
    transition: all 0.3s, opacity 0.2s;
    height: 68px;
    gap: 16px;
  }

  .search-type {
    padding-right: 16px;

    @media (max-width: 768px) {
      padding-right: 0;
    }

    & >>> .input {
      border: none;
    }

    & >>> .multiselect {
      min-width: 128px;
    }

    & >>> .multiselect__tags {
      @apply w-full;

      text-align: center;
    }

    & >>> .multiselect__tags span.multiselect__single {
      overflow: unset;
      font-weight: 600;
      letter-spacing: 0.0025em;
      padding: 8px 12px;
    }

    & >>> .select-picker .dropdown-icon {
      margin-left: 0;
      right: 0;
    }
  }

  .separator {
    @apply bg-foundation-gray-6;
    height: 36px;
    width: 1px;
    border-radius: 8px;

    @media (min-width: 768px) {
      margin-right: 16px;
    }
  }

  .search {
    @apply flex font-primary items-center w-full;
    gap: 16px;

    @media (max-width: 400px) {
      max-width: 195px;
      gap: 4px;
    }

    & .search-field {
      @apply h-full outline-none text-16 text-text-black font-semibold;
      letter-spacing: 0.0015em;
      line-height: 1.4em;
      flex-grow: 1;
      border: none;

      &::placeholder {
        @apply text-text-placeholder;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 400;
      }
    }
  }

  .clear-icon {
    @apply text-icon-default-dark flex;
    transition: 0.2s ease color;
    cursor: pointer;

    &:hover {
      @apply text-icon-hover;
    }
  }

  .slide-leave-to,
  .slide-enter-active {
    overflow: hidden;
    height: 0;
    opacity: 0;
    padding: 0;

    & .search-input,
    & .clear-icon {
      opacity: 0;
    }
  }

  .slide-enter-to {
    overflow: hidden;
    height: 68px;
    opacity: 1;
  }
</style>
