export const ThreadEntities = {
  CONTRACT: 'contract',
  NEGOTIATIONS: 'negotiations',
  OFFER: 'offer',
  MILESTONE: 'milestone',
  DISPUTE: 'dispute',
  INVITATION: 'invitation',
  SERVICE: 'service',
  PAYMENT: 'payment',
  AGENCY_REQUEST: 'agency',
  FREELANCER: 'freelancer',
};

export const ThreadEntityNames = {
  [ThreadEntities.CONTRACT]: 'Contract',
  [ThreadEntities.NEGOTIATIONS]: 'Proposal',
  [ThreadEntities.OFFER]: 'Offer',
  [ThreadEntities.MILESTONE]: 'Milestone',
  [ThreadEntities.DISPUTE]: 'Dispute',
  [ThreadEntities.INVITATION]: 'Invitation',
  [ThreadEntities.SERVICE]: 'Service',
  [ThreadEntities.PAYMENT]: 'Payment',
  [ThreadEntities.AGENCY_REQUEST]: 'Agency Request',
  [ThreadEntities.FREELANCER]: 'Freelancer',
};

export const ThreadMessageMetadataTypes = {
  JOIN: 'join',
  LOOM: 'loom',
  MILESTONE_SYSTEM: 'milestone-system',
  SNAPSHOTS_SYSTEM: 'snapshots-system',
  GOOGLE_INSTANT_MEETING: 'google-instant-meeting',
  ZOOM_INSTANT_MEETING: 'zoom-instant-meeting',
};
