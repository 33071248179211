<template>
  <div
    class="segmented-controll"
    :class="[size]"
  >
    <segment-radio
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :title="option.title"
      :icon="option.icon"
      :count="option.count"
      :size="size"
      :name="name"
      :checked="isActiveButton(option.value)"
      @change="handleClickButton(option.value)"
    />
  </div>
</template>

<script>
  import _every from 'lodash/every';
  import _uniqueId from 'lodash/uniqueId';

  import SegmentRadio from './SegmentRadio.vue';

  export default {
    components: {
      SegmentRadio,
    },
    props: {
      value: {
        type: String,
        default: null,
      },
      options: {
        type: Array,
        required: true,
        validator(options) {
          return _every(options, option => Boolean(option.value && (option.icon || option.title)));
        },
      },
      size: {
        type: String,
        default: 'large',
        validator: val => ['large', 'medium', 'small'].includes(val),
      },
    },
    data() {
      const name = _uniqueId('segmented-control_');

      return {
        name,
      };
    },
    methods: {
      handleClickButton(value) {
        this.$emit('change', value);
      },
      isActiveButton(value) {
        return this.value === value;
      },
    },
  };
</script>

<style lang="postcss" scoped>

.segmented-controll {
  @apply bg-background flex items-center;
  height: fit-content;
  width: fit-content;
  border-radius: 24px;

  &.large {
    padding: 6px;
  }

  &.medium {
    padding: 4px;
  }

  &.small {
    padding: 2px;
  }
}

</style>
