import _find from 'lodash/find';
import moment from 'moment-timezone';

import store from '@/store';
import { paymentTypes } from '@/constants/payment';
import { PAYMENT_TYPE_CYCLE, END_PERIOD_TYPES } from '@/constants/contract';

const getContractLink = (contract, type, additionalParams = {}) => {
  const paymentType = type || contract?.paymentType?.value;

  const isHourlyContractView = paymentType === paymentTypes.Hourly;
  const isFixedContractView = paymentType === paymentTypes.Fixed;

  let name = 'contract';
  let params = { contractId: contract._id };

  if (isHourlyContractView) {
    const tab = additionalParams.tab || 'weekly-summary';
    delete additionalParams.tab;

    name = `hourly-contract.${tab}`;
    params = { ...params, ...additionalParams };
  } else if (isFixedContractView) {
    const tab = additionalParams.tab || 'milestones';
    delete additionalParams.tab;

    name = `fixed-contract.${tab}`;
    params = { ...params, ...additionalParams };
  }

  return {
    name,
    params,
  };
};

const getContractRoles = contract => {
  const { permissions, currentUser } = store.getters;

  const userRoles = {
    employer: contract.employer._id === currentUser._id,
    contractor: contract.contractor._id === currentUser._id,
    collaborator: !!_find(contract.collaborators, collaborator => collaborator.user._id === currentUser._id),
    salesManager: !!(contract.contractorCompany && permissions.isSalesManager(contract.contractorCompany._id)),
    hiringManager: !!(contract.employerCompany && permissions.isHiringManager(contract.employerCompany._id, contract?.team?._id)),
    exclusive: permissions.isExclusiveFreelancer && permissions.exclusiveCompany?._id === contract.contractorCompany?._id,
    companyOwner: contract.employerCompany && permissions.isCompanyOwner(contract.employerCompany._id),
    salesManagerOrContractor: false,
    hiringManagerOrEmployer: false,
    agencyOwner: false,
  };

  if (contract.contractorCompany) {
    userRoles.salesManagerOrContractor = userRoles.salesManager || (contract.parent && userRoles.contractor);
  } else {
    userRoles.salesManagerOrContractor = userRoles.contractor;
  }

  userRoles.hiringManagerOrEmployer = userRoles.hiringManager || userRoles.employer;
  userRoles.agencyOwner = userRoles.salesManager && permissions.isCompanyModerator(contract.contractorCompany._id);

  return userRoles;
};

const calculateScheduledPaymentsCount = paymentScheduleData => {
  const {
    firstPaymentDate, endPeriodDate, paymentCycle, endPeriodType, occurrencesCount,
  } = paymentScheduleData;

  if (endPeriodType === END_PERIOD_TYPES.after.value) {
    return occurrencesCount;
  }

  if (endPeriodType === END_PERIOD_TYPES.on.value) {
    if (!firstPaymentDate || !endPeriodDate) {
      return 0;
    }

    const diff = moment.duration(moment(endPeriodDate).diff(firstPaymentDate));

    if (paymentCycle.value === PAYMENT_TYPE_CYCLE.weekly.value) {
      return Math.floor(diff.asWeeks() + 1);
    }
    if (paymentCycle.value === PAYMENT_TYPE_CYCLE.monthly.value) {
      return Math.floor(diff.asMonths() + 1);
    }
    if (paymentCycle.value === PAYMENT_TYPE_CYCLE['bi-weekly'].value) {
      return Math.floor(diff.asWeeks() / 2) + 1;
    }

    return 0;
  }
  return 0;
};

const calculateScheduledPaymentsTotalAmount = paymentScheduleData => {
  const paymentsCount = calculateScheduledPaymentsCount(paymentScheduleData);
  if (!paymentsCount) {
    return 0;
  }

  const { paymentAmount, firstPaymentAmount } = paymentScheduleData;

  return (paymentsCount - 1) * paymentAmount + Number(firstPaymentAmount);
};

export {
  getContractLink, getContractRoles, calculateScheduledPaymentsCount, calculateScheduledPaymentsTotalAmount,
};
