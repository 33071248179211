<template>
  <gl-base-modal-container
    :show="show"
    :size="size"
    :width="width"
    :centered="true"
    :is-on-icon-close-only="isOnIconCloseOnly"
    :disable-portal="disablePortal"
    :z-index="zIndex"
    :navigation="navigation"
    :prev-disabled="prevDisabled"
    :next-disabled="nextDisabled"
    @close="$emit('close')"
    @show="$emit('show')"
    @hide="$emit('hide')"
    @prev="$emit('prev')"
    @next="$emit('next')"
  >
    <regular-modal-window
      :title="title"
      :subtitle="subtitle"
      :show-back-icon="showBackIcon"
      :show-close-icon="showCloseIcon"
      :scrollable="scrollable"
      :smooth-resize="smoothResize"
      @close="$emit('close')"
      @back="$emit('back')"
    >
      <template #topControls>
        <slot name="topControls"></slot>
      </template>
      <template #title>
        <slot name="title"></slot>
      </template>
      <template #subtitle>
        <slot name="subtitle"></slot>
      </template>
      <template #body>
        <slot name="body"></slot>
      </template>
      <template #footer>
        <slot name="footer"></slot>
      </template>
    </regular-modal-window>
  </gl-base-modal-container>
</template>

<script>
  import _includes from 'lodash/includes';

  import GlBaseModalContainer from './BaseModalContainer.vue';
  import RegularModalWindow from './RegularModalWindow.vue';

  export default {
    components: {
      GlBaseModalContainer,
      RegularModalWindow,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'xxs',
        validator(value) {
          return _includes(['xxl', 'xl', 'l', 'm', 's', 'xxs', 'xs', 'sm'], value);
        },
      },
      width: {
        type: Number,
        default: null,
      },
      title: {
        type: String,
        default: null,
      },
      scrollable: {
        type: Boolean,
        default: true,
      },
      subtitle: {
        type: String,
        default: null,
      },
      showBackIcon: {
        type: Boolean,
        default: false,
      },
      showCloseIcon: {
        type: Boolean,
        default: true,
      },
      isOnIconCloseOnly: {
        type: Boolean,
        default: false,
      },
      disablePortal: {
        type: Boolean,
        default: false,
      },
      zIndex: {
        type: Number,
        default: 999,
      },
      navigation: {
        type: Boolean,
        default: false,
      },
      prevDisabled: {
        type: Boolean,
        default: false,
      },
      nextDisabled: {
        type: Boolean,
        default: false,
      },
      smoothResize: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
