<template>
  <div ref="snowfall">
    <div
      v-for="n in count"
      :key="n"
      class="snowflake"
      :style="getSnowflakeStyle()"
    ></div>
  </div>
</template>

<script>
  import _random from 'lodash/random';

  export default {
    props: {
      count: {
        type: Number,
        default: 20,
      },
      height: {
        type: Number,
        required: true,
      },
      width: {
        type: Number,
        required: true,
      },
    },
    methods: {
      getSnowflakeStyle() {
        return {
          animationDuration: `${_random(5, 9)}s`,
          animationDelay: `${_random(2, 6)}s`,
          '--left-start': `${_random(-5, this.width)}px`,
          '--left-end': `${_random(-5, this.width)}px`,
          '--bottom-end': `${this.height}px`,
        };
      },
    },
  };
</script>

<style lang="postcss" scoped>
.snowflake {
  width: 4px;
  height: 4px;
  background: #e1e3ea;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  animation-name: snowfall;
  animation-iteration-count: infinite;
}

@keyframes snowfall {
  0% {
    transform: translate3d(var(--left-start), 0, 0);
  }
  100% {
    transform: translate3d(var(--left-end), var(--bottom-end), 0);
  }
}
</style>
