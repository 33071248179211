import _concat from 'lodash/concat';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import _reduce from 'lodash/reduce';
import _take from 'lodash/take';

import logger from '@/logger';
import notifications from '@/services/notifications';

import jobApi from '@/services/api/jobs';
import recommendationsApi from '@/services/api/recommendations';
import reportsApi from '@/services/api/reports';

const MIN_FETCH_DELAY = 450;
const MAX_JOBS_PER_CATEGORY = 10;

const categoryAll = {
  _id: 'all-categories',
  name: 'All Categories',
};

const state = {
  recommendedJobs: {
    selectedCategoryId: categoryAll._id,
    selectedJob: {
      job: {},
      isLoading: false,
      isJobSlideoutPanelShown: false,
    },
    jobsByCategories: [],
    jobInfoById: {},
    isLoading: false,
    error: null,
  },

  profileStatistics: {
    isLoading: false,
    statistics: [],
  },
};

const actions = {
  // Recommended Jobs
  setSelectedJob({ commit }, job) {
    commit('setSelectedJob', job);
  },

  setSelectedCategoryId({ commit }, id) {
    commit('setSelectedCategoryId', id);
  },

  setIsJobSlideoutPanelShown({ commit }, isShown) {
    commit('setIsJobSlideoutPanelShown', isShown);
  },

  async setProposal({ commit, dispatch }, { jobId, proposal }) {
    commit('setProposal', { jobId, proposal });
    await dispatch('fetchJobStatistics', jobId);
  },

  async fetchRecommendedJobs({ commit }) {
    commit('setIsJobsLoading', true);

    try {
      const jobsByCategories = await recommendationsApi.getJobsForFreelancer();
      commit('setJobsByCategories', jobsByCategories);
    } catch (error) {
      commit('setJobsError', error);
      logger.error('Fetch jobs error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsJobsLoading', false);
    }
  },

  async fetchJobData({ commit, dispatch }, { id }) {
    commit('setIsJobDataLoading', true);

    // eslint-disable-next-line no-promise-executor-return
    const minFetchDelayP = new Promise(resolve => setTimeout(resolve, MIN_FETCH_DELAY));

    await Promise.all([dispatch('fetchJobStatistics', id), minFetchDelayP]);

    commit('setIsJobDataLoaded', id);
    commit('setIsJobDataLoading', false);
  },

  async fetchJobStatistics({ commit }, id) {
    try {
      const statistics = await jobApi.getJobStatisticsForFreelancer(id);
      commit('setJobStatistics', { jobId: id, statistics });
    } catch (error) {
      commit('setJobsError', error);
      logger.error('Fetch job statistics error', error);
      notifications.showGolanceError(error);
    }
  },

  async dismissJob({ commit }, { jobId, categoryId }) {
    try {
      await recommendationsApi.dismissJob(jobId);
      commit('dismissJob', { jobId, categoryId });
    } catch (error) {
      commit('setJobsError', error);
      logger.error('Dismiss job error', error);
      notifications.showGolanceError(error);
    }
  },

  // Profile Info
  async fetchProfileStatistics({ commit }) {
    commit('setIsProfileStatisticsLoading', true);

    try {
      const statistics = await reportsApi.getFreelancerProfileStatistics();
      commit('setProfileStatistics', statistics);
    } catch (error) {
      commit('setProfileStatisticsError', error);
      logger.error('Fetch jobs error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsProfileStatisticsLoading', false);
    }
  },
};

const mutations = {
  // Recommended Jobs
  setIsJobsLoading({ recommendedJobs }, isLoading) {
    recommendedJobs.isLoading = isLoading;
  },
  setJobsByCategories({ recommendedJobs }, jobsByCategories) {
    const allJobs = _reduce(jobsByCategories, (memo, category) => _concat(memo, category.jobs), []);

    if (jobsByCategories.length > 1) {
      const allJobsSorted = _orderBy(allJobs, ['publishedOn'], ['desc']);
      const allCategories = {
        category: categoryAll,
        jobs: allJobsSorted,
      };

      jobsByCategories.unshift(allCategories);
    }

    recommendedJobs.jobsByCategories = jobsByCategories;
  },
  setJobsError({ recommendedJobs }, error) {
    recommendedJobs.error = error;
  },
  setSelectedJob({ recommendedJobs }, job) {
    recommendedJobs.selectedJob.job = job;
  },
  setSelectedCategoryId({ recommendedJobs }, id) {
    recommendedJobs.selectedCategoryId = id;
  },
  setIsJobDataLoading({ recommendedJobs }, isLoading) {
    recommendedJobs.selectedJob.isLoading = isLoading;
  },
  setIsJobDataLoaded({ recommendedJobs }, jobId) {
    if (!recommendedJobs.jobInfoById[jobId]) {
      recommendedJobs.jobInfoById[jobId] = {};
    }
    recommendedJobs.jobInfoById[jobId].isLoaded = true;
  },
  setJobStatistics({ recommendedJobs }, { jobId, statistics }) {
    if (!recommendedJobs.jobInfoById[jobId]) {
      recommendedJobs.jobInfoById[jobId] = {};
    }
    recommendedJobs.jobInfoById[jobId].statistics = statistics;
  },
  setProposal({ recommendedJobs }, { jobId, proposal }) {
    if (!recommendedJobs.jobInfoById[jobId]) {
      recommendedJobs.jobInfoById[jobId] = {};
    }
    recommendedJobs.jobInfoById[jobId].proposal = proposal;
  },
  setIsJobSlideoutPanelShown({ recommendedJobs }, isShown) {
    recommendedJobs.selectedJob.isJobSlideoutPanelShown = isShown;
  },
  dismissJob({ recommendedJobs }, { jobId, categoryId }) {
    const deleteJobFromCategory = (dismissedJobId, dismissedCategoryId) => {
      let categoryIndex = _findIndex(recommendedJobs.jobsByCategories, jobsByCategory => jobsByCategory.category._id === dismissedCategoryId);

      if (categoryIndex === -1) return;

      const jobsByCategory = recommendedJobs.jobsByCategories[categoryIndex];

      const jobIndex = _findIndex(jobsByCategory.jobs, { _id: dismissedJobId });

      if (jobIndex !== -1) {
        jobsByCategory.jobs.splice(jobIndex, 1);
      }

      if (_isEmpty(jobsByCategory.jobs)) {
        recommendedJobs.jobsByCategories.splice(categoryIndex, 1);

        if (!_isEmpty(recommendedJobs.jobsByCategories)) {
          if (recommendedJobs.jobsByCategories.length === 2) {
            recommendedJobs.jobsByCategories.splice(0, 1);
            categoryIndex -= 1;
          }
          const newIndex = categoryIndex > 0 ? categoryIndex - 1 : 0;
          recommendedJobs.selectedCategoryId = recommendedJobs.jobsByCategories[newIndex].category._id;
          recommendedJobs.selectedJob.job = recommendedJobs.jobsByCategories[newIndex].jobs[0];
        }
      } else if (recommendedJobs.selectedCategoryId === dismissedCategoryId && jobIndex < MAX_JOBS_PER_CATEGORY) {
        const newIndex = jobIndex > 0 ? jobIndex - 1 : 0;
        recommendedJobs.selectedJob.job = jobsByCategory.jobs[newIndex];
      }
    };

    deleteJobFromCategory(jobId, categoryId);
    if (recommendedJobs.jobsByCategories.length > 2) {
      deleteJobFromCategory(jobId, categoryAll._id);
    }
  },

  // Profile Info
  setIsProfileStatisticsLoading({ profileStatistics }, isLoading) {
    profileStatistics.isLoading = isLoading;
  },
  setProfileStatistics({ profileStatistics }, statistics) {
    profileStatistics.statistics = statistics;
  },
};

const getters = {
  recommendedJobs: ({ recommendedJobs }) => recommendedJobs,
  jobsByCategories: ({ recommendedJobs }) => {
    const limitedJobsByCategories = _map(recommendedJobs.jobsByCategories, category => {
      const categoryJobs = {
        category: category.category,
        jobs: _take(category.jobs, MAX_JOBS_PER_CATEGORY),
      };
      return categoryJobs;
    });
    return limitedJobsByCategories;
  },
  job: ({ recommendedJobs }) => recommendedJobs.selectedJob.job,
  selectedJobData: ({ recommendedJobs }) => {
    const selectedJobId = recommendedJobs.selectedJob.job._id;
    return recommendedJobs.jobInfoById[selectedJobId];
  },

  // Profile Info
  statistics: ({ profileStatistics }) => profileStatistics,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
