<template>
  <div
    class="option-item"
    @click="handleOptionChange"
  >
    <gl-checkbox
      :value="checkboxValue"
      class="option-item__checkbox"
      @keyboard-change="handleOptionChange"
    />

    <component
      :is="filterConfig.optionComponent"
      v-if="filterConfig.optionComponent"
      :option="option"
      :selected="checkboxValue"
      :search-query="searchQuery"
    />
    <p
      v-else
      class="option-item__label"
    >
      {{ optionLabel }}
    </p>
  </div>
</template>

<script>
  import _concat from 'lodash/concat';
  import _get from 'lodash/get';

  import { GlCheckbox } from 'uikit/components';

  export default {
    components: {
      GlCheckbox,
    },
    props: {
      option: {
        type: [String, Object],
        required: true,
      },
      filterConfig: {
        type: Object,
        required: true,
      },
      filters: {
        type: Object,
        required: true,
      },
      labelKey: {
        type: String,
        required: true,
      },
      valueKey: {
        type: String,
        required: true,
      },
      returnObject: {
        type: Boolean,
        default: false,
      },
      searchQuery: {
        type: String,
        default: '',
      },
    },

    computed: {
      filterKey() {
        return this.filterConfig.key;
      },
      isMultiple() {
        return this.filterConfig.multiple;
      },
      isRequired() {
        return !!this.filterConfig.required;
      },
      relatedFilter() {
        return this.filters[this.filterKey] || [];
      },
      isFirstOption() {
        return !this.relatedFilter.length;
      },
      checkboxValue() {
        if (this.isMultiple) {
          if (this.returnObject) {
            return !!this.relatedFilter.find(value => value[this.valueKey] === this.option[this.valueKey]);
          }
          return !!this.relatedFilter.find(value => value === this.option[this.valueKey]);
        }

        if (this.returnObject) {
          return this.relatedFilter[this.valueKey] === this.option[this.valueKey];
        }

        return this.relatedFilter === this.option[this.valueKey];
      },
      optionLabel() {
        return _get(this.option, this.labelKey);
      },
    },

    methods: {
      handleOptionChange() {
        const updatedFilter = this.getUpdatedFilter();
        if (this.isRequired && !updatedFilter) {
          return;
        }

        this.$emit('change', { [this.filterKey]: updatedFilter });
      },
      getUpdatedFilter() {
        let updatedFilter;

        if (this.isMultiple) {
          updatedFilter = this.getMultipleUpdatedFilter();
        } else {
          updatedFilter = this.getSingleUpdatedFilter();
        }

        return updatedFilter;
      },
      getMultipleUpdatedFilter() {
        let updatedFilter;
        let isSelected;

        if (this.returnObject) {
          updatedFilter = this.option;
          isSelected = this.relatedFilter.find(filter => filter[this.valueKey] === this.option[this.valueKey]);
        } else {
          updatedFilter = this.option[this.valueKey];
          isSelected = this.relatedFilter.includes(this.option[this.valueKey]);
        }

        if (this.isFirstOption) {
          updatedFilter = [updatedFilter];
        } else if (isSelected) {
          if (this.returnObject) {
            updatedFilter = this.relatedFilter.filter(value => value[this.valueKey] !== this.option[this.valueKey]);
          } else {
            updatedFilter = this.relatedFilter.filter(value => value !== this.option[this.valueKey]);
          }
        } else {
          updatedFilter = _concat(this.relatedFilter, updatedFilter);
        }

        return updatedFilter;
      },
      getSingleUpdatedFilter() {
        let updatedFilter;
        let isSelected;

        if (this.returnObject) {
          updatedFilter = this.option;
          isSelected = this.relatedFilter[this.valueKey] === this.option[this.valueKey];
        } else {
          updatedFilter = this.option[this.valueKey];
          isSelected = this.relatedFilter === this.option[this.valueKey];
        }

        if (isSelected) {
          updatedFilter = null;
        }

        return updatedFilter;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .option-item {
    @apply flex items-center;
    gap: 8px;
    padding: 8px;
    border-radius: 8px;
    transition: 0.1s ease background-color;
    cursor: pointer;

    &__label {
      @apply text-14 text-text-black;
    }

    &__checkbox:focus,
    &__checkbox:focus-visible {
      outline: none;
    }

    &__checkbox {
      & >>> .checkbox {
        margin-right: 0;
        margin-top: 0;
      }
    }

    &:hover,
    &:focus-within {
      @apply bg-foundation-gray-2;
    }
  }
</style>
