<template>
  <label
    class="radio"
    :class="{ disabled: disabled, 'input-error': error }"
  >
    <slot>
      {{ label }}
    </slot>
    <input
      v-model="radioModel"
      :value="value"
      :disabled="disabled"
      type="radio"
      :name="name"
    >
    <span class="checkmark"></span>
  </label>
</template>

<script>
  export default {
    model: {
      prop: 'model',
      event: 'change',
    },

    props: {
      label: {
        type: String,
        default: null,
      },
      value: {
        type: [String, Number, Boolean, Object],
        default: null,
      },
      model: {
        type: [String, Number, Boolean, Object],
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      name: {
        type: String,
        default: '',
      },
      error: {
        type: [String, Boolean],
        default: false,
      },
    },

    computed: {
      radioModel: {
        get() {
          return this.model;
        },
        set() {
          this.$emit('change', this.value);
        },
      },
    },
  };
</script>

<style lang="postcss" scoped>
.radio {
  @apply text-text-black text-14;
  display: block;
  position: relative;
  padding-left: 27px;
  cursor: pointer;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      border: none;
      box-shadow: 0 0 0px 5px inset #3f56b6;
    }

    &:focus-visible ~ .checkmark {
      @apply border-icon-hover;
    }
  }

  &:hover .checkmark {
    @apply border-foundation-gray-6;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover .checkmark,
    & input:checked ~ .checkmark,
    & input:focus-visible ~ .checkmark {
      @apply border-foundation-gray-5;
    }
  }

  &.input-error, &.input-error.disabled {
    &:hover .checkmark,
    & .checkmark,
    & input:checked ~ .checkmark,
    & input:focus-visible ~ .checkmark {
      @apply border-persian-red;
    }
  }
}

.checkmark {
  @apply border border-foundation-gray-4;
  position: absolute;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.2s ease-in;
  box-sizing: border-box;
}
</style>
