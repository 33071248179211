/* eslint-disable no-console */

import config from '@/config';

import { captureException } from '@/services/infrastructure/sentry';

import ENVIROMENTS from '@/constants/config';

const isNotProduction = config.env !== ENVIROMENTS.PRODUCTION;
const isSentryEnabled = config.sentry?.enabled;

const log = (...args) => {
  if (isNotProduction) {
    console.log(...args);
  }
};

const error = (...args) => {
  if (isNotProduction) {
    console.error(...args);
  }

  if (isSentryEnabled) {
    captureException(args);
  }
};

export default {
  log,
  error,
};
