<template>
  <div
    v-click-outside="handleClose"
    class="date-picker"
  >
    <div>
      <slot
        :handleToggle="handleToggle"
        :handleClose="handleClose"
        :isOpened="isOpened"
      ></slot>
    </div>
    <transition name="fade">
      <gl-drop-down
        v-if="isOpened"
        is-fixed
        class="date-picker__dropdown"
      >
        <div class="dropdown__body">
          <gl-date-picker
            v-model="dateModel"
            :lang="lang"
            placeholder="Select Date"
            label=""
            required
            inline
            class="date-picker__picker"
            @input="handleClose"
            @calendar-change="handleCalendarChange"
          />
        </div>
      </gl-drop-down>
    </transition>
  </div>
</template>

<script>
  import GlDatePicker from './index.vue';
  import GlDropDown from '../DropDown.vue';

  const defaultLang = {
    formatLocale: {
      firstDayOfWeek: 1,
    },
  };

  export default {
    components: {
      GlDatePicker,
      GlDropDown,
    },
    model: {
      event: 'change',
      prop: 'date',
    },
    props: {
      date: {
        type: Date,
        required: true,
      },
      lang: {
        type: Object,
        default: () => defaultLang,
      },
    },
    data() {
      return {
        isOpened: false,
      };
    },
    computed: {
      dateModel: {
        get() {
          return this.date;
        },
        set(date) {
          this.$emit('change', date);
        },
      },
    },
    watch: {
      isOpened(val) {
        const eventName = val ? 'open' : 'close';
        this.$emit(eventName, val);
      },
    },
    methods: {
      handleToggle() {
        this.isOpened = !this.isOpened;
      },
      handleClose() {
        this.isOpened = false;
      },
      handleCalendarChange(...args) {
        this.$emit('calendar-change', ...args);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .date-picker {
    @apply relative;
    width: fit-content;
  }

  .date-picker__dropdown.filter-dropdown {
    z-index: 1000;
    border-radius: 12px;
  }

  .date-picker__inputs {
    @apply flex items-center justify-between;
    gap: 16px;
    padding: 16px;

    & >>> .input-wrapper {
      flex: 1;
    }
  }
  .date-picker__picker {
    & >>> .mx-datepicker-main {
      border-radius: 0 0 12px 12px;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-leave {
    opacity: 0;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @media (max-width: 750px) {
    .date-picker__picker {
      & >>> .mx-range-wrapper {
        & .mx-calendar.mx-calendar-panel-date:nth-child(2) {
          display: none;
        }
      }
    }

    .date-picker__inputs {
      @apply flex-col items-stretch;
    }
  }
</style>
