<template>
  <img
    :width="size"
    :height="size"
    :style="{ '--size': `${size}px` }"
    :class="[{ bordered }]"
    :src="avatarUrl"
    class="avatar"
    :alt="avatarUrl"
    loading="lazy"
  >
</template>

<script>
  export default {
    props: {
      avatarUrl: {
        type: String,
        required: true,
      },
      size: {
        type: [String, Number],
        default: 44,
      },
      bordered: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .avatar {
    @apply bg-white;

    border-radius: 50%;
    width: var(--size);
    min-width: var(--size);
    height: var(--size);
    object-fit: cover;

    &.bordered {
      border: 2px solid white;
    }

    &:focus,
    &:active {
      outline: 2px auto -webkit-focus-ring-color;
    }
  }
</style>
