<template>
  <transition name="from-bottom">
    <div
      v-if="buttonShown"
      class="to-top"
      @click="scrollTop"
    >
      <arrow-top-icon />
      <span>Up</span>
    </div>
  </transition>
</template>

<script>
  import ArrowTopIcon from './icons/ArrowTopIcon.vue';

  export default {
    components: { ArrowTopIcon },
    data() {
      return {
        buttonShown: false,
      };
    },
    mounted() {
      this.addListener();
    },
    destroyed() {
      this.removeListener();
    },
    methods: {
      scrollTop() {
        window.scrollBy({ top: -window.scrollY, behavior: 'smooth' });
      },
      setButtonShown() {
        if (window.scrollY > 120 && !this.buttonShown) {
          this.buttonShown = true;
        } else if (window.scrollY <= 120 && this.buttonShown) {
          this.buttonShown = false;
        }
      },
      addListener() {
        window.addEventListener('scroll', this.setButtonShown);
        this.setButtonShown();
      },
      removeListener() {
        window.removeEventListener('scroll', this.setButtonShown);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .to-top {
    @apply fixed flex justify-center items-center bg-wild-strawberry cursor-pointer;
    left: 86px;
    bottom: -1px;
    height: 48px;
    width: 80px;
    z-index: 99;
    border-radius: 4px 4px 0 0;
    & span {
      @apply font-primary font-semibold text-white;
      padding-top: 2px;
      margin-left: 5px;
    }
  }

  .from-bottom-enter-active,
  .from-bottom-leave-active {
    transition: all 0.6s;
  }
  .from-bottom-enter {
    transform: translateY(100%);
  }
  .from-bottom-leave-to {
    transform: translateY(100%);
  }
</style>
