import { getRouteToRedirectFromHome } from '../routerServices';

import freelancerRoutes from './freelancer';
import clientRoutes from './client';
import accountRoutes from './account';
import serviceRoutes from './service';
import jobRoutes from './job';
import invoiceRoutes from './invoice';
import marketplaceRoutes from './marketplace';
import proposalRoutes from './proposal';
import offerRoutes from './offer';
import walletRoutes from './wallet';
import contractRoutes from './contract';
import companyRoutes from './company';
import authRoutes from './auth';
import affiliateRoutes from './affiliate';
import userRoutes from './user';
import inboxRoutes from './inbox';
import paymentRequestRoutes from './paymentRequest';
import disputeRoutes from './dispute';
import welcomeRoutes from './welcome';
import notificationRoutes from './notification';
import agenciesRoutes from './agencies';
import tasksRoutes from './tasks';
import timeOffRoutes from './time-off';

const PageNotFound = () => import(/* webpackChunkName: "PageNotFound", webpackPrefetch: true  */ '@/views/PageNotFound/PageNotFound');
const AccessDenied = () => import(/* webpackChunkName: "AccessDenied", webpackPrefetch: true  */ '@/views/AccessDenied/AccessDenied.vue');

export default [
  {
    name: 'home',
    path: '/',
    beforeEnter: (to, _, next) => next({
      query: to.query,
      ...getRouteToRedirectFromHome(),
    }),
  },

  ...freelancerRoutes,
  ...clientRoutes,
  ...serviceRoutes,
  ...accountRoutes,
  ...jobRoutes,
  ...proposalRoutes,
  ...invoiceRoutes,
  ...marketplaceRoutes,
  ...offerRoutes,
  ...walletRoutes,
  ...contractRoutes,
  ...companyRoutes,
  ...authRoutes,
  ...affiliateRoutes,
  ...userRoutes,
  ...inboxRoutes,
  ...paymentRequestRoutes,
  ...disputeRoutes,
  ...welcomeRoutes,
  ...notificationRoutes,
  ...agenciesRoutes,
  ...tasksRoutes,
  ...timeOffRoutes,

  {
    name: 'page-not-found',
    path: '/404',
    component: PageNotFound,
    meta: { requiresAuth: false },
  },
  {
    name: 'access-denied',
    path: '/denied',
    component: AccessDenied,
    meta: { requiresAuth: false },
  },
  {
    path: '*',
    redirect: () => ({ name: 'page-not-found' }),
  },
];
