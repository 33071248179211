<template>
  <label
    class="segment-button-container"
    :class="[size, { checked }]"
  >
    <input
      :id="value"
      v-model="radioModel"
      :value="value"
      type="radio"
      checked
      class="radio-button"
      :name="name"
    >
    <div
      class="segment-button"
      :class="[size, { checked }]"
    >
      <slot>
        <span class="segment-button__content">
          <gl-icon
            v-if="icon"
            :name="icon"
            :size="iconSize"
          />
          <span
            v-if="title"
            class="title"
          >
            {{ title }}
          </span>
          <gl-num-indicator
            v-if="count"
            variant="blue"
          >
            {{ count }}
          </gl-num-indicator>
        </span>
      </slot>
    </div>
  </label>
</template>

<script>
  import GlIcon from 'uikit/icons/BaseIcon.vue';
  import GlNumIndicator from 'uikit/components/NumIndicator/NumIndicator.vue';

  export default {
    components: {
      GlIcon,
      GlNumIndicator,
    },
    props: {
      title: {
        type: String,
        default: null,
      },
      value: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        default: null,
      },
      count: {
        type: Number,
        default: 0,
      },
      checked: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'large',
        validator: val => ['large', 'medium', 'small'].includes(val),
      },
    },
    computed: {
      radioModel: {
        get() {
          return this.model;
        },
        set() {
          this.$emit('change', this.value);
        },
      },
      iconSize() {
        if (this.size === 'small') {
          return 16;
        }

        return 24;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .segment-button-container {
    @apply relative;

    &:focus-within, &:focus-visible {
      @apply border-royal-blue;
    }
  }

  .segment-button {
    @apply bg-foundation-gray-1 text-14 text-text-black border border-transparent font-medium;
    padding: 6px 16px;
    border-radius: 24px;
    cursor: pointer;
    text-align: center;
    transition: 220ms ease-in-out all;
    outline: none;

    &:hover {
      @apply bg-white;
    }

    &.checked {
      @apply bg-white text-text-link;
    }

    &.large {
      padding: 6px 16px;
      border-radius: 24px;
    }

    &.medium {
      padding: 2px 12px;
      border-radius: 24px;
    }

    &.small {
      padding: 2px 8px;
      border-radius: 24px;
    }
  }

  .segment-button__content {
    @apply flex items-center;
    gap: 8px;
  }

  .radio-button {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    appearance: none;
    -webkit-appearance: none;

    &:focus-visible ~ .segment-button {
      @apply border-royal-blue;
    }
  }
</style>
