<template>
  <action-button
    :label="label"
    class="row-action"
    :class="{ danger, disabled }"
    @click="handleClick"
  >
    <template #icon>
      <slot name="icon"></slot>
    </template>
  </action-button>
</template>

<script>
  import ActionButton from '@/components/buttons/ActionButton.vue';

  export default {
    components: {
      ActionButton,
    },
    props: {
      label: {
        type: String,
        default: '',
      },
      danger: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleClick(ev) {
        if (this.disabled) {
          return;
        }

        this.$emit('click', ev);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .row-action {
    @apply text-text-black font-primary text-14 flex items-center justify-start;
    cursor: pointer;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.1s ease-in all;
    white-space: nowrap;
    height: 38px;

    &.danger {
      @apply text-text-error;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        @apply bg-transparent;
      }
    }

    &:hover {
      @apply bg-foundation-gray-2;
    }
  }
</style>
