import _get from 'lodash/get';

// Trims all tags in string except for <em> (for search matches).

const htmlAsText = (el, binding, vnode) => {
  const except = _get(vnode, 'data.attrs.html-as-text-except', []);

  except.push('em');

  const textContent = (binding.value || '').toString();

  const exceptTagsRegExp = except.join('|');
  const replaceRegExp = new RegExp(`(<(?!/?((${exceptTagsRegExp})\\b))[^>]+>)`, 'gm');

  el.innerHTML = textContent ? textContent.replace(replaceRegExp, ' ').replace(/\s\s+/g, ' ') : '';
};

export default {
  bind(el, binding, vnode) {
    htmlAsText(el, binding, vnode);
  },
  componentUpdated(el, binding, vnode) {
    htmlAsText(el, binding, vnode);
  },
};
