<template>
  <div
    class="table-cell"
    :class="{'with-actions': inlineActions, compact, selectable}"
  >
    <div
      class="table-cell__desktop"
      :class="[column.type, { main: column.main, sortable: column.sort }]"
    >
      <gl-skeleton-loader
        v-if="loading"
        class="table-cell__loading"
      />
      <template v-else>
        <slot
          name="cell"
          :field="column.field"
        >
          {{ fieldValue }}
        </slot>
      </template>
    </div>
    <div
      class="table-cell__mobile"
      :class="[column.type, { main: column.main, vertical: column.mobileBlock }]"
    >
      <span
        v-if="column.title"
        class="cell__heading"
      > {{ column.title }}</span>
      <span
        class="cell__content"
        :class="{'without-header': !column.title}"
      >
        <gl-skeleton-loader
          v-if="loading"
          class="table-cell__loading"
        />
        <template v-else>
          <slot
            name="cell"
            :field="column.field"
          >
            {{ fieldValue }}
          </slot>
        </template>
      </span>
    </div>
  </div>
</template>

<script>
  import _get from 'lodash/get';
  import GlSkeletonLoader from 'uikit/components/skeletons/SkeletonLoader.vue';

  export default {
    components: {
      GlSkeletonLoader,
    },
    props: {
      column: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      inlineActions: {
        type: Boolean,
        default: false,
      },
      selectable: {
        type: Boolean,
        default: false,
      },
      compact: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      fieldValue() {
        return _get(this.record, this.column.field);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .table-cell {
    @apply text-text-black;
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
    width: 100%;

    &.compact {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .table-cell__loading {
    width: 100%;
    height: 20px;
  }

  .table-cell__desktop {
    padding: 0 12px;

    &.number {
      display: flex;
      justify-content: flex-end;

      &.sortable {
        padding-right: 32px;
      }
    }
    &.centered {
      display: flex;
      justify-content: center;
    }
  }

  .table-cell__mobile {
    display: none;
  }

  .cell__heading {
    @apply inline-flex items-start font-semibold;
    padding-right: 8px;
    width: 40%;
    flex-shrink: 0;

    & + .cell__content {
      width: calc(60%);
    }
  }

  @media (max-width: 576px) {
    .table-cell {

      &.with-actions {
        padding-right: 32px;
      }

      &.selectable.with-actions {
        padding-right: 0;
      }

      & + .table-cell {
        margin-top: 24px;
      }

      &.compact {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .table-cell__desktop,
    .table-cell__desktop.centered,
    .table-cell__desktop.number {
      display: none;
    }

    .table-cell__mobile {
      @apply flex;
      text-overflow: unset;

      &.vertical {
        @apply flex-col justify-start;
        gap: 8px;

        & .cell__content, & .cell__heading {
          width: 100%;
        }

        & .cell__content {
          text-align: left;
        }
      }

      & .cell__content {
        text-align: right;
      }
    }
  }
</style>
