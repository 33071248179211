const eventTypesByTab = {
  jobs: [
    'job_created',
    'job_posted',
    'job_edited',
    'job_made_private',
    'job_made_public',
    'job_closed',
    'job_reopened',
    'proposal_new',
    'proposal_shortlisted',
    'proposal_unlisted',
    'proposal_declined',
    'proposal_withdrawn',
    'offer_sent',
    'offer_declined',
    'offer_withdrawn',
  ],
  contracts: [
    'contract_created',
    'contract_paused',
    'contract_resumed',
    'contract_closed',
    'contract_reopened',
    'time_tracking_started',
    'overtime_logged',
    'time_manually_logged',
    'new_task_started',
  ],
};

export { eventTypesByTab }; // eslint-disable-line import/prefer-default-export
