<template>
  <div class="heading">
    <gl-base-icon
      v-if="icon"
      :name="icon"
      size="20"
      color="#6F7689"
      class="heading__icon"
    />
    <span
      :title="title"
      class="heading__title"
      :class="{ 'sort-handle': sortEnabled }"
      @click="handleHeaderClick"
    >
      {{ title }}
    </span>
    <gl-base-popover
      v-if="note"
      trigger="click"
      container=".table-relative-container"
      placement="top"
      class="note-popover"
      popover-inner-class="column-popover__inner"
      :closeable="false"
      :hidden="!note"
    >
      <template #trigger>
        <gl-base-icon
          class="note"
          name="filled-rounded-info"
          size="12"
          color="#6F7689"
        />
      </template>
      <div>{{ note }}</div>
    </gl-base-popover>

    <sort-icon
      v-if="sortEnabled"
      :sort="sort"
      class="sort-icon"
      @click.native="handleHeaderClick"
    />
  </div>
</template>

<script>
  import { GlBaseIcon, GlBasePopover } from 'uikit/components';

  import SortIcon from './SortIcon.vue';

  export default {
    components: {
      GlBasePopover,
      GlBaseIcon,
      SortIcon,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      sort: {
        type: String,
        default: null,
      },
      note: {
        type: String,
        default: null,
      },
      icon: {
        type: String,
        default: null,
      },
      sortEnabled: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleHeaderClick() {
        if (!this.sortEnabled) {
          return;
        }

        let sortDirection;

        if (!this.sort) {
          sortDirection = 'asc';
        } else {
          sortDirection = this.sort === 'asc' ? 'desc' : 'asc';
        }

        this.$emit('sortChange', sortDirection);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    position: relative;
    gap: 4px;
  }

  .note-popover {
    margin-left: 4px;
    margin-right: 4px;
  }

  .heading__icon {
    flex-shrink: 0;
  }

  .heading__title {
    @apply font-primary text-black text-14;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    &.sort-handle {
      cursor: pointer;
    }
  }
</style>
