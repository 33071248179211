<template>
  <table-wrapper
    v-slot="{ gridTemplateColumns }"
    :grid-columns-width="gridColumnsWidth"
    :selectable="selectable"
    :expandable-rows="expandableRows"
    :inline-actions="inlineActions"
    :columns="columns"
    :editable="editable"
  >
    <div
      v-if="data.length || loading"
      class="table__rows"
      :style="{ '--table-col-width': gridTemplateColumns }"
    >
      <template v-if="!data.length">
        <table-row
          v-for="idx in loadingRowsCount"
          :key="idx"
          :columns="columns"
          :record="{}"
          :selectable="false"
          :inline-actions="false"
          :selected="false"
          :loading="true"
          :compact="compact"
          class="table__row"
        />
      </template>
      <template v-else>
        <template v-if="expandableRows">
          <expandable-table-row
            v-for="(record, index) in data"
            :key="record._id"
            :columns="columns"
            :record="record"
            :inline-actions="inlineActions"
            :scrolled="scrolled"
            :loading="loading"
            :clickable="clickableRows"
            :sub-record-spacing="subRecordSpacing"
            :grid-template-columns="gridTemplateColumns"
            :class="{ 'with-actions': inlineActions, 'editable-columns': editable }"
            class="table__row expandable"
            @click="handleRowClick"
          >
            <template #cell="scope">
              <slot
                name="cell"
                v-bind="scope"
                :row-index="index"
              ></slot>
            </template>
            <template
              v-if="$scopedSlots.expandIcon"
              #expandIcon="scope"
            >
              <slot
                name="expandIcon"
                v-bind="scope"
              ></slot>
            </template>
            <template
              v-if="$scopedSlots.inlineActions"
              #inlineActions="scope"
            >
              <slot
                name="inlineActions"
                v-bind="scope"
              ></slot>
            </template>
          </expandable-table-row>
        </template>
        <template v-else>
          <table-row
            v-for="(record, index) in data"
            :key="record._id"
            :columns="columns"
            :record="record"
            :selectable="selectable"
            :clickable="clickableRows"
            :inline-actions="inlineActions"
            :selected="!!selectedRecords[record._id]"
            :scrolled="scrolled"
            :compact="compact"
            :loading="loading"
            :class="{ 'with-actions': inlineActions, 'with-expandable-rows': expandableRows, 'editable-columns': editable }"
            class="table__row"
            @selectChange="handleRecordSelectChange"
            @click="handleRowClick"
          >
            <template #cell="scope">
              <slot
                name="cell"
                v-bind="scope"
                :record="record"
                :row-index="index"
              ></slot>
            </template>
            <template #actionTrigger>
              <slot name="actionTrigger"></slot>
            </template>
            <template
              v-if="$scopedSlots.inlineActions"
              #inlineActions
            >
              <slot
                name="inlineActions"
                :record="record"
              ></slot>
            </template>
            <template
              v-else-if="$scopedSlots.inlineActionButton"
              #inlineActionButton
            >
              <slot
                name="inlineActionButton"
                :record="record"
              ></slot>
            </template>
          </table-row>
        </template>
      </template>
    </div>
    <slot
      v-else-if="!data.length"
      name="emptyState"
    >
      <empty-results />
    </slot>
  </table-wrapper>
</template>

<script>
  import EmptyResults from './EmptyResults.vue';
  import TableWrapper from './TableWrapper.vue';
  import TableRow from './TableRow.vue';
  import ExpandableTableRow from './ExpandableTableRow.vue';

  export default {
    components: {
      EmptyResults,
      TableWrapper,
      TableRow,
      ExpandableTableRow,
    },
    props: {
      columns: {
        type: Array,
        required: true,
      },
      data: {
        type: Array,
        default: () => [],
      },
      expandableRows: {
        type: Boolean,
        default: false,
      },
      clickableRows: {
        type: Boolean,
        default: false,
      },
      selectable: {
        type: Boolean,
        default: true,
      },
      editable: {
        type: Boolean,
        default: true,
      },
      selectedRecords: {
        type: Object,
        default: () => ({}),
      },
      inlineActions: {
        type: Boolean,
        default: false,
      },
      compact: {
        type: Boolean,
        default: false,
      },
      gridColumnsWidth: {
        type: String,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      loadingRowsCount: {
        type: Number,
        default: 6,
      },
      scrolled: {
        type: Boolean,
        default: false,
      },
      subRecordSpacing: {
        type: Number,
        default: 42,
      },
    },
    methods: {
      handleRecordSelectChange(...args) {
        this.$emit('selectChange', ...args);
      },
      handleRowClick(...args) {
        this.$emit('rowClick', ...args);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .table__rows {
    position: relative;
  }

  .table__row {
    @apply font-primary;
    padding-left: 8px;
    padding-right: 8px;
    justify-content: flex-start;

    &.with-actions,
    &.editable-columns,
    &.expandable.editable-columns {
      padding-right: 8px;
    }

    &.expandable {
      padding: 0 24px 4px 4px;
      transition: 0.2s ease background-color, 0.3s max-height, 0.1s linear grid-template-columns;
    }
  }

  .table__row + .table__row {
    margin-top: 8px;
    &.compact {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 576px) {
    .table__row {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: unset;

      &.with-actions,
      &.editable-columns {
        padding-right: 24px;
      }

      &.expandable {
        padding: 0 24px 0 4px;
      }

      & + .table__row.expandable {
        margin-top: 8px;
      }

      & + .table__row {
        margin-top: 24px;
      }

      &.compact + .table__row {
        margin-top: 8px;
      }
    }
  }
</style>
