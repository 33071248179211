const paymentTypes = {
  Hourly: 'hourly',
  Fixed: 'fixed',
};

const paymentTypeLabels = {
  [paymentTypes.Hourly]: 'Hourly Rate',
  [paymentTypes.Fixed]: 'Fixed Cost',
};

const currencies = {
  USD: 'USD',
  EUR: 'EUR',
};

const PaymentCardType = {
  MASTERCARD: 'Mastercard',
  AMERICAN_EXPRESS: 'American Express',
  VISA: 'Visa',
  DISCOVER: 'Discover',
  DINERS_CLUB: 'Diners Club',
};

const BillingPaymentMethodStatus = {
  APPROVED: 'approved',
  DECLINED: 'declined',
  PENDING: 'pending',
};

const BillingPaymentMethodExpiringStatus = {
  EXPIRED: 'expired',
  EXPIRES: 'expires',
};

export {
  paymentTypes,
  paymentTypeLabels,
  currencies,
  PaymentCardType,
  BillingPaymentMethodExpiringStatus,
  BillingPaymentMethodStatus,
};
