<template>
  <transition
    name="modal"
    @enter="handleModalShow"
    @leave="handleModalHide"
  >
    <slot></slot>
  </transition>
</template>

<script>
  export default {
    methods: {
      handleModalShow() {
        this.$emit('show');
      },
      handleModalHide() {
        this.$emit('hide');
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .modal-enter,
  .modal-leave-to {
    opacity: 0;

    & >>> .modal-container {
      transform: scale(1.1);
    }
  }

  .modal-enter-active,
  .modal-leave-active {
    transition: 0.3s ease;
  }
</style>
