<template>
  <div class="options-list">
    <options-search
      v-if="filterConfig.withSearch"
      :search="search"
      class="options-list__search"
      @input="handleSearchChange"
    />
    <template v-if="optionsComputed.length">
      <recycle-scroller
        v-if="filterConfig.useRecycleScroll"
        v-slot="{ item }"
        :items="optionsComputed"
        :item-size="recycleOptionHeight"
        :key-field="valueKey"
        class="options-list__options recycle-scroller"
      >
        <option-item
          :option="item"
          :filter-config="filterConfig"
          :filters="filters"
          :label-key="labelKey"
          :value-key="valueKey"
          :return-object="returnObject"
          @change="handleOptionChange"
        />
      </recycle-scroller>
      <div
        v-else
        class="options-list__options simple-scroller"
      >
        <option-item
          v-for="(option, index) in optionsComputed"
          :key="index"
          :option="option"
          :filter-config="filterConfig"
          :filters="filters"
          :label-key="labelKey"
          :value-key="valueKey"
          :return-object="returnObject"
          @change="handleOptionChange"
        />
      </div>
    </template>
    <gl-empty-results
      v-else
    />
  </div>
</template>

<script>
  import { RecycleScroller } from 'vue-virtual-scroller';
  import _cloneDeep from 'lodash/cloneDeep';

  import logger from '@/logger';

  import { GlEmptyResults } from 'uikit/components';

  import OptionItem from './OptionItem.vue';
  import OptionsSearch from './OptionsSearch.vue';

  const DEFAULT_RECYCLE_OPTION_HEIGHT = 40;

  export default {
    components: {
      OptionItem,
      OptionsSearch,
      RecycleScroller,
      GlEmptyResults,
    },
    props: {
      filters: {
        type: Object,
        required: true,
      },
      filterConfig: {
        type: Object,
        required: true,
      },
      labelKey: {
        type: String,
        default: 'name',
      },
      valueKey: {
        type: String,
        default: 'value',
      },
      returnObject: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        loading: false,
        search: '',
        fetchedOptions: [],
      };
    },

    computed: {
      isWithFetch() {
        return !!this.filterConfig.optionsFetcher;
      },
      optionsComputed() {
        if (this.isWithFetch) {
          return this.filterConfig.optionsFetcher.fetchedOptions;
        }

        return this.getSearchOptions();
      },
      recycleOptionHeight() {
        return this.filterConfig.optionHeight || DEFAULT_RECYCLE_OPTION_HEIGHT;
      },
    },

    watch: {
      'filterConfig.key'() {
        if (this.isWithFetch) {
          this.handleOptionsFetch();
        } else {
          this.search = '';
        }
      },
    },

    methods: {
      getSearchOptions() {
        if (!this.search) {
          return _cloneDeep(this.filterConfig.options);
        }

        return this.filterConfig.options.filter(option => option[this.filterConfig.optionsLabelKey].toLowerCase().includes(this.search.toLowerCase()));
      },
      handleOptionChange(filter) {
        this.$emit('change', filter);
      },
      handleSearchChange(value) {
        this.search = value;

        if (this.isWithFetch) {
          this.handleOptionsFetch();
        }
      },
      async handleOptionsFetch() {
        this.loading = true;

        try {
          await this.filterConfig.optionsFetcher.fetch();
        } catch (error) {
          logger.error(error);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>

<style lang="postcss" scoped>
.options-list {
  @apply flex flex-col;
  flex-grow: 1;

  &__search {
    padding: 24px;

    & ~ .options-list__options {
      padding: 8px 16px 24px;
    }
  }

  &__options {
    padding: 24px 16px;
    max-height: 360px;
    overflow: auto;

    &.simple-scroller {
      @apply flex flex-col;
      gap: 4px;
    }
  }
}

@media (max-width: 991px) {
  .options-list__search {
    padding-top: 0;

    & ~ .options-list__options {
      padding: 8px 16px 12px;
    }
  }

  .options-list__options {
     padding: 0 16px 12px;
  }
}
</style>
