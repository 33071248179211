import client, { Api } from './client';

const BASE_URL = '/companies';

export default {
  get(companyId) {
    return client.get([BASE_URL, companyId]);
  },
  delete(companyId) {
    return Api.waitForCommand(client.delete([BASE_URL, companyId]));
  },
  getPublic(companyId) {
    return client.get([BASE_URL, companyId, 'public']);
  },
  create(data) {
    const request = client.post(BASE_URL, data);
    return Api.waitForCommand(request);
  },
  updateUsername(companyId, data) {
    return Api.waitForCommand(client.post([BASE_URL, companyId, 'username'], data));
  },
  getStaff(companyId) {
    return client.get([BASE_URL, companyId, 'staff']);
  },
  getAll(conditions) {
    return client.get(BASE_URL, conditions);
  },
  flag(companyId, params) {
    return client.post([BASE_URL, companyId, 'flag'], params);
  },
  leave(companyId) {
    return Api.waitForCommand(client.post([BASE_URL, companyId, 'leave']));
  },
  getInvitationList(companyId, conditions) {
    return client.get([BASE_URL, companyId, 'invitations'], conditions);
  },
  withdrawInvitation(invitationId) {
    return Api.waitForCommand(client.put([BASE_URL, 'invitation', invitationId, 'withdraw'], {}));
  },
  resendInvitation(companyId, invitationId) {
    return Api.waitForCommand(client.post([BASE_URL, companyId, 'reinvite-user', invitationId], {}));
  },
  inviteUser(companyId, data) {
    return Api.waitForCommand(client.post([BASE_URL, companyId, 'invite-user'], data));
  },
  inviteUsersBatch(companyId, data) {
    return Api.waitForCommand(client.post([BASE_URL, companyId, 'invite-users-batch'], data));
  },
  removeUser(companyId, userId) {
    return Api.waitForCommand(client.delete([BASE_URL, companyId, 'remove-user', userId]));
  },
  updateUserRoles(companyId, userId, data) {
    return Api.waitForCommand(client.post([BASE_URL, companyId, 'user-roles', userId], data));
  },
  updateBackgroundImage(companyId, file, dimensions) {
    return Api.waitForCommand(client.postFile([BASE_URL, companyId, 'background-image'], file, dimensions));
  },
  deleteBackgroundImage(companyId) {
    return Api.waitForCommand(client.delete([BASE_URL, companyId, 'background-image']));
  },
  updateProfileInfo(companyId, data) {
    return Api.waitForCommand(client.post([BASE_URL, companyId], data));
  },
  updateHighlights(companyId, data) {
    return Api.waitForCommand(client.put([BASE_URL, companyId, 'highlights'], data));
  },
  uploadLogo(companyId, file, dimensions) {
    const requestPromise = client.postFile([BASE_URL, companyId, 'logo'], file, dimensions);
    return Api.waitForCommand(requestPromise);
  },
  updateVideo(companyId, video) {
    return Api.waitForCommand(client.post([BASE_URL, companyId, 'video'], video));
  },
  updateContactInfo(companyId, data) {
    return Api.waitForCommand(client.post([BASE_URL, companyId, 'contact-info'], data));
  },
  updateBillingInfo(companyId, data) {
    return Api.waitForCommand(client.post([BASE_URL, companyId, 'billingInfo'], data));
  },
  canDeletePaymentNethod(companyId, paymentMethodId) {
    return client.get([BASE_URL, companyId, 'payment-methods', paymentMethodId, 'can-delete']);
  },
  deletePaymentMethod(companyId, paymentMethodId) {
    return client.delete([BASE_URL, companyId, 'payment-methods', paymentMethodId]);
  },
  updatePaymentMethodNickname(companyId, paymentMethodId, data) {
    return client.put([BASE_URL, companyId, 'payment-methods', paymentMethodId, 'nickname'], data);
  },
  makePaymentMethodPrimary(companyId, paymentMethodId) {
    return client.put([BASE_URL, companyId, 'payment-methods', paymentMethodId, 'make-primary']);
  },
  createTeam(companyId, data) {
    return Api.waitForCommand(client.post([BASE_URL, companyId, 'team'], data));
  },
  updateTeam(companyId, teamId, data) {
    return client.put([BASE_URL, companyId, 'team', teamId], data);
  },
  deleteTeam(companyId, teamId) {
    return client.delete([BASE_URL, companyId, 'team', teamId]);
  },
  updateUserVisibility(companyId, userId, data) {
    return client.put([BASE_URL, companyId, 'update-visibility', userId], data);
  },
  getOwnerPaymentMethods(companyId) {
    return client.get([BASE_URL, companyId, 'owner-payment-methods']);
  },
  updatePaymentMethod(companyId, data) {
    return Api.waitForEvent(
      client.post([BASE_URL, companyId, 'payment-method'], data),
      {
        success: 'io:company:paymentMethodChanged',
      },
      (_, evtData) => companyId === evtData._id,
    );
  },
  updateSocialNetworks(companyId, socialNetworks) {
    const request = client.post([BASE_URL, companyId, 'social-networks'], { socialNetworks });
    return Api.waitForCommand(request);
  },
  updateExclusiveness(companyId, userId, data) {
    return Api.waitForCommand(client.post([BASE_URL, companyId, 'user-exclusiveness', userId], data));
  },
  validateName(name) {
    return client.post([BASE_URL, 'validate-name'], { name });
  },
  updateProfileTipsVisibility(companyId, isProfileTipsHidden) {
    return client.put([BASE_URL, companyId, 'profile-tips'], { isProfileTipsHidden });
  },
  getCompanyFee(companyId) {
    return client.get([BASE_URL, companyId, 'fee-percentage']);
  },
  getCompanyInvitation(invitationId) {
    return client.get([BASE_URL, 'invitation', invitationId]);
  },
  acceptCompanyInvitation(invitationId) {
    return client.put([BASE_URL, 'invitation', invitationId, 'accept']);
  },
  declineCompanyInvitation(invitationId) {
    return client.put([BASE_URL, 'invitation', invitationId, 'decline']);
  },

  savePortfolioItem(agencyId, data) {
    let request;
    if (data._id) {
      request = client.put([BASE_URL, agencyId, 'portfolio-item', data._id], data);
    } else {
      request = client.post([BASE_URL, agencyId, 'portfolio-item'], data);
    }

    return Api.waitForCommand(request);
  },
  deletePortfolioItem(agencyId, portfolioItemId) {
    const request = client.delete([BASE_URL, agencyId, 'portfolio-item', portfolioItemId]);
    return Api.waitForCommand(request);
  },
  orderPortfolio(agencyId, portfolioItemIds) {
    const request = client.put([BASE_URL, agencyId, 'portfolio', 'order'], { portfolioItemIds });
    return Api.waitForCommand(request);
  },
  sendMessage(companyId, data) {
    return client.post([BASE_URL, companyId, 'message'], data);
  },
  updateCategories(companyId, data) {
    const request = client.post([BASE_URL, companyId, 'categories'], data);
    return Api.waitForCommand(request);
  },
  addPortfolioItemView: (agencyId, portfolioItemId) => client.post([BASE_URL, agencyId, 'portfolio-item', portfolioItemId, 'view']),
};
