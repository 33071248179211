import client, { Api } from './client';

const BASE_URL = '/threads';

const apiClient = {
  getAll(conditions) {
    return client.get(BASE_URL, conditions);
  },
  getThreads(conditions) {
    return client.get([BASE_URL, 'search'], conditions);
  },
  get(threadId, conditions) {
    return client.get([BASE_URL, threadId], conditions);
  },
  getMessages(threadId, conditions) {
    return client.get([BASE_URL, threadId, 'messages'], conditions);
  },
  sendMessage(threadId, data) {
    return client.post([BASE_URL, threadId, 'messages'], data);
  },
  sendMessageToANewThread(data) {
    return client.post([BASE_URL], data);
  },
  markAsRead(threadId) {
    return Api.waitForCommand(client.put([BASE_URL, threadId, 'read'], {}));
  },
  markAllAsRead() {
    return client.post([BASE_URL, 'readAll']);
  },
};

export default apiClient;
