import _throttle from 'lodash/throttle';

import { screenSizes } from 'uikit/constants/screenSizes';

class ScreenSize {
  constructor() {
    this.currentScreenSize = {
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false,
      mobile: false,
      tablet: false,
      desktop: false,
      largeDesktop: false,
      windowWidth: 0,
    };

    this.onResize = this.onResize.bind(this);

    this.throttledResize = _throttle(this.onResize, 100);
  }

  updateScreenSize(width) {
    Object.keys(screenSizes).forEach(key => {
      this.currentScreenSize[key] = width >= screenSizes[key];
    });
    this.currentScreenSize.windowWidth = width;
  }

  onResize() {
    this.updateScreenSize(window.innerWidth);
  }

  init() {
    this.updateScreenSize(window.innerWidth);
    window.addEventListener('resize', this.throttledResize);
  }

  cleanup() {
    window.removeEventListener('resize', this.throttledResize);
  }

  getScreenSize() {
    return this.currentScreenSize;
  }
}

export default new ScreenSize();
