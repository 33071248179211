import config from '@/config';

import moment from 'moment-timezone';
import client, { Api } from './client';

const BASE_URL = '/jobs';

const jobApi = {
  get(jobId) {
    return client.get([BASE_URL, jobId]);
  },
  getAll(conditions) {
    return client.get([BASE_URL], conditions);
  },
  getViews(jobId) {
    return client.get([BASE_URL, jobId, 'views']);
  },
  getSimilarJobs(jobId, conditions = {}) {
    return client.get([BASE_URL, jobId, 'similar'], conditions);
  },
  getOtherJobsFromClient(jobId, conditions) {
    return client.get([BASE_URL, jobId, 'other-jobs-from-client'], conditions);
  },
  // todo: remove and use proposals endpoint
  getJobApplicants(jobId, conditions) {
    return client.get([BASE_URL, jobId, 'applicants'], conditions);
  },
  getJobApplicantsCount(jobId, conditions = {}) {
    conditions.count = true;
    return client.get([BASE_URL, jobId, 'applicants'], conditions);
  },
  getContracts(jobId) {
    return client.get([BASE_URL, jobId, 'contracts']);
  },
  getApplicantsStatistics(jobId) {
    return client.get([BASE_URL, jobId, 'applicants-statistics']);
  },
  getApplicantsFilters(jobId, conditions) {
    return client.get([BASE_URL, jobId, 'applicants', 'filters'], conditions);
  },

  search(conditions) {
    return client.get([BASE_URL, 'search'], conditions);
  },
  searchApplicants(jobId, conditions) {
    return client.get([BASE_URL, jobId, 'applicants', 'search'], conditions);
  },

  create(data) {
    const request = client.post(`${BASE_URL}`, data);
    return Api.waitForCommand(request);
  },
  update(jobId, data) {
    const request = client.put([BASE_URL, jobId], data);
    return Api.waitForCommand(request);
  },
  close(jobId, data) {
    const request = client.post([BASE_URL, jobId, 'close'], data);
    return Api.waitForCommand(request);
  },
  sendJobClientView(jobId) {
    return client.post([BASE_URL, jobId, 'client-view']);
  },
  sendUserView(jobId) {
    return client.post([BASE_URL, jobId, 'user-view']);
  },
  sendMarketingView(jobId, statId) {
    return client.post([BASE_URL, jobId, 'marketing-stats', 'view'], { statId });
  },
  flag(jobId, data) {
    return client.post([BASE_URL, jobId, 'flag'], data);
  },
  getJobStatisticsForClient(jobId) {
    return client.get([BASE_URL, jobId, 'statistics-for-client']);
  },
  getJobStatisticsForFreelancer(jobId) {
    return client.get([BASE_URL, jobId, 'statistics-for-freelancer']);
  },
  count(conditions) {
    return client.get([BASE_URL], { count: true, ...conditions });
  },
  generateDescription(conditions) {
    return client.get([BASE_URL, 'generate'], { ...conditions });
  },
  aiReview(data) {
    const request = client.post([BASE_URL, 'ai-review'], data);
    return Api.waitForCommand(request);
  },
  getRecommendedJobs() {
    return client.get([BASE_URL, 'recommended']);
  },
};

export default jobApi;

export const calculateDaysToExpire = publishedOn => {
  const { closeJobAfter, notifyAboutClosingSoonAfter } = config.job;
  const expireDate = moment(publishedOn).add(notifyAboutClosingSoonAfter.value, notifyAboutClosingSoonAfter.unit).add(closeJobAfter.value, closeJobAfter.unit);

  return expireDate.diff(moment(), 'days');
};
