<template>
  <div
    class="loom-preview__container"
    :style="{ height: `${height}px`, width: `${width}px` }"
  >
    <iframe
      v-if="srcComputed"
      :src="srcComputed"
      :width="width"
      :height="height"
      class="loom-preview"
      frameborder="0"
      allow="fullscreen"
    ></iframe>
  </div>
</template>

<script>
  export default {
    props: {
      src: {
        type: String,
        default: '',
      },
      width: {
        type: Number,
        default: 340,
      },
      height: {
        type: Number,
        default: 250,
      },
      hideOverlay: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      srcComputed() {
        if (this.hideOverlay) {
          return `${this.src}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`;
        }
        return this.src;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .loom-preview__container {
    background: url(https://res.cloudinary.com/golance/image/upload/v1687940124/loom-loader_oz4qvx.gif);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
  }

  .loom-preview {
    border-radius: 12px;
  }
</style>
