<template>
  <transition name="header-popup">
    <header-popup
      v-if="show"
      class="popup"
    >
      <h4 class="popup__header">
        Messages
        <gl-num-indicator v-if="currentUser.unreadInboxCount">
          {{ currentUser.unreadInboxCount }}
        </gl-num-indicator>

        <gl-button
          :disabled="!currentUser.unreadInboxCount"
          class="popup__button"
          variant="link-button"
          size="small"
          @click="handleAllRead"
        >
          Mark all as read
        </gl-button>
      </h4>

      <div
        v-if="loading"
        class="threads-list"
      >
        <message-preview-skeleton
          v-for="num in preloadedCount"
          :key="num"
        />
      </div>
      <template v-else-if="threads.length">
        <div class="popup__list">
          <thread-preview
            v-for="thread in threads"
            :key="thread._id"
            :thread="thread"
            @click.native="handlePreviewClick"
          />
        </div>
        <div class="popup__link-wrapper">
          <router-link
            :to="{ name: 'inbox' }"
            @click.native="handleLinkClick"
          >
            View all Messages
          </router-link>
        </div>
      </template>
      <div
        v-else
        class="popup__empty-state"
      >
        No messages available
      </div>
    </header-popup>
  </transition>
</template>

<script>
  import { mapGetters } from 'vuex';

  import threadsApi from '@/services/api/threads';
  import logger from '@/logger';

  import { GlNumIndicator, GlButton } from 'uikit/components';

  import HeaderPopup from './HeaderPopup.vue';
  import ThreadPreview from './ThreadPreview.vue';
  import MessagePreviewSkeleton from './MessagePreviewSkeleton.vue';

  export default {
    components: {
      GlButton,
      HeaderPopup,
      ThreadPreview,
      GlNumIndicator,
      MessagePreviewSkeleton,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        threads: [],
        loading: false,
        preloadedCount: 3,
      };
    },
    computed: {
      ...mapGetters(['currentUser']),
    },
    watch: {
      show(val) {
        if (!val) {
          return;
        }
        this.fetchThreads();
      },
      '$route.name'() {
        this.close();
      },
    },
    methods: {
      async fetchThreads() {
        this.loading = true;
        try {
          const response = await threadsApi.getAll({
            pageNumber: 1,
            itemsPerPage: this.preloadedCount,
          });
          this.threads = response.results;
        } catch (err) {
          logger.log(err);
        }
        this.loading = false;
      },
      async handleAllRead() {
        this.loading = true;
        try {
          await threadsApi.markAllAsRead();
        } catch (err) {
          logger.log(err);
        }
        this.loading = false;
      },
      handlePreviewClick() {
        this.close();
      },
      handleLinkClick() {
        this.close();
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .popup {
    @apply absolute bg-white;
    top: 42px;
    right: 40px;
    z-index: 101;
    transition: 0.2s linear all;
    line-height: 1.4em;
    padding: 12px 0;
  }

  .popup__header {
    @apply flex items-center font-semibold text-text-black text-16 font-primary;
    letter-spacing: 0.0015em;
    padding: 12px 24px;
  }

  .popup__button.small {
    padding: 0;
    margin-left: auto;
  }

  .popup__empty-state {
    @apply text-foundation-gray text-14 font-primary;
    letter-spacing: 0.0025em;
    text-align: center;
    line-height: 1.4em;
    padding: 12px 24px;
  }

  .popup__list {
    @apply flex flex-col;
  }

  .popup__link-wrapper {
    padding: 12px 24px;
    margin-top: 4px;
    & a {
      @apply font-primary text-text-link text-14 font-medium;
      -webkit-font-smoothing: antialiased;
      letter-spacing: 0.0125em;
      outline: none;
      &:hover,
      &:focus-visible {
        @apply text-text-focus;
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 440px) {
    .popup {
      right: -48px;
    }
  }
</style>
