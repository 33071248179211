import logger from '@/logger';

import reportsApi from '@/services/api/reports';
import notifications from '@/services/notifications';

const MIN_FETCH_DELAY = 450;

const state = {
  currentWeek: {
    isLoading: false,
    statistics: {},
    error: null,
  },
  previousWeeks: {
    isLoading: false,
    statistics: {},
    error: null,
  },
};

const actions = {
  async fetchCurrentWeek({ commit }) {
    commit('setIsCurrentWeekLoading', true);

    try {
      const minFetchDelayP = new Promise(resolve => setTimeout(resolve, MIN_FETCH_DELAY));

      const [statistics] = await Promise.all([reportsApi.getFreelancerCurrentWeekStatistics(), minFetchDelayP]);
      commit('setCurrentWeek', statistics);
    } catch (error) {
      commit('setCurrentWeekError', error);
      logger.error('Fetch current week statistics error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsCurrentWeekLoading', false);
    }
  },

  async fetchPreviousWeeks({ commit }) {
    commit('setIsPreviousWeeksLoading', true);

    try {
      const minFetchDelayP = new Promise(resolve => setTimeout(resolve, MIN_FETCH_DELAY));

      const [statistics] = await Promise.all([reportsApi.getFreelancerPreviousWeeksStatistics(), minFetchDelayP]);
      commit('setPreviousWeeks', statistics);
    } catch (error) {
      commit('setPreviousWeeksError', error);
      logger.error('Fetch previous weeks statistics error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsPreviousWeeksLoading', false);
    }
  },
};

const mutations = {
  // Current Week
  setIsCurrentWeekLoading({ currentWeek }, isLoading) {
    currentWeek.isLoading = isLoading;
  },
  setCurrentWeek({ currentWeek }, statistics) {
    currentWeek.statistics = statistics;
  },
  setCurrentWeekError({ currentWeek }, error) {
    currentWeek.error = error;
  },

  // Previous Weeks
  setIsPreviousWeeksLoading({ previousWeeks }, isLoading) {
    previousWeeks.isLoading = isLoading;
  },
  setPreviousWeeks({ previousWeeks }, statistics) {
    previousWeeks.statistics = statistics;
  },
  setPreviousWeeksError({ previousWeeks }, error) {
    previousWeeks.error = error;
  },
};

const getters = {
  currentWeek: ({ currentWeek }) => currentWeek,
  previousWeeks: ({ previousWeeks }) => previousWeeks,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
