<template>
  <div
    class="action-button"
    @click="$emit('click', $event)"
  >
    <span
      v-if="icon || $slots.icon"
      class="icon"
    >
      <slot name="icon">
        <component
          :is="icon"
        />
      </slot>
    </span>
    <span
      v-if="label"
      class="label"
    >
      {{ label }}
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: null,
      },
      icon: {
        type: Object,
        default: null,
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .action-button {
    @apply font-primary text-14 select-none flex;
    cursor: pointer;
    gap: 12px;
  }
  .action-button, .action-button .icon {
    display: flex;
    align-items: center;
  }
</style>
