import _includes from 'lodash/includes';

import { UserRoles } from '@/constants/user';

const links = {
  freelancerDashboard: { name: 'freelancer-home.info' },
  employerDashboard: { name: 'client-home' },
  freelancerProfile: { name: 'freelancer-profile.overview' },
  employerProfile: { name: 'client-profile.about' },
  accountSettings: { name: 'account-settings.settings' },
};

const getUserProfileRole = user => {
  if (_includes(user.type, UserRoles.Employer)) {
    return UserRoles.Employer;
  }
  return UserRoles.Freelancer;
};

const getUserProfileHomeLink = (user, role) => {
  role = role || getUserProfileRole(user);

  return role === UserRoles.Employer ? links.employerDashboard : links.freelancerDashboard;
};

const getUserProfileLink = (user, role) => {
  if (!user?._id && !user?.username) {
    return {
      name: 'page-not-found',
    };
  }

  role = role || getUserProfileRole(user);

  const link = role === UserRoles.Employer ? links.employerProfile : links.freelancerProfile;

  return {
    ...link,
    params: { ...link.params, userId: user.username || user._id },
  };
};

export {
  getUserProfileRole,
  getUserProfileHomeLink,
  getUserProfileLink,
  links,
};
