import logger from '@/logger';

const fbq = window.fbq
  ? window.fbq
  : (...args) => {
    console.log('Not sending pixel request in dev environment with params', ...args);
  };

export default {
  track(eventName, data) {
    try {
      return fbq('trackCustom', eventName, data);
    } catch (err) {
      logger.error(err);
    }
  },
};
