<template>
  <li class="accordion-item">
    <button
      v-if="clickable"
      class="accordion-item__label-container accordion-button"
      @click="handleButtonClick"
    >
      <span class="accordion-item__label">
        {{ label }}
      </span>
    </button>
    <div
      v-else
      class="accordion-item__label-container"
      :class="{ open }"
      @click="handleLabelClick"
    >
      <span class="accordion-item__label">
        {{ label }}
      </span>
      <gl-icon
        :open="open"
        name="dropdown"
        class="dropdown-icon"
      />
    </div>
    <transition name="expand">
      <div
        v-if="open"
        class="accordion-item__content"
      >
        <slot></slot>
      </div>
    </transition>
  </li>
</template>

<script>
  import GlIcon from '@/uikit/icons/BaseIcon.vue';
  import navStateRoutes from '../constants/navStateRoutes';

  export default {
    components: {
      GlIcon,
    },
    props: {
      label: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        default: null,
      },
      open: {
        type: Boolean,
        default: false,
      },
      clickable: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleLabelClick() {
        this.$emit('item-toggle', !this.open);
      },
      handleButtonClick() {
        const linkParams = navStateRoutes[this.value];
        this.$router.push({ name: linkParams.name, params: linkParams.params || {} });
      },
    },
  };
</script>

<style lang="postcss" scoped>
.dropdown-icon {
  @apply text-icon-default-dark;
}

.accordion-item {
  list-style-type: none;
}

.accordion-item__label-container {
  @apply flex items-center justify-between;
  user-select: none;
  padding: 12px 20px 12px 16px;
  height: 48px;
  cursor: pointer;
  &.open .accordion-item__label {
    @apply text-text-focus;
  }
}

.accordion-button {
  width: 100%;
}

.accordion-item__label {
  @apply font-primary font-semibold text-text-black text-16;
  transition: 0.3s linear all;
  line-height: 1.4em;
  letter-spacing: 0.0015em;
}

.accordion-item__content {
  transition: 0.4s ease all;
}

.expand-leave-to,
.expand-enter-active {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.expand-enter-to {
  max-height: 600px;
  opacity: 1;
}
</style>
