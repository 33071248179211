import { DateTime } from 'luxon';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _extend from 'lodash/extend';

import logger from '@/logger';
import notifications from '@/services/notifications';
import activityFeedApi from '@/services/api/activityFeed';

const MIN_FETCH_DELAY = 450;

const state = {
  events: {
    isInitialLoading: false,
    isLoading: false,
    events: [],
    eventsForSelectedDate: [],
    upcomingEvents: [],
    selectedDate: new Date(),
  },
};

const getEventsFromResponse = data => {
  if (!data) return [];

  const { meetings, holidays } = data;
  return [
    ..._map(meetings, m => _extend(m, { type: 'meeting' })),
    ..._map(holidays, h => {
      // Convert holiday date in UTC format to same local date
      const utcDate = DateTime.fromISO(h.date, { zone: 'utc' });
      const { zoneName } = DateTime.local();
      const date = utcDate.setZone(zoneName, { keepLocalTime: true });

      return _extend(h, { type: 'holiday', date });
    }),
  ];
};

const actions = {
  // Events
  async fetchInitialEvents({ commit }) {
    commit('setIsInitialEventsLoading', true);

    try {
      const fromDate = DateTime.local().startOf('months').toISO();
      const toDate = DateTime.local().endOf('months').toISO();
      const type = 'freelancer';

      // eslint-disable-next-line no-promise-executor-return
      const minFetchDelayP = new Promise(resolve => setTimeout(resolve, MIN_FETCH_DELAY));
      const [events, upcomingEvents] = await Promise.all([activityFeedApi.getCalendarEvents({ type, fromDate, toDate }), activityFeedApi.getUpcomingCalendarEvents({ type }), minFetchDelayP]);

      commit('setEvents', events);
      commit('setEventsForSelectedDate');
      commit('setUpcomingEvents', upcomingEvents);
    } catch (error) {
      logger.error('Fetch initial activity feed events error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsInitialEventsLoading', false);
    }
  },

  async fetchEvents({ commit }, conditions) {
    commit('setIsEventsLoading', true);

    try {
      conditions.type = 'freelancer';
      const res = await activityFeedApi.getCalendarEvents(conditions);
      commit('setEvents', res);
    } catch (error) {
      logger.error('Fetch activity feed events error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsEventsLoading', false);
    }
  },
};

const mutations = {
  // Events
  setIsInitialEventsLoading({ events }, isLoading) {
    events.isInitialLoading = isLoading;
  },
  setIsEventsLoading({ events }, isLoading) {
    events.isLoading = isLoading;
  },
  setEvents({ events }, res) {
    events.events = getEventsFromResponse(res);
  },
  setEventsForSelectedDate({ events }) {
    const startOfSelectedDate = DateTime.fromJSDate(events.selectedDate).startOf('day');
    const endOfSelectedDate = startOfSelectedDate.endOf('day');

    const eventsForSelectedDate = _filter(events.events, event => {
      const date = DateTime.fromISO(event.date);
      return startOfSelectedDate <= date && date <= endOfSelectedDate;
    });

    events.eventsForSelectedDate = eventsForSelectedDate;
  },
  setUpcomingEvents({ events }, res) {
    events.upcomingEvents = getEventsFromResponse(res);
  },
  setSelectedDate({ events }, selectedDate) {
    events.selectedDate = selectedDate;
  },
};

const getters = {
  events: ({ events }) => events,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
