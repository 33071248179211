<template>
  <select-component
    v-model="rowsCountModel"
    v-bind="$attrs"
    :options="options"
    :label="label"
    class="rows-per-page-select"
  />
</template>

<script>
  import SelectComponent from './Select.vue';

  export default {
    components: {
      SelectComponent,
    },
    model: {
      event: 'change',
      prop: 'rowsCount',
    },
    props: {
      label: {
        type: String,
        default: 'Rows per page',
      },
      rowsCount: {
        type: Number,
        required: true,
      },
      options: {
        type: Array,
        default: () => [10, 25, 50, 100],
      },
    },
    computed: {
      rowsCountModel: {
        get() {
          return this.rowsCount;
        },
        set(val) {
          this.$emit('change', val);
        },
      },
    },
  };
</script>

<style lang="postcss" scoped>
  @media (max-width: 576px) {
    .rows-per-page-select {
      &.multi-select {
        flex-direction: column;
        & >>> .multi-select__input {
          width: 100%;
        }
        & >>> .multi-select__header {
          font-size: 12px;
          margin-bottom: 3px;
          margin-right: 0;
        }
      }
    }
  }
</style>
