<template>
  <div class="filters-accordions">
    <accordion-item
      v-for="(config, index) in filtersConfig"
      :key="`${config.key}-${index}`"
      :ref="`accordion-${index}`"
      :pre-open="index === selectedIndex"
      :label="config.title"
      :index="index"
      class="filters-accordions__accordion"
      @open="handleAccordionChange"
    >
      <filter-data
        :filter-config="config"
        :filters="filters"
        class="filters-accordions__filter-data"
        @change="handleFilterChange"
      />
    </accordion-item>
    <p
      class="filters-accordions__clear-all"
      @click="handleClearAll"
    >
      Clear all
    </p>
  </div>
</template>

<script>
  import _values from 'lodash/values';
  import _pick from 'lodash/pick';
  import _times from 'lodash/times';
  import _forEach from 'lodash/forEach';
  import _toPairs from 'lodash/toPairs';
  import _fromPairs from 'lodash/fromPairs';

  import AccordionItem from '../../../../shared/AccordionItem.vue';
  import FilterData from '../FilterData/index.vue';

  export default {
    components: {
      AccordionItem,
      FilterData,
    },
    props: {
      filters: {
        type: Object,
        required: true,
      },
      filtersConfig: {
        type: Array,
        required: true,
      },
      selectedIndex: {
        type: Number,
        required: true,
      },
    },
    watch: {
      selectedIndex(value) {
        this.handleSelectedFilter(value);
      },
    },
    methods: {
      handleFilterChange(data) {
        this.$emit('change', data);
      },
      handleClearAll() {
        const requiredFilters = this.getRequiredFilters();
        this.$emit('change', requiredFilters);
      },
      handleSelectedFilter(index) {
        const refsIds = _times(this.filtersConfig.length, value => `accordion-${value}`);
        const accordionRefs = _values(_pick(this.$refs, refsIds)).flat();

        _forEach(accordionRefs, ((ref, refIndex) => {
          if (refIndex === index) {
            ref.openAccordion();
          } else {
            ref.closeAccordion();
          }
        }));
      },
      handleAccordionChange(index) {
        this.$emit('activeChange', index);
      },
      getRequiredFilters() {
        const filtersEntries = _toPairs(this.filters);
        const filteredEntries = filtersEntries.filter(entry => {
          const filterConfig = this.filtersConfig.find(config => config.key === entry[0]);

          return filterConfig.required;
        });

        return _fromPairs(filteredEntries);
      },
    },
  };
</script>

<style lang="postcss" scoped>
.filters-accordions {
  min-width: 340px;
  width: 80vw;

  &__accordion {
    @apply border-1 border-foundation-gray-2;
  }

  &__filter-data {
    min-width: unset;
    border: none;
  }

  &__clear-all {
    @apply text-14 font-medium text-text-link;
    padding: 16px;
    width: fit-content;
    cursor: pointer;
  }
}
</style>
