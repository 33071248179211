<template>
  <span
    class="text-wrapper"
    v-html="highlightedText"
  ></span>
</template>

<script>
  import sanitizeHTML from 'sanitize-html';
  import _forEach from 'lodash/forEach';

  export default {
    props: {
      query: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        default: '',
      },
    },
    computed: {
      highlightedText() {
        if (!this.query) {
          return sanitizeHTML(this.text, {
            allowedTags: ['span'],
            allowedAttributes: {
              span: ['class'],
            },
          });
        }

        const matchRegExpOperators = /[|\\{}()[\]^$+*?.]/g;
        const escapedQuery = this.query.replace(matchRegExpOperators, '\\$&');

        let { text } = this;
        const globalRegExp = new RegExp(escapedQuery, 'ig');
        const matchedResults = text.match(globalRegExp);

        // if there are several results, loop through all of them and
        // highlight only not already highlighted on previous iterations parts of string
        const singleMatchRegExp = new RegExp(`(?![^<]*(<|>))${escapedQuery}`, 'i');

        _forEach(matchedResults, result => {
          text = text.replace(singleMatchRegExp, `<span class='highlighted'>${result}</span>`);
        });
        return sanitizeHTML(text, {
          allowedTags: ['span'],
          allowedAttributes: {
            span: ['class'],
          },
        });
      },
    },
  };
</script>

<style lang="postcss" scoped>
.text-wrapper >>> .highlighted {
  @apply font-semibold;
}
</style>
