<template>
  <button
    class="active-filter"
    @click.stop="handleOpen"
  >
    <component
      :is="filterConfig.pillComponent"
      v-if="filterConfig.pillComponent"
      :filter-value="filterValue"
      class="active-filter__component"
    />
    <template v-else>
      <p class="active-filter__title">
        {{ filter.label }}
      </p>
    </template>
    <gl-icon
      v-if="!isRequiredFilter"
      :size="20"
      name="close"
      class="active-filter__close-icon"
      @click.native.stop="handleRemove"
    />
    <div class="active-filter__layer"></div>
  </button>
</template>

<script>
  import _keys from 'lodash/keys';
  import _values from 'lodash/values';

  import GlIcon from '@/uikit/icons/BaseIcon.vue';

  export default {
    components: {
      GlIcon,
    },
    props: {
      filter: {
        type: Object,
        required: true,
      },
      filtersConfig: {
        type: Array,
        required: true,
      },
    },
    computed: {
      filterKey() {
        return _keys(this.filter)[0];
      },
      filterConfig() {
        return this.filtersConfig.find(config => config.key === this.filterKey) || {};
      },
      isRequiredFilter() {
        return this.filterConfig.required;
      },
      filterValue() {
        return _values(this.filter)[0];
      },
    },
    methods: {
      handleOpen() {
        const filterIndex = this.filtersConfig.findIndex(config => config.key === this.filterKey);
        this.$emit('open', filterIndex);
      },
      handleRemove() {
        this.$emit('remove', this.filter, this.filterConfig);
      },
    },
  };
</script>

<style lang="postcss" scoped>
.active-filter {
  @apply flex items-center bg-white border-1 border-white;
  position: relative;
  gap: 10px;
  flex-wrap: nowrap;
  min-width: min-content;
  padding: 7px 14px;
  border-radius: 20px;
  transition: .2s ease all;

  &__layer {
    @apply border-1 border-white bg-white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    transition: .2s ease all;
  }

  &__component {
    z-index: 1;
  }

  &__title {
    @apply text-14 font-medium text-text-black;
    white-space: nowrap;
    transition: .2s ease all;
    text-transform: capitalize;
    z-index: 1;
  }

  &__close-icon {
    padding: 3px;
    border-radius: 6px;
    transition: .2s ease all;
    z-index: 1;
  }

  &:focus {
    outline: none;
  }

  &:hover .active-filter__layer{
    @apply bg-blue-gray border-1 border-icon-blue;
  }
}

.active-filter__close-icon:hover ~ .active-filter__layer {
  @apply border-icon-error;
  background-color: #FFECEC;
}
</style>
