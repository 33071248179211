<template>
  <notifications
    group="default"
    width="604px"
    class="toaster-notification"
    position="top center"
    :max="10"
    :duration="15000"
  >
    <template #body="{ item, close }">
      <div
        :class="[item.type]"
        class="toaster-notification__body"
        @click="
          () => {
            if (!item.data.showCloseIcon) close()
          }
        "
      >
        <div class="toaster-notification__info">
          <gl-base-icon
            :name="icons[item.type]"
            :size="24"
            class="toaster-notification__icon"
          />
          <div
            class="toaster-notification__content"
            :class="{ 'with-title': !!item.data.title }"
          >
            <h3
              v-if="item.data.title"
              class="toaster-notification__title"
            >
              {{ item.data.title }}
            </h3>
            <div
              class="content__body"
              :class="{ 'with-button': item.data.button }"
            >
              <span
                v-if="item.data.link"
                class="flex flex-col gap-2 items-start"
              ><span>{{ item.data.link.text }}</span><gl-button
                class="button"
                variant="link"
                @click="item.data.link.action"
              > {{ item.data.link.actionLabel }}</gl-button></span>
              <span
                v-else
                class="toaster-notification__text"
                v-html="item.text"
              ></span>
              <div class="toaster-notification__buttons-container">
                <a
                  v-if="item.data.button"
                  :href="item.data.button.url"
                  :target="item.data.button.target || '_self'"
                  tabindex="2"
                  class="toaster-notification__link-button"
                >
                  {{ item.data.button.label }}
                </a>
              </div>
            </div>
          </div>

          <gl-icon-button
            v-if="item.data.showCloseIcon"
            :size="24"
            icon-name="close"
            tabindex="2"
            class="toaster-notification__close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
  import GlIconButton from 'uikit/components/buttons/IconButton.vue';
  import GlBaseIcon from 'uikit/icons/BaseIcon.vue';
  import GlButton from 'uikit/components/buttons/Button.vue';

  const icons = {
    info: 'solid-exclamanation-circle',
    warning: 'solid-exclamanation-triangle',
    danger: 'solid-exclamanation-octagon',
    success: 'solid-check-circle',
  };

  export default {
    components: {
      GlBaseIcon,
      GlIconButton,
      GlButton,
    },

    data() {
      return {
        icons,
      };
    },
  };
</script>

<style lang="postcss" scoped>
  .toaster-notification {
    max-width: 604px;
    min-width: 328px;
    margin-top: 24px;

    & >>> .vue-notification-wrapper {
      margin-bottom: 12px;
      overflow: visible;
    }
  }

  .toaster-notification__body {
    @apply flex items-center text-text-black;
    padding: 16px;
    border-radius: 16px;
    gap: 12px;
    width: fit-content;
    max-width: 100%;
    min-width: 328px;
    margin: 0 auto;

    &.success {
      background-color: #f5fbf5;
      border: 1px solid #1fc67e;

      & >>> .toaster-notification__icon {
        color: #1fc67e;
      }
    }

    &.danger {
      background-color: #fdf7f8;
      border: 1px solid #dd2929;

      & >>> .toaster-notification__icon {
        color: #dd2929;
      }
    }

    &.warning {
      background-color: #fefcf2;
      border: 1px solid #ffa83a;

      & >>> .toaster-notification__icon {
        color: #ffa83a;
      }
    }

    &.info {
      @apply bg-foundation-gray-2;
      border: 1px solid #4163dd;

      & >>> .toaster-notification__icon {
        color: #475baf;
      }
    }
  }

  .toaster-notification__info {
    @apply flex items-start;
    flex-grow: 1;
    gap: 16px;
  }

  .toaster-notification__icon {
    flex-shrink: 0;
  }

  .toaster-notification__buttons-container {
    @apply flex items-center;
    flex: 0 0 auto;
    gap: 16px;
  }

  .toaster-notification__link-button {
    @apply text-14 font-normal;
    color: inherit;
    padding: 8px 20px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.15);
    transition: 0.3s ease all;
    line-height: 20px;
    letter-spacing: 0.0125em;
    font-feature-settings: 'kern' off;

    &:hover,
    &:focus-visible {
      color: inherit;
      background: rgba(255, 255, 255, 0.25);
    }

    &:active {
      color: inherit;
      background: rgba(255, 255, 255, 0.15);
    }
  }

  .toaster-notification__close-button {
    color: inherit;

    &:hover,
    &:focus-visible {
      color: inherit;
      background: rgba(255, 255, 255, 0.25);
    }

    &:active {
      color: inherit;
      background: rgba(255, 255, 255, 0.15);
      border-color: transparent;
    }
  }

  .toaster-notification__text {
    @apply font-normal text-14;
    word-break: break-word;
  }

  .toaster-notification__content {
    @apply flex flex-col w-full;
    gap: 4px;
    min-height: 24px;
  }

  .toaster-notification__title {
    @apply font-semibold text-16 font-primary;
    line-height: 24px;
  }

  .content__body {
    @apply flex items-center;
    flex-grow: 1;
    gap: 16px;
  }

  @media (max-width: 767px) {
    .toaster-notification__body {
      max-width: 90vw;
    }

    .content__body.with-button {
      @apply flex-col;
    }
  }
</style>
