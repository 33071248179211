<template>
  <div
    :class="{ loading }"
    :style="{'--loading-grey': color}"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: true,
      },
      color: {
        type: String,
        default: '#F4F5FB',
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .loading {
    background-color: var(--loading-grey);
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, .5) 50%,
      rgba(255, 255, 255, 0) 60%
    ) var(--loading-grey);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border-radius: 4px;
  }

  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
</style>
