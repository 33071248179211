export default {
  USD: () => import('@/components/icons/currency/flags/UsdIcon.vue'),
  EUR: () => import('@/components/icons/currency/flags/EurIcon.vue'),
  MXN: () => import('@/components/icons/currency/flags/MxnIcon.vue'),
  HUF: () => import('@/components/icons/currency/flags/HufIcon.vue'),
  GBP: () => import('@/components/icons/currency/flags/GbpIcon.vue'),
  DKK: () => import('@/components/icons/currency/flags/DkkIcon.vue'),
  PLN: () => import('@/components/icons/currency/flags/PlnIcon.vue'),
  CAD: () => import('@/components/icons/currency/flags/CadIcon.vue'),
  AUD: () => import('@/components/icons/currency/flags/AudIcon.vue'),
  SEK: () => import('@/components/icons/currency/flags/SekIcon.vue'),
  SGD: () => import('@/components/icons/currency/flags/SgdIcon.vue'),
  HKD: () => import('@/components/icons/currency/flags/HkdIcon.vue'),
  BGN: () => import('@/components/icons/currency/flags/BgnIcon.vue'),
  NOK: () => import('@/components/icons/currency/flags/NokIcon.vue'),
  CZK: () => import('@/components/icons/currency/flags/CzkIcon.vue'),
  JPY: () => import('@/components/icons/currency/flags/JpyIcon.vue'),
  ILS: () => import('@/components/icons/currency/flags/IlsIcon.vue'),
  PHP: () => import('@/components/icons/currency/flags/PhpIcon.vue'),
  ZAR: () => import('@/components/icons/currency/flags/ZarIcon.vue'),
};
