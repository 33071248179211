import { getUserPermissions } from '../routerServices';

const HourlyTasks = () => import(/* webpackChunkName: "HourlyTasks" */ '@/views/Tasks/index.vue');
const HourlyTasksToDo = () => import(/* webpackChunkName: "HourlyTasks" */ '@/views/Tasks/ToDo/index.vue');
const HourlyTasksTaskPage = () => import(/* webpackChunkName: "HourlyTasks" */ '@/views/Tasks/TaskPage/index.vue');

export default [
  {
    name: 'hourly-tasks',
    path: '/tasks',
    meta: { requiresAuth: true },
    component: HourlyTasks,
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();

      if (!permissions.isFreelancer) {
        return next({ name: 'client-home' });
      }

      next();
    },
    redirect: () => ({ name: 'hourly-tasks.to-do' }),
    children: [
      {
        name: 'hourly-tasks.to-do',
        path: '',
        component: HourlyTasksToDo,
      },
    ],
  },
  {
    name: 'hourly-task',
    path: '/tasks/:id',
    meta: { requiresAuth: true },
    component: HourlyTasksTaskPage,
    beforeEnter: (to, from, next) => {
      // TO DO: Implement permissions
      next();
    },
  },
];
