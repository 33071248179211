import { getImageThumbnailUrl } from '@/services/cloudinaryImageUrl';
import { defaultAvatarUrl } from '@/constants/user';

const multiplySize = (size, multiplier = 1) => Math.round(size * multiplier);

export default (user, size) => {
  const originalUrl = user?.avatarUrl || defaultAvatarUrl;

  if (!size) {
    return originalUrl;
  }

  const thumbnailSize = {
    width: null,
    height: null,
  };

  const { multiplier } = size;

  if (typeof size === 'number') {
    thumbnailSize.width = multiplySize(size, multiplier);
  }

  if (size.width) {
    thumbnailSize.width = multiplySize(size.width, multiplier);
  }

  if (size.height) {
    thumbnailSize.height = multiplySize(size.height, multiplier);
  }

  return getImageThumbnailUrl(originalUrl, thumbnailSize);
};
