<template>
  <component
    :is="drawerComponent"
    :value="drawerOpen"
    @input="handleInput"
    @close="handleClose"
  />
</template>

<script>
  export default {
    props: {
      drawerComponent: {
        type: [Object, Function],
        required: true,
      },
    },
    data() {
      return {
        drawerOpen: true,
      };
    },
    methods: {
      handleInput(val) {
        this.drawerOpen = val;
      },
      handleClose() {
        this.drawerOpen = false;
        this.$emit('close');
      },
    },
  };
</script>
