// XXX: use actual config
const config = window._bootstrap || {};

config.S3BucketUrl = config.amazonS3bucketUrl;
config.signupUrl = `${config.rootUrl}/signup`;
config.loginUrl = `${config.rootUrl}/login`;
config.howItWorks = `${config.landingUrl}/platform`;
config.contactUs = `${config.landingUrl}/contact-us`;
config.isAuthorized = !!config.user;
config.marketingUrl = config.rootUrl;
config.termsOfServiceUrl = `${config.marketingUrl}/terms-of-service `;
config.isStaging = config.env === 'staging';
config.isProduction = config.env === 'production';
config.batchBonusTemplateLink = config.isProduction
  ? 'https://golance-production-batch-bonus-template.s3.amazonaws.com/BatchBonusesTemplateFiles.csv'
  : 'https://golance-staging-batch-bonus-template.s3.amazonaws.com/BatchBonusesTemplateFiles.csv';

config.termsOfServiceLink = `${config.rootUrl}/terms-of-service`;
config.cardHolderAgreementLink = `${config.rootUrl}/cardholder-agreement`;
config.bankingArbitrage = config.flexibleBankingArbitrageCurrencies;
config.safetyWingsUrl = 'https://safetywing.com/remote-health/member/signup/goLance';
config.insuranceUSAUrl = 'https://golancehealth.com';
config.blogUrl = 'https://blog.golance.com';
config.ipApiApiKey = 'Yt9yab5BqBDBxk6';

export default config;
