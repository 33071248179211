<template>
  <span
    class="num-indicator"
    :class="[variant]"
  >
    <slot></slot>
  </span>
</template>

<script>
  export default {
    props: {
      variant: {
        type: String,
        default: 'default',
        validator: val => ['default', 'blue'].includes(val),
      },
    },
  };
</script>

<style lang="postcss" scoped>
.num-indicator {
  @apply bg-foundation-gray-2 inline-flex text-14 items-center justify-center font-normal text-text-black;
  margin-left: 4px;
  height: 20px;
  min-width: 20px;
  padding: 0px 4px;
  border-radius: 16px;
  flex-shrink: 0;
  line-height: 20px;

  &.blue {
    @apply bg-blue-liberty text-white;
  }
}
</style>
