import moment from 'moment-timezone';
import store from '@/store/index';

function getTimezone() {
  return store.getters.currentTimezone;
}

export function getCurrentWeek() {
  return {
    start: moment.utc().startOf('isoWeek').toDate(),
    end: moment.utc().endOf('day').toDate(),
  };
}
export function getLastWeek() {
  const lastMonday = moment.utc().isoWeekday(-6);
  return {
    start: lastMonday.clone().startOf('isoWeek').toDate(),
    end: lastMonday.clone().endOf('isoWeek').toDate(),
  };
}

export function getCurrentMonth() {
  return {
    start: moment.utc().startOf('month').toDate(),
    end: moment.utc().endOf('month').toDate(),
  };
}
export function getLastMonth() {
  return {
    start: moment.utc().subtract(1, 'months').startOf('month').toDate(),
    end: moment.utc().subtract(1, 'months').endOf('month').toDate(),
  };
}
export function getCurrentQuarter() {
  return {
    start: moment.utc().startOf('quarter').toDate(),
    end: moment.utc().endOf('quarter').toDate(),
  };
}
export function getLastQuarter() {
  return {
    start: moment.utc().subtract(1, 'quarter').startOf('quarter').toDate(),
    end: moment.utc().subtract(1, 'quarter').endOf('quarter').toDate(),
  };
}
export function getCurrentYear() {
  return {
    start: moment.utc().startOf('year').toDate(),
    end: moment.utc().endOf('year').toDate(),
  };
}
export function getAllTime() {
  return {
    start: moment(0).toDate(),
    end: moment.utc().endOf('year').toDate(),
  };
}
export function getWeekBeforeLast() {
  return {
    start: moment.utc().subtract(2, 'weeks').startOf('isoWeek').toDate(),
    end: moment.utc().subtract(2, 'weeks').endOf('isoWeek').toDate(),
  };
}

export function compareByDay(firstDate, lastDate) {
  return moment(firstDate.start).isSame(lastDate.start, 'day') && moment(firstDate.end).isSame(lastDate.end, 'day');
}

export function convertTimezoneDateToUTC(date, timezone) {
  const formattedDate = moment.utc(date).format('YYYY-MM-DDTHH:mm:ss');
  return moment
    .tz(formattedDate, timezone || getTimezone())
    .utc()
    .format();
}
