/* eslint-disable import/prefer-default-export */

const downLoadFile = (data, fileName) => {
  const anchor = document.createElement('a');
  anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
  anchor.target = '_blank';
  anchor.download = fileName;
  anchor.click();
  anchor.remove();
};

const downloadFileByBlob = (fileData, fileName, mimeType) => {
  const downloadElement = document.createElement('a');
  const file = new Blob([fileData], { type: mimeType });

  document.body.appendChild(downloadElement);
  downloadElement.href = URL.createObjectURL(file);
  downloadElement.target = '_blank';
  downloadElement.download = fileName;
  downloadElement.click();
  document.body.removeChild(downloadElement);
};

const downloadReport = (content, mimeType, name) => {
  const downloadLink = document.createElement('a');
  const file = new Blob([content]);

  document.body.appendChild(downloadLink);
  downloadLink.href = URL.createObjectURL(file);
  downloadLink.target = '_blank';
  downloadLink.download = name;
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const getBase64FromUrl = async url => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = error => {
      reject(error);
    };
  });
};

async function getFileFromUrl(url, name, defaultType = 'image/jpeg') {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

function downloadFileFromUrl(dataUrl, filename, delay = 0) {
  return new Promise(resolve => {
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = filename;

    link.addEventListener('click', () => {
      setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
        resolve();
      }, delay);
    });

    document.body.appendChild(link);
    link.click();
  });
}

export {
  downLoadFile, downloadFileByBlob, downloadReport, getBase64FromUrl, getFileFromUrl, downloadFileFromUrl,
};
