<template>
  <div class="message__container">
    <div class="message__info">
      <span class="message__label">
        <skeleton-loader-vue
          v-bind="skeleton"
          height="20px"
          width="150px"
        />
      </span>
      <span class="message__content">
        <skeleton-loader-vue
          v-bind="skeleton"
          height="18px"
          width="220px"
        />
      </span>
      <span class="message__date">
        <skeleton-loader-vue
          v-bind="skeleton"
          height="18px"
          width="130px"
        />
      </span>
    </div>
    <skeleton-loader-vue
      v-bind="skeleton"
      height="40px"
      width="40px"
      class="message__icon"
    />
  </div>
</template>

<script>
  import SkeletonLoaderVue from 'skeleton-loader-vue';

  export default {
    components: {
      SkeletonLoaderVue,
    },
    data() {
      return {
        skeleton: {
          type: 'rect',
          color: '#F0F1F5',
          animation: 'wave',
          rounded: true,
          radius: 4,
        },
      };
    },
  };
</script>

<style lang="postcss" scoped>
  .message__container {
    @apply border-b border-foundation-gray-2 flex justify-between items-center;
    gap: 16px;
    padding: 12px 24px;
  }

  .message__info {
    @apply flex flex-col items-start relative;
    gap: 4px;
  }

  .message__icon {
    flex-shrink: 0;
  }
</style>
