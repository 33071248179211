import { getCurrentUser } from '../routerServices';

const AffiliateProgram = () => import(/* webpackChunkName: "AffiliateProgram", webpackPrefetch: true */ '@/views/AffiliateProgram');

export default [
  {
    name: 'affiliate-program',
    path: '/affiliate-program',
    component: AffiliateProgram,
    beforeEnter: (to, from, next) => {
      const user = getCurrentUser();

      if (!user?.referralSettings?.enabled) {
        return next({
          name: 'page-not-found',
        });
      }

      return next();
    },
  },
];
