<template>
  <emoji-picker
    :data="emojiIndex"
    set="apple"
    title="Pick your emoji..."
    :size="32"
    @select="handleSelectEmoji"
  />
</template>

<script>
  import { Picker, EmojiIndex } from 'emoji-mart-vue-fast';
  import data from 'emoji-mart-vue-fast/data/apple.json';

  const emojiIndex = new EmojiIndex(data, {
    exclude: ['flags'],
  });

  export default {
    components: {
      EmojiPicker: Picker,
    },

    data() {
      return {
        emojiIndex,
      };
    },

    methods: {
      handleSelectEmoji(emoji) {
        this.$emit('select', emoji);
      },
    },
  };
</script>
