<template>
  <div class="balance">
    <div class="balance__label">
      <component
        :is="currencyIconComponent"
        class="balance__icon"
      />
      <span class="balance__name">{{ currency }} wallet</span>
    </div>
    <div class="balance__value">
      {{ value | formatCurrencyValue({ currency }) }}
    </div>
  </div>
</template>

<script>
  import formatCurrencyValue from '@/filters/currency';

  import icons from '@/components/icons/currency/flags/index';

  export default {
    filters: {
      formatCurrencyValue,
    },
    props: {
      currency: {
        type: String,
        required: true,
      },
      value: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      currencyIconComponent() {
        return icons[this.currency];
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .balance {
    @apply inline-flex justify-between items-center w-full;
    gap: 4px;

    & .balance__label {
      @apply flex items-center;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.004em;
      gap: 4px;
    }

    & .balance__name {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .balance__value {
      @apply font-semibold;
      flex-shrink: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .balance__icon {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
    }
  }
</style>
