<template>
  <div>
    <div
      v-if="visible"
      class="drawer-overlay"
      @click="handleClose"
    ></div>

    <transition :name="transitionName">
      <div
        v-if="visible"
        class="drawer"
        :class="position"
        :style="drawerStyle"
      >
        <div class="drawer-header">
          <h3
            v-if="!$slots.header"
            class="drawer-title"
          >
            {{ title }}
          </h3>
          <slot name="header"></slot>
          <close-button
            v-if="showCloseIcon"
            :size="32"
            :icon-size="24"
            @click="handleClose"
          />
        </div>

        <div class="drawer-content">
          <slot></slot>
        </div>

        <div
          v-if="$slots.footer"
          class="drawer-footer"
        >
          <slot name="footer"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import CloseButton from '@/components/buttons/CloseButton.vue';

  export default {
    components: {
      CloseButton,
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      position: {
        type: String,
        default: 'left',
        validator: value => ['left', 'right', 'top', 'bottom'].includes(value),
      },
      width: {
        type: String,
        default: '300px',
      },
      height: {
        type: String,
        default: '100%',
      },
      title: {
        type: String,
        default: '',
      },
      showCloseIcon: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      visible() {
        return this.value;
      },
      drawerStyle() {
        const maxWidth = '100vw'; // Max width limited to viewport width
        const maxHeight = '100vh'; // Max height limited to viewport height

        let style = {};
        if (this.position === 'left' || this.position === 'right') {
          style = { width: this.width, maxWidth };
        } else {
          style = { height: this.height, maxHeight };
        }
        return style;
      },
      transitionName() {
        return `slide-${this.position}`;
      },
    },
    watch: {
      visible: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            // Disable scrolling when the drawer is visible
            document.body.style.overflow = 'hidden';
          } else {
            // Re-enable scrolling when the drawer is closed
            document.body.style.overflow = '';
          }
        },
      },
    },
    beforeDestroy() {
      document.body.style.overflow = '';
    },
    methods: {
      handleClose() {
        this.$emit('input', false);
        this.$emit('close');
      },
    },
  };
</script>

<style scoped>
  .drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(36, 38, 46, 0.8);
    transition: opacity 0.3s ease;
    z-index: 900;
  }

  .drawer {
    position: fixed;
    background: white;
    z-index: 901;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .drawer.left {
    top: 0;
    left: 0;
    height: 100%;
  }

  .drawer.right {
    top: 0;
    right: 0;
    height: 100%;
  }

  .drawer.top {
    top: 0;
    left: 0;
    width: 100%;
  }

  .drawer.bottom {
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }

  .drawer-footer {
    margin-top: auto;
    padding: 16px;
  }

  .drawer-content {
    flex-grow: 1;
    padding: 16px;
    overflow-y: auto;
  }

  .drawer-title {
    margin: 0;
    flex-grow: 1;
  }

  .slide-left-enter-active,
  .slide-left-leave-active {
    transition: transform 0.45s ease;
  }

  .slide-left-enter,
  .slide-left-leave-to {
    transform: translateX(-100%);
  }

  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: transform 0.45s ease;
  }

  .slide-right-enter,
  .slide-right-leave-to {
    transform: translateX(100%);
  }

  .slide-top-enter-active,
  .slide-top-leave-active {
    transition: transform 0.45s ease;
  }

  .slide-top-enter,
  .slide-top-leave-to {
    transform: translateY(-100%);
  }

  .slide-bottom-enter-active,
  .slide-bottom-leave-active {
    transition: transform 0.45s ease;
  }

  .slide-bottom-enter,
  .slide-bottom-leave-to {
    transform: translateY(100%);
  }
</style>
