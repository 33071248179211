import { DateTime } from 'luxon';

import _map from 'lodash/map';
import _some from 'lodash/some';
import _filter from 'lodash/filter';
import _concat from 'lodash/concat';
import _extend from 'lodash/extend';
import _includes from 'lodash/includes';
import _differenceBy from 'lodash/differenceBy';

import logger from '@/logger';
import notifications from '@/services/notifications';
import activityFeedApi from '@/services/api/activityFeed';
import { eventTypesByTab } from './constants';

const MIN_FETCH_DELAY = 450;

const eventGroupsByTab = {
  jobs: ['job', 'proposal', 'offer'],
  contracts: ['contract', 'time_tracking'],
};

const state = {
  contractsActivities: {
    isInitialLoading: false,
    isMoreLoading: false,
    activities: [],
    totalActivitiesCount: 0,
  },
  jobsActivities: {
    isInitialLoading: false,
    isMoreLoading: false,
    activities: [],
    totalActivitiesCount: 0,
  },
  events: {
    isInitialLoading: false,
    isLoading: false,
    events: [],
    eventsForSelectedDate: [],
    upcomingEvents: [],
    selectedDate: new Date(),
  },
};

const checkIfIncludesActivity = (activities, activity) => _some(activities, { _id: activity._id });

const getEventsFromResponse = ({ meetings, charges, holidays }) => ([
  ..._map(meetings, m => _extend(m, { type: 'meeting' })),
  ..._map(charges, c => _extend(c, { type: 'charge' })),
  ..._map(holidays, h => {
    // Convert holiday date in UTC format to same local date
    const utcDate = DateTime.fromISO(h.date, { zone: 'utc' });
    const { zoneName } = DateTime.local();
    const date = utcDate.setZone(zoneName, { keepLocalTime: true });

    return _extend(h, { type: 'holiday', date });
  }),
]);

const actions = {
  // Contracts Activities
  async fetchInitialContractsActivities({ commit }) {
    commit('setIsInitialContractsActivitiesLoading', true);
    commit('resetContractsActivities');

    try {
      const query = { type: 'client', offset: 0, types: eventGroupsByTab.contracts.join(',') };
      const minFetchDelayP = new Promise(resolve => setTimeout(resolve, MIN_FETCH_DELAY));
      const [res] = await Promise.all([activityFeedApi.getActivityEvents(query), minFetchDelayP]);
      commit('setContractsActivities', res);
    } catch (error) {
      logger.error('Fetch contracts activities error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsInitialContractsActivitiesLoading', false);
    }
  },

  async fetchMoreContractsActivities({ commit, state: { contractsActivities } }) {
    commit('setIsMoreContractsActivitiesLoading', true);

    try {
      const query = { type: 'client', offset: contractsActivities.activities.length, types: eventGroupsByTab.contracts.join(',') };
      const res = await activityFeedApi.getActivityEvents(query);
      commit('setContractsActivities', res);
    } catch (error) {
      logger.error('Fetch contracts activities error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsMoreContractsActivitiesLoading', false);
    }
  },

  async addNewContractActivity({ commit }, activity) {
    if (!_includes(eventTypesByTab.contracts, activity.eventType)) {
      return;
    }

    commit('addContractActivity', activity);
  },

  // Jobs Activities
  async fetchInitialJobsActivities({ commit }) {
    commit('setIsJobsActivitiesInitialLoading', true);
    commit('resetJobsActivities');

    try {
      const query = { type: 'client', offset: 0, types: eventGroupsByTab.jobs.join(',') };
      const minFetchDelayP = new Promise(resolve => setTimeout(resolve, MIN_FETCH_DELAY));

      const [activities] = await Promise.all([activityFeedApi.getActivityEvents(query), minFetchDelayP]);
      commit('setJobsActivities', activities);
    } catch (error) {
      logger.error('Fetch jobs activities error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsJobsActivitiesInitialLoading', false);
    }
  },

  async fetchMoreJobsActivities({ commit, state: { jobsActivities } }) {
    commit('setIsMoreJobsActivitiesLoading', true);

    try {
      const query = { type: 'client', offset: jobsActivities.activities.length, types: eventGroupsByTab.jobs.join(',') };

      const activities = await activityFeedApi.getActivityEvents(query);
      commit('setJobsActivities', activities);
    } catch (error) {
      logger.error('Fetch more jobs activities error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsMoreJobsActivitiesLoading', false);
    }
  },

  async addNewJobActivity({ commit }, activity) {
    if (!_includes(eventTypesByTab.jobs, activity.eventType)) {
      return;
    }

    commit('addJobActivity', activity);
  },

  // Events
  async fetchInitialEvents({ commit }) {
    commit('setIsInitialEventsLoading', true);

    try {
      const fromDate = DateTime.local().startOf('months').toISO();
      const toDate = DateTime.local().endOf('months').toISO();
      const type = 'client';

      const minFetchDelayP = new Promise(resolve => setTimeout(resolve, MIN_FETCH_DELAY));
      const [
        events,
        upcomingEvents,
      ] = await Promise.all([
        activityFeedApi.getCalendarEvents({ type, fromDate, toDate }),
        activityFeedApi.getUpcomingCalendarEvents({ type }),
        minFetchDelayP,
      ]);

      commit('setEvents', events);
      commit('setEventsForSelectedDate');
      commit('setUpcomingEvents', upcomingEvents);
    } catch (error) {
      logger.error('Fetch initial activity feed events error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsInitialEventsLoading', false);
    }
  },

  async fetchEvents({ commit }, conditions) {
    commit('setIsEventsLoading', true);

    try {
      conditions.type = 'client';
      const res = await activityFeedApi.getCalendarEvents(conditions);
      commit('setEvents', res);
    } catch (error) {
      logger.error('Fetch activity feed events error', error);
      notifications.showGolanceError(error);
    } finally {
      commit('setIsEventsLoading', false);
    }
  },
};

const mutations = {
  // Contracts Activities
  setIsInitialContractsActivitiesLoading({ contractsActivities }, isLoading) {
    contractsActivities.isInitialLoading = isLoading;
  },
  setIsMoreContractsActivitiesLoading({ contractsActivities }, isLoading) {
    contractsActivities.isMoreLoading = isLoading;
  },
  resetContractsActivities({ contractsActivities }) {
    contractsActivities.activities = [];
    contractsActivities.totalActivitiesCount = 0;
  },
  setContractsActivities({ contractsActivities }, { results, totalCount }) {
    const newActivities = _differenceBy(results, contractsActivities.activities, '_id');

    contractsActivities.activities = _concat(contractsActivities.activities, newActivities);
    contractsActivities.totalActivitiesCount = totalCount;
  },
  addContractActivity({ contractsActivities }, activity) {
    if (!checkIfIncludesActivity(contractsActivities.activities, activity)) {
      contractsActivities.activities.unshift(activity);
      contractsActivities.totalActivitiesCount +=1;
    }
  },

  // Jobs Activities
  setIsJobsActivitiesInitialLoading({ jobsActivities }, isLoading) {
    jobsActivities.isInitialLoading = isLoading;
  },
  setIsMoreJobsActivitiesLoading({ jobsActivities }, isMoreLoading) {
    jobsActivities.isMoreLoading = isMoreLoading;
  },
  resetJobsActivities({ jobsActivities }) {
    jobsActivities.activities = [];
    jobsActivities.totalActivitiesCount = 0;
  },
  setJobsActivities({ jobsActivities }, { results, totalCount }) {
    const newActivities = _differenceBy(results, jobsActivities.activities, '_id');

    jobsActivities.activities = _concat(jobsActivities.activities, newActivities);
    jobsActivities.totalActivitiesCount = totalCount;
  },
  addJobActivity({ jobsActivities }, activity) {
    if (!checkIfIncludesActivity(jobsActivities.activities, activity)) {
      jobsActivities.activities.unshift(activity);
      jobsActivities.totalActivitiesCount +=1;
    }
  },

  // Events
  setIsInitialEventsLoading({ events }, isLoading) {
    events.isInitialLoading = isLoading;
  },
  setIsEventsLoading({ events }, isLoading) {
    events.isLoading = isLoading;
  },
  setEvents({ events }, res) {
    events.events = getEventsFromResponse(res);
  },
  setEventsForSelectedDate({ events }) {
    const startOfSelectedDate = DateTime.fromJSDate(events.selectedDate).startOf('day');
    const endOfSelectedDate = startOfSelectedDate.endOf('day');

    const eventsForSelectedDate = _filter(events.events, event => {
      const date = DateTime.fromISO(event.date);
      return startOfSelectedDate <= date && date <= endOfSelectedDate;
    });

    events.eventsForSelectedDate = eventsForSelectedDate;
  },
  setUpcomingEvents({ events }, res) {
    events.upcomingEvents = getEventsFromResponse(res);
  },
  setSelectedDate({ events }, selectedDate) {
    events.selectedDate = selectedDate;
  },
};

const getters = {
  contractsActivities: ({ contractsActivities }) => contractsActivities,
  jobsActivities: ({ jobsActivities }) => jobsActivities,
  events: ({ events }) => events,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
