import { getContractRouteToRedirect } from '../routerServices';

const ContractHourly = () => import(/* webpackChunkName: "ContractHourly" */ '@/views/Contract/Hourly');
const ContractHourlyWeeklySummary = () => import(/* webpackChunkName: "ContractHourlyWeeklySummary" */ '@/views/Contract/Hourly/WeeklySummary');
const ContractHourlyWorkDiary = () => import(/* webpackChunkName: "ContractHourlyWorkDiary" */ '@/views/Contract/Hourly/WorkDiary');
const ContractHourlyManualInvoiceRequests = () => import(/* webpackChunkName: "ContractHourlyManualInvoiceRequests" */ '@/views/Contract/Hourly/ManualInvoiceRequests');
const ContractHourlyFinance = () => import(/* webpackChunkName: "ContractHourlyFinance" */ '@/views/Contract/Hourly/Finance');
const ContractHourlyHistory = () => import(/* webpackChunkName: "ContractHourlyHistory" */ '@/views/Contract/Hourly/History');
const ContractHourlyReports = () => import(/* webpackChunkName: "ContractHourlyReports" */ '@/views/Contract/Hourly/Reporting');
const ContractHourlyDisputes = () => import(/* webpackChunkName: "ContractHourlyDisputes" */ '@/views/Contract/Hourly/Disputes');
const ContractHourlyTimeOff = () => import(/* webpackChunkName: "ContractHourlyTimeOff" */ '@/views/Contract/Hourly/TimeOff');

const ContractFixed = () => import(/* webpackChunkName: "FixedContract" */ '@/views/Contract/Fixed');
const ContractFixedMilestones = () => import(/* webpackChunkName: "ContractFixedMilestones" */ '@/views/Contract/Fixed/Milestones');
const ContractFixedScheduledPayments = () => import(/* webpackChunkName: "ContractFixedScheduledPayments" */ '@/views/Contract/Fixed/ScheduledPayments');
const ContractFixedFinance = () => import(/* webpackChunkName: "ContractFixedFinance" */ '@/views/Contract/Fixed/Finance');

export default [
  {
    name: 'hourly-contract',
    path: '/contracts/hourly/:contractId',
    component: ContractHourly,
    redirect: () => ({ name: 'hourly-contract.weekly-summary' }),
    children: [
      {
        name: 'hourly-contract.weekly-summary',
        path: 'weekly-summary',
        component: ContractHourlyWeeklySummary,
      },
      {
        name: 'hourly-contract.work-diary',
        path: 'work-diary',
        component: ContractHourlyWorkDiary,
      },
      {
        name: 'hourly-contract.time-off',
        path: 'time-off',
        component: ContractHourlyTimeOff,
      },
      {
        name: 'hourly-contract.manual-invoices',
        path: 'manual-invoices',
        component: ContractHourlyManualInvoiceRequests,
      },
      {
        name: 'hourly-contract.finance',
        path: 'finance',
        component: ContractHourlyFinance,
      },
      {
        name: 'hourly-contract.history',
        path: 'history',
        component: ContractHourlyHistory,
      },
      {
        name: 'hourly-contract.reporting',
        path: 'reporting',
        component: ContractHourlyReports,
      },
      {
        name: 'hourly-contract.disputes',
        path: 'disputes',
        component: ContractHourlyDisputes,
      },
    ],
  },
  {
    name: 'fixed-contract',
    path: '/contracts/fixed/:contractId',
    component: ContractFixed,
    children: [
      {
        name: 'fixed-contract.milestones',
        path: 'milestones',
        component: ContractFixedMilestones,
      },
      {
        name: 'fixed-contract.scheduled-payments',
        path: 'scheduled-payments',
        component: ContractFixedScheduledPayments,
      },
      {
        name: 'fixed-contract.finance',
        path: 'finance',
        component: ContractFixedFinance,
      },
    ],
  },

  {
    name: 'contract', // Route to redirect to fixed or hourly contract
    path: '/contracts/:contractId',
    beforeEnter: async (to, from, next) => {
      const contractRoute = await getContractRouteToRedirect(to.params?.contractId);
      next({
        query: to.query,
        ...contractRoute,
      });
    },
    children: [
      {
        name: 'contract.outdated-tab', // Route to handle old contract links (will be redirected by root beforeEnter hook)
        path: ':outdatedTab',
      },
    ],
  },
];
