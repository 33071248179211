import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import _includes from 'lodash/includes';

export default (date, options = {}) => {
  const distance = DateTime.fromISO(date).toRelative({ locale: 'en-US', ...options });
  return _includes(distance, 'second') ? 'just now' : distance;
};

export const timeAgoExpanded = (date, includeJustNow = true) => {
  const pastDateM = moment(date);

  const todayM = moment();

  if (pastDateM.isSame(todayM, 'minute') && includeJustNow) {
    return 'just now';
  }

  if (pastDateM.isSame(todayM, 'day')) {
    return pastDateM.format('H:mm A');
  }

  if (pastDateM.isSame(todayM.clone().subtract(1, 'day'), 'day')) {
    return 'yesterday';
  }

  if (pastDateM.isSame(todayM, 'week')) {
    return pastDateM.format('dddd');
  }

  if (pastDateM.isSame(todayM, 'year')) {
    return pastDateM.format('DD MMM');
  }

  return pastDateM.format('DD MMM, YYYY');
};
