import { eventProperties } from '@/services/analytics';
import store from '@/store';

export default function getNotificationLink(notification) {
  const state = {
    name: '',
    params: {},
  };

  let metadata;
  let counter;

  const { permissions } = store.getters;

  const { isFreelancer, user } = permissions;

  switch (notification.type) {
    case 'proposal-created':
      metadata = notification.data.metadata || {};
      counter = metadata.counter || 1;
      if (counter > 1) {
        state.name = 'job-own.pending';
        state.params = {
          jobId: notification.data.jobShortId || notification.data.jobId,
        };
      } else if (isFreelancer) {
        state.name = 'freelancer-job-manager.jobs-applied';
        state.query = { proposalId: notification.data.proposalId };
      } else {
        state.name = 'proposal';
        state.params = { proposalId: notification.data.proposalId };
      }
      break;
    case 'proposal-declined':
    case 'proposal-withdrawn':
    case 'proposal-interview-declined':
    case 'proposal-interview-confirmed':
      if (isFreelancer) {
        state.name = 'freelancer-job-manager.jobs-applied';
        state.query = { proposalId: notification.data.proposalId };
      } else {
        state.name = 'proposal';
        state.params = { proposalId: notification.data.proposalId };
      }
      break;
    case 'proposal-interview-cancelled':
      state.name = 'job-public';
      state.params = { jobId: notification.data.job.shortId, jobSlug: notification.data.job.slug };
      break;
    case 'offer-created':
    case 'offer-declined':
    case 'offer-withdrawn':
      state.name = 'offer';
      state.params = { offerId: notification.data.offerId };
      break;
    case 'offer-accepted':
      state.name = 'contract';
      state.params = { contractId: notification.data.contractId };
      break;
    case 'contract-closed':
      state.name = 'contract';
      state.params = { contractId: notification.data.contractId };
      state.query = { leaveReview: true };
      break;
    case 'contract-paused':
    case 'contract-unpaused':
    case 'service-contract-created-freelancer':
    case 'service-contract-created-client':
      state.name = 'contract';
      state.params = { contractId: notification.data.contractId };
      break;
    case 'payment-request-created':
      if (notification.data.receiverId === user._id) {
        state.name = 'payment-requests-pay';
        state.params = { id: notification.data.paymentRequestId };
      } else {
        state.name = 'gowallet.payment-requests';
        state.query = { paymentRequestId: notification.data.paymentRequestId };
      }
      break;
    case 'payment-request-cancelled':
      if (notification.data.receiverId === user._id) {
        state.name = 'payment-requests-pay';
        state.params = { id: notification.data.paymentRequestId };
      } else {
        state.name = 'gowallet.payment-requests';
        state.query = { paymentRequestId: notification.data.paymentRequestId };
      }
      break;
    case 'payment-request-paid':
      state.name = 'gowallet.payment-requests';
      state.query = { paymentRequestId: notification.data.paymentRequestId };
      break;
    case 'contract-title-changed':
    case 'contract-rate-changed':
    case 'contract-weekly-limit-changed':
    case 'bonus':
      state.name = 'contract';
      state.params = { contractId: notification.data.contract._id };
      break;
    case 'milestone-funded':
    case 'milestone-paid':
    case 'milestone-released':
      state.name = 'fixed-contract.milestones';
      state.params = {
        contractId: notification.data.contract._id,
        milestoneId: notification.data.metadata.milestoneId,
      };
      break;
    case 'milestone-work-submitted':
    case 'milestone-work-declined':
    case 'milestone-added':
    case 'milestone-updated':
    case 'milestone-deleted':
      state.name = 'fixed-contract.milestones';
      state.params = {
        contractId: notification.data.contract._id,
        milestoneId: notification.data.milestone._id,
      };
      break;
    case 'scheduled-payment-rule-created':
    case 'scheduled-payment-rule-updated':
    case 'scheduled-payment-rule-deleted':
      state.name = 'fixed-contract.scheduled-payments';
      state.params = {
        contractId: notification.data.contract._id,
      };
      break;
    case 'scheduled-payment-updated':
    case 'scheduled-payment-deleted':
    case 'scheduled-payment-is-tomorrow':
      state.name = 'fixed-contract.scheduled-payments';
      state.params = {
        contractId: notification.data.contract._id,
        scheduledPaymentId: notification.data.scheduledPayment._id,
      };
      break;
    case 'scheduled-payment-charge-succeeded':
    case 'scheduled-payment-release-succeeded':
      state.name = 'fixed-contract.scheduled-payments';
      state.params = {
        contractId: notification.data.contract._id,
        scheduledPaymentId: notification.data.metadata.scheduledPaymentId,
      };
      break;
    case 'dispute-created':
    case 'dispute-status-changed':
      state.name = 'disputes';
      state.params = { contractId: notification.data.contract._id };
      break;
    case 'dispute-solver-assigned':
      state.name = 'disputes';
      state.params = { contractId: notification.data.contract._id };
      break;
    case 'feedback-completed':
      state.name = 'user';
      state.params = { userId: notification.receiverId };
      break;
    case 'contract-feedback-reminder':
    case 'feedback-expired':
      state.name = 'contract';
      state.params = { contractId: notification.data.contractId };
      break;
    case 'contract-collaborator-added':
      state.name = 'contract';
      state.params = { contractId: notification.data.contractId };
      break;
    case 'contract-collaborator-removed':
      state.name = 'contract';
      state.params = { contractId: notification.data.contractId };
      break;
    case 'contract-review-period-disabled':
    case 'contract-review-period-changed':
      state.name = 'contract';
      state.params = { contractId: notification.data.contractId };
      break;
    case 'user-invited-to-company':
      state.name = 'invitation-to-company';
      state.params = { invitationId: notification.data.invitationId };
      break;
    case 'company-invitation-accepted':
    case 'company-invitation-declined':
      if (isFreelancer) {
        state.name = 'agencies';
      } else {
        state.name = 'companies';
      }
      break;
    case 'referral-signed-up':
      metadata = notification.data.metadata || {};
      counter = metadata.counter || 1;
      if (counter > 1) {
        state.name = 'affiliate-program';
      } else {
        state.name = 'user';
        state.params = { userId: notification.data.referralId };
      }
      break;
    case 'payment-method-status-changed':
    case 'payment-method-added':
    case 'payment-method-deleted':
      state.name = 'gowallet.payment-methods';
      break;
    case 'funds-load-succeeded':
    case 'funds-unload-succeeded':
    case 'peer-to-peer':
    case 'debit-card-negative-balance':
      state.name = 'gowallet';
      break;
    case 'virtual-card-added':
      state.name = 'gowallet.cards';
      break;
    case 'manual-invoice-created':
    case 'manual-invoice-paid':
      state.name = 'hourly-contract.manual-invoices';
      state.params = {
        contractId: notification.data.contractId,
      };
      state.query = {
        invoiceId: notification.data.manualInvoiceId,
      };
      break;
    case 'job-closing-soon':
    case 'job-closed-by-system':
    case 'job-posted':
      state.name = 'job-own.pending';
      state.params = {
        jobId: notification.data.jobShortId,
        jobSlug: notification.data.jobSlug,
      };
      break;
    case 'freelancer-invoice-batch-pdf-created':
      state.name = 'gowallet.invoices';
      break;
    case 'invoice-batch-pdf-created':
    case 'receipt-batch-pdf-created':
    case 'invoice-receipt-batch-pdf-created':
      state.name = 'gowallet.invoices';
      break;
    default:
      state.name = 'home';
      state.query = { notifications: true };
      break;
  }
  state.params = state.params || {};
  state.query = state.query || {};
  state.query.section = eventProperties.section.values.directLink;

  return state;
}
