<template>
  <span
    class="company-logo-wrapper"
    :class="[`company-logo-${size}`, shape, !!sizeStyle ? 'company-logo-custom-size' : '']"
    :style="sizeStyle || {}"
  >
    <img
      :src="thumbnailUrl"
      loading="lazy"
      :class="['company-logo', { 'company-logo-outline': outline }]"
    >
  </span>
</template>

<script>
  import _includes from 'lodash/includes';
  import { defaultCompanyLogoUrl } from '@/services/infrastructure/constants';

  import { getImageThumbnailUrl } from '@/services/cloudinaryImageUrl';

  export default {
    props: {
      url: {
        type: String,
        default: defaultCompanyLogoUrl,
      },
      size: {
        type: [String, Number],
        default: 'sm',
        validator(value) {
          if (Number.isInteger(value)) {
            return true;
          }

          return _includes(['sm', 'md', '16', '20', '24', '32', '38', '44', '48', '52', '140', 'stretch'], value);
        },
      },
      shape: {
        type: String,
        default: 'circle',
        validator(value) {
          return _includes(['circle', 'square'], value);
        },
      },
      outline: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      thumbnailUrl() {
        const size = Number(this.size);

        if (!size) {
          return this.url;
        }

        return getImageThumbnailUrl(this.url, { width: Math.round(size * 1.5) });
      },
      sizeStyle() {
        const size = Number(this.size);

        if (!Number.isInteger(size)) {
          return null;
        }

        return {
          '--image-size': `${size}px`,
        };
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .company-logo-wrapper {
    @apply inline-flex;
    flex-shrink: 0;

    &.square {
      @apply bg-white;
      border-radius: 4px;
      padding: 3px;

      & .company-logo {
        border-radius: 2px;
      }
    }
  }

  .company-logo {
    @apply rounded-full;
    object-fit: cover;
    width: 100%;
  }

  .company-logo-custom-size {
    width: var(--image-size, 16px);
    height: var(--image-size, 16px);
  }

  .company-logo-sm {
    @apply w-7 h-7;
  }

  .company-logo-md {
    @apply w-14 h-14;
  }

  .company-logo-16 {
    width: 16px;
    height: 16px;
  }

  .company-logo-20 {
    width: 20px;
    height: 20px;
  }

  .company-logo-24 {
    width: 24px;
    height: 24px;
  }

  .company-logo-32 {
    width: 32px;
    height: 32px;
  }

  .company-logo-38 {
    width: 38px;
    height: 38px;
  }

  .company-logo-44 {
    width: 44px;
    height: 44px;
  }

  .company-logo-48 {
    width: 48px;
    height: 48px;
  }

  .company-logo-52 {
    width: 52px;
    height: 52px;
    &.square {
      & .company-logo {
        border-radius: 10px;
      }
    }
  }

  .company-logo-140 {
    width: 140px;
    height: 140px;
    &.square {
      & .company-logo {
        border-radius: 5px;
      }
    }
  }

  .company-logo-44,
  .company-logo-48 {
    &.square {
      border-radius: 8px;
      padding: 0;

      & .company-logo {
        border-radius: 8px;
      }
    }
  }

  .company-logo-stretch {
    @apply w-full h-full;
  }

  .company-logo-outline {
    @apply border-3 rounded-full border-white;
  }
</style>
