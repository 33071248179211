<template>
  <div class="thread-preview-context">
    <span class="thread-preview-context__type">{{ threadEntityType }}</span>
    <template v-if="threadEntityTitle">
      <button
        v-if="threadEntityLink"
        :class="[pillColor]"
        class="thread-preview-context__title link"
        @click="handleLinkClick"
      >
        {{ threadEntityTitle }}
      </button>
      <span
        v-else
        :class="[pillColor]"
        class="thread-preview-context__title"
      >
        {{ threadEntityTitle }}
      </span>
    </template>
  </div>
</template>

<script>
  import { ThreadEntityNames } from '@/constants/threads';
  import { getThreadEntityLink } from '@/services/thread';

  import unescapeHtml from '@/services/unescapeHtml';

  export default {
    components: {
    },
    props: {
      entity: {
        type: Object,
        required: true,
      },
      pillColor: {
        type: String,
        default: 'white',
        validator: val => ['white', 'gray'].includes(val),
      },
    },
    computed: {
      threadEntityType() {
        const threadEntityType = ThreadEntityNames[this.entity.type];
        return this.threadEntityTitle ? `${threadEntityType}:` : `${threadEntityType}`;
      },
      threadEntityTitle() {
        return unescapeHtml(this.entity?.title || '');
      },
      threadEntityLink() {
        return getThreadEntityLink(this.entity);
      },
    },
    methods: {
      handleLinkClick() {
        const routeData = this.$router.resolve(this.threadEntityLink);
        window.open(routeData.href, '_blank');
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .thread-preview-context {
    @apply flex items-center justify-start;
    gap: 8px;

    &__type {
      @apply text-text-placeholder text-12;
      line-height: 150%;
      letter-spacing: 0.048px;
    }

    &__title {
      @apply bg-white text-text-black text-14 font-medium truncate;
      border-radius: 20px;
      padding: 2px 8px;
      line-height: 20px;

      &.gray {
        @apply bg-foundation-gray-2;

        &.link:hover, &.link:focus-visible  {
          @apply text-text-hover bg-foundation-gray-4;
          outline: none;
        }
      }

      &.link {
        @apply text-text-link;
        transition: .3s ease;

        &:hover, &:focus-visible {
          @apply text-text-hover bg-foundation-gray-3;
          outline: none;
        }
      }
    }
  }
</style>
