<template>
  <v-popover
    :trigger="trigger"
    :placement="placement"
    :container="container"
    :boundaries-element="boundariesElement"
    :offset="offset"
    :open="isOpen && !hidden"
    :popover-inner-class="popoverInnerClass"
    :popover-base-class="popoverBaseClass"
    :popover-class="popoverClass"
    :disabled="disabled"
    :auto-hide="autoHide"
    class="popover-wrapper"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <div
      class="trigger"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      <slot name="trigger"></slot>
    </div>

    <template slot="popover">
      <div
        class="base-popover"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
      >
        <div
          v-if="title"
          class="header"
        >
          <div class="title">
            {{ title }}
          </div>
          <close-icon
            v-if="closeable"
            v-close-popover
            class="close-icon"
          />
        </div>

        <slot></slot>
      </div>
    </template>
  </v-popover>
</template>

<script>
  import _includes from 'lodash/includes';

  import CloseIcon from '@/components/icons/CloseIcon.vue';

  export default {
    components: {
      CloseIcon,
    },
    props: {
      trigger: {
        type: String,
        default: 'manual',
      },
      delay: {
        type: Number,
        default: 50,
      },
      title: {
        type: String,
        default: '',
      },
      closeable: {
        type: Boolean,
        default: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      autoHide: {
        type: Boolean,
        default: true,
      },
      container: {
        type: String,
        required: true,
      },
      offset: {
        type: [Number, String],
        default: 0,
      },
      popoverInnerClass: {
        type: String,
        default: '',
      },
      popoverBaseClass: {
        type: String,
        default: 'tooltip popover',
      },
      popoverClass: {
        type: String,
        default: 'vue-popover-theme',
      },
      placement: {
        type: String,
        default: 'right',
        validator(value) {
          return _includes(
            ['top', 'right', 'left', 'bottom', 'bottom-end', 'bottom-start'],
            value,
          );
        },
      },
      hidden: {
        type: Boolean,
        default: false,
      },
      boundariesElement: {
        type: String,
        default: '.app-container',
      },
    },
    data() {
      return {
        isOpen: false,
        timeoutId: null,
      };
    },
    methods: {
      handleMouseEnter() {
        if (this.trigger === 'manual') {
          this.handleOpenPopover();
        }
      },
      handleMouseLeave() {
        if (this.trigger === 'manual') {
          this.handleClosePopover();
        }
      },
      handleOpenPopover() {
        if (this.timeoutId) this.removeTimeout();

        this.isOpen = true;
      },
      closePopover() {
        this.removeTimeout();
        this.isOpen = false;
      },
      handleClosePopover() {
        if (this.timeoutId) return;

        this.timeoutId = setTimeout(this.closePopover, this.delay);
      },
      removeTimeout() {
        clearTimeout(this.timeoutId);

        this.timeoutId = null;
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .popover-wrapper {
    @apply flex self-center;
  }

  .base-popover {
    @apply bg-white;
    padding: 12px;
    border-radius: 8px;

    & .close-icon {
      width: 15px;
      height: 15px;
      position: absolute;
      right: 15px;
      top: 15px;
    }

    & .title {
      @apply text-text-black;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }
</style>
