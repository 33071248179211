<template>
  <div
    class="empty-results"
    :class="{ bordered: bordered }"
  >
    <img
      :src="imageSrc"
      :width="imageWidth"
      :height="imageHeight"
      :style="{ width: `${imageWidth}px`, height: `${imageHeight}px` }"
      class="empty-results__image"
      alt="empty-search"
    >
    <div class="empty-results__text">
      <p class="empty-results__title">
        {{ title }}
      </p>
      <p
        class="empty-results__description"
        v-html="description"
      ></p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  const defaultEmptyImageSrc = 'https://res.cloudinary.com/golance/image/upload/v1708079973/Frame_48095741_voo2hg.svg';

  export default {
    props: {
      title: {
        type: String,
        default: 'There are no results',
      },
      description: {
        type: String,
        default: 'We cannot find anything matching your search criteria or filters.',
      },
      imageSrc: {
        type: String,
        default: defaultEmptyImageSrc,
      },
      imageWidth: {
        type: Number,
        default: 108,
      },
      imageHeight: {
        type: Number,
        default: 101,
      },
      bordered: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="postcss" scoped>
.empty-results {
  @apply flex items-center bg-white;
  gap: 32px;
  width: 100%;
  padding: 42px;
  border-radius: 20px;

  &.bordered {
    border-radius: 20px;
    border-width: 1px;
    border-color: #dadce3;
  }

  &__image {
    align-self: start;
  }

  &__text {
    @apply flex flex-col gap-5;
  }

  &__title {
    @apply text-16 font-semibold text-black;
  }

  &__description {
    @apply text-14 text-text-weak;
  }
}

@media (max-width: 567px) {
  .empty-results {
    @apply flex-col items-center justify-center;

    &__image {
      align-self: center;
    }

    &__title, &__description {
      text-align: center;
    }

  }

}
</style>
