<template>
  <div class="input-wrapper">
    <field-label
      v-if="label"
      :for="inputId"
      :text="label"
      :required="required"
      :error="error"
      :note="note"
      :note-container="noteContainer"
    />
    <div
      v-if="description"
      class="input-description"
    >
      {{ description }}
    </div>

    <div :class="['input', { 'input-error': error, 'with-right-icon': $slots['right-icon'], 'with-icon': $slots.icon && !amountMaxButtonVisible, clearable, 'with-counter': showCounter }]">
      <span
        v-if="$slots.icon"
        class="input-icon"
        @click="focus"
      >
        <slot name="icon"></slot>
      </span>
      <input
        v-bind="$attrs"
        :id="inputId"
        ref="input"
        :type="type"
        :placeholder="placeholder"
        :value="value"
        :spellcheck="spellcheck"
        :min="min"
        :max="max"
        :step="step"
        :maxlength="maxlength"
        :readonly="readonly"
        :disabled="disabled"
        :aria-label="ariaLabel || label"
        @input="handleInput"
        @change="$emit('change', $event.target.value)"
        @focusin="$emit('focusin')"
        @focusout="$emit('focusout')"
        @keypress="$emit('keypress', $event)"
        @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)"
        @mouseover="$emit('mouseover', $event)"
        @mouseout="$emit('mouseout', $event)"
        @wheel="handleWheelEvent"
      >
      <slot name="right-icon">
        <icon-button
          v-if="clearable"
          :disabled="disabled"
          class="clearable-button"
          @click="clearInput"
        >
          <close-rounded-icon />
        </icon-button>
      </slot>
      <span
        v-if="showCounter && maxlength"
        class="counter"
        :class="{ oversized: textLength > maxlength }"
      > {{ textLength }}/{{ maxlength }} </span>
    </div>
    <div
      v-if="typeof error === 'string' && error"
      class="error-text"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
  import _uniqueId from 'lodash/uniqueId';

  import htmlText from '@/services/htmlText';

  import FieldLabel from 'uikit/components/FieldLabel.vue';
  import CloseRoundedIcon from '@/components/icons/CloseRoundedIcon.vue';
  import IconButton from './InputIconButton.vue';

  export default {
    components: {
      FieldLabel,
      IconButton,
      CloseRoundedIcon,
    },

    props: {
      value: {
        type: [String, Number],
        default: null,
      },
      type: {
        type: String,
        default: 'text',
      },
      id: {
        type: String,
        default: null,
      },
      spellcheck: {
        type: Boolean,
        default: true,
      },
      error: {
        type: [Boolean, String],
        default: null,
      },
      label: {
        type: String,
        default: null,
      },
      ariaLabel: {
        type: String,
        default: null,
      },
      placeholder: {
        type: String,
        default: null,
      },
      min: {
        type: Number,
        default: null,
      },
      max: {
        type: Number,
        default: null,
      },
      maxlength: {
        type: Number,
        default: null,
      },
      step: {
        type: [String, Number],
        default: null,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      description: {
        type: String,
        default: null,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      showCounter: {
        type: Boolean,
        default: false,
      },
      amountMaxButtonVisible: {
        type: Boolean,
        default: false,
      },
      note: {
        type: String,
        default: '',
      },
      noteContainer: {
        type: String,
        default: 'body',
      },
    },
    data() {
      return {
        inputId: null,
      };
    },

    computed: {
      textLength() {
        return htmlText.countCharacters(this.value);
      },
    },
    created() {
      this.inputId = this.id || _uniqueId('input_');
    },

    methods: {
      selectInput() {
        this.$refs.input.select();
      },
      focus() {
        this.$refs.input.focus();
      },
      blur() {
        this.$refs.input.blur();
      },
      clearInput() {
        this.$emit('input', '');
        this.$emit('change', '');
        this.$emit('blur');
      },
      async handleInput(e) {
        if (this.type !== 'text') {
          this.$emit('input', e.target.value);
          return;
        }

        const inputRef = this.$refs.input;
        const cursorPosition = inputRef.selectionStart;
        this.$emit('input', e.target.value);

        if (this.type !== 'text') return;

        await this.$nextTick();
        inputRef.setSelectionRange(cursorPosition, cursorPosition);
      },
      handleWheelEvent(ev) {
        if (this.type !== 'number' || ev.target !== document.activeElement) return;

        ev.target.blur();

        setTimeout(() => {
          ev.target.focus();
        }, 0);
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .input-wrapper {
    @apply text-12 font-primary text-text-black;

    & .input {
      @apply w-full text-14 flex flex-1 items-center text-text-black relative;
      font-weight: 500;

      & > input {
        @apply w-full border border-foundation-gray-4;
        height: 40px;
        padding: 8px 12px;
        line-height: 20px;
        border-radius: 8px;
        box-sizing: border-box;
        transition: 0.2s ease border;

        &:not(:disabled):not(:focus):hover {
          @apply border-foundation-gray-6;
        }

        &:focus {
          @apply border-royal-blue;
          outline: none;

          & + .clearable-button {
            @apply text-icon-hover;
          }
        }

        &:disabled {
          @apply bg-foundation-gray-3 border-foundation-gray-5 text-text-placeholder;
          pointer-events: none;

          & + .clearable-button {
            cursor: default;
          }
        }

        &::placeholder {
          @apply font-primary text-14 font-normal text-text-placeholder opacity-100;
        }
      }

      & .input-icon {
        @apply absolute inline-block text-icon-default;
        height: 16px;
        width: 16px;
        left: 13px;
      }

      &.with-icon {
        & > input {
          padding-left: 40px;
        }
      }

      &.clearable > input,
      &.with-right-icon > input {
        padding-right: 40px;
      }

      &.with-counter > input {
        padding-right: 64px;
      }

      &.input-error {
        & > input,
        & > input:hover {
          @apply border-persian-red !important;
        }
      }
    }
  }

  .clearable-button {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 16px;
    height: 16px;
  }

  .counter {
    @apply text-text-placeholder absolute;
    top: 12px;
    right: 12px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.004em;

    &.oversized {
      @apply text-text-error;
    }
  }

  .input-description {
    @apply font-primary text-comet block text-12 mb-2;
  }

  .error-text {
    @apply font-primary text-text-error text-12 mt-1;
    width: 100%;
    min-width: 150px;
    line-height: 18px;

    @media (max-width: 490px) {
      min-width: 100%;
    }
  }
</style>
