import { UserRoles } from '@/constants/user';

const AuthPages = () => import(/* webpackChunkName: "AuthPages", webpackPrefetch: true */ '@/views/AuthPages');
const SignUpChooseType = () => import(/* webpackChunkName: "SignUpChooseType", webpackPrefetch: true */ '@/views/AuthPages/SignUpChooseType');
const SignUp = () => import(/* webpackChunkName: "SignUp", webpackPrefetch: true */ '@/views/AuthPages/SignUp');
const SignUpSuccess = () => import(/* webpackChunkName: "SignUp", webpackPrefetch: true */ '@/views/AuthPages/SignUpSuccess');
const SignUpClientUsageReason = () => import(/* webpackChunkName: "SignUpClientUsageReason", webpackPrefetch: true */ '@/views/AuthPages/SignUpClientUsageReason');
const LoginPage = () => import(/* webpackChunkName: "Login", webpackPrefetch: true */ '@/views/AuthPages/LoginPage');
const ResetPassword = () => import(/* webpackChunkName: "ResetPassword", webpackPrefetch: true */ '@/views/AuthPages/ResetPassword');
const ForgotPassword = () => import(/* webpackChunkName: "ForgotPassword", webpackPrefetch: true */ '@/views/AuthPages/ForgotPassword');

export default [
  {
    path: '/auth-page', // This url will never be called
    component: AuthPages,
    meta: {
      hideFooter: true,
      hideHeader: true,
      requiresAuth: false,
    },
    redirect: to => ({ name: 'login', query: to.query }),
    children: [
      {
        name: 'sign-up',
        path: '/signup',
        component: SignUpChooseType,
        beforeEnter(to, from, next) {
          if ([UserRoles.Employer, UserRoles.Freelancer].includes(to.query.type)) {
            const routeRoleMap = {
              [UserRoles.Employer]: 'sign-up-client-usage-reason',
              [UserRoles.Freelancer]: 'sign-up-freelancer',
            };
            return next({
              name: routeRoleMap[to.query.type],
              query: to.query,
            });
          }
          next();
        },
      },
      {
        name: 'sign-up-client',
        path: '/signup/client',
        props: {
          role: UserRoles.Employer,
        },
        component: SignUp,
      },
      {
        name: 'sign-up-freelancer',
        path: '/signup/freelancer',
        props: {
          role: UserRoles.Freelancer,
        },
        component: SignUp,
      },
      {
        name: 'sign-up-success',
        path: '/signup/success/:userId',
        props: route => ({
          confirmUrl: route.query.confirmUrl ? decodeURIComponent(route.query.confirmUrl) : null,
          userId: route.params.userId,
          email: route.params.email,
        }),
        component: SignUpSuccess,
      },
      {
        name: 'sign-up-client-usage-reason',
        path: '/signup/client/usage-reason',
        component: SignUpClientUsageReason,
      },
      {
        name: 'login',
        path: '/login',
        component: LoginPage,
      },
      {
        name: 'forgot-password',
        path: '/forgot-password',
        component: ForgotPassword,
      },
      {
        name: 'reset-password',
        path: '/reset-password',
        component: ResetPassword,
        beforeEnter: (to, from, next) => {
          if (!to.query.token) {
            return next({ name: 'forgot-password' });
          }

          next();
        },
        props: route => ({ token: route.query.token }),
      },
    ],
  },
];
