var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.text)?_c('label',{staticClass:"input-label"},[_vm._v("\n  "+_vm._s(_vm.text)+"\n  "),(_vm.required)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e(),_vm._v(" "),(_vm.note)?_c('gl-base-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.note,
      container: _vm.noteContainer,
      classes: 'tooltip-white',
      trigger: 'hover',
      offset: 5,
      position: 'top-end',
      html: true,
    }),expression:"{\n      content: note,\n      container: noteContainer,\n      classes: 'tooltip-white',\n      trigger: 'hover',\n      offset: 5,\n      position: 'top-end',\n      html: true,\n    }"}],attrs:{"name":"filled-rounded-info","size":"12","color":"#6F7689"}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }