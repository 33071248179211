<template>
  <div class="textarea-wrapper">
    <field-label
      v-if="label"
      class="textarea-label"
      :required="required"
      :text="label"
    />

    <textarea
      ref="textarea"
      :rows="rows"
      :placeholder="placeholder"
      :value="value"
      :class="['textarea', { 'textarea-error': error, 'input-error': error, 'auto-resizible': isAutoResizible }]"
      @input="handleInput"
      @keypress.enter="$emit('keypress', $event)"
      @blur="$emit('blur')"
      @change="$emit('change', $event)"
    >
    </textarea>
    <field-validation-error
      v-if="error && typeof error === 'string'"
      :error="error"
      class="mt-1"
    />
  </div>
</template>

<script>
  import FieldLabel from 'uikit/components/FieldLabel.vue';
  import FieldValidationError from '@/components/FieldValidationError.vue';

  export default {
    components: {
      FieldValidationError,
      FieldLabel,
    },
    props: {
      value: {
        type: [String, Number],
        default: null,
      },
      error: {
        type: [Boolean, String],
        default: null,
      },
      label: {
        type: String,
        default: null,
      },
      placeholder: {
        type: String,
        default: null,
      },
      rows: {
        type: String,
        default: '1',
      },
      isAutoResizible: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      async handleInput({ target }) {
        this.$emit('input', target.value);
        if (this.isAutoResizible) {
          await this.$nextTick();
          target.style.height = 'auto';
          target.style.height = `${target.scrollHeight}px`;
        }
      },
      focus() {
        this.$refs.textarea.focus();
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .textarea-wrapper {
    @apply text-14 font-primary text-text-black;
    & .textarea-label {
      @apply text-text-black font-normal block;
      margin-bottom: 6px;
      max-width: 100%;
    }
    & .textarea {
      @apply flex flex-1 items-center w-full border border-foundation-gray-4;
      transition: 0.2s ease border;
      padding: 12px;
      line-height: 18px;
      border-radius: 8px;
      &:hover {
        @apply border-foundation-gray-6;
      }
      &:focus {
        @apply border-royal-blue;
        outline: none;
      }
      &::placeholder {
        @apply font-primary text-text-placeholder opacity-100;
      }
    }
    & .textarea.textarea-error {
      @apply border-red;
      &:focus {
        @apply border-red;
      }
      &:hover {
        @apply border-red;
      }
    }
    & .auto-resizible {
      min-height: 42px;
      overflow-y: hidden;
    }

    @media screen and (max-width: 412px) {
      & .auto-resizible {
        min-height: 94px;
      }
    }
    @media screen and (max-width: 351px) {
      & .auto-resizible {
        min-height: 118px;
      }
    }
  }
</style>
