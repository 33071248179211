export default function create({ rates, conversionConfig }) {
  const { conversionRateBuffer } = conversionConfig;

  return {
    convert({ amount, sourceCurrency, targetCurrency }) {
      if (sourceCurrency === targetCurrency) {
        return amount;
      }

      if (!rates) {
        return 0;
      }

      const sourceRate = rates[sourceCurrency]?.[targetCurrency];

      if (!sourceRate) {
        return 0;
      }

      const rateBuffer = conversionRateBuffer[sourceCurrency] ?? conversionRateBuffer.default;

      const rate = sourceRate.rate * (1 - (rateBuffer / 100));

      return amount * rate;
    },
  };
}
